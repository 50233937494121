import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { withOktaAuth } from '@okta/okta-react'
import { AuthService, AuthState } from '../../../models/Auth'
import { TeacherAccessLevel, TeacherCreateDto } from '../../../models/Teacher'
import { addTeacher } from '../../../services/TeacherApi'
import { Button, Alert } from 'reactstrap'
import { FiHelpCircle } from 'react-icons/fi'
import BaseClass from '../../Bases/BaseClass'

import BreadCrumbs from '../../layout/BreadCrumbs'
import { isTeacherAdminOrSuper } from '../../../shared/Helper'
import { IBaseState } from '../../Bases/BaseState'
import { ISessionData } from '../../../AppRouter'

interface IParams {
    schoolId: string
}

interface IProps extends RouteComponentProps<IParams> {
    // baseUrl: string;
    authService: AuthService
    authState: AuthState
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
    history: any
    match: any
}

interface IState extends IBaseState {
    teacher: TeacherCreateDto
    isDataLoading: boolean
}

class TeacherCreate extends BaseClass<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            isLoading: false,
            error: '',
            visible: false,
            //   authenticatedTeacher: null,
            teacher: {
                firstname: '',
                lastname: '',
                email: '',
                accessLevel: TeacherAccessLevel.Standard.toString(),
                teacherSettings: null,
            },
            isDataLoading: false,
        }
    }

    componentDidMount() {
        this.loadData()
    }

    async loadData() {
        this.props.setIsDataLoading(true)
        // await super.getAuthenticatedTeacher(
        //   this.props.authState.accessToken.accessToken
        // );
        this.props.setIsDataLoading(false)
        this.props.setIsDataLoading(false)
    }

    handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            teacher: {
                ...this.state.teacher,
                [e.target.id]: e.target.value,
            },
        })
    }

    onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (
            !this.state.teacher.firstname ||
            !this.state.teacher.lastname ||
            !this.state.teacher.email
        ) {
            return
        }

        let token = await this.props.authState.accessToken.accessToken

        this.props.setIsDataLoading(true)

        return addTeacher(this.props.sessionData.selectedSchool.id, this.state.teacher, token)
            .then((res) => {
                if (res.ok) {
                    this.props.setIsDataLoading(false)
                    this.goBack()
                } else {
                    this.handleknownError(res.error)
                }
            })
            .catch((error) => {
                this.handleUnknownError(error)
            })
    }

    goBack = () => {
        this.props.history.push(`/admin/teacher/${this.props.sessionData.selectedSchool.id}`)
    }

    render() {
        //    // if (this.props.isDataLoading) return null;

        return (
            <div>
                <div className="mb-2">
                    <BreadCrumbs
                        items={[
                            { title: 'Home', link: '/' },
                            {
                                title: 'Admin',
                                link: `/admin/select/${this.props.sessionData.selectedSchool.id}`,
                            },
                            {
                                title: 'Teacher',
                                link: `/admin/teacher/${this.props.sessionData.selectedSchool.id}`,
                            },
                            { title: 'Create Teacher', link: '' },
                        ]}
                    />
                </div>
                <h2 className="pb-2">Create Teacher</h2>
                <p className="font-weight-light">
                    Create an account for a teacher here. Their login details will be emailed to
                    them automatically.
                </p>
                <div className="row">
                    <div className="col-md-5">
                        <form onSubmit={this.onSubmit}>
                            <div className="form-group">
                                <label htmlFor="firstname">First Name: </label>
                                <input
                                    type="text"
                                    id="firstname"
                                    className="form-control"
                                    onChange={this.handleOnChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastname">Last Name: </label>
                                <input
                                    type="text"
                                    id="lastname"
                                    className="form-control"
                                    onChange={this.handleOnChange}
                                    required
                                />
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="email">Email: </label>
                                <input
                                    type="email"
                                    id="email"
                                    className="form-control"
                                    onChange={this.handleOnChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <div
                                    id="selectAdmin"
                                    className="custom-control mb-4"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    data-delay="0"
                                    title="Teachers with Admin access can create new teachers, classes, and purchase new licences"
                                >
                                    <input
                                        type="checkbox"
                                        checked={isTeacherAdminOrSuper(
                                            this.state.teacher.accessLevel
                                        )}
                                        className="form-check-input"
                                        id="admin"
                                        onChange={(e) =>
                                            this.setState({
                                                teacher: {
                                                    accessLevel: e.target.checked
                                                        ? TeacherAccessLevel[
                                                              TeacherAccessLevel.Admin
                                                          ].toString()
                                                        : TeacherAccessLevel[
                                                              TeacherAccessLevel.Standard
                                                          ].toString(),
                                                    firstname: this.state.teacher.firstname,
                                                    lastname: this.state.teacher.lastname,
                                                    email: this.state.teacher.email,
                                                    teacherSettings:
                                                        this.state.teacher.teacherSettings,
                                                },
                                            })
                                        }
                                    ></input>
                                    <label className="form-check-label" htmlFor="admin">
                                        {' '}
                                    </label>
                                    Give Admin access <FiHelpCircle></FiHelpCircle>
                                </div>
                            </div>

                            {this.state.visible ? (
                                <Alert color="warning">
                                    <p>{this.state.error}</p>
                                </Alert>
                            ) : null}

                            <div className="form-group">
                                <div className="space-between">
                                    <Button
                                        color="secondary"
                                        tag={Link}
                                        to={`/admin/teacher/${this.props.sessionData.selectedSchool.id}`}
                                        disabled={this.state.isLoading}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        id="create"
                                        className="text-nowrap"
                                        color="primary"
                                        type="submit"
                                        disabled={this.state.isLoading}
                                    >
                                        {this.state.isLoading ? (
                                            <>
                                                <i className="fa fa-spinner fa-spin" />{' '}
                                            </>
                                        ) : null}
                                        Create teacher and send email
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default withOktaAuth(TeacherCreate)
