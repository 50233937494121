/* eslint-disable no-unused-vars */
import { QuestionDto } from './Question'

export interface DiagnosticDto {
    id: string
    name: string
    createdDate: string
    createdDateUtcEpochTicks: number
    startedDate: string
    finishedDate: string
}

export interface DiagnosticCreateDto {
    name: string
    schoolClassId: string
    studentItems: StudentItem[]
    diagnosticType: string
}

export enum AssessmentType {
    KeyConcept,
    Sampling,
}

export interface StudentItem {
    studentId: string
    items: any[]
}

export interface StudentKeyConcept {
    studentId: string
    keyConcepts: any[]
}

export interface StudentSamplingAssessment {
    studentId: string
    samplingAssessments: any[]
}

export interface DiagnosticUpdateDto {
    id: string
    name: string
}

export interface AddStudentsToDiagnosticDto {
    diagnosticId: string
    studentItemsToAdd: StudentItem[]
}

export interface DiagnosticStopDto {
    id: string
    schoolClassId: string
}

export interface StudentDiagnosticDto {
    studentId: string
    firstName: string
    lastName: string
    username: string
    password: string
    diagnosticId: string
    diagnosticName: string
    startedDate: string
    startedOnlineTest: boolean
    finishedDate: string
    hasInterviewQuestion: boolean
    hasAnyQuestion: boolean
    keyConceptIds: string[]
    samplingAssessmentIds: string[]
    timeFormatted: string
    showLogInDetails: boolean
    isStudentLicenced: boolean
    schoolClassFormattedName: string
    note: string
    stoppedByTeacherId: string
}

export interface StudentDiagnosticUpdateDto {
    diagnosticId: string
    studentId: string
}

export interface StudentDiagnosticFinishDto {
    diagnosticId: string
    studentId: string
    questions: InterviewQuestionDto[]
    note: string
}

export interface InterviewQuestionDto {
    questionId: string
    correct: boolean
}

export interface KeyConceptInterviewGroup {
    keyConceptName: string
    assets: string[]
    interviewGroupQuestions: InterviewGroupQuestion[]
}

export interface InterviewGroupQuestion {
    questionText: string
    questions: StudentDiagnosticInterviewQuestionDto[]
}

export interface StudentDiagnosticInterviewQuestionDto {
    studentId: string
    questionId: string
    diagnosticId: string
    questionText: string
    questionDisplayText: string
    questionNumber: number
    questionAnswer: string
    answeredDate: string
    correct: boolean
    assets: string[]
    knowns: string[]
    notKnowns: string[]
    note: string
}

export interface StudentDiagnosticQuestionDto {
    studentId: string
    questionId: string
    diagnosticId: string
    question: QuestionDto
    startedDate: string
    answeredDate: string
    correct: boolean
    studentAnswer: string
    screenShotUri: string
    audioAnswerUri: string
    teacherOverride: boolean
    studentAnswerDescription: string
}

export interface StudentDiagnosticResultDto {
    studentDiagnosticDto: StudentDiagnosticDto
    questionsDtos: StudentDiagnosticQuestionDto[]
}

export interface StudentDiagnosticInterviewDto {
    studentDiagnosticDto: StudentDiagnosticDto
    questionsDtos: StudentDiagnosticInterviewQuestionDto[]
    interviewGroupQuestions: InterviewGroupQuestion[]
}

export interface DiagnosticEstimateDto {
    numberOfInterviews: number
    schoolClassCompletionHighTimeFormatted: string
    schoolClassCompletionLowTimeFormatted: string
    schoolClassCompletionTimeRangeFormatted: string
    studentEstimates: StudentEstimateDto[]
}

export interface StudentEstimateDto {
    studentId: string
    timeFormatted: string
    hasInterview: boolean
}

export enum DiagnosticType {
    Sampling = 'Sampling',
    KeyConcept = 'KeyConcept',
}
