import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Button, Alert } from 'reactstrap'
import { addBulkSchoolClasses } from '../../../services/SchoolClassApi'
import BreadCrumbs from '../../layout/BreadCrumbs'
import DemoPopovers from '../../layout/DemoPopovers'
import { ISessionData } from '../../../AppRouter'
import { useOktaAuth } from '@okta/okta-react'

interface IParams {
    schoolId: string
}

interface IProps extends RouteComponentProps<IParams> {
    // baseUrl: string;
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
    history: any
}

interface IState {
    selectedFile: any
    selectedFileName: string
    isDataLoading: boolean
}

export default function SchoolBulkRollover(props: IProps) {
    const [error, setError] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null)
    const [selectedFileName, setSelectedFileName] = useState('filename...')
    const [isSaving, setIsSaving] = useState(false)
    const { authState, authService } = useOktaAuth()

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        props.setIsDataLoading(false)
    }

    const handleFileSelect = (e: any) => {
        console.log(e.target.files[0])
        setSelectedFile(e.target.files[0])
        setSelectedFileName(e.target.files[0].name)
    }

    const onSubmit = async (e: any) => {
        props.setIsDataLoading(true, ['Please wait while we process your data...'])

        setIsSaving(true)

        e.preventDefault()
        console.log(selectedFile)
        const formData = new FormData()

        formData.append('file', selectedFile)

        setError(null)

        return addBulkSchoolClasses(
            props.sessionData.selectedSchool.id,
            formData,
            authState.accessToken.accessToken
        )
            .then((res) => {
                setIsSaving(false)
                props.setIsDataLoading(false)
                if (res.ok) {
                    goBack()
                } else {
                    // handleknownError(res.error)
                }
            })
            .catch((error) => {
                //handleUnknownError(error)
            })
    }

    const goBack = () => {(props.history as any).goBack()}

    return (
        <div data-cy="school-bulk-rollover-page-loaded">
            {props.sessionData.authenticatedTeacher.isDemo && (
                <DemoPopovers items={[{ target: 'upload', placement: 'right' }]} />
            )}

            <div className="mb-2">
                <div>
                    <BreadCrumbs
                        items={[
                            { title: 'Home', link: '/' },
                            {
                                title: 'Admin',
                                link: `/admin/select/${props.sessionData.selectedSchool.id}`,
                            },
                            { title: 'Bulk School Rollover', link: '' },
                        ]}
                    />
                </div>
                <h2 className="pb-2">Bulk School Rollover</h2>
                <p className="font-weight-light">
                    1. Download the sample spreadsheet below.
                    <br />
                    2. Enter your class details. You can add teachers to their classes now, or do it
                    later.
                    <br />
                    3. Save the spreadsheet to your computer.
                    <br />
                    4. Use the &apos;Browse&apos; button to upload the spreadsheet.
                    <br />
                    <br />
                </p>
            </div>
            {error ? (
                <Alert color="warning">
                    <p>{error}</p>
                </Alert>
            ) : null}
            <div className="row">
                <div className="col-md-7">
                    <form onSubmit={onSubmit}>
                        <label htmlFor="email">1. Download and edit student data </label>
                        <div className="mb-3">
                            <Button
                                color="success"
                                rel="noopener noreferrer"
                                href="https://tridentazurestorage.blob.core.windows.net/files/SchoolClassImport.xlsx"
                                target="_blank"
                                disabled={isSaving}
                            >
                                {isSaving ? <i className="fa fa-spinner fa-spin" /> : null} Download
                                spreadsheet
                            </Button>
                        </div>
                        <div className="form-group mb-4">
                            <label htmlFor="email">2. Enter your email </label>
                            <input type="email" id="email" className="form-control" required />
                        </div>
                        <div className="form-group mb-4">
                            <label htmlFor="email">3. Set a rollover date</label>
                            <input
                                type="date"
                                id="rollover-date"
                                className="form-control"
                                required
                            />
                        </div>
                        <label htmlFor="email">4. Upload spreadsheet</label>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupFileAddon01">
                                    Select excel file
                                </span>
                            </div>
                            <div className="custom-file">
                                <input
                                    type="file"
                                    className="custom-file-input"
                                    id="exampleFormControlFile1"
                                    aria-describedby="inputGroupFileAddon01"
                                    onChange={handleFileSelect}
                                />
                                <label
                                    className="custom-file-label"
                                    htmlFor="exampleFormControlFile1"
                                >
                                    {selectedFileName}
                                </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="space-between">
                                <Button color="secondary" onClick={goBack} disabled={isSaving}>
                                    Cancel
                                </Button>
                                <Button
                                    id="upload"
                                    color="primary"
                                    type="submit"
                                    disabled={isSaving || selectedFile === null}
                                >
                                    {isSaving ? <i className="fa fa-spinner fa-spin" /> : null}{' '}
                                    Rollover School
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
