import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { withOktaAuth } from '@okta/okta-react'
import { Button, Alert } from 'reactstrap'
import { AuthService, AuthState } from '../../../models/Auth'
import { addBulkSchoolClasses } from '../../../services/SchoolClassApi'
import BaseClass from '../../Bases/BaseClass'
import BreadCrumbs from '../../layout/BreadCrumbs'
import DemoPopovers from '../../layout/DemoPopovers'
import { IBaseState } from '../../Bases/BaseState'
import { ISessionData } from '../../../AppRouter'

interface IParams {
    schoolId: string
}

interface IProps extends RouteComponentProps<IParams> {
    authService: AuthService
    authState: AuthState
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
    history: any
}

interface IState extends IBaseState {
    selectedFile: any
    selectedFileName: string
    isDataLoading: boolean
}

class SchoolClassCreateBulk extends BaseClass<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            isLoading: false,
            error: '',
            visible: false,
            //authenticatedTeacher: null,
            selectedFile: null,
            selectedFileName: 'filename...',
            isDataLoading: true,
        }
    }

    componentDidMount() {
        this.loadData()
    }

    loadData() {
        this.props.setIsDataLoading(false)
    }

    handleFileSelect = (e: any) => {
        console.log(e.target.files[0])
        this.setState({
            selectedFile: e.target.files[0],
            selectedFileName: e.target.files[0].name,
        })
    }

    onSubmit = async (e: any) => {
        this.props.setIsDataLoading(true, ['Please wait while your school classes are created...'])

        e.preventDefault()
        const formData = new FormData()

        formData.append('file', this.state.selectedFile)
        let token = this.props.authState.accessToken.accessToken

        this.setState({ visible: false })

        return addBulkSchoolClasses(this.props.sessionData.selectedSchool.id, formData, token)
            .then((res) => {
                this.props.setIsDataLoading(false)
                if (res.ok) {
                    this.goBack()
                } else {
                    this.handleknownError(res.error)
                }
            })
            .catch((error) => {
                this.handleUnknownError(error)
            })
    }

    goBack = () => {
        ;(this.props.history as any).goBack()
    }

    render() {
        //  // if (this.props.isDataLoading) return null;

        return (
            <>
                {this.props.sessionData.authenticatedTeacher.isDemo && (
                    <DemoPopovers items={[{ target: 'upload', placement: 'right' }]}></DemoPopovers>
                )}

                <div className="mb-2">
                    <div>
                        <BreadCrumbs
                            items={[
                                { title: 'Home', link: '/' },
                                {
                                    title: 'Admin',
                                    link: `/admin/select/${this.props.sessionData.selectedSchool.id}`,
                                },
                                {
                                    title: 'Classes',
                                    link: `/admin/class/${this.props.sessionData.selectedSchool.id}`,
                                },
                                { title: 'Bulk Upload Classes', link: '' },
                            ]}
                        />
                    </div>
                    <h2 className="pb-2">Bulk Upload Classes</h2>
                    <p className="font-weight-light">
                        1. Download the sample spreadsheet below.
                        <br></br>
                        2. Enter your class details. You can add teachers to their classes now, or
                        do it later.
                        <br></br>
                        3. Save the spreadsheet to your computer.
                        <br></br>
                        4. Use the &apos;Browse&apos; button to upload the spreadsheet.
                        <br></br>
                        <br></br>
                    </p>
                </div>
                {this.state.visible ? (
                    <Alert color="warning">
                        <p>{this.state.error}</p>
                    </Alert>
                ) : null}
                <div className="row">
                    <div className="col-md-7">
                        <form onSubmit={this.onSubmit}>
                            <div className="mb-3">
                                <Button
                                    color="success"
                                    rel="noopener noreferrer"
                                    href="https://tridentazurestorage.blob.core.windows.net/files/SchoolClassImport.xlsx"
                                    target="_blank"
                                    disabled={this.state.isLoading}
                                >
                                    {this.state.isLoading ? (
                                        <i className="fa fa-spinner fa-spin" />
                                    ) : null}{' '}
                                    Download Sample
                                </Button>
                            </div>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupFileAddon01">
                                        Select excel file
                                    </span>
                                </div>
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        id="exampleFormControlFile1"
                                        aria-describedby="inputGroupFileAddon01"
                                        onChange={this.handleFileSelect}
                                    />
                                    <label
                                        className="custom-file-label"
                                        htmlFor="exampleFormControlFile1"
                                    >
                                        {this.state.selectedFileName}
                                    </label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="space-between">
                                    <Button
                                        color="secondary"
                                        onClick={this.goBack}
                                        disabled={this.state.isLoading}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        id="upload"
                                        color="primary"
                                        type="submit"
                                        disabled={
                                            this.state.isLoading || this.state.selectedFile === null
                                        }
                                    >
                                        {this.state.isLoading ? (
                                            <i className="fa fa-spinner fa-spin" />
                                        ) : null}{' '}
                                        Upload Classes
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}
export default withOktaAuth(SchoolClassCreateBulk)
