import React from 'react'
import { Popover, PopoverBody } from 'reactstrap'
import { ISessionData } from '../../AppRouter'
import { TeacherDto } from '../../models/Teacher'

interface IProps {
    items: any
    // history: any;
    // sessionData: ISessionData;
}

interface IState {
    isPopoverOpen: boolean
}

export default class DemoPopovers extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            isPopoverOpen: false,
        }
    }

    componentDidMount() {
        this.loadData()
    }

    // componentDidUpdate() {
    //   this.props.sessionData && this.loadData();
    // }

    loadData() {
        setTimeout(() => {
            if (!this.state.isPopoverOpen) {
                this.setState({ isPopoverOpen: true })
            }
        }, 1000)
    }

    render() {
        //if (!this.props.sessionData) return null;

        // const authenticatedTeacher = this.props.sessionData.authenticatedTeacher;

        return (
            <>
                {this.props.items &&
                    this.props.items.map((item: any, index: number) => {
                        return (
                            <Popover
                                key={index}
                                style={{ width: '200px' }}
                                placement={item.placement ? item.placement : 'top'}
                                isOpen={this.state.isPopoverOpen}
                                target={item.target}
                            >
                                <PopoverBody className="text-center">
                                    {item.message ? (
                                        item.message
                                    ) : (
                                        <>This feature isn&apos;t available for trial accounts.</>
                                    )}
                                </PopoverBody>
                            </Popover>
                        )
                    })}
            </>
        )
    }
}
