import { UrlApiMap } from '../models/UrlApiMap'

const urlApiMap: UrlApiMap[] = [
    {
        name: 'local',
        url: 'http://localhost:3000/',
        // teacherApiUrl: 'https://localhost:5001/',
        teacherApiUrl: 'https://teacherbackendtesting.azurewebsites.net/',

        internalApiUrl: 'https://internalbackendtesting.azurewebsites.net/',
    },
    {
        name: 'local',
        url: 'https://localhost:5001/',
        teacherApiUrl: 'https://localhost:5001/',
        internalApiUrl: 'https://localhost:5005/',
    },
    {
        name: 'client-test',
        url: 'https://teacherclienttesting.z26.web.core.windows.net/',
        teacherApiUrl: 'https://teacherbackendtesting.azurewebsites.net/',
        internalApiUrl: 'https://internalbackendtesting.azurewebsites.net/',
    },
    {
        name: 'client-demo',
        url: 'https://teacherclientdemo.z8.web.core.windows.net/',
        teacherApiUrl: 'https://teacherbackenddemo.azurewebsites.net/',
        internalApiUrl: 'https://internalbackenddemo.azurewebsites.net/',
    },
    {
        name: 'client-staging',
        url: 'https://teacherclientstaging.z26.web.core.windows.net/',
        teacherApiUrl: 'https://teacherbackendproduction-staging.azurewebsites.net/',
        internalApiUrl: 'https://internalbackendproduction.azurewebsites.net/',
    },
    {
        name: 'client-origin',
        url: 'https://teacherclientproduction.z26.web.core.windows.net/',
        teacherApiUrl: 'https://teacherbackendproduction.azurewebsites.net/',
        internalApiUrl: 'https://internalbackendproduction.azurewebsites.net/',
    },
    {
        name: 'client-cdn',
        url: 'https://teacherclientproductioncdn.azureedge.net/',
        teacherApiUrl: 'https://teacherbackendproduction.azurewebsites.net/',
        internalApiUrl: 'https://internalbackendproduction.azurewebsites.net/',
    },
    {
        name: 'client-production',
        url: 'https://portal.mathspathway.com/',
        teacherApiUrl: 'https://teacherbackendproduction.azurewebsites.net/',
        internalApiUrl: 'https://internalbackendproduction.azurewebsites.net/',
    },
]

export const getUrlApiMap = () => {
    let currentUrl = window.location.href.toLowerCase()
    let map = urlApiMap.find((val) => currentUrl.includes(val.url.toLowerCase()))

    if (!map) {
        alert('no api map found')

        return
    }

    return map
}

export const getInternalApiUrl = () => {
    return getUrlApiMap().internalApiUrl
}

export const getTeacherApiUrl = () => {
    return getUrlApiMap().teacherApiUrl
}

export const diagnosticApiUrl = '/api/diagnostic'

export const studentDiagnosticApiUrl = '/api/studentDiagnostic'
export const startStudentDiagnosticApiUrl = '/api/startStudentDiagnostic'

export const studentDiagnosticQuestionApiUrl = '/api/studentdiagnosticquestion'

export const studentApiUrl = '/api/student'

export const spacedPracticeStudentSessionQuestionApiUrl =
    '/api/spacedPracticeStudentSessionQuestion'

export const spacedPracticeApiUrl = '/api/spacedPractice'

export const studentReportApiUrl = '/api/studentReport'

export const schoolClassApiUrl = '/api/schoolClass'

export const schoolApiUrl = '/api/school'

export const teacherApiUrl = '/api/teacher'

export const orderApiUrl = '/api/order'

export const groupApiUrl = '/api/group'

export const curriculumApiUrl = '/api/curriculum'

export const monteCarloDiagnosticApiUrl = '/api/monteCarloDiagnostic'

export const testSupportApiUrl = '/api/testsupport'

export const teacherBaseApiUrl = getTeacherApiUrl()

export const internalBaseApiUrl = getInternalApiUrl()
