import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { SchoolClassDto } from '../../../models/SchoolClass'
import { AuthService, AuthState } from '../../../models/Auth'
import { withOktaAuth } from '@okta/okta-react'
import { getCalendarYearLevels, getSchoolClassLevels } from '../../../shared/Helper'
import {
    updateSchoolClass,
    getSchoolClassFromApi,
    getSchoolClassesFromApi,
} from '../../../services/SchoolClassApi'
import { Button, Alert } from 'reactstrap'
import Select from 'react-select'
import { TeacherDto } from '../../../models/Teacher'
import { getTeachers } from '../../../services/TeacherApi'
import BaseClass from '../../Bases/BaseClass'
import BreadCrumbs from '../../layout/BreadCrumbs'
import { IBaseState } from '../../Bases/BaseState'
import { ISessionData } from '../../../AppRouter'

interface IParams {
    schoolClassId: string
    schoolId: string
}

interface IProps extends RouteComponentProps<IParams> {
    // baseUrl: string;
    authService: AuthService
    authState: AuthState
    //reloadNavBarClass: any;
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
    //  changeSessionData: any;

    onChangeSessionData: any
    history: any
    match: any
}

interface IState extends IBaseState {
    updating: boolean
    schoolClass: SchoolClassDto | null
    modal: boolean
    levels: any
    teachers: TeacherDto[]
    selectedLevel: any
    selectedTeachers: any
    calendarYears: any
    selectedCalendarYear: any
    isDataLoading: boolean
}

export default withOktaAuth(
    class SchoolClassEdit extends BaseClass<IProps, IState> {
        constructor(props: IProps) {
            super(props)
            this.state = {
                isLoading: false,
                error: '',
                visible: false,
                //authenticatedTeacher: null,
                updating: false,
                modal: false,
                schoolClass: null,
                teachers: [],
                levels: getSchoolClassLevels(),
                selectedLevel: null,
                selectedTeachers: null,
                calendarYears: getCalendarYearLevels(),
                selectedCalendarYear: getCalendarYearLevels()[0],
                isDataLoading: true,
            }
        }

        componentDidMount() {
            this.loadData()
        }

        loadData() {
            Promise.all([
                this.getSchoolClass(this.props.match.params.schoolClassId),
                this.getTeachers(this.props.sessionData.selectedSchool.id),
            ]).then(() => {
                if (this.state.schoolClass) {
                    let teacherSchoolClass = this.state.schoolClass.teachers

                    this.setState({
                        selectedTeachers: teacherSchoolClass.map(function (data: TeacherDto) {
                            return {
                                value: data.id,
                                label: `${data.firstname[0]}. ${data.lastname}`,
                            }
                        }),
                    })

                    this.props.setIsDataLoading(false)
                }
            })
        }

        async getTeachers(schoolId: string) {
            let token = await this.props.authState.accessToken.accessToken

            return getTeachers(schoolId, token)
                .then((res) => {
                    if (res.ok) {
                        this.setState({
                            teachers: res.data.map((data: TeacherDto) => {
                                return {
                                    value: data.id,
                                    label: `${data.firstname[0]}. ${data.lastname}`,
                                }
                            }),
                        })
                    } else {
                        this.handleknownError(res.error)
                    }
                })
                .catch((error) => {
                    this.handleUnknownError(error)
                })
        }

        async getSchoolClass(schoolClassId: string) {
            let token = await this.props.authState.accessToken.accessToken
            return getSchoolClassFromApi(schoolClassId, token)
                .then((res) => {
                    if (res.ok) {
                        this.setState({
                            schoolClass: res.data,
                            selectedLevel: this.wrap(res.data.levels),
                            selectedCalendarYear: {
                                value: res.data.calendarYear,
                                label: res.data.calendarYear.toString(),
                            },
                        })
                    } else {
                        this.props.setIsDataLoading(false)
                        this.setState({ error: res.error, visible: true })
                    }
                })
                .catch((error) => {
                    this.handleUnknownError(error)
                })
        }

        wrap(levels: string[]): any {
            let result: any = []

            levels.forEach((level) => {
                let found = this.state.levels.find((obj: any) => {
                    return obj.value === level
                })

                if (found) result.push(found)
            })

            return result
        }

        onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
            e.preventDefault()
            if (
                !this.state.schoolClass ||
                !this.state.schoolClass.name ||
                !this.state.selectedLevel
            ) {
                return
            }

            if (!this.state.selectedTeachers || this.state.selectedTeachers.length === 0) {
                this.showError('You must select a teacher.')

                return
            }

            if (!this.state.selectedLevel || this.state.selectedLevel.length === 0) {
                this.showError('You must select a level.')

                return
            }

            let token = await this.props.authState.accessToken.accessToken

            this.state.schoolClass.levels = this.state.selectedLevel.map((a: any) => a.value)

            this.setState({
                updating: true,
            })

            return updateSchoolClass(
                {
                    id: this.state.schoolClass.id,
                    name: this.state.schoolClass.name,
                    levels: this.state.schoolClass.levels,
                    teachers: this.state.selectedTeachers.map((a: any) => a.value),
                    calendarYear: this.state.selectedCalendarYear.value,
                },
                token
            )
                .then(async (res) => {
                    this.setState({ updating: false })
                    if (res.ok) {
                        this.setState({
                            updating: false,
                            schoolClass: null,
                        })

                        //this.props.reloadNavBarClass();

                        // TODO: make sure this updates globally
                        //  this.props.changeSessionData(this.props.sessionData);

                        var result = await getSchoolClassesFromApi(
                            this.props.authState.accessToken.accessToken,
                            true
                        )
                        let updatedSessionData = {
                            ...this.props.sessionData,
                            schoolClasses: result.data,
                        }
                        this.props.onChangeSessionData(updatedSessionData)

                        this.props.history.push(
                            `/admin/class/${this.props.sessionData.selectedSchool.id}`
                        )

                        //  location.href =  `/admin/class/${this.props.sessionData.selectedSchool.id}`

                        // this.props.history.push(
                        //   `/admin/class/${this.props.sessionData.selectedSchool.id}`
                        // );
                    } else {
                        this.handleknownError(res.error)
                    }
                })
                .catch((error) => {
                    this.handleUnknownError(error)
                })
        }

        onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (this.state.schoolClass) {
                this.setState({
                    schoolClass: {
                        ...this.state.schoolClass,
                        name: e.target.value,
                    },
                })
            }
        }

        handleChange = (selectedOption: any) => {
            this.setState({ selectedLevel: selectedOption })
        }

        toggle = () => {
            this.setState((prevState) => ({
                modal: !prevState.modal,
            }))
        }

        handleTeacherChange = (selectedTeachers: any) => {
            this.setState({
                selectedTeachers: !selectedTeachers ? [] : selectedTeachers,
            })
        }

        handleCalendarYearChange = (selectedOption: any) => {
            this.setState({ selectedCalendarYear: selectedOption })
        }

        goBack = () => {(this.props.history as any).goBack()}

        render() {
            //	// if (this.props.isDataLoading) return null;
            return (
                <div>
                    <div className="mb-2">
                        <BreadCrumbs
                            items={[
                                { title: 'Home', link: '/' },
                                {
                                    title: 'Admin',
                                    link: `/admin/select/${this.props.sessionData.selectedSchool.id}`,
                                },
                                {
                                    title: 'Classes',
                                    link: `/admin/class/${this.props.sessionData.selectedSchool.id}`,
                                },
                                { title: 'Class Edit', link: '' },
                            ]}
                        />
                    </div>
                    <h2 className="pb-2">Class Edit</h2>
                    {this.state.visible ? (
                        <Alert color="warning">
                            <p>{this.state.error}</p>
                        </Alert>
                    ) : null}
                    <div className="row">
                        <div className="col-md-4">
                            <form onSubmit={this.onSubmit}>
                                <div className="form-group">
                                    <label htmlFor="name">Name: </label>
                                    {this.state.schoolClass && (
                                        <input
                                            type="text"
                                            id="name"
                                            className="form-control"
                                            value={this.state.schoolClass.name}
                                            onChange={this.onChangeName}
                                        />
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="teachers">Select Teachers</label>
                                    <Select
                                        isMulti
                                        value={this.state.selectedTeachers}
                                        onChange={this.handleTeacherChange}
                                        options={this.state.teachers}
                                        placeholder="Select Teacher"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Select Levels</label>
                                    <Select
                                        isMulti
                                        value={this.state.selectedLevel}
                                        onChange={this.handleChange}
                                        options={this.state.levels}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="calendarYear">Select Calendar Year</label>
                                    <Select
                                        id="calendarYear"
                                        isSearchable={false}
                                        value={this.state.selectedCalendarYear}
                                        onChange={this.handleCalendarYearChange}
                                        options={this.state.calendarYears}
                                    />
                                </div>
                                <div className="form-group">
                                    <div className="space-between">
                                        <Button
                                            color="secondary"
                                            tag={Link}
                                            to={`/admin/class/${this.props.sessionData.selectedSchool.id}`}
                                            disabled={this.state.updating}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color="primary"
                                            type="submit"
                                            disabled={this.state.updating}
                                        >
                                            {this.state.updating ? (
                                                <i className="fa fa-spinner fa-spin" />
                                            ) : null}{' '}
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )
        }
    }
)
