import { GamesRatioType } from './../components/spaced/SpacedPractice'
import axios from 'axios'
import { handleApiSuccess, handleApiError, IApiResult } from '../shared/ApiHandler'
import {
    spacedPracticeApiUrl,
    spacedPracticeStudentSessionQuestionApiUrl,
    studentApiUrl,
    teacherBaseApiUrl,
} from '../shared/ApiUrls'
import { StudentCreateDto } from '../models/Student'

export const getStudentsApi = (
    token: string,
    schoolClassId: string,
    dateStartUtc: string,
    dateEndUtc: string
): Promise<IApiResult> => {
    let timeZoneOffset = (new Date().getTimezoneOffset() / 60) * -1
    return axios
        .get(
            `${spacedPracticeApiUrl}/GetStudents/${schoolClassId}/${dateStartUtc}/${dateEndUtc}/${timeZoneOffset}`,
            {
                headers: { Authorization: `bearer ${token}` },
                baseURL: teacherBaseApiUrl,
            }
        )
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const getSessionsApi = (token: string, studentId: string): Promise<IApiResult> => {
    return axios
        .get(`${spacedPracticeApiUrl}/getsessions/${studentId}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const getSpacedPracticeStudentSessionQuestionsApi = (
    token: string,
    id: string,
    start: string,
    end: string,
    offset: number
): Promise<IApiResult> => {
    let ct = axios.CancelToken.source()

    return axios
        .get(`${spacedPracticeStudentSessionQuestionApiUrl}/${id}/${start}/${end}/${offset}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
            cancelToken: ct.token,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const postFeedback = (
    token: string,
    studentId: string,
    //sessionId: string,
    questionId: string,
    teacherId: string,
    feedback: string
): Promise<IApiResult> => {
    // let ct = axios.CancelToken.source();
    let data = JSON.stringify(feedback)
    return axios
        .post(
            `${spacedPracticeStudentSessionQuestionApiUrl}/postfeedback/${studentId}/${questionId}/${teacherId}`,
            data,
            {
                headers: {
                    Authorization: `bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                baseURL: teacherBaseApiUrl,
            }
        )
        .then(handleApiSuccess)
        .catch(handleApiError)

    // return axios
    //   .post(`${spacedPracticeStudentSessionQuestionApiUrl}/${studentId}/${sessionId}/${questionId}/${teacherId}`, {
    //     headers: { Authorization: `bearer ${token}` },
    //     baseURL: teacherBaseApiUrl,
    //     cancelToken: ct.token,
    //   })
    //   .then(handleApiSuccess)
    //   .catch(handleApiError);
}

export const UpdateContentSettingsApi = (
    token: string,
    studentId: string,
    contentSettingsType: ContentSettingsType,
    keyConceptIds: string[]
): Promise<IApiResult> => {
    return axios
        .put(
            `${spacedPracticeApiUrl}/UpdateContentSettings/${studentId}/${contentSettingsType}`,
            keyConceptIds,
            {
                headers: {
                    Authorization: `bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                baseURL: teacherBaseApiUrl,
            }
        )
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const UpdateContentSettingsForSchoolClassApi = (
    token: string,
    schoolClassId: string,
    contentSettingsType: ContentSettingsType,
    isOverride: boolean,
    keyConceptIds: string[]
): Promise<IApiResult> => {
    // let data = JSON.stringify(keyConceptIds)

    return axios
        .put(
            `${spacedPracticeApiUrl}/UpdateContentSettingsForSchoolClass/${schoolClassId}/${contentSettingsType}/${isOverride}`,
            keyConceptIds,
            {
                headers: {
                    Authorization: `bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                baseURL: teacherBaseApiUrl,
            }
        )
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const UpdateGameRatioSettingsApi = (
    token: string,
    studentId: string,
    gamesRatioType: GamesRatioType
): Promise<IApiResult> => {
    return axios
        .put(
            `${spacedPracticeApiUrl}/UpdateGameRatioSettings/${studentId}/${gamesRatioType}`,
            null,
            {
                headers: {
                    Authorization: `bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                baseURL: teacherBaseApiUrl,
            }
        )
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const UpdateGameRatioSettingsForSchoolClassApi = (
    token: string,
    schoolClassId: string,
    isOverride: boolean,
    gamesRatioType: GamesRatioType
): Promise<IApiResult> => {
    return axios
        .put(
            `${spacedPracticeApiUrl}/UpdateGameRatioSettingsForSchoolClass/${schoolClassId}/${gamesRatioType}/${isOverride}`,
            null,
            {
                headers: {
                    Authorization: `bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                baseURL: teacherBaseApiUrl,
            }
        )
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export enum ContentSettingsType {
    Default,
    Custom,
}
