import React from 'react'
import { DiagnosticDto, DiagnosticStopDto } from '../../models/Diagnostic'
import { Link, RouteComponentProps } from 'react-router-dom'
import { withOktaAuth } from '@okta/okta-react'
import { Button, Table, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap'
import { SchoolClassDto } from '../../models/SchoolClass'
import {
    getDiagnosticsApi,
    stopDiagnosticApi,
    deleteDiagnosticApi,
} from '../../services/DiagnosticApi'
import './Diagnostic.css'
import { AuthService, AuthState } from '../../models/Auth'
import BaseClass from '../Bases/BaseClass'
import {
    archivedClassGuid,
    convertUtcToLocalDate,
    demoClassGuid,
    isTeacherAdminOrSuper,
} from '../../shared/Helper'
import BreadCrumbs from '../layout/BreadCrumbs'
import DemoPopovers from '../layout/DemoPopovers'
import { ISessionData } from '../../AppRouter'

interface IProps extends RouteComponentProps<any> {
    // baseUrl: string;
    authService: AuthService
    authState: AuthState
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
    history: any
    match: any
}

interface IState {
    diagnosticList: DiagnosticDto[]
    error: string
    visible: boolean
    selectedDiagnostic: DiagnosticDto | null
    modal: boolean
    deleteModal: boolean
    diagnosticIsRunning: boolean
    title: string
    isDataLoading: boolean
}

class DiagnosticIndex extends BaseClass<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            diagnosticList: Array<DiagnosticDto>(),
            error: '',
            visible: false,
            selectedDiagnostic: null,
            modal: false,
            deleteModal: false,
            diagnosticIsRunning: false,
            title: '',
            isDataLoading: true,
        }
    }

    componentDidMount() {
        this.loadData()
    }

    componentDidUpdate(prevProps) {
        // if (
        //   this.props.navSchoolClassIdSelected !== prevProps.navSchoolClassIdSelected
        // ) {
        //   this.loadData()
        // }
        if (!prevProps.sessionData && this.props.sessionData) {
            return this.loadData()
        }
        if (prevProps.sessionData && prevProps.sessionData != this.props.sessionData) {
            return this.loadData()
        }
    }

    async loadData() {
        this.props.setIsDataLoading(true)

        const { authenticatedTeacher, selectedSchoolClass } = this.props.sessionData

        if (selectedSchoolClass) {
            this.setState({ title: `Assessments (${selectedSchoolClass.name})` })
            await this.getDiagnostics(selectedSchoolClass.id)
        } else {
            this.props.setIsDataLoading(false)
            if (authenticatedTeacher && isTeacherAdminOrSuper(authenticatedTeacher.accessLevel)) {
                this.props.history.push(`/admin/class/${authenticatedTeacher.schoolId}`)
            } else {
                this.props.history.push('/no-data-yet')
            }
        }

        this.props.setIsDataLoading(false)
    }

    async getDiagnostics(schoolClassId: string) {
        let token = this.props.authState.accessToken.accessToken

        return getDiagnosticsApi(schoolClassId, token)
            .then((res) => {
                if (res.ok) {
                    this.setState({
                        diagnosticList: res.data,
                        diagnosticIsRunning: res.data.some((diagnostic: DiagnosticDto) => {
                            return diagnostic.startedDate && !diagnostic.finishedDate
                        }),
                    })
                } else {
                    this.handleknownError(res.error)
                }
            })
            .catch((error) => {
                this.handleUnknownError(error)
            })
    }

    getStatus = (diagnostic: DiagnosticDto) => {
        if (diagnostic.finishedDate) {
            return <span className="text-success font-weight-bold">Completed</span>
        }

        if (diagnostic.startedDate) {
            return <span className="text-warning font-weight-bold text-nowrap">In Progress</span>
        }

        return <span className="text-dark text-nowrap">Not Started</span>
    }

    toggle = () => {
        this.setState((prevState) => ({
            modal: !prevState.modal,
        }))
    }

    toggleDelete = (diagnostic) => {
        this.setState({
            selectedDiagnostic: diagnostic,
            deleteModal: !this.state.deleteModal,
        })
    }

    openDialogStopDiagnostic = (diagnostic: DiagnosticDto) => {
        this.toggle()
        this.setState({
            selectedDiagnostic: diagnostic,
        })
    }

    closeDiagnosticToStopDiagnostic = () => {
        this.toggle()
        this.setState({
            selectedDiagnostic: null,
        })
    }

    confirmStopDiagnostic = async () => {
        const { authenticatedTeacher, selectedSchoolClass } = this.props.sessionData

        this.toggle()
        this.props.setIsDataLoading(true)
        if (selectedSchoolClass && this.state.selectedDiagnostic) {
            let token = await this.props.authState.accessToken.accessToken
            let dto: DiagnosticStopDto = {
                id: this.state.selectedDiagnostic.id,
                schoolClassId: selectedSchoolClass.id,
            }

            return stopDiagnosticApi(dto, token)
                .then((res) => {
                    this.props.setIsDataLoading(false)
                    if (res.ok) {
                        // update diagnosticList
                        let copyOfDiagnosticList = [...this.state.diagnosticList]
                        let index = copyOfDiagnosticList.findIndex((a) => a.id === dto.id)

                        if (index !== -1) {
                            copyOfDiagnosticList[index].finishedDate = new Date().toUTCString()

                            this.setState({
                                diagnosticList: copyOfDiagnosticList,
                                diagnosticIsRunning: false,
                            })
                        }
                    } else {
                        this.handleknownError(res.error)
                    }
                })
                .catch((error) => {
                    this.handleUnknownError(error)
                })
        }
    }

    goBack() {(this.props.history as any).goBack()}

    delete = async () => {
        const { authenticatedTeacher, selectedSchoolClass } = this.props.sessionData

        this.toggleDelete(this.state.selectedDiagnostic)
        this.props.setIsDataLoading(true)
        let token = await this.props.authState.accessToken.accessToken

        if (selectedSchoolClass.id) {
            return deleteDiagnosticApi(
                selectedSchoolClass.id,
                this.state.selectedDiagnostic.id,
                token
            )
                .then((res) => {
                    this.props.setIsDataLoading(false)
                    if (res.ok) {
                        this.getDiagnostics(selectedSchoolClass.id)
                    } else {
                        this.handleknownError(res.error)
                    }
                })
                .catch((error) => {
                    this.handleUnknownError(error)
                })
        }
    }

    render() {
        // // if (this.props.isDataLoading) return null;

        const { selectedSchoolClass } = this.props.sessionData

        return (
            <div data-cy="assessments-page-loaded" className="pb-2">
                {this.props.sessionData.authenticatedTeacher.isDemo && (
                    <DemoPopovers
                        items={[
                            {
                                target: 'actions',
                                placement: 'top',
                                message: 'These features are not available for trial accounts.',
                            },
                        ]}
                    ></DemoPopovers>
                )}

                <div className="mb-2">
                    <BreadCrumbs
                        items={[
                            { title: 'Home', link: '/' },
                            { title: 'Assessments', link: '' },
                        ]}
                    />
                </div>
                <h2 className="pb-2">{this.state.title}</h2>
                {this.state.visible ? (
                    <Alert color="warning">
                        <p>{this.state.error}</p>
                    </Alert>
                ) : null}

                <p className="font-weight-light mb-0">
                    When you’re ready to collect data about your students, add a new assessment.
                    Each student can only be in one active assessment at a time, so don’t forget to
                    STOP any previous assessments that are still in progress. Don’t worry, this
                    won’t affect your students if they haven’t finished yet. The new assessment will
                    pick up right where they left off.
                </p>
                <p className="font-weight-light">
                    This page also shows a record of all the assessments your students have
                    completed so far.
                </p>

                {selectedSchoolClass && selectedSchoolClass.id === archivedClassGuid ? null : (
                    <>
                        <div className="mb-2">
                            <Button
                                className={
                                    // this.state.diagnosticIsRunning && (this.state.selectedSchoolClassId !== demoClassGuid)
                                    //   ? "btn btn-secondary"
                                    //   :
                                    'btn btn-success'
                                }
                                tag={Link}
                                to="/assessment/create"
                                // disabled={this.state.diagnosticIsRunning && (this.state.selectedSchoolClassId !== demoClassGuid)}
                            >
                                Start a new assessment
                            </Button>
                        </div>

                        <Table bordered striped className="bg-white">
                            <thead>
                                <tr>
                                    <th>Assessment Name</th>
                                    <th>Created</th>
                                    <th>Status</th>
                                    <th id="actions">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.diagnosticList.map((diagnostic: DiagnosticDto) => {
                                    //   var a  =convertUtcToLocalDate(diagnostic.createdDate)
                                    return (
                                        <tr key={diagnostic.id}>
                                            <td className="align-middle font-weight-bold ">
                                                <Link to={`/assessment/details/${diagnostic.id}`}>
                                                    {diagnostic.name}
                                                </Link>
                                            </td>
                                            <td className="align-middle">
                                                {convertUtcToLocalDate(diagnostic.createdDate)}
                                            </td>
                                            <td className="align-middle font-weight-bold">
                                                {this.getStatus(diagnostic)}
                                            </td>
                                            <td className="align-middle">
                                                {/* <div className="d-flex justify-content-between"> */}
                                                <div className="text-nowrap">
                                                    <Button
                                                        color="primary"
                                                        tag={Link}
                                                        to={`assessment/edit/${diagnostic.id}`}
                                                        disabled={
                                                            selectedSchoolClass &&
                                                            selectedSchoolClass.id === demoClassGuid
                                                        }
                                                    >
                                                        Edit
                                                    </Button>{' '}
                                                    {diagnostic.startedDate ? (
                                                        ''
                                                    ) : (
                                                        <>
                                                            <Button
                                                                color="danger"
                                                                onClick={() =>
                                                                    this.toggleDelete(diagnostic)
                                                                }
                                                                //disabled={this.state.isLoading}
                                                                disabled={
                                                                    selectedSchoolClass &&
                                                                    selectedSchoolClass.id ===
                                                                        demoClassGuid
                                                                }
                                                            >
                                                                Delete
                                                            </Button>{' '}
                                                        </>
                                                    )}
                                                    {diagnostic.startedDate &&
                                                    !diagnostic.finishedDate ? (
                                                        <Button
                                                            color="danger"
                                                            onClick={() =>
                                                                this.openDialogStopDiagnostic(
                                                                    diagnostic
                                                                )
                                                            }
                                                            //  disabled={this.state.isLoading}
                                                            disabled={
                                                                selectedSchoolClass &&
                                                                selectedSchoolClass.id ===
                                                                    demoClassGuid
                                                            }
                                                        >
                                                            Stop
                                                        </Button>
                                                    ) : null}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        {this.state.selectedDiagnostic ? (
                            <>
                                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                                    <ModalHeader toggle={this.toggle}>
                                        Stop {this.state.selectedDiagnostic.name}
                                    </ModalHeader>
                                    <ModalBody>
                                        Stopping this assessment will submit all students answers
                                        automatically. To continue gathering student data, a new
                                        assessment will need to be started. Do you want to manually
                                        stop this assessment for all students?
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="danger" onClick={this.confirmStopDiagnostic}>
                                            Stop
                                        </Button>
                                        <Button
                                            color="secondary"
                                            onClick={this.closeDiagnosticToStopDiagnostic}
                                        >
                                            Cancel
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                            </>
                        ) : null}
                        <Modal isOpen={this.state.deleteModal} toggle={this.toggleDelete}>
                            <ModalHeader toggle={this.toggleDelete}>Delete Assessment</ModalHeader>
                            <ModalBody>Are you sure you want to delete {''} assessment?</ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={this.toggleDelete}>
                                    Cancel
                                </Button>
                                <Button color="danger" onClick={this.delete}>
                                    Delete
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </>
                )}
            </div>
        )
    }
}
export default withOktaAuth(DiagnosticIndex)
