import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { withOktaAuth } from '@okta/okta-react'
import { StudentDto } from '../../models/Student'
import { StatementDto, StudentStatementReportDto } from '../../models/Statement'
import { getStudentReport } from '../../services/ReportApi'
import { ContentDescriptorByLevel } from '../../models/Report'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    CardBody,
    Card,
    CardHeader,
} from 'reactstrap'
import {
    StrandReport,
    SubStrandReport,
    ContentDescriptorByOrder,
    ContentDescriptorReport,
} from '../../models/Strand'
import { AuthService, AuthState } from '../../models/Auth'
import BaseClass from '../Bases/BaseClass'
import {
    getStudentIndividualReportViewingModeFromLocalStorage,
    setStudentIndividualReportViewingModeToLocalStorage,
} from '../../shared/LocalStorageHelpers'
import BreadCrumbs from '../layout/BreadCrumbs'
import { ISessionData } from '../../AppRouter'

interface IParams {
    studentId: string
}

interface IProps extends RouteComponentProps<IParams> {
    // baseUrl: string;
    authService: AuthService
    authState: AuthState
    sessionData: ISessionData
    // navSchoolClassIdSelected: string;
    setIsDataLoading: any
    isDataLoading: boolean
    history: any
    match: any
}

interface IState {
    isLoading: boolean
    error: string
    visible: boolean
    modal: boolean
    modalViewingMode: boolean
    student: StudentDto | null
    strands: StrandReport[]
    uniqueLevels: number[]
    selectedContentDescriptor: ContentDescriptorReport | null
    selectedStudentStatements: StudentStatementReportDto[]
    selectedMasteredStatements: StudentStatementReportDto[]
    selectedFailedStatements: StudentStatementReportDto[]
    selectedNotAssessedStatements: StudentStatementReportDto[]
    maxNumberOfContentDescriptorInLevel: number
    contentDescriptorByLevel: ContentDescriptorByLevel[]
    curriculumType: string
    viewingMode: string
    // statements: StatementDto[]
    isDataLoading: boolean
}

class StudentIndividualReportPage extends BaseClass<IProps, IState> {
    levelDescriptors: string[]

    constructor(props: IProps) {
        super(props)
        this.state = {
            isLoading: true,
            error: '',
            visible: false,
            modal: false,
            modalViewingMode: false,
            student: null,
            strands: [],
            uniqueLevels: [],
            selectedContentDescriptor: null,
            selectedStudentStatements: [],
            selectedMasteredStatements: [],
            selectedFailedStatements: [],
            selectedNotAssessedStatements: [],
            maxNumberOfContentDescriptorInLevel: 0,
            contentDescriptorByLevel: [],
            curriculumType: '',
            viewingMode: 'Currently assessed content only',
            //    statements: [],
            isDataLoading: true,
        }

        this.levelDescriptors = this.getLevelDescriptors()
    }

    // getStatements = async () => {
    //     let token = await this.props.authState.accessToken.accessToken
    //     return getStatementsFromApi(token)
    //         .then((res) => {
    //             if (res.ok) {
    //                 this.setState({
    //                     statements: res.data,
    //                 })
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error)
    //         })
    // }

    getLevelDescriptors() {
        return [
            'Foundation level',
            'Level 1',
            'Level 2',
            'Level 3',
            'Level 4',
            'Level 5',
            'Level 6',
        ]
    }

    async componentDidMount() {
        let viewingMode = getStudentIndividualReportViewingModeFromLocalStorage()

        if (!viewingMode) {
            this.toggleViewingMode(null)
        } else {
            this.setState({ viewingMode })
        }

        this.props.sessionData && this.loadData()
    }

    // componentDidUpdate(prevProps) {
    //   // if (
    //   //   this.props.navSchoolClassIdSelected !== prevProps.navSchoolClassIdSelected
    //   // ) {
    //   //   //this.loadData();
    //   //   this.props.history.push("/data/individual");
    //   // }
    // }

    componentDidUpdate(prevProps, nextState) {
        if (this.props.sessionData !== prevProps.sessionData) {
            this.props.history.push('/')
        }
    }

    async loadData() {
        this.props.setIsDataLoading(true)
        await this.getStudentReport(this.props.match.params.studentId)
        this.props.setIsDataLoading(false)
    }

    async getStudentReport(studentId: string) {
        this.props.setIsDataLoading(true)
        let token = await this.props.authState.accessToken.accessToken

        return getStudentReport(studentId, token)
            .then((res) => {
                this.props.setIsDataLoading(false)
                if (res.ok) {
                    this.setState({
                        student: res.data.student,
                        strands: res.data.strands,
                        uniqueLevels: this.fetchLevels(res.data.strands),
                        curriculumType: res.data.curriculumType,
                    })
                } else {
                    this.handleknownError(res.error)
                }
            })
            .catch((error) => {
                this.handleUnknownError(error)
            })
    }

    fetchLevels = (strands: StrandReport[]): number[] => {
        let uniqueLevels: number[] = []

        strands.forEach((strand: StrandReport) => {
            strand.subStrandReports.forEach((substrand: SubStrandReport) => {
                if (substrand.contentDescriptorsByOrder.length > 0) {
                    substrand.contentDescriptorsByOrder.forEach(
                        (contentDescriptorByOrder: ContentDescriptorByOrder) => {
                            contentDescriptorByOrder.contentDescriptors.forEach(
                                (contentDescriptor: ContentDescriptorReport) => {
                                    if (uniqueLevels.indexOf(contentDescriptor.level) === -1) {
                                        uniqueLevels.push(contentDescriptor.level)
                                    }
                                }
                            )
                        }
                    )
                }
            })
        })

        return uniqueLevels.sort()
    }

    getStatement(statementId: string, statements: StatementDto[]) {
        return statements.find((v) => v.id === statementId)
    }

    handleOnClick = (
        contentDescriptor: ContentDescriptorReport,
        studentStatements: StudentStatementReportDto[]
    ) => {
        if (contentDescriptor && studentStatements) {
            this.toggle()
            this.setState(() => ({
                selectedContentDescriptor: contentDescriptor,
                //   selectedStudentStatements: contentDescriptor.studentStatements,
                selectedMasteredStatements: studentStatements.filter(
                    (statement) => statement.mastered && statement.answeredDate
                ),
                selectedFailedStatements: studentStatements.filter(
                    (statement) => !statement.mastered && statement.answeredDate
                ),
                selectedNotAssessedStatements: studentStatements.filter(
                    (statement) => !statement.answeredDate
                ),
            }))
        }
    }

    toggle = () => {
        this.setState((prevState) => ({
            modal: !prevState.modal,
        }))
    }

    toggleViewingMode = (viewingMode) => {
        this.setState((prevState) => ({
            modalViewingMode: !prevState.modalViewingMode,
        }))

        if (viewingMode !== null) {
            this.setState({
                viewingMode,
            })

            setStudentIndividualReportViewingModeToLocalStorage(viewingMode)
            this.loadData()
        }
    }

    getLevelName = (level: number) => {
        return this.levelDescriptors[level]
    }

    getMasteryLevelColor = (masteryLevel: number): string => {
        if (masteryLevel === 1) {
            return 'table-success'
        }

        if (masteryLevel >= 0.5) {
            return 'table-warning'
        }

        return ''
    }

    render() {
        // if (this.props.isDataLoading) return null;

        const title =
            this.state.student === null ? (
                <h2 className="pb-2">Individual Student Report</h2>
            ) : (
                <h2>
                    Individual Student Report for{' '}
                    {`${this.state.student.firstName} ${this.state.student.lastName}`}
                </h2>
            )

        const modalBody =
            this.state.selectedContentDescriptor === null ||
            this.state.selectedContentDescriptor.studentStatements === null ? null : 
            this.state.selectedContentDescriptor.studentStatements.length === 0 ? (
                <>
                    <div>
                        <strong>General statement:</strong>
                        <div className='mt-2'>
                            Teacher observation during contextualised learning experiences is recommended for this content descriptor.
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div>
                        <div>
                            <strong>Mastered Statements:</strong>
                            <ul>
                                {this.state.selectedMasteredStatements.map((masteredStatement) => {
                                    let statement = this.getStatement(
                                        masteredStatement.statementId,
                                        this.props.sessionData.statements
                                    )

                                    if (!statement) {
                                        return null
                                    }

                                    return (
                                        <li key={statement.code}>
                                            {statement.code}
                                            {' - '}
                                            {statement.name}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div>
                            <strong>Not Mastered Statements:</strong>
                            <ul>
                                {this.state.selectedFailedStatements.map((failedStatement) => {
                                    let statement = this.getStatement(
                                        failedStatement.statementId,
                                        this.props.sessionData.statements
                                    )

                                    if (!statement) {
                                        return null
                                    }

                                    return (
                                        <li key={statement.code}>
                                            {statement.code}
                                            {' - '}
                                            {statement.name}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        {/* {!this.state.assessedOnly ? */}
                        <div>
                            <strong>Not Assessed Statements:</strong>
                            <ul>
                                {this.state.selectedNotAssessedStatements.map((s, index) => {
                                    let statement = this.getStatement(
                                        s.statementId,
                                        this.props.sessionData.statements
                                    )

                                    if (!statement) {
                                        return null
                                    }

                                    // CV03.02
                                    return (
                                        // <li key={statement.code}>
                                        <li key={index}>
                                            {statement.code}
                                            {' - '}
                                            {statement.name}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        {/* : ""} */}
                    </div>
                </>
            )

        return (
            <div className="pb-2">
                <div className="mb-2">
                    <BreadCrumbs
                        items={[
                            { title: 'Home', link: '/' },
                            { title: 'Data', link: '/data' },
                            { title: 'Student List', link: '/data/individual' },
                            { title: 'Individual Student Report', link: '' },
                        ]}
                    />
                </div>
                <div>
                    {title}
                    <p className="font-weight-light">{this.getSubHeading()}</p>
                    <div className="d-flex  pb-3 space-between">
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span className="dot-success mr-2" />
                                        </td>
                                        <td>Mastered</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="dot-warning mr-2" />
                                        </td>
                                        <td>Not Mastered Yet</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="dot-not-asses-yet mr-2" />
                                        </td>
                                        <td>Not Assessed Yet</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className=" ">
                            <Card style={{ minWidth: '200px', maxWidth: '550px' }}>
                                <CardHeader style={{ textAlign: 'center' }}>
                                    <strong className="pr-2">
                                        Viewing mode: {this.state.viewingMode}{' '}
                                    </strong>
                                    <Button
                                        size="sm"
                                        color="primary"
                                        onClick={() => this.toggleViewingMode(null)}
                                    >
                                        Change
                                    </Button>
                                </CardHeader>

                                {this.state.viewingMode === 'Currently assessed content only' ? (
                                    <CardBody className="p-2" style={{ fontSize: '14px' }}>
                                        <div>
                                            &apos;Students&apos; attainment against each outcome is
                                            estimated using all of their responses to assessment
                                            items so far. Where there is not enough data collected
                                            to perform this estimate, the outcome is shown as “not
                                            yet assessed”.
                                        </div>
                                    </CardBody>
                                ) : null}

                                {/* <CardBody>
                'Students’ attainment against each outcome is estimated using all of their responses to assessment items so far. Where there is not enough data collected to perform this estimate, the outcome is shown as “not yet assessed”.

               </CardBody> */}
                            </Card>

                            {/* {this.state.viewingMode === "Broad" ? (
                <div className='pb-2'>
                  'Students’ attainment against each outcome is estimated using all of their responses to assessment items so far. Where there is not enough data collected to perform this estimate, the outcome is shown as “not yet assessed”.
                </div>
              ) : (null)} */}
                        </div>
                    </div>

                    <table id="individual-table" className="table">
                        <thead>
                            <tr>
                                {this.state.uniqueLevels.map((level) => {
                                    return this.getLevelHeadings(level)
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.strands.map((strand: StrandReport) => {
                                return (
                                    <React.Fragment key={strand.id}>
                                        <tr className="strand-header-colour">
                                            <td colSpan={this.state.uniqueLevels.length}>
                                                <strong>{strand.name}</strong>
                                            </td>
                                        </tr>
                                        {strand.subStrandReports.map(
                                            (substrand: SubStrandReport) => {
                                                return (
                                                    <React.Fragment key={substrand.id}>
                                                        <tr className="table-secondary">
                                                            <td
                                                                colSpan={
                                                                    this.state.uniqueLevels.length
                                                                }
                                                            >
                                                                {substrand.name}
                                                            </td>
                                                        </tr>
                                                        {substrand.contentDescriptorsByOrder.map(
                                                            (
                                                                contentDescriptorByOrder: ContentDescriptorByOrder
                                                            ) => {
                                                                return this.getContentDescriptors(
                                                                    contentDescriptorByOrder,
                                                                    substrand.id
                                                                )
                                                            }
                                                        )}
                                                    </React.Fragment>
                                                )
                                            }
                                        )}
                                    </React.Fragment>
                                )
                            })}
                        </tbody>
                    </table>
                    <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle}>
                        <ModalHeader toggle={this.toggle}>Detailed Information</ModalHeader>
                        <ModalBody>{modalBody}</ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.toggle}>
                                Close
                            </Button>
                        </ModalFooter>
                    </Modal>

                    <Modal size="lg" isOpen={this.state.modalViewingMode}>
                        <ModalHeader>Select Viewing Mode</ModalHeader>
                        <ModalBody>
                            <div className="row ">
                                <div className="col-6  ">
                                    <Button
                                        color="light"
                                        id="show-currently-assessed-content-only-button"
                                        className="p-2"
                                        onClick={() => {
                                            let div = document.getElementById(
                                                'show-currently-assessed-content-only-button'
                                            )

                                            div.style.backgroundColor = 'rgb(195, 230, 203)'
                                            setTimeout(() => {
                                                this.toggleViewingMode(
                                                    'Currently assessed content only'
                                                )
                                            }, 300)
                                        }}
                                        style={{ height: '100%' }}
                                    >
                                        <strong>Show currently assessed content only</strong>
                                        <br></br>
                                        <br></br>
                                        View how this student is achieving in the areas that have
                                        been assessed so far.
                                        <br></br>
                                        <br></br>
                                        The shading of the boxes only represents statements that
                                        have been assessed. Statements that have not been assessed
                                        yet are not included in the shading.
                                        <br></br>
                                        <br></br>
                                        {/* <br></br> */}
                                    </Button>
                                </div>

                                <div className="col-6 ">
                                    <Button
                                        color="light"
                                        id="show-all-content-button"
                                        className="p-2"
                                        onClick={() => {
                                            let div =
                                                document.getElementById('show-all-content-button')

                                            div.style.backgroundColor = 'rgb(195, 230, 203)'
                                            setTimeout(() => {
                                                this.toggleViewingMode('All content')
                                            }, 300)
                                        }}
                                        style={{ height: '100%' }}
                                    >
                                        <strong>Show all content</strong>
                                        <br></br>
                                        <br></br>
                                        {/* View the depth to which each part of the curriculum has been assessed so far. */}
                                        View how this student is achieving in all areas of the
                                        curriculum.
                                        <br></br>
                                        <br></br>
                                        {/* All possible data points are shown against each outcome, including nuances which have not yet been directly assessed. */}
                                        The shading of the boxes represents all content. Clicking on
                                        the box will show a list of the statements this student has
                                        Mastered, Not Mastered and which statements they have not
                                        been assessed on yet.
                                        <br></br>
                                        {/* <br></br> */}
                                    </Button>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        )
    }

    getContentDescriptors(contentDescriptorByOrder: ContentDescriptorByOrder, key): any {
        if (this.state.curriculumType === 'NSW') {
            return (
                <React.Fragment key={key}>
                    <tr>
                        {contentDescriptorByOrder.contentDescriptors.map(
                            (contentDescriptor: ContentDescriptorReport) => {
                                return this.getContentDescriptorsFirstRowForNsw(
                                    contentDescriptor,
                                    contentDescriptorByOrder.contentDescriptors
                                )
                            }
                        )}
                    </tr>
                    <tr key={contentDescriptorByOrder.order}>
                        {contentDescriptorByOrder.contentDescriptors.map(
                            (contentDescriptor: ContentDescriptorReport) => {
                                return this.getContentDescriptorsSecondRowForNsw(contentDescriptor)
                            }
                        )}
                    </tr>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment key={key}>
                <tr>
                    {contentDescriptorByOrder.contentDescriptors.map(
                        (contentDescriptor: ContentDescriptorReport) => {
                            return (
                                <td
                                    key={contentDescriptor.id}
                                    // className={this.getMasteryLevelColor(
                                    //   contentDescriptor.masteryLevel
                                    // )}

                                    // style={{ background: "linear-gradient(to right, #c3e6cb, #c3e6cb 33.33%,  #ffeeba 33.33%,  #ffeeba 66.66%, white 66.66%)" }}
                                    style={{
                                        background: this.getMasteryGradient(
                                            contentDescriptor.studentStatements
                                        ),
                                    }}
                                    // style={{background: linear-gradient(to right, #002395, #002395 33.33%, white 33.33%, white 66.66%, #ed2939 66.66%); }}

                                    onClick={() =>
                                        this.handleOnClick(
                                            contentDescriptor,
                                            contentDescriptor.studentStatements
                                        )
                                    }
                                >
                                    <small>{contentDescriptor.description}</small>
                                </td>
                            )
                        }
                    )}
                </tr>
            </React.Fragment>
        )
    }

    getMasteryGradient(studentStatements: StudentStatementReportDto[]) {
        if (studentStatements && studentStatements.length > 0) {
            let selectedMasteredStatements = studentStatements.filter(
                (statement) => statement.mastered && statement.answeredDate
            )

            let selectedFailedStatements = studentStatements.filter(
                (statement) => !statement.mastered && statement.answeredDate
            )

            // let selectedNotAssessedStatements = studentStatements.filter(
            //   (statement) => !statement.answeredDate
            // );

            if (this.state.viewingMode === 'All content') {
                let masteredPercent =
                    (selectedMasteredStatements.length / studentStatements.length) * 100
                let failedPercent =
                    (selectedFailedStatements.length / studentStatements.length) * 100

                return `linear-gradient(to right, #c3e6cb ${masteredPercent}%,  #ffeeba ${masteredPercent}% ${
                    failedPercent + masteredPercent
                }%,  white ${failedPercent + masteredPercent}%)`
            }

            let total = selectedMasteredStatements.length + selectedFailedStatements.length
            let masteredPercent = (selectedMasteredStatements.length / total) * 100
            let failedPercent = (selectedFailedStatements.length / total) * 100

            // let notAssessedPercent = (selectedNotAssessedStatements.length / contentDescriptor.studentStatements.length) * 100;
            return `linear-gradient(to right, #c3e6cb ${masteredPercent}%,  #ffeeba ${masteredPercent}% ${
                failedPercent + masteredPercent
            }%)`

            // return "linear-gradient(to right, #c3e6cb, #c3e6cb 50%,  #ffeeba 33.33%,  #ffeeba 66.66%, white 66.66%)"
        }

        return ''
    }

    getLevelHeadings(level: number): any {
        if (this.state.curriculumType === 'NSW') {
            switch (level) {
                case 0:
                    return (
                        <th
                            className="text-center nsw-table-first-col-width"
                            key={level}
                            colSpan={1}
                        >
                            {'Early Stage 1'}
                        </th>
                    )
                case 1:
                    return (
                        <th
                            className="text-center nsw-table-other-col-width"
                            key={level}
                            colSpan={2}
                        >
                            {'Stage 1'}
                        </th>
                    )
                case 3:
                    return (
                        <th
                            className="text-center nsw-table-other-col-width"
                            key={level}
                            colSpan={2}
                        >
                            {'Stage 2'}
                        </th>
                    )
                default:
                    return null
            }
        } else {
            return (
                <th className="text-center" key={level}>
                    {this.getLevelName(level)}
                </th>
            )
        }
    }

    getSubHeading(): React.ReactNode {
        if (this.state.curriculumType === 'NSW') {
            return (
                <>
                    The Individual Student Report displays the statements this student has mastered,
                    mapped against the outcomes from the NSW curriculum.
                    <br></br>
                    Clicking on an outcome will display a list of the statements that are mapped to
                    that specific outcome or part.
                </>
            )
        }

        return (
            <>
                The Individual Student Report displays the statements this student has mastered,
                mapped against the content descriptors from the curriculum.
                <br></br>
                Clicking on a content descriptor will display a list of the statements that are
                mapped to that specific descriptor.
            </>
        )
    }

    getContentDescriptorsFirstRowForNsw(
        contentDescriptor: ContentDescriptorReport,
        contentDescriptors: ContentDescriptorReport[]
    ): any {
        switch (contentDescriptor.level) {
            case 0:
                return (
                    <td
                        rowSpan={2}
                        colSpan={1}
                        key={contentDescriptor.id}
                        // className={this.getMasteryLevelColor(
                        //   contentDescriptor.masteryLevel
                        // )}

                        style={{
                            background: this.getMasteryGradient(
                                contentDescriptor.studentStatements
                            ),
                        }}
                        onClick={() =>
                            this.handleOnClick(
                                contentDescriptor,
                                contentDescriptor.studentStatements
                            )
                        }
                    >
                        <small>{contentDescriptor.description}</small>
                    </td>
                )
            case 1: {
                let combinedStudentStatements = this.getCombinedStudentStatementsFromParts(
                    contentDescriptors,
                    1,
                    2
                )

                return (
                    <td
                        rowSpan={1}
                        colSpan={2}
                        key={contentDescriptor.id}
                        // className={this.getMasteryLevelColor(masteryLevel)}
                        style={{
                            background: this.getMasteryGradient(combinedStudentStatements),
                        }}
                        onClick={() =>
                            this.handleOnClick(contentDescriptor, combinedStudentStatements)
                        }
                    >
                        <small>{contentDescriptor.description}</small>
                    </td>
                )
            }
            case 3: {
                //  let masteryLevel = this.getMasteryFromParts(contentDescriptors, 3, 4);
                return (
                    <td
                        rowSpan={1}
                        colSpan={2}
                        key={contentDescriptor.id}
                        // className={this.getMasteryLevelColor(masteryLevel)}
                        style={{
                            background: this.getMasteryGradient(
                                contentDescriptor.studentStatements
                            ),
                        }}
                        onClick={() =>
                            this.handleOnClick(
                                contentDescriptor,
                                contentDescriptor.studentStatements
                            )
                        }
                    >
                        <small>{contentDescriptor.description}</small>
                    </td>
                )
            }
            default:
                return null
        }
    }

    private getMasteryFromParts(
        contentDescriptors: ContentDescriptorReport[],
        level1: number,
        level2: number
    ) {
        let masteryLevel = 0
        let part1 = contentDescriptors.find((v) => v.level === level1)
        let part2 = contentDescriptors.find((v) => v.level === level2)

        if (part1 && part2 && part1.masteryLevel === 1 && part2.masteryLevel === 1) {
            masteryLevel = 1
        }

        return masteryLevel
    }

    private getCombinedStudentStatementsFromParts(
        contentDescriptors: ContentDescriptorReport[],
        level1: number,
        level2: number
    ) {
        // let masteryLevel = 0;
        let part1 = contentDescriptors.find((v) => v.level === level1)
        let part2 = contentDescriptors.find((v) => v.level === level2)
        // if (
        //   part1 &&
        //   part2 &&
        //   part1.masteryLevel === 1 &&
        //   part2.masteryLevel === 1
        // ) {
        //   masteryLevel = 1;
        // }

        let a = []
        let b = []

        if (part1 && part1.studentStatements) {
            a = part1.studentStatements
        }

        if (part2 && part2.studentStatements) {
            b = part2.studentStatements
        }

        let c = a.concat(b)

        return c
    }

    getContentDescriptorsSecondRowForNsw(contentDescriptor: ContentDescriptorReport): any {
        switch (contentDescriptor.level) {
            case 1:
                return (
                    <td
                        rowSpan={1}
                        colSpan={1}
                        key={contentDescriptor.id}
                        // className={
                        //   this.getMasteryLevelColor(contentDescriptor.masteryLevel) +
                        //   " text-center"
                        // }
                        className={' text-center'}
                        style={{
                            background: this.getMasteryGradient(
                                contentDescriptor.studentStatements
                            ),
                        }}
                        onClick={() =>
                            this.handleOnClick(
                                contentDescriptor,
                                contentDescriptor.studentStatements
                            )
                        }
                    >
                        <small>Part 1</small>
                    </td>
                )
            case 2:
                return (
                    <td
                        rowSpan={1}
                        colSpan={1}
                        key={contentDescriptor.id}
                        // className={
                        //   this.getMasteryLevelColor(contentDescriptor.masteryLevel) +
                        //   " text-center"
                        // }
                        className={' text-center'}
                        style={{
                            background: this.getMasteryGradient(
                                contentDescriptor.studentStatements
                            ),
                        }}
                        onClick={() =>
                            this.handleOnClick(
                                contentDescriptor,
                                contentDescriptor.studentStatements
                            )
                        }
                    >
                        <small>Part 2</small>
                    </td>
                )
            case 3:
                return (
                    <td
                        rowSpan={1}
                        colSpan={1}
                        key={contentDescriptor.id}
                        // className={
                        //   this.getMasteryLevelColor(contentDescriptor.masteryLevel) +
                        //   " text-center"
                        // }

                        className={' text-center'}
                        style={{
                            background: this.getMasteryGradient(
                                contentDescriptor.studentStatements
                            ),
                        }}
                        onClick={() =>
                            this.handleOnClick(
                                contentDescriptor,
                                contentDescriptor.studentStatements
                            )
                        }
                    >
                        <small>Part 1</small>
                    </td>
                )
            case 4:
                return (
                    <td
                        rowSpan={1}
                        colSpan={1}
                        key={contentDescriptor.id}
                        // className={
                        //   this.getMasteryLevelColor(contentDescriptor.masteryLevel) +
                        //   " text-center"
                        // }
                        className={' text-center'}
                        style={{
                            background: this.getMasteryGradient(
                                contentDescriptor.studentStatements
                            ),
                        }}
                        onClick={() =>
                            this.handleOnClick(
                                contentDescriptor,
                                contentDescriptor.studentStatements
                            )
                        }
                    >
                        <small>Part 2</small>
                    </td>
                )
            default:
                return null
        }
    }
}

export default withOktaAuth(StudentIndividualReportPage)
