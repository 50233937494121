import React, { Component, useEffect, useRef, useState } from 'react'
import { Route, withRouter, Switch, RouteComponentProps, useLocation } from 'react-router-dom'
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react'
import Home from './components/home/Home'
import Login from './components/auth/Login'
import appConfig, { getOktaClientId } from './app.config'
import NotFound from './components/home/NotFound'
import SchoolClassCreate from './components/admin/schoolClass/SchoolClassCreate'
import SchoolClassEdit from './components/admin/schoolClass/SchoolClassEdit'
import DiagnosticIndex from './components/diagnostic/DiagnosticIndex'
import DiagnosticCreate from './components/diagnostic/DiagnosticCreate'
import Sandbox from './components/diagnostic/Sandbox'
import DiagnosticDetails from './components/diagnostic/DiagnosticDetails'
import DiagnosticEdit from './components/diagnostic/DiagnosticEdit'
import StudentDiagnosticIndex from './components/diagnostic/StudentDiagnosticIndex'
import Student from './components/student/Student'
import StudentCreate from './components/student/StudentCreate'
import StudentEdit from './components/student/StudentEdit'
import Data from './components/data/Data'
import StudentDiagnosticResult from './components/diagnostic/StudentDiagnosticResult'
import ClassReportPage from './components/data/ClassReportPage'
import StudentListReportPage from './components/data/StudentListReportPage'
import ComponentReport from './components/data/ComponentReport'
import GroupIndex from './components/group/GroupIndex'
import StudentIndividualReportPage from './components/data/StudentIndividualReportPage'
import SchoolClassIndex from './components/admin/schoolClass/SchoolClassIndex'
import TeacherIndex from './components/admin/teacher/TeacherIndex'
import TeacherCreate from './components/admin/teacher/TeacherCreate'
import TeacherEdit from './components/admin/teacher/TeacherEdit'
import StudentCreateBulk from './components/student/StudentCreateBulk'
import OrderComplete from './components/order/OrderComplete'
import Payment from './components/order/Payment'
import AdminIndex from './components/admin/Admin'
import Licence from './components/admin/licence/Licence'
import TeacherCreateBulk from './components/admin/teacher/TeacherCreateBulk'
import SchoolClassCreateBulk from './components/admin/schoolClass/SchoolClassCreateBulk'
import NoDataYet from './components/layout/NoDataYet'
import Demo from './components/order/Demo'
import DemoComplete from './components/order/DemoComplete'
import SecureApp from './components/layout/SecureApp'
import SchoolBulkRollover from './components/admin/school/SchoolBulkRollover'
import StudentRollover from './components/student/StudentRollover'
import Order from './components/order/Order'
import DemoIFrame from './components/order/DemoIFrame'
import StudentProfile from './components/student/StudentProfile'
import HelpIndex from './components/help/HelpIndex'
import Faq from './components/help/Faq'
import { TeacherDto } from './models/Teacher'
import { SchoolClassDto } from './models/SchoolClass'
import { SchoolDto } from './models/School'
import { FullPageSpinner } from './components/layout/FullPageSpinner'
import { oktaAuthConfig, oktaSignInConfig } from './components/auth/oktaConfig'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { useHistory } from 'react-router-dom'
import SpacedPractice from './components/spaced/SpacedPractice'
import { KeyConceptDto } from './models/KeyConcept'
import OrderIFrame from './components/order/OrderIFrame'
import SpacedPracticeResult from './components/spaced/SpacedPracticeResult'
import { StatementDto } from './models/Statement'

export interface ISessionData {
    authenticatedTeacher: TeacherDto
    selectedSchoolClass: SchoolClassDto // check this for change to reload pages
    selectedSchool: SchoolDto
    schools: SchoolDto[]
    schoolClasses: SchoolClassDto[]
    keyConcepts: KeyConceptDto[]
    statements: StatementDto[]
}

export const AppRouter = (props) => {
    let history = useHistory()
    const [sessionData, setSessionData] = useState(null)
    const [spinnerSentences, setSpinnerSentences] = useState([])
    const [isDataLoading, setIsDataLoading] = useState(false)

    const onAuthRequired = () => {
        history.push('/login')
    }

    const restoreOriginalUri = (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri || '/', window.location.origin))
    }

    const location = useLocation();

    //  useEffect(() => {
    // // //  console.log('Location changed',location.pathname);
    // debugger
    //    if (location.pathname !== '/login')
    //      setIsDataLoading(true)

    //  }, [location]);

    useEffect(() => {
        if (["/demo","/trial",'/demo-iframe', '/trial-iframe','/order/apply-discount', '/order/applydiscount','/order','/order-iframe'].findIndex(v=>location.pathname.toLocaleLowerCase().startsWith(v.toLocaleLowerCase())) > -1){
            return;
        }
        else{
            setIsDataLoading(true)
            setSpinnerSentences([])
        }

    }, [])

    return (
        <div style={{ height: '100%' }}>
            <Security
                oktaAuth={
                    new OktaAuth({
                        issuer: 'https://dev-537791.okta.com/oauth2/default',
                        clientId: getOktaClientId(),
                        redirectUri: window.location.origin + '/login/callback',
                    })
                }
                restoreOriginalUri={(a, b) => restoreOriginalUri(a, b)}
                onAuthRequired={() => onAuthRequired()}
                pkce={true}
            >
                <Switch>
                    <Route
                        exact
                        path={['/demo', '/trial']}
                        render={(props) => (
                            <Demo
                                {...props}
                                setIsDataLoading={(visible, sentences = null) => {
                                    setIsDataLoading(visible)
                                    setSpinnerSentences(sentences)
                                }}
                                isDataLoading={isDataLoading}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={['/demo-iframe', '/trial-iframe']}
                        render={(props) => (
                            <DemoIFrame
                                {...props}
                                setIsDataLoading={(visible, sentences = null) => {
                                    setIsDataLoading(visible)
                                    setSpinnerSentences(sentences)
                                }}
                                isDataLoading={isDataLoading}
                                inPortal={false}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={['/demo/complete', '/trial/complete']}
                        component={DemoComplete}
                    />
                    <Route
                        exact
                        path="/login"
                        render={() => (
                            <Login
                                config={oktaSignInConfig}
                                setIsDataLoading={(visible, sentences = null) => {
                                    setIsDataLoading(visible)
                                    setSpinnerSentences(sentences)
                                }}
                            />
                        )}
                    />
                    <Route path="/implicit/callback" component={LoginCallback} />
                    <Route
                        exact
                        path={['/order/apply-discount', '/order/applydiscount']}
                        render={(props) => (
                            <Order
                                {...props}
                                applyDiscount={true}
                                inPortal={false}
                                sessionData={null}
                                setIsDataLoading={(visible, sentences = null) => {
                                    setIsDataLoading(visible)
                                    setSpinnerSentences(sentences)
                                }}
                                isDataLoading={isDataLoading}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/order"
                        render={(props) => (
                            <Order
                                {...props}
                                applyDiscount={false}
                                inPortal={false}
                                sessionData={null}
                                setIsDataLoading={(visible, sentences = null) => {
                                    setIsDataLoading(visible)
                                    setSpinnerSentences(sentences)
                                }}
                                isDataLoading={isDataLoading}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/order-iframe"
                        render={(props) => (
                            <OrderIFrame
                                {...props}
                                applyDiscount={false}
                                inPortal={false}
                                setIsDataLoading={(visible, sentences = null) => {
                                    setIsDataLoading(visible)
                                    setSpinnerSentences(sentences)
                                }}
                                isDataLoading={isDataLoading}
                            />
                        )}
                    />

                    <Route
                        path={['/order/payment/:intentId/:validForOrderStatus']}
                        render={(props) => (
                            <Payment
                                {...props}
                                inPortal={false}
                                setIsDataLoading={(visible, sentences = null) => {
                                    setIsDataLoading(visible)
                                    setSpinnerSentences(sentences)
                                }}
                                isDataLoading={isDataLoading}
                            />
                        )}
                    />
                    <Route exact path={['/order/complete/:orderType']} component={OrderComplete} />
                    <Route exact path="/sandbox" render={() => <Sandbox />} />

                    <SecureRoute path="*">
                        {/* <SecureRoute> */}
                        <SecureApp
                            {...props}
                            sessionData={sessionData}
                            onChangeSessionData={(data: ISessionData) => setSessionData(data)}
                            setIsDataLoading={(visible, sentences = null) => {
                                setIsDataLoading(visible)
                                setSpinnerSentences(sentences)
                            }}
                            isDataLoading={isDataLoading}
                        >
                            {/* navMenuDataLoaded={context.navMenuDataLoaded}  */}
                            <SecureRoute
                                exact
                                path={['/', '/home']}
                                render={(props) => (
                                    <Home
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            <SecureRoute exact path="/no-data-yet" component={NoDataYet} />
                            <SecureRoute
                                exact
                                path="/student"
                                render={(props) => (
                                    <Student
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            <SecureRoute
                                exact
                                path="/spaced-practice"
                                render={(props) => (
                                    <SpacedPractice
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            <SecureRoute
                                exact
                                path="/spaced-practice/result/:studentId/:start/:end"
                                render={(props) => (
                                    <SpacedPracticeResult
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            <SecureRoute
                                exact
                                path="/student/profile/:id"
                                render={(props) => (
                                    <StudentProfile
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            <SecureRoute exact path="/help" component={HelpIndex} />
                            <SecureRoute exact path="/help/faq" component={Faq} />
                            <SecureRoute
                                exact
                                path="/admin/order"
                                render={(props) => (
                                    <Order
                                        {...props}
                                        sessionData={sessionData}
                                        applyDiscount={false}
                                        inPortal={true}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            <SecureRoute
                                path={['/admin/order/payment/:intentId/:validForOrderStatus']}
                                render={(props) => (
                                    <Payment
                                        {...props}
                                        sessionData={sessionData}
                                        inPortal={true}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            <SecureRoute
                                exact
                                path={['/admin/order/complete/:orderType']}
                                render={(props) => (
                                    <OrderComplete
                                        {...props}
                                        sessionData={sessionData}
                                        inPortal={true}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            <SecureRoute
                                exact
                                path="/admin/select/:schoolId"
                                render={(props) => (
                                    <AdminIndex
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            <SecureRoute
                                exact
                                path="/admin/licence/:schoolId"
                                render={(props) => (
                                    <Licence
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            <SecureRoute
                                exact
                                path="/admin/teacher/:schoolId"
                                render={(props) => (
                                    <TeacherIndex
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            <SecureRoute
                                exact
                                path="/admin/teacher/bulk/:schoolId"
                                render={(props) => (
                                    <TeacherCreateBulk
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            <SecureRoute
                                exact
                                path="/admin/teacher/create/:schoolId"
                                render={(props) => (
                                    <TeacherCreate
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            <SecureRoute
                                exact
                                path="/admin/teacher/edit/:teacherId"
                                render={(props) => (
                                    <TeacherEdit
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            {/* reloadNavBarClass={reloadNavBarClass} */}
                            <SecureRoute
                                exact
                                path="/admin/class/:schoolId"
                                render={(props) => (
                                    <SchoolClassIndex
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                        onChangeSessionData={(data: ISessionData) =>
                                            setSessionData(data)
                                        }
                                    />
                                )}
                            />
                            <SecureRoute
                                exact
                                path="/admin/class/bulk/:schoolId"
                                render={(props) => (
                                    <SchoolClassCreateBulk
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            <SecureRoute
                                exact
                                path="/admin/school/rollover/:schoolId"
                                // component={SchoolBulkRollover}
                                render={(props) => (
                                    <SchoolBulkRollover
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            {/* reloadNavBarClass={reloadNavBarClass} */}
                            <SecureRoute
                                exact
                                path="/admin/class/create/:schoolId"
                                render={(props) => (
                                    <SchoolClassCreate
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                        onChangeSessionData={(data: ISessionData) =>
                                            setSessionData(data)
                                        }
                                    />
                                )}
                            />
                            {/* reloadNavBarClass={reloadNavBarClass} */}
                            <SecureRoute
                                exact
                                path="/admin/class/edit/:schoolId/:schoolClassId"
                                render={(props) => (
                                    <SchoolClassEdit
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                        onChangeSessionData={(data: ISessionData) =>
                                            setSessionData(data)
                                        }
                                    />
                                )}
                            />
                            {/*  navSchoolClassIdSelected={navSchoolClassIdSelected} */}
                            <SecureRoute
                                exact
                                path="/assessment"
                                render={(props) => (
                                    <DiagnosticIndex
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            {/*  navSchoolClassIdSelected={navSchoolClassIdSelected} */}

                            <SecureRoute
                                exact
                                path="/assessment/create"
                                //  component={DiagnosticCreate}
                                render={(props) => (
                                    <DiagnosticCreate
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            {/*  navSchoolClassIdSelected={navSchoolClassIdSelected} */}

                            <SecureRoute
                                exact
                                path="/assessment/details/:diagnosticId"
                                // component={DiagnosticDetails}
                                render={(props) => (
                                    <DiagnosticDetails
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            {/*  navSchoolClassIdSelected={navSchoolClassIdSelected} */}

                            <SecureRoute
                                exact
                                path="/assessment/edit/:diagnosticId"
                                //  component={DiagnosticEdit}
                                render={(props) => (
                                    <DiagnosticEdit
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            {/*  navSchoolClassIdSelected={navSchoolClassIdSelected} */}

                            <SecureRoute
                                exact
                                path="/assessment/details/:diagnosticId/:studentId/:parentPage"
                                // component={StudentDiagnosticIndex}
                                render={(props) => (
                                    <StudentDiagnosticIndex
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            {/*  navSchoolClassIdSelected={navSchoolClassIdSelected} */}

                            <SecureRoute
                                exact
                                path="/assessment/results/:diagnosticId/:studentId/:parentPage"
                                //   component={StudentDiagnosticResult}
                                render={(props) => (
                                    <StudentDiagnosticResult
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            {/*  navSchoolClassIdSelected={navSchoolClassIdSelected} */}

                            <SecureRoute
                                exact
                                path="/student/create/:schoolClassId"
                                //  component={StudentCreate}
                                render={(props) => (
                                    <StudentCreate
                                        {...props}
                                        sessionData={sessionData}
                                        //  screenLoader={spinner}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            {/*  navSchoolClassIdSelected={navSchoolClassIdSelected} */}

                            <SecureRoute
                                exact
                                path="/student/edit/:id"
                                // component={StudentEdit}
                                render={(props) => (
                                    <StudentEdit
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />

                            {/*  navSchoolClassIdSelected={navSchoolClassIdSelected} */}
                            <SecureRoute
                                exact
                                path="/class/student/rollover"
                                // component={StudentEdit}
                                render={(props) => (
                                    <StudentRollover
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />

                            {/*  navSchoolClassIdSelected={navSchoolClassIdSelected} */}

                            <SecureRoute
                                exact
                                path="/class/student/bulk"
                                //  component={StudentCreateBulk}
                                render={(props) => (
                                    <StudentCreateBulk
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            {/*  navSchoolClassIdSelected={navSchoolClassIdSelected} */}

                            <SecureRoute
                                exact
                                path="/data"
                                render={(props) => (
                                    <Data
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            {/*  navSchoolClassIdSelected={navSchoolClassIdSelected} */}

                            <SecureRoute
                                exact
                                path="/data/class"
                                // component={ClassReportPage}
                                render={(props) => (
                                    <ClassReportPage
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            {/*  navSchoolClassIdSelected={navSchoolClassIdSelected} */}

                            <SecureRoute
                                exact
                                path="/data/individual"
                                // component={StudentListReportPage}
                                render={(props) => (
                                    <StudentListReportPage
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            {/*  navSchoolClassIdSelected={navSchoolClassIdSelected} */}

                            <SecureRoute
                                exact
                                path="/data/individual/:studentId"
                                //   component={StudentIndividualReportPage}
                                render={(props) => (
                                    <StudentIndividualReportPage
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            {/*  navSchoolClassIdSelected={navSchoolClassIdSelected} */}

                            <SecureRoute
                                exact
                                path="/data/component/:componentId"
                                render={(props) => (
                                    <ComponentReport
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            {/*  navSchoolClassIdSelected={navSchoolClassIdSelected} */}

                            <SecureRoute
                                exact
                                path="/group"
                                render={(props) => (
                                    <GroupIndex
                                        {...props}
                                        sessionData={sessionData}
                                        setIsDataLoading={(visible, sentences = null) => {
                                            setIsDataLoading(visible)
                                            setSpinnerSentences(sentences)
                                        }}
                                        isDataLoading={isDataLoading}
                                    />
                                )}
                            />
                            {/* <SecureRoute
                                 path="*"
                                render={(props) => (
                                    <NotFound
                                        setIsDataLoading={(visible) => setIsDataLoading(visible)}
                                    />
                                )}
                            /> */}
                            {/* <SecureRoute exact path="/group/create" component={GroupCreate} /> */}
                        </SecureApp>

                     <SecureRoute
                                render={(props) => (
                                    <NotFound
                                        setIsDataLoading={(visible) => setIsDataLoading(visible)}
                                    />
                                )}
                            />

                    </SecureRoute>
                    <Route
                        render={(props) => (
                            <NotFound
                                setIsDataLoading={(visible, sentences = null) => {
                                    setIsDataLoading(visible)
                                    setSpinnerSentences(sentences)
                                }}
                            />
                        )}
                    />
                </Switch>
            </Security>

            <FullPageSpinner sentences={spinnerSentences} isVisible={isDataLoading} />
        </div>
    )
}
