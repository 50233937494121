import { getClientWebsiteType, WebsiteType } from './shared/Helper'

export const getOktaClientId = () => {
    let type = getClientWebsiteType()

    switch (type) {
        case WebsiteType.DEMO:
            return '0oa1mln4lfkbREEPc357'
        case WebsiteType.LOCAL_WITH_LOCAL_API:
        case WebsiteType.LOCAL_WITH_EXTERNAL_API:
        case WebsiteType.TESTING:
            //  case WebsiteType.STATIC:
            return '0oa1mljqhnIJdFByq357'
        case WebsiteType.PRODUCTION:
            return '0oatxifb7T0o65YZL356'
        case WebsiteType.STAGING:
            return '0oa5qoaqcsEJSj7ea357'
        default:
            return '0oa1mljqhnIJdFByq357'
    }
}

const getStripePublicKey = () => {
    let type = getClientWebsiteType()

    switch (type) {
        case WebsiteType.DEMO:
        case WebsiteType.LOCAL_WITH_LOCAL_API:
        case WebsiteType.LOCAL_WITH_EXTERNAL_API:
        case WebsiteType.TESTING:
            //  case WebsiteType.STATIC:
            return 'pk_test_51HWuLfGyi6J9GrDZo4c0WUiFGVuh8HwaBs5U1D5FtIyqNh0CdhJGHa7BRbHXkqvXDewbYHqVFTCYgPvMWDBiMECg00aNOeEUB0'
        case WebsiteType.STAGING:
        case WebsiteType.PRODUCTION:
            return 'pk_live_yvpgSpSnYnY3XHsBmh887yNz'
        default:
            console.log('getStripePublicKey website type not found')
    }
}

export default {
    url: 'https://dev-537791.okta.com',
    issuer: 'https://dev-537791.okta.com/oauth2/default',
    redirect_uri: `${window.location.origin.toLowerCase()}/implicit/callback`,
    client_id: getOktaClientId(),
    stripePublicKey: getStripePublicKey(),
    //isOrderComplete: false
}

// 0oatxifb7T0o65YZL356 TeacherProdWebSite
// 0oa1mljqhnIJdFByq357 TeacherTestingWebSite
// 0oa1mln4lfkbREEPc357 TeacherDemoWebSite
