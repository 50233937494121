/* eslint-disable no-unused-vars */
import { DiagnosticDto } from './Diagnostic'

export interface StudentDto {
    id: string
    firstName: string
    lastName: string
    preferredName: string
    username: string
    password: string
    schoolClassId: string
    schoolClassLevel: string
    isLicenced: boolean
    currentDiagnostic: DiagnosticDto
}

export interface StudentDiagnosticItemDto {
    studentId: string
    firstName: string
    lastName: string
    name: string
    keyConcepts: any[]
    timeFormatted: string
}

export interface StudentKeyConceptDto {
    studentId: string
    firstName: string
    lastName: string
    keyConcepts: any[]
    timeFormatted: string
}

export interface StudentItem {
    studentId: string
    firstName: string
    lastName: string
    items: any[]
    timeFormatted: string
    currentDiagnostic: StudentDiagnosticItemDto
}

export interface StudentCreateDto {
    firstName: string
    lastName: string
    preferredName: string
    password: string
    schoolClassId: string
    schoolClassLevel: string
}

export interface StudentMoveDto {
    studentId: string
    oldSchoolClassId: string
    newSchoolClassId: string
    newSchoolClassName: string
    firstName: string
    lastName: string
}

export enum StudentSchoolClassLogType {
    Initial,
    Move,
    Rollover,
}
