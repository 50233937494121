import React, { useEffect, useState } from 'react'
import {
    Collapse,
    Container,
    Navbar,
    NavbarToggler,
    NavItem,
    NavLink,
    Nav,
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap'
import { Link, RouteComponentProps } from 'react-router-dom'
import { AuthService, AuthState } from '../../models/Auth'
import { SchoolClassDto } from '../../models/SchoolClass'
import {
    GetShorterName,
    archivedClassGuid,
    getWebsiteTypeHeading,
    isTeacherSuper,
    isTeacherAdminOrSuper,
    logout,
} from '../../shared/Helper'
import './NavMenu.css'
import logo_mini from '../../assets/images/EarlyInsights_mini_logo.png'
import 'tippy.js/dist/tippy.css' // optional
import 'tippy.js/animations/scale-extreme.css'
import BaseClass from '../Bases/BaseClass'
import { SaveSalesForceActionApi } from '../../services/OrderApi'
import { SalesForceActionEnum } from '../../models/Order'
import { SchoolDto } from '../../models/School'
import { updateSuperTeacherToNewSchoolApi } from '../../services/TeacherApi'
import { ISessionData } from '../../AppRouter'
import { getSchoolClassesFromApi } from '../../services/SchoolClassApi'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import {
    removeSelectedSchoolClassFromLocalStorage,
    setSelectedSchoolClassToLocalStorage,
    setSelectedSchoolToLocalStorage,
} from '../../shared/LocalStorageHelpers'
import { FormHelperText } from '@material-ui/core'

interface SecureNavMenuProps extends RouteComponentProps {
    authService: AuthService
    authState: AuthState
    sessionData: ISessionData
    onChangeSessionData: any
    setIsDataLoading: any
    isDataLoading: boolean
    refreshAllData: any
}

// interface SecureNavMenuState {
//   error: string;
//   visible: boolean;
//   isLoading: boolean;
//   collapsed: boolean;
//   dropdownOpen: boolean;
//   schooldropdownOpen: boolean;
//   isPopoverOpen: boolean;
//   selectedNavId: string;
//   sessionData: ISessionData;
// }

export default function SecureNavMenu(props: SecureNavMenuProps) {
    //class NavMenu extends BaseClass<NavMenuProps, NavMenuState> {
    const defaultSelectedClass: SchoolClassDto = {
        id: '',
        userId: '',
        name: '',
        levels: [''],
        teachers: [],
        schoolId: '',
        calendarYear: 2020,
    }

    const defaultSelectedSchool: SchoolDto = {
        id: '',
        name: '',
    }

    const [error, setError] = useState('')
    const [visible, setVisible] = useState(false)
    const [title, setTitle] = useState('Students ()')
    const { oktaAuth, authState, authService } = useOktaAuth()

    const [schooldropdownOpen, setSchooldropdownOpen] = useState(false)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [collapsed, setCollapsed] = useState(true)
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    const [selectedNavId, setSelectedNavId] = useState('')

    const [sessionData, setSessionData] = useState(props.sessionData)
    const history = useHistory()

    // constructor(props: NavMenuProps) {
    //   super(props);
    //   state = {
    //     schooldropdownOpen: false,
    //     error: "",
    //     visible: false,
    //     isLoading: false,
    //     collapsed: true,
    //     dropdownOpen: false,
    //     isPopoverOpen: false,
    //     selectedNavId: "",
    //     sessionData: props.sessionData,
    //   };
    // }

    const togglePopover = () => {}

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        if (props.sessionData.selectedSchoolClass) {
            if (
                !props.sessionData.schoolClasses.find(
                    (v) => v.id == props.sessionData.selectedSchoolClass.id
                )
            ) {
                props.sessionData.selectedSchoolClass = props.sessionData.schoolClasses[0]
            }
        }

        // if (!props.sessionData.selectedSchoolClass) {
        //   if (props.sessionData.schoolClasses.length > 0)
        //     props.sessionData.selectedSchoolClass = props.sessionData.schoolClasses[]
        // }

        setSessionData(props.sessionData)
    }, [props.sessionData])

    const loadData = async () => {}

    const getUserSchoolClasses = () => {
        let token = authState.accessToken.accessToken
        return getSchoolClassesFromApi(token, true)
            .then((res) => {
                if (res.ok) {
                    //  populateSchoolClasses(res.data);
                    return res.data
                }
                // handleknownError(res.error)
            })
            .catch((error) => {
                // handleUnknownError(error)
            })
    }

    const selectSchoolClass = (schoolClasses: SchoolClassDto[]) => {
        if (doesSchoolClassExist(sessionData.selectedSchoolClass, schoolClasses)) {
            return sessionData.selectedSchoolClass
        } else if (schoolClasses.length > 0) {
            return schoolClasses[0]
        } else {
            // removeSelectedSchoolClassFromLocalStorage();
        }
    }

    const changeSchoolClass = (schoolClass: SchoolClassDto) => {
        let updatedSessionData = {
            ...sessionData,
            selectedSchoolClass: schoolClass,
            //  schoolClasses,
        }

        setSessionData(updatedSessionData)
        props.onChangeSessionData(updatedSessionData)
        setSelectedSchoolClassToLocalStorage(schoolClass)
    }

    const doesSchoolClassExist = (selectedSchoolClass, schoolClasses: SchoolClassDto[]) => {
        return (
            selectedSchoolClass &&
            schoolClasses.findIndex(
                (sc) => sc.id === selectedSchoolClass.id && sc.name === selectedSchoolClass.name
            ) !== -1
        )
    }

    const toggleNavbar = () => {
        setCollapsed(!collapsed)
    }

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen)
    }

    const toggleSchoolDropdown = () => {
        setSchooldropdownOpen(!schooldropdownOpen)
    }

    const changeSchool = async (school: SchoolDto) => {
        if (sessionData.selectedSchool !== school) {
            await assignSuperTeacherToSchool(sessionData.authenticatedTeacher.id, school.id)
            await props.refreshAllData()
            if (isTeacherSuper(sessionData.authenticatedTeacher)) {
                let index = sessionData.schools.findIndex(
                    (v) => v.id === sessionData.authenticatedTeacher.schoolId
                )

                if (index >= 0) {
                    let schoolClasses = await getUserSchoolClasses()
                    let updatedSessionData = {
                        ...sessionData,
                        selectedSchool: school,
                        schoolClasses,
                        selectedSchoolClass: schoolClasses[0],
                    }

                    setSessionData(updatedSessionData)
                    props.onChangeSessionData(updatedSessionData)
                    removeSelectedSchoolClassFromLocalStorage()
                    setSelectedSchoolToLocalStorage(school)

                    history.push('/')
                }
            }

            return school
        }
    }

    const formatSchoolClassName = (schoolClassName: string) => {
        if (schoolClassName.length > 20) {
            return `${GetShorterName(schoolClassName, 20)}...`
        }

        return schoolClassName
    }

    const formatSchoolName = (schoolName: string) => {
        if (schoolName.length > 20) {
            return `${GetShorterName(schoolName, 20)}...`
        }

        return schoolName
    }

    const assignSuperTeacherToSchool = (teacherId, schoolId) => {
        return updateSuperTeacherToNewSchoolApi(
            teacherId,
            schoolId,
            authState.accessToken.accessToken
        )
            .then((res) => {
                if (res.ok) {
                    return res.data
                }
                //  handleknownError(res.errors);
            })
            .catch((error) => {
                console.log(error)
                // handleUnknownError(error);
            })
    }

    const saveSalesForceAction = (demoAction: SalesForceActionEnum) => {
        if (
            !sessionData.authenticatedTeacher.isDemo ||
            !sessionData.authenticatedTeacher.demoOpportunityId
        ) {
            return
        }

        let token = authState.accessToken.accessToken

        return SaveSalesForceActionApi(
            token,
            sessionData.authenticatedTeacher.demoOpportunityId,
            demoAction
        )
            .then((res) => {
                if (res.ok) {
                    return res.data
                }
                //  handleknownError(res.errors);
            })
            .catch((error) => {
                console.log(error)
                // handleUnknownError(error);
            })
    }

    const getStyle = (id) => {
        if (id === selectedNavId) {
            return { fontWeight: 700, color: 'white' }
        }

        return { fontWeight: 400, color: 'white' }
    }

    if (!sessionData) return null

    // if (props.authState.isPending || !context.navMenuDataLoaded) return <div></div>;
    const admin =
        sessionData.authenticatedTeacher &&
        (isTeacherAdminOrSuper(sessionData.authenticatedTeacher.accessLevel) ||
        sessionData.authenticatedTeacher.isDemo ? (
            <React.Fragment>
                <NavItem>
                    <NavLink
                        data-cy="nav-admin-button"
                        id="admin-popover"
                        tag={Link}
                        onClick={() => setSelectedNavId('admin')}
                        style={getStyle('admin')}
                        to={`/admin/select/${sessionData.authenticatedTeacher.schoolId}`}
                    >
                        Admin
                    </NavLink>
                </NavItem>
            </React.Fragment>
        ) : null)

    const menuName = (
        <React.Fragment>
            <NavItem style={{ padding: '0', border: '0' }}>
                <NavLink
                    data-cy="nav-home-button"
                    tag={Link}
                    to="/"
                    style={{ padding: '0' }}
                    onClick={() => setSelectedNavId('home')}
                >
                    <img className="logo-mini" alt="logo" src={logo_mini}></img>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    data-cy="nav-students-button"
                    tag={Link}
                    to="/student"
                    onClick={() => setSelectedNavId('student')}
                    style={getStyle('student')}
                >
                    Students
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    data-cy="nav-assessment-button"
                    tag={Link}
                    to="/assessment"
                    onClick={() => {
                        saveSalesForceAction(SalesForceActionEnum.Demo_assessment_page_viewed__c)

                        setSelectedNavId('assessment')
                    }}
                    style={getStyle('assessment')}
                >
                    Assessment
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    data-cy="nav-data-button"
                    tag={Link}
                    to="/data"
                    onClick={() => {
                        saveSalesForceAction(SalesForceActionEnum.Demo_data_page_viewed__c)

                        setSelectedNavId('data')
                    }}
                    style={getStyle('data')}
                >
                    Data
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    data-cy="nav-groups-button"
                    tag={Link}
                    to="/group"
                    onClick={() => setSelectedNavId('groups')}
                    style={getStyle('groups')}
                >
                    Groups
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    data-cy="nav-spaced-practice-button"
                    tag={Link}
                    to="/spaced-practice"
                    onClick={() => setSelectedNavId('spaced-practice')}
                    style={getStyle('spaced-practice')}
                >
                    Spaced Practice
                </NavLink>
            </NavItem>
            {admin}
        </React.Fragment>
    )

    const authNav = (
        <Nav>
            {isTeacherSuper(sessionData.authenticatedTeacher) ? (
                <Dropdown
                    id="school-dropdown"
                    nav
                    isOpen={schooldropdownOpen}
                    toggle={() => toggleSchoolDropdown()}
                >
                    <DropdownToggle nav caret className="text-white">
                        {sessionData.selectedSchool ? sessionData.selectedSchool.name : ''}
                    </DropdownToggle>
                    <DropdownMenu>
                        {sessionData.schools.map((school: SchoolDto) => {
                            return (
                                <DropdownItem
                                    id={school.id}
                                    key={school.id}
                                    onClick={() => changeSchool(school)}
                                >
                                    <strong>{formatSchoolName(school.name)}</strong>
                                </DropdownItem>
                            )
                        })}
                    </DropdownMenu>
                </Dropdown>
            ) : null}

            {sessionData.schoolClasses.length === 0 ? (
                <span className="navbar-text">No Class Available</span>
            ) : (
                <Dropdown
                    id="school-class-dropdown"
                    nav
                    isOpen={dropdownOpen}
                    toggle={() => toggleDropdown()}
                >
                    <DropdownToggle nav caret className="text-white">
                        {sessionData.selectedSchoolClass
                            ? formatSchoolClassName(sessionData.selectedSchoolClass.name)
                            : ''}
                    </DropdownToggle>
                    <DropdownMenu>
                        {sessionData.schoolClasses.map((schoolClass: SchoolClassDto) => {
                            return (
                                <DropdownItem
                                    key={schoolClass.id}
                                    id={schoolClass.id}
                                    onClick={() => changeSchoolClass(schoolClass)}
                                >
                                    {schoolClass.schoolId !==
                                    sessionData.authenticatedTeacher.schoolId ? (
                                        <>
                                            <strong>
                                                {formatSchoolClassName(schoolClass.name)}
                                            </strong>
                                        </>
                                    ) : schoolClass.id === archivedClassGuid ? (
                                        <>{formatSchoolClassName(schoolClass.name)}</>
                                    ) : (
                                        <>{formatSchoolClassName(schoolClass.name)}</>
                                    )}
                                </DropdownItem>
                            )
                        })}
                    </DropdownMenu>
                </Dropdown>
            )}
            <Button
                data-cy="nav-logout-button"
                auto="true"
                tag={Link}
                outline
                className="text-white"
                color="white"
                to="/"
                onClick={() => {
                    //  props.setIsDataLoading(true) // causes double load on logout
                    logout(oktaAuth)
                }}
            >
                Logout{getWebsiteTypeHeading()}
            </Button>
        </Nav>
    )

    return (
        <>
            <header>
                <Navbar
                    data-cy="secure-nav-menu-loaded"
                    className="navbar-expand-sm navbar-toggleable-sm navbar-custom border-bottom box-shadow mb-3"
                    light
                >
                    <Container>
                        <NavbarToggler onClick={() => toggleNavbar()} className="mr-2" />
                        <Collapse className="collapse navbar-collapse" isOpen={!collapsed} navbar>
                            <Nav className="navbar-nav mr-auto">{menuName}</Nav>
                            <div>{authNav}</div>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        </>
    )
}

//export default NavMenu;
