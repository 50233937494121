import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { withOktaAuth } from '@okta/okta-react'
import { StudentDto } from '../../models/Student'
import { SchoolClassDto } from '../../models/SchoolClass'
import { getStudentsFromApi } from '../../services/StudentApi'
import { Button, Table } from 'reactstrap'
import { AuthService, AuthState } from '../../models/Auth'
import BaseClass from '../Bases/BaseClass'
import BreadCrumbs from '../layout/BreadCrumbs'
import { isTeacherAdminOrSuper } from '../../shared/Helper'
import { ISessionData } from '../../AppRouter'

interface IProps extends RouteComponentProps<any> {
    // baseUrl: string;
    authService: AuthService
    authState: AuthState
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
    history: any
    match: any
    // navSchoolClassIdSelected: string;
}

interface IState {
    studentList: StudentDto[]
    isLoading: boolean
    error: string
    visible: boolean
    selectedSchoolClass: SchoolClassDto | null
    title: string
    isDataLoading: boolean
}

class StudentListReportPage extends BaseClass<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            isLoading: true,
            studentList: Array<StudentDto>(),
            error: '',
            visible: false,

            selectedSchoolClass: null,
            title: 'Students ()',
            isDataLoading: true,
        }
    }

    async componentDidMount() {
        this.loadData()
    }

    componentDidUpdate(prevProps) {}

    async loadData() {
        let teacher = this.props.sessionData.authenticatedTeacher

        if (!teacher) {
            return
        }

        this.props.setIsDataLoading(true)
        let selectedClass = this.props.sessionData.selectedSchoolClass

        if (selectedClass) {
            this.setState({
                selectedSchoolClass: selectedClass,
                title: `Students (${selectedClass.name})`,
            })

            await this.getStudents(selectedClass.id)
        } else {
            this.props.setIsDataLoading(false)
            if (teacher && isTeacherAdminOrSuper(teacher.accessLevel)) {
                this.props.history.push(`/admin/class/${teacher.schoolId}`)
            } else {
                this.props.history.push('/no-data-yet')
            }
        }

        this.props.setIsDataLoading(false)
    }

    async getStudents(schoolClassId: string) {
        let token = await this.props.authState.accessToken.accessToken

        this.props.setIsDataLoading(true)

        return getStudentsFromApi(schoolClassId, token)
            .then((res) => {
                this.props.setIsDataLoading(false)
                if (res.ok) {
                    this.setState({
                        studentList: res.data,
                    })

                    return res.data
                } else {
                    this.handleknownError(res.error)
                }
            })
            .catch((error) => {
                this.handleUnknownError(error)
            })
    }

    render() {
        // if (this.props.isDataLoading) return null;

        // return (this.state.isLoading || this.props.authState.isPending || !this.context.navMenuDataLoaded) ? (
        return (
            <div>
                <div className="mb-2">
                    <BreadCrumbs
                        items={[
                            { title: 'Home', link: '/' },
                            { title: 'Data', link: '/data' },
                            { title: 'Student List', link: '' },
                        ]}
                    />
                </div>
                <h2 className="pb-2">{this.state.title}</h2>
                <Table bordered striped className="bg-white">
                    <thead>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.studentList.map((student: StudentDto) => {
                            return (
                                <tr key={student.id}>
                                    <td className="align-middle">{student.firstName}</td>
                                    <td className="align-middle">{student.lastName}</td>
                                    <td className="align-middle">
                                        <Button
                                            color="primary"
                                            tag={Link}
                                            to={`individual/${student.id}`}
                                        >
                                            View
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                {/* <div className="d-flex justify-content-between mb-2">
            <div>
              <Button color="primary" tag={Link} to={"/"}>
                Back
            </Button>
            </div>
          </div> */}
            </div>
        )
    }
}

export default withOktaAuth(StudentListReportPage)
