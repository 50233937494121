/* eslint-disable no-unused-vars */
import { useState, useEffect, useContext, useRef } from 'react'
import { StudentMoveDto, StudentSchoolClassLogType } from '../../models/Student'
import { RouteComponentProps } from 'react-router-dom'
import { Button, Input, Label, Table, Alert } from 'reactstrap'
import { getStudentsFromApi, moveStudentsApi } from '../../services/StudentApi'
import './Student.css'
import { useOktaAuth } from '@okta/okta-react'
import { getSchoolClassesFromApi } from '../../services/SchoolClassApi'
import {
    archivedClassGuid,
    logout,
    demoClassGuid,
    ScrollToTop,
    isTeacherAdminOrSuper,
} from '../../shared/Helper'
import { SchoolClassDto } from '../../models/SchoolClass'
import BreadCrumbs from '../layout/BreadCrumbs'
import DemoPopovers from '../layout/DemoPopovers'
import { ISessionData } from '../../AppRouter'

interface IParams {}

interface IProps extends RouteComponentProps<IParams> {
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean,
    history: any
}

export default function StudentRollover(props: IProps) {
    const [studentMoveList, setStudentMoveList] = useState(Array<StudentMoveDto>())
    const [error, setError] = useState('')
    const [visible, setVisible] = useState(false)
    // const [selectedSchoolClass, setSelectedSchoolClass] = useState(null);
    const [title, setTitle] = useState('Students ()')
    const { authState, authService } = useOktaAuth()
    const [schoolClasses, setSchoolClasses] = useState(null)
    const [masterSelectedSchoolClassId, setMasterSelectedSchoolClassId] = useState(null)

    // useEffect(() => {
    // 	loadData();
    // }, []);

    // //TODO:
    // useEffect(
    // 	() => {
    // 		props.history.push('/');
    // 	},
    // 	[ props.sessionData ]
    // );

    useEffect(() => {
        loadData()
    }, [props.sessionData])

    const loadData = async () => {
        props.setIsDataLoading(true)
        let teacher = props.sessionData.authenticatedTeacher
        if (!teacher) return

        let selectedSchoolClass = props.sessionData.selectedSchoolClass

        if (selectedSchoolClass) {
            setTitle(`Students Move/Rollover class (${selectedSchoolClass.name})`)
            //  setSelectedSchoolClass(selectedSchoolClass);
            await getUserSchoolClasses()
            await getStudents(selectedSchoolClass)
            setMasterSelectedSchoolClassId(selectedSchoolClass.id)
        } else {
            props.setIsDataLoading(false)
            if (teacher && isTeacherAdminOrSuper(teacher.accessLevel)) {
                props.history.push(`/admin/class/${teacher.schoolId}`)
            } else {
                props.history.push('/no-data-yet')
            }
        }

        props.setIsDataLoading(false)
    }

    const getUserSchoolClasses = async () => {
        props.setIsDataLoading(true)
        let token = await authState.accessToken.accessToken
        return getSchoolClassesFromApi(token, true)
            .then((res) => {
                if (res.ok) {
                    props.setIsDataLoading(false)
                    setSchoolClasses(res.data)
                } else {
                    handleknownError(res.error)
                }
            })
            .catch((error) => {
                handleUnknownError(error)
            })
    }

    const setPageIsLoading = (loading) => {
        props.setIsDataLoading(loading)
        ;(context as any).setShowScreenLoader(loading)
    }

    const handleknownError = (errors) => {
        ScrollToTop()
        //setShowMoveClassModal(false)
        props.setIsDataLoading(false)
        setError(errors.detail)
        setVisible(true)
    }

    const handleUnknownError = (error) => {
        ScrollToTop()
        if (error.status === 401) {
            //   return logout(authService);
        }

        props.setIsDataLoading(false)
        console.log(error)
        setError(error.statusText)
    }

    const getStudents = async (schoolClass: SchoolClassDto) => {
        let token = await authState.accessToken.accessToken

        props.setIsDataLoading(true)

        return getStudentsFromApi(schoolClass.id, token)
            .then((res) => {
                props.setIsDataLoading(false)
                if (res.ok) {
                    let result = []

                    res.data.forEach((item) => {
                        result.push({
                            studentId: item.id,
                            oldSchoolClassId: schoolClass.id,
                            newSchoolClassId: schoolClass.id,
                            newSchoolClassName: schoolClass.name,
                            firstName: item.firstName,
                            lastName: item.lastName,
                        })
                    })

                    setStudentMoveList(result)
                } else {
                    handleknownError(res.error)
                }
            })
            .catch((error) => {
                handleUnknownError(error)
            })
    }

    const goBack = () => {
        ;(props.history as any).goBack()
    }

    const rollover = async () => {
        return moveStudentsApi(
            StudentSchoolClassLogType.Rollover,
            studentMoveList,
            authState.accessToken.accessToken
        )
            .then((res) => {
                if (res.ok) {
                    alert(`You have successfully rolled over ${res.data} students`)
                    loadData()
                } else {
                    handleknownError(res.error)
                }
            })
            .catch((error) => {
                handleUnknownError(error)
            })
    }

    const setMoveStudent = (studentId, classId) => {
        const i = studentMoveList.findIndex((item) => item.studentId === studentId)
        let schoolClass = schoolClasses.find((v) => v.id === classId)
        let studentMoveListCopy = [...studentMoveList]

        studentMoveListCopy[i].newSchoolClassId = schoolClass.id
        studentMoveListCopy[i].newSchoolClassName = schoolClass.name
        setStudentMoveList(studentMoveListCopy)
    }

    return (
        <div className="mb-2">
            {props.sessionData.authenticatedTeacher.isDemo && (
                <DemoPopovers items={[{ target: 'rollover', placement: 'top' }]} />
            )}

            <div className="mb-2">
                <BreadCrumbs
                    items={[
                        { title: 'Home', link: '/' },
                        { title: 'Students', link: '/student' },
                        { title: 'Rollover', link: '' },
                    ]}
                />
            </div>
            <h2 className="pb-2">{title}</h2>
            {visible ? (
                <Alert color="warning">
                    <p>{error}</p>
                </Alert>
            ) : null}

            <div className="mb-2">
                {/* <Label className="label-bold-style" for="exampleSelect" >
Change all:          </Label> */}

                <p className="font-weight-light">
                    Use this area to Move, or Rollover students at the end of the year. Once you click
                    &apos;Move&apos;, students and their data will immediately move to their new
                    classes.
                </p>

                <ul className="font-weight-light">
                    <li>
                        A destination class must already exist before you can move students.
                        Teachers with Admin access can create new classes.
                    </li>
                    <li>
                        If you don&apos;t select a new destination class for a student, their data
                        will stay in this class and they can be rolled over later if necessary.
                    </li>
                </ul>

                <Label className="label-bold-style" for="masterSelect">
                    Select a destination class for all students:
                </Label>

                <Input
                    value={masterSelectedSchoolClassId}
                    type="select"
                    name="select"
                    id="masterSelect"
                    onChange={(e) => {
                        setMasterSelectedSchoolClassId(e.target.value)
                        studentMoveList.forEach((element) => {
                            setMoveStudent(element.studentId, e.target.value)
                        })
                    }}
                >
                    {/* <option key={selectedSchoolClass.id} value={selectedSchoolClass.name}>
              {selectedSchoolClass.name}
            </option> */}
                    {schoolClasses &&
                        schoolClasses.map((schoolClass: SchoolClassDto) => {
                            return (
                                <option key={schoolClass.id} value={schoolClass.id}>
                                    {schoolClass.name}
                                </option>
                            )
                        })}
                </Input>
            </div>

            <Table bordered striped className="bg-white">
                <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        {/* <th>Preferred Name</th> */}
                        {/* <th>Username</th> */}
                        {/* <th>Password</th> */}
                        {/* <th>Year Level</th> */}
                        <th id="actions">Destination Class</th>
                    </tr>
                </thead>
                <tbody>
                    {studentMoveList.map((item: StudentMoveDto) => {
                        return (
                            <tr key={item.studentId}>
                                <td className="align-middle">{item.firstName}</td>
                                <td className="align-middle">{item.lastName}</td>
                                <td className="align-middle text-nowrap">
                                    <Input
                                        value={item.newSchoolClassId}
                                        type="select"
                                        name="select"
                                        id="exampleSelect"
                                        onChange={(e) => {
                                            setMoveStudent(item.studentId, e.target.value)
                                        }}
                                    >
                                        {schoolClasses &&
                                            schoolClasses.map((schoolClass: SchoolClassDto) => {
                                                return (
                                                    <option
                                                        key={schoolClass.id}
                                                        value={schoolClass.id}
                                                    >
                                                        {schoolClass.name}
                                                    </option>
                                                )
                                            })}
                                    </Input>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>

            <div className=" form-group ">
                <div className="space-between">
                    <Button
                        color="secondary"
                        //tag={Link}
                        //to="/student"
                        onClick={goBack}
                        // disabled={updating}
                    >
                        Cancel
                    </Button>
                    <Button
                        id="rollover"
                        color="primary"
                        type="submit"
                        disabled={props.sessionData.selectedSchoolClass.id === demoClassGuid}
                        onClick={rollover}
                    >
                        {' '}
                        Move to new classes now
                    </Button>
                </div>
            </div>
            <div className="row m-2" />
        </div>
    )
}
