import { AxiosResponse, AxiosError } from 'axios'

const clearCache = (reloadAfterClear: boolean) => {
    if('caches' in window){
        caches.keys().then((names) => {
            names.forEach(async (name) => {
                await caches.delete(name)
            })
        })

        if(reloadAfterClear)
            window.location.reload()
    }
};


export interface IApiResult {
    ok: boolean
    message?: string
    error?: any
    data?: any
    type?: any
}

export const handleApiSuccess = (response: AxiosResponse): IApiResult => {
    return {
        ok: true,
        data: response.data,
    }
}

export const handleApiError = (err: AxiosError): IApiResult => {
    // when error code is 400 and response body contains properties, return object with errors, else log and rethrow
    if (err.response && err.response.status === 400 && err.response.data) {
        const data = err.response.data
        const result: IApiResult = {
            ok: false,
            error: {},
        }

        if (typeof data !== 'string') {
            getProblemDetailsError(data, result)
        } else {
            getSimpleTextError(result, data)
        }

        return result
    } else if (err.response && err.response.status === 466) {
        console.log(err.response.data)
        alert('There has been an important update installed. Early Insights will now restart.')
        clearCache(true);
        return;
    } else if (err.response && err.response.status === 465) {
        console.log(err.response.data)
        if (confirm('There is an update waiting. Would you like to restart?')) {
            clearCache(true);
            return;
        }
        return;
    }

    if (err.response) {
        throw err.response
    } else {
        throw err
    }
}

function getSimpleTextError(result: IApiResult, data: string) {
    result.message = String(data)
}

function getProblemDetailsError(data: any, result: IApiResult) {
    for (let prop in data) {
        if (data.hasOwnProperty(prop)) {
            result.error[prop] = data[prop].toString()
        }
    }
}
