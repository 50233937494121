import React from 'react'
import { components } from 'react-select'
import { FaPlus } from 'react-icons/fa'

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <FaPlus />
        </components.DropdownIndicator>
    )
}

export default DropdownIndicator
