import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { withOktaAuth } from '@okta/okta-react'
import {
    addStudentsToDiagnosticApi,
    getEstimatesForExistingDiagnosticApi,
    getEstimatesForNewDiagnosticApi,
    getStudentDiagnosticsByDiagnosticIdApi,
} from '../../services/DiagnosticApi'
import {
    StudentDiagnosticDto,
    DiagnosticEstimateDto,
    DiagnosticType,
} from '../../models/Diagnostic'
import clock from '../../assets/images/clock.svg'
import clockAnimated from '../../assets/images/clockAnimated.svg'
import user from '../../assets/images/user.svg'
import { ItemDto } from '../../models/KeyConcept'
import { AuthService, AuthState } from '../../models/Auth'
import { SamplingAssessmentDto } from '../../models/SamplingAssessment'
import BaseClass from '../Bases/BaseClass'
import { SchoolClassDto } from '../../models/SchoolClass'
import BreadCrumbs from '../layout/BreadCrumbs'
import DemoPopovers from '../layout/DemoPopovers'
import axios from 'axios'
import DiagnosticDetailsTable, { DiagnosticDetailsTableDisplayType } from './DiagnosticDetailsTable'
import { Card, Button, CardBody, Collapse, CardFooter } from 'reactstrap'
import DiagnosticCreateTable from './DiagnosticCreateTable'
import { getStudentsFromApi } from '../../services/StudentApi'
import { cloneList, demoClassGuid, logout, ScrollToTop } from '../../shared/Helper'
import { StudentDto } from '../../models/Student'
import { getKeyConceptsFromApi, getSamplingAssessmentsFromApi } from '../../services/CurriculumApi'
import { ISessionData } from '../../AppRouter'
import $ from 'jquery'

interface IParams {
    diagnosticId: string
    parentPage: string
    studentId: string
}

interface IProps extends RouteComponentProps<IParams> {
    // baseUrl: string;
    authService: AuthService
    authState: AuthState
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
    oktaAuth: any
    match: any
    history: any
}

interface IState {
    studentDiagnosticList: StudentDiagnosticDto[]
    isLoading: boolean
    error: string
    visible: boolean
    modal: boolean
    firstName: string
    lastName: string
    studentId: string
    title: string
    expandKeyConceptView: boolean
    isCalculatingTimes: boolean
    diagnosticEstimateDto: DiagnosticEstimateDto | null
    samplingAssessments: SamplingAssessmentDto[]
    isOpen: boolean
    isShowNoStudentsAvailable: boolean
    loadingModalText: string
    showLoadingModal: boolean
    isDataLoading: boolean
}

class DiagnosticDetails extends BaseClass<IProps, IState> {
    samplingSelectorRef
    keyConceptSelectorRef
    diagnosticCreateTableRef

    cancellationTokenSource: any
    monteCarloTimeout

    constructor(props: IProps) {
        super(props)

        this.samplingSelectorRef = React.createRef()
        this.keyConceptSelectorRef = React.createRef()
        this.diagnosticCreateTableRef = React.createRef()

        this.state = {
            studentDiagnosticList: [],
            isLoading: true,
            error: '',
            visible: false,
            modal: false,
            firstName: '',
            lastName: '',
            studentId: '',
            title: '',
            expandKeyConceptView: false,
            isCalculatingTimes: false,
            diagnosticEstimateDto: null,
            samplingAssessments: [],
            isOpen: false,
            isShowNoStudentsAvailable: false,
            loadingModalText: '',
            showLoadingModal: false,
            isDataLoading: true,
        }
    }

    clearEstimates() {}

    componentDidMount() {
        this.loadData()
    }

    componentWillUnmount() {
        if (this.cancellationTokenSource) {
            this.cancellationTokenSource.cancel('Operation canceled by the user.')
        }

    }

    async loadData() {
        this.props.setIsDataLoading(true)

        let studentDiagnostics = await this.getStudentDiagnostics(
            this.props.match.params.diagnosticId
        )

        if (!this.isEntireDiagnosticFinished(studentDiagnostics)) {
            this.monteCarloTimeout && clearTimeout(this.monteCarloTimeout)
            this.monteCarloTimeout = setTimeout(() => {
                this.getEstimatesForExistingDiagnostic()
            }, 2000)
        }

        this.props.setIsDataLoading(false)
    }

    setShowLoginDetails(studentId: string, showLoginDetails: boolean) {
        let studentDiagnostics = this.state.studentDiagnosticList
        let s = studentDiagnostics.find((v) => v.studentId === studentId)

        s.showLogInDetails = showLoginDetails
        this.setState({ studentDiagnosticList: studentDiagnostics })
    }

    getEstimatesForExistingDiagnostic = async () => {
        this.clearEstimates()
        let token = await this.props.authState.accessToken.accessToken
        let diagnosticId = this.props.match.params.diagnosticId

        this.setState({
            isCalculatingTimes: true,
        })

        this.cancellationTokenSource = axios.CancelToken.source()

        return getEstimatesForExistingDiagnosticApi(
            diagnosticId,
            token,
            this.cancellationTokenSource
        )
            .then((res) => {
                if (res.ok) {
                    this.setState({
                        isCalculatingTimes: false,
                    })

                    let result: DiagnosticEstimateDto = res.data

                    this.setState({
                        diagnosticEstimateDto: result,
                    })

                    if (result && result.studentEstimates) {
                        let skc = this.state.studentDiagnosticList

                        result.studentEstimates.forEach((v) => {
                            let s = skc.find((x) => x.studentId === v.studentId)

                            if (s) {
                                s.timeFormatted = v.timeFormatted
                            }
                        })

                        this.setState({ studentDiagnosticList: skc })
                    }
                } else {
                    //  this.handleknownError(res.errors);
                }
            })
            .catch((error) => {
                //this.handleUnknownError(error);
                // if (error.status === 401) {
                //   return logout(this.props.authService);
                // }
                console.log(error)
                this.setState({
                    isCalculatingTimes: false,
                    //error: error.statusText,
                    isLoading: false,
                })
            })
    }

    async getStudentDiagnostics(diagnosticId: string) {
        let token = await this.props.authState.accessToken.accessToken
        this.props.setIsDataLoading(true)
        return getStudentDiagnosticsByDiagnosticIdApi(diagnosticId, token)
            .then((res) => {
                this.props.setIsDataLoading(false)
                if (res.ok) {
                    if (res.data.length > 0) {
                        this.setState({
                            studentDiagnosticList: res.data,
                            // title: "Assessment Details (" + res.data[0].diagnosticName + ")",
                            title: res.data[0].diagnosticName,
                        })
                    }

                    return res.data
                }

                this.handleknownError(res.error)
            })
            .catch((error) => {
                this.handleUnknownError(error)
            })
    }

    async toggle() {
        this.setState({ isOpen: !this.state.isOpen })
        let selectedClass = this.props.sessionData.selectedSchoolClass
        await this.getStudents(selectedClass.id)
        this.getDropdownItems()
    }

    getStudents = async (schoolClassId: string) => {
        let token = await this.props.authState.accessToken.accessToken

        return getStudentsFromApi(schoolClassId, token)
            .then((res) => {
                this.setState({ isLoading: false })
                if (res.ok) {
                    let students = res.data

                    students = students.filter((v) => !v.currentDiagnostic)
                    students = students.filter(
                        (v) => !this.state.studentDiagnosticList.find((x) => x.studentId === v.id)
                    )

                    if (students.length === 0) {
                        this.setState({ isShowNoStudentsAvailable: true })

                        return
                    }

                    let studentItems = students.map((student: StudentDto) => {
                        return {
                            studentId: student.id,
                            firstName: student.firstName,
                            lastName: student.lastName,
                            items: [],
                            currentDiagnostic: student.currentDiagnostic,
                        }
                    })

                    this.samplingSelectorRef.current.setStudentItems(studentItems)
                    this.samplingSelectorRef.current.setStudents(students)
                    this.keyConceptSelectorRef.current.setStudents(cloneList(students))
                    this.keyConceptSelectorRef.current.setStudentItems(cloneList(studentItems))
                }
            })
            .catch((error) => {
                if (error.status === 401) {
                    return logout(this.props.oktaAuth)
                }

                ScrollToTop()
                this.setState({
                    error: error.statusText,
                    isLoading: false,
                })
            })
    }

    getDropdownItems = async () => {
        let token = await this.props.authState.accessToken.accessToken

        //  getKeyConceptsFromApi(token)
        //      .then((res) => {
        //         this.setState({ isLoading: false })
        //  if (res.ok) {
        if (this.keyConceptSelectorRef.current) {
            let items = this.props.sessionData.keyConcepts.map((item: any) => {
                return {
                    value: item.id,
                    label: item.name,
                    order: item.order,
                }
            })

            this.keyConceptSelectorRef.current.setOptionsOfMasterItems(items)
            let items2 = this.props.sessionData.keyConcepts.map((item: any) => {
                return {
                    value: item.id,
                    label: item.name,
                    order: item.order,
                }
            })

            this.keyConceptSelectorRef.current.setOptionsOfStudentMasterItems(items2)

            if (this.state.studentDiagnosticList.length > 0) {
                let kcids = this.state.studentDiagnosticList[0].keyConceptIds

                if (kcids && kcids.length > 0) {
                    this.diagnosticCreateTableRef.current.setDiagnosticType(false)
                    let selectedItems = items.filter((v) => kcids.includes(v.value))

                    this.keyConceptSelectorRef.current.handleOnMasterSelect(selectedItems, null)

                    this.keyConceptSelectorRef.current.setStudentOptions(selectedItems)
                }
            }
        }
        //     }
        // })
        // .catch((error) => {
        //     if (error.status === 401) {
        //         return logout(this.props.oktaAuth)
        //     }

        //     ScrollToTop()
        //     this.setState({
        //         error: error.statusText,
        //         isLoading: false,
        //     })
        // })

        getSamplingAssessmentsFromApi(token)
            .then((res) => {
                this.setState({ isLoading: false })
                if (res.ok) {
                    if (this.samplingSelectorRef.current) {
                        let items = res.data
                            .filter(
                                (v) =>
                                    this.props.sessionData.selectedSchoolClass.levels.includes(
                                        v.schoolClassLevel
                                    ) || v.schoolClassLevel === 'All'
                            )
                            .map((item: ItemDto) => {
                                return {
                                    value: item.id,
                                    label: item.name,
                                    order: item.order,
                                }
                            })

                        this.samplingSelectorRef.current.setOptionsOfMasterItems(items)
                        let items2 = res.data.map((item: ItemDto) => {
                            return {
                                value: item.id,
                                label: item.name,
                                order: item.order,
                            }
                        })

                        this.samplingSelectorRef.current.setOptionsOfStudentMasterItems(items2)

                        if (this.state.studentDiagnosticList.length > 0) {
                            let kcids = this.state.studentDiagnosticList[0].keyConceptIds

                            if (!(kcids && kcids.length > 0)) {
                                this.diagnosticCreateTableRef.current.setDiagnosticType(true)
                                let sampIds =
                                    this.state.studentDiagnosticList[0].samplingAssessmentIds
                                let selectedItems = items.filter((v) => sampIds.includes(v.value))

                                this.samplingSelectorRef.current.handleOnMasterSelect(
                                    selectedItems,
                                    null
                                )

                                this.samplingSelectorRef.current.setStudentOptions(selectedItems)
                            }
                        }
                    }
                }
            })
            .catch((error) => {
                if (error.status === 401) {
                    return logout(this.props.oktaAuth)
                }

                ScrollToTop()
                this.setState({
                    error: error.statusText,
                    isLoading: false,
                })
            })
    }

    addStudentsToDiagnostic = async () => {
        if (this.diagnosticCreateTableRef.current.getDiagnosticType() === DiagnosticType.Sampling) {
            this.samplingSelectorRef.current.validateItems()
        } else if (
            this.diagnosticCreateTableRef.current.getDiagnosticType() === DiagnosticType.KeyConcept
        ) {
            this.keyConceptSelectorRef.current.validateItems()
        }

        if (!this.props.sessionData.selectedSchoolClass) {
            return
        }

        let token = await this.props.authState.accessToken.accessToken

        this.setState({
            isLoading: true,
            loadingModalText: 'Adding students to your assessment...',
            showLoadingModal: true,
        })

        let newDiagnostic = null
        let diagnosticId = this.props.match.params.diagnosticId
        let diagnosticType = this.diagnosticCreateTableRef.current.getDiagnosticType()

        if (diagnosticType === DiagnosticType.Sampling) {
            newDiagnostic = {
                diagnosticId,
                studentItems: this.samplingSelectorRef.current.getStudentItems(),
                diagnosticType,
            }
        } else if (diagnosticType === DiagnosticType.KeyConcept) {
            newDiagnostic = {
                diagnosticId,
                studentItems: this.keyConceptSelectorRef.current.getStudentItems(),
                diagnosticType,
            }
        }

        if (newDiagnostic.studentItems && newDiagnostic.studentItems.length > 0) {
            newDiagnostic.studentItems = newDiagnostic.studentItems.filter(
                (v) => v.items && v.items.length > 0 && !v.currentDiagnostic
            )
        }

        return addStudentsToDiagnosticApi(newDiagnostic, token).then((res) => {
            if (res.ok) {
                this.loadData()
                this.toggle()
            } else {
                ScrollToTop()
                this.setState({
                    isLoading: false,
                    showLoadingModal: false,
                    error: res.error.title,
                    visible: true,
                })
            }
        })
    }

    getEstimatesForNewDiagnostic = async (studentItems) => {
        if (this.state.isCalculatingTimes) {
            return
        }

        this.setState({
            isCalculatingTimes: true,
        })

        let newDiagnostic = null
        let token = await this.props.authState.accessToken.accessToken

        if (this.diagnosticCreateTableRef.current.getDiagnosticType() === DiagnosticType.Sampling) {
            this.samplingSelectorRef.current.clearEstimates()
            newDiagnostic = {
                name: 'fake',
                schoolClassId: this.props.sessionData.selectedSchoolClass.id,
                studentItems: this.samplingSelectorRef.current.getStudentItems(),
                diagnosticType: DiagnosticType.Sampling,
            }
        } else if (
            this.diagnosticCreateTableRef.current.getDiagnosticType() === DiagnosticType.KeyConcept
        ) {
            this.keyConceptSelectorRef.current.clearEstimates()
            newDiagnostic = {
                name: 'fake',
                schoolClassId: this.props.sessionData.selectedSchoolClass.id,
                studentItems: this.keyConceptSelectorRef.current.getStudentItems(),
                diagnosticType: DiagnosticType.KeyConcept,
            }
        }

        if (newDiagnostic.studentItems && newDiagnostic.studentItems.length > 0) {
            newDiagnostic.studentItems = newDiagnostic.studentItems.filter(
                (v) => v.items && v.items.length > 0 && !v.currentDiagnostic
            )
        }

        this.cancellationTokenSource = axios.CancelToken.source()

        return getEstimatesForNewDiagnosticApi(newDiagnostic, token, this.cancellationTokenSource)
            .then((res) => {
                if (res.ok) {
                    this.setState({
                        isCalculatingTimes: false,
                    })

                    let result: DiagnosticEstimateDto = res.data

                    this.setState({
                        diagnosticEstimateDto: result,
                    })

                    if (result && result.studentEstimates) {
                        if (
                            this.diagnosticCreateTableRef.current.getDiagnosticType() ===
                            DiagnosticType.Sampling
                        ) {
                            let skc = studentItems

                            result.studentEstimates.forEach((v) => {
                                let s = skc.find((x) => x.studentId === v.studentId)

                                if (s) {
                                    s.timeFormatted = v.timeFormatted
                                }
                            })

                            this.samplingSelectorRef.current.setStudentItems(skc)
                        } else if (
                            this.diagnosticCreateTableRef.current.getDiagnosticType() ===
                            DiagnosticType.KeyConcept
                        ) {
                            let skc = studentItems

                            result.studentEstimates.forEach((v) => {
                                let s = skc.find((x) => x.studentId === v.studentId)

                                if (s) {
                                    s.timeFormatted = v.timeFormatted
                                }
                            })

                            this.keyConceptSelectorRef.current.setStudentItems(skc)
                        }
                    }
                } else {
                    // ScrollToTop();
                    // this.setState({
                    //   isCalculatingTimes: false,
                    //   error: res.errors.title,
                    //   visible: true,
                    // });
                }
            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    isCalculatingTimes: false,
                    isLoading: false,
                })
            })
    }

    render() {
        // if (this.props.isDataLoading) return null;

        return (
            <div className="pb-2">
                {/* {this.props.sessionData.authenticatedTeacher.isDemo &&
          <DemoPopovers
            // sessionData={this.props.sessionData}
            // history={this.props.history}
            items={[
              {
                target: "actions",
                placement: "top",
                message: "These features are not available for trial accounts.",
              },
            ]}
          ></DemoPopovers>
        } */}

                <div className="mb-2">
                    <BreadCrumbs
                        items={[
                            { title: 'Home', link: '/' },
                            { title: 'Assessments', link: '/assessment' },
                            { title: 'Assessment Details', link: '' },
                        ]}
                    />
                </div>

                <div className="row">
                    <div className="col ">
                        <h2 className="pb-2">{this.state.title}</h2>
                        <p className="font-weight-light">
                            The assessment has two modes for collecting student data - a one-to-one
                            interview with the teacher and digital questions students complete
                            independently. If a student has interview questions, these must be
                            completed before they can start the digital questions. Sometimes there
                            won&apos;t be any interview questions - these students can start on the
                            digital questions straight away by going to{' '}
                            <a style={{ fontWeight: 500 }} href="https://gromo.me/">
                                https://gromo.me/
                            </a>
                            .
                        </p>
                    </div>

                    <div className="col  align-self-center">
                        <div className="border bg-white container  ">
                            <div className="row p-3 ">
                                <div className="col mr-3 p-2 border text-center">
                                    <div className="row">
                                        <div className="col ">
                                            <img
                                                alt="clock"
                                                className="clock"
                                                src={
                                                    this.state.isCalculatingTimes
                                                        ? clockAnimated
                                                        : clock
                                                }
                                            ></img>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col small-label-bold-style">
                                            {this.getSchoolClassEstimate()}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col small-label-style">
                                            Approximate length of digital portion
                                        </div>
                                    </div>
                                </div>
                                <div className="col p-2 border text-center">
                                    <div className="row">
                                        <div className="col">
                                            <img src={user} alt="user"></img>
                                            <img src={user} alt="user"></img>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col small-label-bold-style">
                                            {this.getNumberOfStudentInterviews()} students
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col small-label-style">
                                            will have 2-3 minute interviews
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    <DiagnosticDetailsTable
                        displayType={DiagnosticDetailsTableDisplayType.DiagnosticDetails}
                        studentDiagnosticList={this.state.studentDiagnosticList}
                        authState={this.props.authState}
                        refresh={() => this.loadData()}
                        parentPage="assessment-details"
                        sessionData={this.props.sessionData}
                    ></DiagnosticDetailsTable>
                </div>

                {!this.isEntireDiagnosticFinished(this.state.studentDiagnosticList) ? (
                    <>
                        <div className="pt-3">
                            <Button
                                color="primary"
                                onClick={() => this.toggle()}
                                style={{ marginBottom: '1rem' }}
                            >
                                Add more students
                            </Button>
                            <Collapse isOpen={this.state.isOpen}>
                                <Card>
                                    <CardBody className="pt-0 pl-3 pr-3">
                                        <DiagnosticCreateTable
                                            ref={this.diagnosticCreateTableRef}
                                            getEstimatesForNewDiagnostic={
                                                this.getEstimatesForNewDiagnostic
                                            }
                                            authState={this.props.authState}
                                            samplingSelectorRef={this.samplingSelectorRef}
                                            keyConceptSelectorRef={this.keyConceptSelectorRef}
                                            isDiagnosticTypeReadOnly={true}
                                            isShowNoStudentsAvailable={
                                                this.state.isShowNoStudentsAvailable
                                            }
                                            isCalculatingTimes={this.state.isCalculatingTimes}
                                        />
                                    </CardBody>
                                    <CardFooter>
                                        <div className="space-between">
                                            <Button
                                                color="secondary"
                                                onClick={() => {
                                                    this.toggle()
                                                }}
                                                disabled={this.state.isLoading}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                id="create"
                                                color="primary"
                                                type="submit"
                                                onClick={() => {
                                                    this.addStudentsToDiagnostic()
                                                }}
                                                disabled={
                                                    this.state.isShowNoStudentsAvailable ||
                                                    (this.props.sessionData.selectedSchoolClass &&
                                                        this.props.sessionData.selectedSchoolClass
                                                            .id === demoClassGuid)
                                                }
                                            >
                                                {this.state.isLoading ? (
                                                    <i className="fa fa-spinner fa-spin" />
                                                ) : null}{' '}
                                                Add
                                            </Button>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Collapse>
                        </div>
                    </>
                ) : null}
            </div>
        )
    }

    private getSchoolClassEstimate(): React.ReactNode {
        return this.state.diagnosticEstimateDto
            ? this.state.diagnosticEstimateDto.schoolClassCompletionTimeRangeFormatted
            : '... mins'
    }

    private getNumberOfStudentInterviews(): React.ReactNode {
        return this.state.diagnosticEstimateDto
            ? this.state.diagnosticEstimateDto.numberOfInterviews
            : '...'
    }

    getEstimate(studentDiagnostic: StudentDiagnosticDto): React.ReactNode {
        return studentDiagnostic.timeFormatted ? studentDiagnostic.timeFormatted : '... mins'
    }

    isEntireDiagnosticFinished(studentDiagnosticList) {
        if (studentDiagnosticList.length === 0) return true
        return !studentDiagnosticList.find((v) => !v.finishedDate)
    }
}
export default withOktaAuth(DiagnosticDetails)
