/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Button, Alert, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import './Order.css'
import Select from 'react-select'
import { SchoolCombo } from './SchoolCombo'
import {
    CheckTeacherIsValidForOrderApi as CheckOrderIsValidFromApi,
    CreateStripeIntentOrder,
} from '../../services/OrderApi'
import NumberFormat from 'react-number-format'
import ReactPixel from 'react-facebook-pixel'

require('typeface-dosis')

interface IProps extends RouteComponentProps<any> {
    applyDiscount: boolean
    inPortal: boolean
    setIsDataLoading: any
    isDataLoading: boolean
    match: any
    history: any
}

const options = [
    { value: 'NSW', label: 'NSW' },
    { value: 'VIC', label: 'VIC' },
    { value: 'QLD', label: 'QLD' },
    { value: 'ACT', label: 'ACT' },
    { value: 'TAS', label: 'TAS' },
    { value: 'NT', label: 'NT' },
    { value: 'WA', label: 'WA' },
    { value: 'SA', label: 'SA' },
]

export enum CreateOrderValidationEnum {
    Failed,
    NewCustomer,
    NewTeacherAndExistingCustomer,
    ExistingTeacherAndExistingCustomer,
    SchoolDoesNotExist,
}

export default function OrderIFrame(props: IProps) {
    const [disableSchools, setDisableSchools] = useState(false)
    const [email, setEmail] = useState(null)
    const [quantity, setQuantity] = useState(null)
    const [schoolOption, setSchoolOption] = useState(null)
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [months, setMonths] = useState('12')
    const [error, setError] = useState('')
    const [errorVisible, setVisible] = useState(false)
    const [totalPrice, setTotalPrice] = useState(0)
    const [showMustSelectSchool, setMustSelectSchool] = useState(false)
    const [useCustomSchool, setUseCustomSchool] = useState(false)
    const [customSchoolName, setCustomSchoolName] = useState('')
    const [customSchoolAddress, setCustomSchoolAddress] = useState('')
    const [customSchoolAddress2, setCustomSchoolAddress2] = useState('')
    const [customSchoolState, setCustomSchoolState] = useState('')
    const [customSchoolSuburb, setCustomSchoolSuburb] = useState('')
    const [customSchoolPostCode, setCustomSchoolPostCode] = useState('')
    const [customSchoolPhone, setCustomSchoolPhone] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)

    const pricePerLicense = 20
    const discountedPricePerLicense = 9
    const childRef = useRef()

    useEffect(() => {
        if (!props.inPortal) {
            try {
                document.domain = 'mathspathway.com'
            } catch (error) {
                // console.log(error);
            }
            // override background colour
            document.querySelector('html').style.backgroundColor = 'white'
            document.getElementById('root').style.backgroundColor = 'white'

            ReactPixel.init('356309674824876')
            ReactPixel.pageView() // For tracking page view
            ReactPixel.track('InitiateCheckout') // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
        } else {
            // eslint-disable-next-line no-inner-declarations
            async function setExistingSchoolValuesAsync() {
                let teacher = this.props.sessionData.authenticatedTeacher

                if (!teacher) {
                    return
                }

                setSchoolOption({ value: '', label: teacher.schoolName })
                setEmail(teacher.email)
                setFirstName(teacher.firstname)
                setLastName(teacher.lastname)
            }

            setExistingSchoolValuesAsync()
        }
    }, [])

    const onHandleChange = (e: any) => {
        if (childRef.current != null) {
            ;(childRef.current as any).changeAusState(e)
        }
    }

    // const isSchoolInAustralianList = async (school) => {
    //   setVisible(false);
    //   setError("");
    //   try {
    //     const res = await IsSchoolInAustralianListApi(school);
    //     if (res.ok) {
    //       return res.data;
    //     }
    //     else {
    //       setIsLoading(false);
    //       setVisible(true);
    //       setError(res.errors.detail);
    //     }
    //   } catch (error) {
    //     setIsLoading(false);
    //     setVisible(true);
    //     setError(error.statusText);
    //   }
    // }

    const checkOrderIsValid = () => {
        setVisible(false)
        setError('')
        let school = useCustomSchool ? customSchoolName : schoolOption.label

        return CheckOrderIsValidFromApi(email, school)
            .then((res) => {
                if (res.ok) {
                    return res.data
                }

                props.setIsDataLoading(false)
                setVisible(true)
                setError(res.error.detail)

                return CreateOrderValidationEnum.Failed
            })
            .catch((error) => {
                props.setIsDataLoading(false)
                setVisible(true)
                setError(error.statusText)

                return CreateOrderValidationEnum.Failed
            })
    }

    const createOrder = async (confirmed) => {
        props.setIsDataLoading(true, ['Please wait a minute while we set up your order...'])
        setMustSelectSchool(false)

        if (!useCustomSchool && !schoolOption) {
            setMustSelectSchool(true)
            props.setIsDataLoading(false)

            return
        }

        if (quantity <= 0) {
            props.setIsDataLoading(false)
            setVisible(true)
            setError('The licence quantity must be higher than 0.')

            return
        }

        let createCustomerValidation: any

        if (confirmed) {
            createCustomerValidation = CreateOrderValidationEnum.NewTeacherAndExistingCustomer
        } else {
            createCustomerValidation = await checkOrderIsValid()
            switch (createCustomerValidation) {
                case CreateOrderValidationEnum.NewTeacherAndExistingCustomer:
                    return setConfirmModal(true)
                //  case CreateOrderValidationEnum.SchoolDoesNotExist: {}
                case CreateOrderValidationEnum.Failed:
                    return
                default:
                    console.log('createCustomerValidation does not exist')
            }
        }

        CreateStripeIntentOrder({
            school: useCustomSchool ? customSchoolName : schoolOption.label,
            quantity,
            email,
            months,
            firstName,
            lastName,
            isPuchaseOrder: false,
            useCustomSchool,
            customSchool: {
                schoolName: customSchoolName,
                schoolSuburb: customSchoolSuburb,
                schoolState: customSchoolState,
                schoolPostCode: customSchoolPostCode,
                schoolAddress: customSchoolAddress,
                schoolAddress2: customSchoolAddress2,
                schoolPhone: customSchoolPhone,
            },
            applyDiscount: !!props.applyDiscount,
            // createdDate: date.format(new Date(), 'YYYY-MM-DD HH:mm:ss')
        })
            .then((res) => {
                if (res.ok) {
                    props.setIsDataLoading(false)
                    if (props.inPortal) {
                        props.history.push(
                            `/admin/order/payment/${res.data.intentId}/${(
                                createCustomerValidation as number
                            ).toString()}`
                        )
                    } else {
                        props.history.push(
                            `/order/payment/${res.data.intentId}/${(
                                createCustomerValidation as number
                            ).toString()}`
                        )
                    }
                } else {
                    props.setIsDataLoading(false)
                    setVisible(true)
                    setError(res.error.detai)
                }
            })
            .catch((error) => {
                props.setIsDataLoading(false)
                setVisible(true)
                setError(error.statusText)
            })
    }

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        await createOrder(false)
    }

    const setLicences = (e) => {
        let price = pricePerLicense
        let applyDiscount = props.applyDiscount

        if (applyDiscount) {
            price = discountedPricePerLicense
        }

        setTotalPrice(parseInt(e.target.value) * price)
        setQuantity(e.target.value)
    }

    // const togglePopover = () => {
    //   setIsOpen(!isOpen);
    // }

    const toggleConfirm = () => {
        props.setIsDataLoading(false)
        setConfirmModal(!confirmModal)
    }

    const confirm = async () => {
        await createOrder(true)
    }

    const dansStyling = {
        fontWeight: 600,
        color: 'black',
    }

    return (
        <div data-cy="order-iframe-page-loaded" className="container">
            <link rel="stylesheet" type="text/css" href={'iframe-style.css'} />

            {/* {isLoading ? (
        <>
          <FullPageSpinner />
        </>
      ) : (
        <></>
      )} */}

            {errorVisible ? (
                <Alert color="warning">
                    <p>{error}</p>
                </Alert>
            ) : null}

            {/* <div className="flex flex-wrap justify-between">
        <div className="w-full md:w-1/2 mb-4 md:pr-4">
          <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">First name</label>
          <input type="text" className="w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight" />
        </div>
        <div className="w-full md:w-1/2 mb-4 md:pl-4">
          <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">Last name</label>
          <input type="text" className="w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight" />
        </div>
        <div className="w-full md:w-1/2 mb-4 md:pr-4">
          <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">Email</label>
          <input type="email" className="w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight" />
        </div>
        <div className="w-full md:w-1/2 mb-4 md:pl-4">
          <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">State</label>
          <select className="w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight appearance-none bg-no-repeat" >
            <option>Choose state</option>
          </select>
        </div>
        <div className="w-full mb-4">
          <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">School</label>
          <select className="w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight appearance-none bg-no-repeat" >
            <option>Find school</option>
          </select>
        </div>
        <div className="w-full mb-4">
          <input type="checkbox" id="no-school" name="no-school" className="ml-2" />
          <label htmlFor="scales" className="ml-2">I can´t find my school</label>
        </div>
        <div className="w-full mb-4 mt-4">
          <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">Total number of students</label>
          <input type="number" className="w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight" />
        </div>
        <p className="w-full text-2xl md:text-3xl mb-6 text-center">
          <strong className="font-bold">$0.00</strong> (ex GST) <span className="text-lg md:text-xl">$0.00 (inc GST)</span>
        </p>
        <div className="flex justify-center w-full pb-4 md:pb-0">
          <button className="relative px-8 py-4 rounded-full bg-orange-500 text-lg text-white font-bold leading-none button-border-orange hover:bg-orange-400 transition duration-200">Go to payment</button>
        </div>
      </div> */}

            <form className="needs-validation" onSubmit={onSubmit}>
                <div className="iframe-font flex flex-wrap justify-between">
                    {/* <div className="flex flex-wrap justify-between"> */}

                    {!props.inPortal ? (
                        <>
                            <div className="iframe w-full md:w-1/2 mb-4 md:pr-4">
                                <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">
                                    First Name
                                </label>
                                <input
                                    data-cy="first-name-input"
                                    type="text"
                                    className="form-control w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight"
                                    style={dansStyling}
                                    id="firstName"
                                    placeholder="John"
                                    required
                                    onChange={(e) => setFirstName(e.target.value)}
                                ></input>
                                {/* <div className="invalid-feedback">
                  Valid first name is required.
                    </div> */}
                            </div>
                            <div className="w-full md:w-1/2 mb-4 md:pl-4">
                                <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">
                                    Last Name
                                </label>
                                <input
                                    data-cy="last-name-input"
                                    type="text"
                                    className="form-control w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight"
                                    style={dansStyling}
                                    id="lastName"
                                    placeholder="Smith"
                                    required
                                    onChange={(e) => setLastName(e.target.value)}
                                ></input>
                                {/* <div className="invalid-feedback">
                  Valid last name is required.
                    </div> */}
                            </div>

                            <div className="w-full md:w-1/2 mb-4 md:pr-4">
                                <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">
                                    Email
                                </label>
                                <input
                                    data-cy="email-input"
                                    type="email"
                                    className="form-control w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight"
                                    style={dansStyling}
                                    id="email"
                                    placeholder="you@example.com"
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                ></input>
                                {/* <div className="invalid-feedback">
                  enter a valid email address.
                  </div> */}
                            </div>

                            <div className="w-full md:w-1/2 mb-4 md:pl-4">
                                <label
                                    className="block ml-2 text-lg leading-tight mb-3 font-semibold"
                                    style={dansStyling}
                                >
                                    State
                                </label>
                                <Select
                                    id="state"
                                    isDisabled={disableSchools}
                                    options={options}
                                    onChange={onHandleChange}
                                />
                            </div>

                            {/* SCHOOL */}

                            <div className="w-full mb-4">
                                {/* <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">School</label> */}
                                {/* <select className="w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight appearance-none bg-no-repeat" >
                  <option>Find school</option>
                </select> */}

                                {useCustomSchool ? (
                                    <></>
                                ) : (
                                    <>
                                        <div className="mb-2">
                                            <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">
                                                School
                                            </label>
                                            <span id="school">
                                                <SchoolCombo
                                                    ref={childRef}
                                                    isDisabled={disableSchools}
                                                    onChange={setSchoolOption}
                                                />
                                            </span>
                                        </div>
                                        {showMustSelectSchool ? (
                                            <>
                                                <Alert color="warning">
                                                    You must select a school from the dropdown. if
                                                    you cannot find it please tick the box below.
                                                </Alert>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                )}
                            </div>

                            {/* <div className="form-group">
                <div className="custom-control mb-3" style={{ zIndex: 0 }}>
                  <input type="checkbox" className="form-check-input" id="save-info-000" onChange={(e) => setUseCustomSchool(e.target.checked)} ></input>
                  <label className="form-check-label" htmlFor="save-info-000"> </label>
    I can’t find my school
                  </div>
              </div> */}

                            <div className="w-full mb-4">
                                <input
                                    type="checkbox"
                                    id="save-info-000"
                                    name="no-school"
                                    className="ml-2"
                                    onChange={(e) => setUseCustomSchool(e.target.checked)}
                                />
                                <label htmlFor="scales" className="ml-2">
                                    I can´t find my school
                                </label>
                            </div>

                            {useCustomSchool ? (
                                <>
                                    <Alert color="warning">
                                        Please enter your school details — you must be associated
                                        with a school to proceed with your purchase. Early Insights
                                        is not currently available for homeschooling or private
                                        tutoring.
                                    </Alert>
                                    <div className="w-full mb-4">
                                        <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">
                                            School Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight"
                                            style={dansStyling}
                                            id="Name"
                                            placeholder="Name"
                                            required
                                            onChange={(e) => setCustomSchoolName(e.target.value)}
                                        ></input>
                                    </div>
                                    <div className="w-full mb-4">
                                        <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">
                                            School Address
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight"
                                            style={dansStyling}
                                            id="Address"
                                            placeholder="Address"
                                            required
                                            onChange={(e) => setCustomSchoolAddress(e.target.value)}
                                        ></input>
                                    </div>
                                    <div className="w-full mb-4">
                                        <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">
                                            School Address 2
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight"
                                            style={dansStyling}
                                            id="Address2"
                                            placeholder="Address2"
                                            onChange={(e) =>
                                                setCustomSchoolAddress2(e.target.value)
                                            }
                                        ></input>
                                    </div>
                                    <div className="w-full mb-4">
                                        <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">
                                            School Suburb
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight"
                                            style={dansStyling}
                                            id="Suburb"
                                            placeholder="Suburb"
                                            required
                                            onChange={(e) => setCustomSchoolSuburb(e.target.value)}
                                        ></input>
                                    </div>
                                    <div className="w-full mb-4">
                                        <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">
                                            School State
                                        </label>
                                        <Select
                                            id="state2"
                                            options={options}
                                            onChange={(e) => setCustomSchoolState((e as any).value)}
                                        />
                                    </div>
                                    <div className="w-full mb-4">
                                        <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">
                                            School Postcode
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight"
                                            style={dansStyling}
                                            id="Postcode"
                                            placeholder="Postcode"
                                            required
                                            onChange={(e) =>
                                                setCustomSchoolPostCode(e.target.value)
                                            }
                                        ></input>
                                    </div>
                                    <div className="w-full mb-4">
                                        <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">
                                            School Phone
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight"
                                            style={dansStyling}
                                            id="Phone"
                                            placeholder="Phone"
                                            required
                                            onChange={(e) => setCustomSchoolPhone(e.target.value)}
                                        ></input>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : null}

                    <div className="w-full mb-4 mt-4">
                        <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">
                            Total number of students
                        </label>
                        <input
                            data-cy="number-of-licences-input"
                            type="number"
                            id="licences"
                            placeholder="Total number of students"
                            min="1"
                            className="form-control w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight"
                            style={dansStyling}
                            required
                            onChange={(e) => setLicences(e)}
                        />
                    </div>
                    <p className="w-full text-2xl md:text-3xl mb-6 text-center">
                        <strong className="font-bold">
                            <NumberFormat
                                value={totalPrice}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                            />
                        </strong>{' '}
                        (ex GST){' '}
                        <span className="text-lg md:text-xl">
                            <NumberFormat
                                value={totalPrice + totalPrice * 0.1}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                            />{' '}
                            (inc GST)
                        </span>
                    </p>
                    <div className="flex justify-center w-full pb-4 md:pb-0">
                        <button
                            data-cy="submit-button"
                            className="relative px-8 py-3 rounded-full bg-orange-500 text-lg text-white font-bold leading-none button-border-orange hover:bg-orange-400 transition duration-200"
                        >
                            Go to payment
                        </button>
                    </div>

                    {/* <div className="mb-3">
              <input type="number" min="1" className="form-control" id="licences" placeholder="Total number of students" required onChange={(e) => setLicences(e)} ></input>
            </div> */}
                    {/* <span style={{ fontSize: 15 }}>
            <strong>  <NumberFormat value={price} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} /> (Excl GST)</strong>
          </span>
          <span style={{ fontSize: 12 }}>
            {" "} <NumberFormat value={price + (price * 0.1)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} /> (Incl GST)
                  </span> */}
                    {/* <hr className="mb-4"></hr> */}
                    {/* <div className="row">
            <div className="col">
              <div className="mb-2">
                <button className="btn btn-success btn-lg btn-block" type="submit">Go to payment</button>
              </div>
            </div>
          </div> */}

                    <div>
                        <Modal isOpen={confirmModal} toggle={toggleConfirm}>
                            <ModalHeader toggle={toggleConfirm}>
                                Continue with purchase?
                            </ModalHeader>
                            <ModalBody>
                                Your school already has an Early Insights account. Do you want to
                                purchase new student licences for your school?
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={toggleConfirm}>
                                    Cancel
                                </Button>
                                <Button color="primary" onClick={confirm}>
                                    Yes
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                </div>
            </form>
        </div>
    )
}
