import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { withOktaAuth } from '@okta/okta-react'
import { AuthService, AuthState } from '../../models/Auth'
import { SchoolClassDto } from '../../models/SchoolClass'
import {
    ShortGroupDto,
    StudentGroupTable,
    GroupTableByStudentReportDto,
    StudentGroupUpdate,
    GroupUpdateDto,
    GroupCreateDto,
} from '../../models/Group'
import { KeyConceptDto } from '../../models/KeyConcept'
import GroupTable from './GroupTable'
import { archivedClassGuid, demoClassGuid, isTeacherAdminOrSuper } from '../../shared/Helper'
import {
    addGroup,
    AddStudentsToGroup,
    DeleteGroup,
    getGroups,
    RemoveStudentsFromGroup,
    UpdateGroupName,
} from '../../services/GroupApi'
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import MySelect from '../diagnostic/MySelect'
import BaseClass from '../Bases/BaseClass'
import BreadCrumbs from '../layout/BreadCrumbs'
import DemoPopovers from '../layout/DemoPopovers'
import { getKeyConceptsFromApi } from '../../services/CurriculumApi'
import { TeacherDto } from '../../models/Teacher'
import { ISessionData } from '../../AppRouter'
import { updateTeacherSettingsApi } from '../../services/TeacherApi'

interface IProps extends RouteComponentProps<any> {
    authService: AuthService
    authState: AuthState
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
    history: any
    match: any
}

interface ISelect {
    value: string
    label: string
    order: number
}

interface IState {
    error: string
    errorVisible: boolean
    // selectedSchoolClass: SchoolClassDto | null;
    keyConcepts: KeyConceptDto[]
    filteredKeyConcepts: KeyConceptDto[]
    selectedKeyConcepts: ISelect[]
    optionsOfMasterKeyConcepts: any
    originalGroupTableByStudent: GroupTableByStudentReportDto[]
    groupTableByStudent: GroupTableByStudentReportDto[]
    groups: ShortGroupDto[]
    modal: boolean
    title: string
    teacher: TeacherDto
    modalCreateGroup: boolean
    newName: string
    clearSelection: number
    // isDataLoading: boolean;
}

class GroupIndex extends BaseClass<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            error: '',
            errorVisible: false,
            //   selectedSchoolClass: null,
            keyConcepts: [],
            filteredKeyConcepts: [],
            selectedKeyConcepts: [],
            optionsOfMasterKeyConcepts: [],
            groupTableByStudent: [],
            originalGroupTableByStudent: [],
            groups: [],
            modal: false,
            title: '',
            teacher: null,
            modalCreateGroup: false,
            newName: '',
            clearSelection: 0,
            //  isDataLoading: true
        }
    }

    componentDidMount() {
        this.loadData()
    }

    componentWillUnmount() {
        this.updateTeacherSettingsToApi()
    }

    componentWillUpdate(nextProps) {}

    componentDidUpdate(prevProps, prevState) {
        if (
            !prevProps.sessionData &&
            this.props.sessionData &&
            this.props.sessionData.selectedSchoolClass !== prevProps.sessionData.selectedSchoolClass
        ) {
            this.loadData()
        }

        if (this.state.filteredKeyConcepts !== prevState.filteredKeyConcepts) {
            this.updateTeacherSettings()
        }
    }

    updateTeacherSettings() {
        if (this.state.teacher) {
            let selectedKcs = ''

            if (this.state.selectedKeyConcepts) {
                selectedKcs = this.state.selectedKeyConcepts.map((v) => v.value).join(',')
            }

            //TOOO: make sure session updates

            this.state.teacher.teacherSettings.groupPageSettings.filteredKeyConceptIds = selectedKcs
        }
    }

    updateTeacherSettingsToApi() {
        if (this.state.teacher) {
            updateTeacherSettingsApi(
                this.state.teacher.id,
                this.state.teacher.teacherSettings,
                this.props.authState.accessToken.accessToken
            )
        }
    }

    async loadData() {
        this.props.setIsDataLoading(true)

        const { authenticatedTeacher, selectedSchoolClass } = this.props.sessionData

        if (selectedSchoolClass) {
            this.setState({
                //  selectedSchoolClass: selectedSchoolClass,
                title: `Groups (${selectedSchoolClass.name})`,
            })

            await this.getKeyConcepts(authenticatedTeacher.teacherSettings)
            await this.getGroups(selectedSchoolClass.id)
        } else {
            this.props.setIsDataLoading(false)
            if (authenticatedTeacher && isTeacherAdminOrSuper(authenticatedTeacher.accessLevel)) {
                this.props.history.push(`/admin/class/${authenticatedTeacher.schoolId}`)
            } else {
                this.props.history.push('/no-data-yet')
            }
        }

        this.props.setIsDataLoading(false)
    }

    getKeyConcepts = async (teacherSettings: any) => {
        let token = await this.props.authState.accessToken.accessToken
        // return getKeyConceptsFromApi(token)
        //     .then((res) => {
        //       if (res.ok) {
        let skcs = [this.props.sessionData.keyConcepts[0]] // default if no settings;
        if (
            teacherSettings.groupPageSettings.filteredKeyConceptIds &&
            teacherSettings.groupPageSettings.filteredKeyConceptIds.length > 0
        ) {
            skcs = this.props.sessionData.keyConcepts.filter((v) =>
                teacherSettings.groupPageSettings.filteredKeyConceptIds
                    .toLowerCase()
                    .split(',')
                    .includes(v.id.toLowerCase())
            )
        }
        this.setState({
            keyConcepts: this.props.sessionData.keyConcepts,
            filteredKeyConcepts: skcs,
            selectedKeyConcepts: skcs.map((keyConcept: KeyConceptDto) => {
                return {
                    value: keyConcept.id,
                    label: keyConcept.name,
                    order: keyConcept.order,
                }
            }),

            optionsOfMasterKeyConcepts: this.props.sessionData.keyConcepts.map(
                (keyConcept: KeyConceptDto) => {
                    return {
                        value: keyConcept.id,
                        label: keyConcept.name,
                        order: keyConcept.order,
                    }
                }
            ),
        })
        //     }
        // })
        // .catch((error) => {
        //     console.log(error)
        // })
    }

    getGroups = async (schoolClassId: string) => {
        let token = await this.props.authState.accessToken.accessToken
        return getGroups(schoolClassId, token)
            .then((res) => {
                if (res.ok) {
                    this.setState({
                        groupTableByStudent: res.data.groupTableByStudent,
                        originalGroupTableByStudent: res.data.groupTableByStudent,
                        groups: this.filterGroups(res.data.groupTableByStudent),
                    })
                } else {
                    this.handleknownError(res.error)
                }
            })
            .catch((error) => {
                this.handleUnknownError(error)
            })
    }

    filterGroups = (groups: GroupTableByStudentReportDto[]) => {
        return groups.filter((gr: GroupTableByStudentReportDto) => {
            return {
                id: gr.id,
                name: gr.name,
            }
        })
    }

    defaultFilteredGroup = (groups: GroupTableByStudentReportDto[]) => {
        const updatedGroups = groups.map((group) => {
            return {
                id: group.id,
                name: group.name,
                students: group.students.map((student: StudentGroupTable) => {
                    return {
                        id: student.id,
                        name: student.name,
                        groupId: student.groupId,
                    }
                }),
            }
        })

        return updatedGroups
    }

    // Remove students from group and assign them to another group
    handleOnDelete = async (students: StudentGroupTable[], groupId: string) => {
        this.clearTableSelection()
        this.props.setIsDataLoading(true)
        if (this.props.sessionData.selectedSchoolClass) {
            let studentsToRemove: StudentGroupUpdate = {
                groupId: archivedClassGuid,
                schoolClassId: this.props.sessionData.selectedSchoolClass.id,
                students: students.map((a) => a.id),
            }
            let token = await this.props.authState.accessToken.accessToken

            return RemoveStudentsFromGroup(studentsToRemove, token)
                .then(() => {
                    this.moveStudents(students, groupId, archivedClassGuid)
                    this.props.setIsDataLoading(false)
                })
                .catch((error) => {
                    this.handleUnknownError(error)
                })
        }
    }

    // Remove from empty group to another group
    handleOnAssign = async (students: StudentGroupTable[], groupId: string) => {
        this.clearTableSelection()
        this.props.setIsDataLoading(true)
        if (this.props.sessionData.selectedSchoolClass) {
            let studentsToAdd: StudentGroupUpdate = {
                groupId,
                schoolClassId: this.props.sessionData.selectedSchoolClass.id,
                students: students.map((a) => a.id),
            }
            let token = await this.props.authState.accessToken.accessToken
            return AddStudentsToGroup(studentsToAdd, token)
                .then((res) => {
                    if (res.ok) {
                        this.moveStudents(students, archivedClassGuid, groupId)
                        this.props.setIsDataLoading(false)
                    } else {
                        this.handleknownError(res.error)
                    }
                })
                .catch((error) => {
                    this.handleUnknownError(error)
                })
        }
    }

    moveStudents = (students: StudentGroupTable[], fromGroupId: string, toGroupId: string) => {
        const oldCopyOfStudentsByGroup = [...this.state.groupTableByStudent]
        const groupRemovedFrom = oldCopyOfStudentsByGroup.findIndex((gr) => {
            return gr.id === fromGroupId
        })
        const groupToBeAdded = oldCopyOfStudentsByGroup.findIndex((gr) => {
            return gr.id === toGroupId
        })

        // remove list of students from group
        if (groupRemovedFrom !== -1) {
            const oldCopyOfStudents = [...oldCopyOfStudentsByGroup[groupRemovedFrom].students]
            students.forEach((student: StudentGroupTable) => {
                let index = oldCopyOfStudents.indexOf(student)

                if (index !== -1) {
                    oldCopyOfStudents.splice(index, 1)
                }
            })

            oldCopyOfStudentsByGroup[groupRemovedFrom].students = oldCopyOfStudents

            this.setState({
                groupTableByStudent: oldCopyOfStudentsByGroup,
            })
        }

        // add studens to another group
        if (groupToBeAdded !== -1) {
            const newArray = [...this.state.groupTableByStudent]

            students.forEach((student: StudentGroupTable) => {
                student.groupId = toGroupId
                newArray[groupToBeAdded].students.push(student)
            })

            this.setState({
                groupTableByStudent: newArray,
            })
        }
    }

    clearTableSelection() {
        this.setState({ clearSelection: this.state.clearSelection + 1 })
    }

    handleOnDeleteGroup = async (group: GroupTableByStudentReportDto) => {
        this.clearTableSelection()
        this.props.setIsDataLoading(true)
        let token = await this.props.authState.accessToken.accessToken
        if (this.props.sessionData.selectedSchoolClass) {
            return DeleteGroup(this.props.sessionData.selectedSchoolClass.id, group.id, token)
                .then((res) => {
                    this.props.setIsDataLoading(false)
                    if (res.ok) {
                        // move all students from group to un-assigned group
                        this.moveStudents(group.students, group.id, archivedClassGuid)
                        // remove group from groups
                        let copyOfGroup = [...this.state.groupTableByStudent]
                        let index = copyOfGroup.indexOf(group)

                        if (index !== -1) {
                            copyOfGroup.splice(index, 1)
                            this.setState({ groupTableByStudent: copyOfGroup })
                            this.setState({ groups: copyOfGroup })
                        }
                    } else {
                        this.handleknownError(res.error)
                    }
                })
                .catch((error) => {
                    this.handleUnknownError(error)
                })
        }
    }

    handleChangeOnKeyConceptSelect = (selectedKeyConcepts: ISelect[]) => {
        if (selectedKeyConcepts && selectedKeyConcepts.length > 0) {
            const sortedKeyConcepts = selectedKeyConcepts.sort((a, b) => {
                return a.order - b.order
            })
            let newListOfKeyConcepts = new Array<KeyConceptDto>()
            selectedKeyConcepts.forEach((kc: any) => {
                const index = this.state.keyConcepts.findIndex((k) => k.id === kc.value)

                if (index !== -1) {
                    newListOfKeyConcepts.push(this.state.keyConcepts[index])
                }
            })
            this.setState({
                selectedKeyConcepts: sortedKeyConcepts,
                filteredKeyConcepts: newListOfKeyConcepts,
            })
        } else {
            this.setState({
                selectedKeyConcepts: [],
                filteredKeyConcepts: [],
            })
        }
    }

    selectAllKeyConceptsByDefault = (keyConcepts: KeyConceptDto[]) => {
        this.setState({ filteredKeyConcepts: keyConcepts })
    }

    handleOnEditGroup = async (group: GroupTableByStudentReportDto, newGroupName: string) => {
        this.clearTableSelection()
        this.props.setIsDataLoading(true)
        let token = this.props.authState.accessToken.accessToken
        const groupUpdateDto: GroupUpdateDto = {
            id: group.id,
            name: newGroupName,
        }

        if (this.props.sessionData.selectedSchoolClass) {
            return UpdateGroupName(
                this.props.sessionData.selectedSchoolClass.id,
                groupUpdateDto,
                token
            )
                .then((res) => {
                    this.props.setIsDataLoading(false)
                    if (res.ok) {
                        const copyOfGroupTableByStudent = [...this.state.groupTableByStudent]
                        const index = copyOfGroupTableByStudent.findIndex((gr) => {
                            return gr.id === group.id
                        })

                        if (index !== -1) {
                            copyOfGroupTableByStudent[index].name = newGroupName
                            this.setState({
                                groupTableByStudent: copyOfGroupTableByStudent,
                            })
                        }

                        const copyOfShortGroups = [...this.state.groups]
                        const indexOfShortGroup = copyOfShortGroups.findIndex((gr) => {
                            return gr.id === group.id
                        })

                        if (indexOfShortGroup !== -1) {
                            copyOfShortGroups[indexOfShortGroup].name = newGroupName
                            this.setState({
                                groups: copyOfShortGroups,
                            })
                        }
                    } else {
                        this.handleknownError(res.error)
                    }
                })
                .catch((error) => {
                    this.handleUnknownError(error)
                })
        }
    }

    toggleGroupModal = () => {
        this.setState((prevState) => ({
            modalCreateGroup: !prevState.modalCreateGroup,
        }))
    }

    createNewGroup = async () => {
        this.clearTableSelection()
        let newName = (document.getElementById('newName') as any).value
        this.toggleGroupModal()
        this.props.setIsDataLoading(true)
        let newGroup: GroupCreateDto = {
            name: newName,
            schoolClassId: this.props.sessionData.selectedSchoolClass.id,
        }

        return addGroup(newGroup, this.props.authState.accessToken.accessToken)
            .then((res) => {
                if (res.ok) {
                    this.props.setIsDataLoading(false)
                    this.loadData()
                } else {
                    this.handleknownError(res.error)
                }
            })
            .catch((error) => {
                this.handleUnknownError(error)
            })
    }

    render() {
        // if (this.props.isDataLoading) return null;

        return (
            <div data-cy="groups-page-loaded">
                {this.props.sessionData.authenticatedTeacher.isDemo && (
                    <DemoPopovers
                        items={[{ target: 'create', placement: 'bottom' }]}
                    ></DemoPopovers>
                )}

                <div className="mb-2">
                    <BreadCrumbs
                        items={[
                            { title: 'Home', link: '/' },
                            { title: 'Groups', link: '' },
                        ]}
                    />
                </div>
                {this.props.sessionData.selectedSchoolClass &&
                this.props.sessionData.selectedSchoolClass.id === archivedClassGuid ? null : (
                    <div>
                        <div className="d-flex justify-content-between  pb-4">
                            <div>
                                <h2 className="pb-2">{this.state.title}</h2>
                                <p className="font-weight-light">
                                    Use your granular data to create groups to help you deliver
                                    point-of-need teaching to all your students.
                                </p>
                                <p>
                                    <Button
                                        id="create"
                                        color="success"
                                        onClick={this.toggleGroupModal}
                                        disabled={
                                            this.props.sessionData.selectedSchoolClass &&
                                            this.props.sessionData.selectedSchoolClass.id ===
                                                demoClassGuid
                                        }
                                    >
                                        Create new group
                                    </Button>
                                </p>
                                <Modal
                                    isOpen={this.state.modalCreateGroup}
                                    toggle={this.toggleGroupModal}
                                >
                                    <ModalHeader toggle={this.toggleGroupModal}>
                                        Create New Group
                                    </ModalHeader>
                                    <ModalBody>
                                        <div className="form-group">
                                            <label htmlFor="name">Name: </label>
                                            <input
                                                type="text"
                                                id="newName"
                                                className="form-control"
                                            />
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="secondary" onClick={this.toggleGroupModal}>
                                            Cancel
                                        </Button>
                                        <Button color="danger" onClick={this.createNewGroup}>
                                            Create
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                            </div>
                            <div>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span className="dot-mastered mr-2" />
                                            </td>
                                            <td>Recently Mastered</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="dot-previously-mastered mr-2" />
                                            </td>
                                            <td>Previously Mastered</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="dot-not-mastered mr-2" />
                                            </td>
                                            <td>Not Mastered Yet</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="dot-not-asses-yet mr-2" />
                                            </td>
                                            <td>Not Assessed Yet</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {this.state.groups.length <= 0 ? null : (
                            <>
                                <Label className="label-bold-style">Key Concepts Filter:</Label>
                                <div className="mb-4">
                                    <MySelect
                                        id="masterSelect"
                                        isMulti
                                        allowSelectAll={true}
                                        value={this.state.selectedKeyConcepts}
                                        onChange={this.handleChangeOnKeyConceptSelect}
                                        options={this.state.optionsOfMasterKeyConcepts}
                                    />
                                </div>
                                <div className="mb-4">
                                    {this.state.groupTableByStudent.map((studentsByGroup) => {
                                        let editableGroup = true

                                        if (studentsByGroup.id === archivedClassGuid) {
                                            editableGroup = false
                                        }
                                        return (
                                            <div key={studentsByGroup.id}>
                                                <GroupTable
                                                    allKeyConcepts={this.state.keyConcepts}
                                                    filteredKeyConcepts={
                                                        this.state.filteredKeyConcepts
                                                    }
                                                    groupTableByStudent={studentsByGroup}
                                                    editableGroup={editableGroup}
                                                    groups={this.state.groups}
                                                    onDelete={this.handleOnDelete}
                                                    onAssign={this.handleOnAssign}
                                                    onDeleteGroup={this.handleOnDeleteGroup}
                                                    onEditGroup={this.handleOnEditGroup}
                                                    selectedSchoolClass={
                                                        this.props.sessionData.selectedSchoolClass
                                                    }
                                                    clearSelection={this.state.clearSelection}
                                                />
                                                <br />
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        )
    }
}

export default withOktaAuth(GroupIndex)
