import React, { Component } from 'react'
import { DiagnosticType } from '../../models/Diagnostic'
import { FormGroup, Label } from 'reactstrap'
import StudentDiagnosticSelector from './StudentDiagnosticSelector'

interface IProps {
    authState: any
    samplingSelectorRef: any
    keyConceptSelectorRef: any
    getEstimatesForNewDiagnostic: any
    // setDiagnosticType:any;
    // diagnosticTypeRef: any;
    isDiagnosticTypeReadOnly: any
    isShowNoStudentsAvailable: boolean
    isCalculatingTimes: boolean
}

interface IState {
    diagnosticType: DiagnosticType
    // isCalculatingTimes: boolean;
}

class DiagnosticCreateTable extends Component<IProps, IState> {
    //
    constructor(props: IProps) {
        super(props)
        this.state = {
            diagnosticType: DiagnosticType.KeyConcept,
            // isShowNoStudentsAvailable: false,
            // isCalculatingTimes: false,
        }
    }

    async componentDidMount() {}

    componentWillUnmount() {}

    async componentDidUpdate() {}

    getDiagnosticType() {
        return this.state.diagnosticType
    }

    setDiagnosticType(isSampling) {
        if (isSampling) {
            this.setState({ diagnosticType: DiagnosticType.Sampling })
        } else {
            this.setState({ diagnosticType: DiagnosticType.KeyConcept })
        }
    }

    render() {
        return (
            <>
                {this.props.isShowNoStudentsAvailable ? (
                    <div className="d-flex justify-content-center mt-4">
                        <h5>All students are currently completing other assessments</h5>
                    </div>
                ) : (
                    <div>
                        <div className="border mt-3 bg-white">
                            <FormGroup>
                                <div className="d-block mt-3 pl-3">
                                    <Label className="label-bold-style" for="name">
                                        Assessment Type:{' '}
                                    </Label>
                                    <div className="pb-1">
                                        <div className="custom-control custom-radio">
                                            <input
                                                disabled={this.props.isDiagnosticTypeReadOnly}
                                                id="deep"
                                                name="assessmentType"
                                                type="radio"
                                                className="custom-control-input"
                                                checked={
                                                    this.state.diagnosticType ===
                                                    DiagnosticType.KeyConcept
                                                }
                                                onChange={(e) =>
                                                    this.setDiagnosticType(
                                                        e.currentTarget.value !== 'on'
                                                    )
                                                }
                                            ></input>
                                            <label className="custom-control-label" htmlFor="deep">
                                                Deep Assessment - Collect detailed data to directly
                                                assess student&apos;s classwork - pre-tests,
                                                post-tests and formative assessments.
                                            </label>
                                        </div>
                                    </div>
                                    <div className="custom-control custom-radio">
                                        <input
                                            disabled={this.props.isDiagnosticTypeReadOnly}
                                            id="broad"
                                            name="assessmentType"
                                            type="radio"
                                            className="custom-control-input"
                                            checked={
                                                this.state.diagnosticType ===
                                                DiagnosticType.Sampling
                                            }
                                            onChange={(e) =>
                                                this.setDiagnosticType(
                                                    e.currentTarget.value === 'on'
                                                )
                                            }
                                        ></input>
                                        <label className="custom-control-label" htmlFor="broad">
                                            Broad Assessment - Quickly gain a snapshot of attainment
                                            across the curriculum broadly.
                                        </label>
                                    </div>
                                </div>
                            </FormGroup>
                        </div>

                        <div
                            style={
                                this.state.diagnosticType === DiagnosticType.Sampling
                                    ? { display: '' }
                                    : { display: 'none' }
                            }
                        >
                            <StudentDiagnosticSelector
                                ref={this.props.samplingSelectorRef}
                                isCalculatingTimes={this.props.isCalculatingTimes}
                                authState={this.props.authState}
                                onGetEstimatedTime={this.props.getEstimatesForNewDiagnostic}
                                title={'Select Curriculum Strand/s:'}
                                subtitle={null}
                                type={DiagnosticType.Sampling}
                                studentListDescription={
                                    'You can customise this assessment by changing the Curriculum Strand or Year Level for individual students using the list below.'
                                }
                            ></StudentDiagnosticSelector>
                        </div>
                        <div
                            style={
                                this.state.diagnosticType === DiagnosticType.KeyConcept
                                    ? { display: '' }
                                    : { display: 'none' }
                            }
                        >
                            <StudentDiagnosticSelector
                                ref={this.props.keyConceptSelectorRef}
                                isCalculatingTimes={this.props.isCalculatingTimes}
                                authState={this.props.authState}
                                onGetEstimatedTime={this.props.getEstimatesForNewDiagnostic}
                                title={'Select Key Concepts:'}
                                subtitle={
                                    'Each Key Concept contains the relevant content from all year levels. This allows students to be accurately diagnosed on their individual level of knowledge.'
                                }
                                type={DiagnosticType.KeyConcept}
                                studentListDescription={
                                    'You can fully customise this assessment by adding and removing Key Concepts for individual students using the list below.'
                                }
                            ></StudentDiagnosticSelector>
                        </div>
                    </div>
                )}
            </>
        )
    }
}
export default DiagnosticCreateTable
