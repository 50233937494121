import React, { useEffect, useRef } from 'react'
import { useStripe, useElements } from '@stripe/react-stripe-js'
import { CardElement } from '@stripe/react-stripe-js'
import CardSection from './CardSection'
import { StripeCardElement } from '@stripe/stripe-js'
import NumberFormat from 'react-number-format'
import stripeBadge from '../../assets/images/Powered by Stripe - blurple.svg'

interface IProps {
    onSuccessfulPayment: any
    onError: any
    clientSecret: string
    price: number
    setIsDataLoading: any
}

function CheckoutForm(props: IProps) {
    const stripe = useStripe()
    const elements = useElements()

    useEffect(() => {
        // Update the document title using the browser API
        //document.title = `You clicked ${count} times`;
        // createPaymentIntent(props.earlyInsightsOrder);
    }, [])

    // const [paymentIntent, setPaymentIntent] = useState(null);
    // const [secret, setSecret] = useState(null);
    const checkboxTermsRef = useRef()

    const handleSubmit = async (event: any) => {
        event.preventDefault()
        if (!(checkboxTermsRef.current as any).checked) {
            return props.onError('You must agree to the Terms and Conditions')
        }

        props.setIsDataLoading(true)

        //	props.setIsDataLoading(true);
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            props.setIsDataLoading(false)

            return
        }

        const cardElement = elements.getElement(CardElement)
        const result = await stripe.confirmCardPayment(props.clientSecret, {
            payment_method: {
                card: cardElement,
                metadata: {},
            },
        })

        //	props.setIsDataLoading(true);

        if (result.error) {
            console.log(result.error.message)
            props.setIsDataLoading(false)

            return props.onError(result.error.message)
        }

        if (!result.paymentIntent) {
            props.setIsDataLoading(false)

            return props.onError('no paymentIntent data')
        }

        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded') {
            //  alert("payment - " + result.paymentIntent.status);
            // Show a success message to your customer
            // There's a risk of the customer closing the window before callback
            // execution. Set up a webhook or plugin to listen for the
            // payment_intent.succeeded event that handles any business critical
            // post-payment actions.
            props.onSuccessfulPayment(result.paymentIntent)
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <CardSection />

                {/* <CardElement /> */}
            </div>
            <hr className="mb-3" />
            <div className="form-group">
                <div className="custom-control mb-3">
                    <input
                        data-cy="terms-and-conditions-checkbox"
                        type="checkbox"
                        className="form-check-input"
                        id="terms"
                        ref={checkboxTermsRef}
                    />
                    <label className="form-check-label" htmlFor="terms">
                        {' '}
                    </label>
                    <a
                        href="https://tridentazurestorage.blob.core.windows.net/files/Terms_and_Conditions_-_Early_Insights_-_2020.pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <u>I agree to Terms and Conditions</u>
                    </a>
                </div>
            </div>
            <button
                data-cy="pay-button"
                className="btn btn-primary btn-lg btn-block"
                disabled={!stripe}
            >
                Pay{' '}
                <NumberFormat
                    value={props.price}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                />{' '}
                (AUD)
            </button>
            <div className="row">
                <div className="col">
                    <div className="mt-3 center">
                        {/* Your payment is secured by Stripe */}
                        <img
                            className="mx-auto d-block logo "
                            alt="logo"
                            style={{ width: '150px', height: '34px' }}
                            src={stripeBadge}
                        />
                    </div>
                </div>
            </div>
        </form>
    )
}
export default CheckoutForm
