import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
    StudentDiagnosticInterviewQuestionDto,
    StudentDiagnosticDto,
    StudentDiagnosticFinishDto,
    InterviewQuestionDto,
    InterviewGroupQuestion,
    KeyConceptInterviewGroup,
} from '../../models/Diagnostic'
import { Button, ButtonGroup, Input } from 'reactstrap'
import { withOktaAuth } from '@okta/okta-react'
import { SchoolClassDto } from '../../models/SchoolClass'
import {
    finishStudentDiagnosticInterviewApi,
    getStudentDiagnosticInterviewQuestionsApi,
    updateStudentDiagnosticNoteApi,
} from '../../services/DiagnosticApi'
import * as _ from 'lodash'
import './Diagnostic.css'
import { AuthService, AuthState } from '../../models/Auth'
import BaseClass from '../Bases/BaseClass'
import { demoClassGuid, isTeacherAdminOrSuper } from '../../shared/Helper'
import BreadCrumbs from '../layout/BreadCrumbs'
import DemoPopovers from '../layout/DemoPopovers'
import { withSnackbar } from 'notistack'
import { ISessionData } from '../../AppRouter'

interface IParams {
    diagnosticId: string
    studentId: string
    parentPage: string
}

interface IProps extends RouteComponentProps<IParams> {
    authService: AuthService
    authState: AuthState
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
    history: any
    match: any
}

interface IState {
    error: string
    errorVisible: boolean
    studentDiagnosticDto: StudentDiagnosticDto | null
    questionsDtos: StudentDiagnosticInterviewQuestionDto[]
    interviewGroupQuestions: KeyConceptInterviewGroup[]
    selectedSchoolClass: SchoolClassDto | null
    ableToSubmitInterview: boolean
    note: string
    noteHasChanged: boolean
    isDataLoading: boolean
}

//ableToSubmitInterview: if interview not submitted but all interview questions are marked then let to submit
// do not let to submit interview again if all questions are submitted
class StudentDiagnosticIndex extends BaseClass<IProps, IState> {
    blankQuestionText = 'Blank'
    constructor(props: IProps) {
        super(props)
        this.state = {
            error: '',
            errorVisible: false,
            studentDiagnosticDto: null,
            questionsDtos: new Array<StudentDiagnosticInterviewQuestionDto>(),
            interviewGroupQuestions: new Array<KeyConceptInterviewGroup>(),
            selectedSchoolClass: null,
            ableToSubmitInterview: false,
            note: '',
            noteHasChanged: false,
            isDataLoading: true,
        }
    }

    componentDidMount() {
        this.loadData()
    }

    // componentDidUpdate(prevProps) {
    //   // if (
    //   //   this.props.navSchoolClassIdSelected !== prevProps.navSchoolClassIdSelected
    //   // ) {
    //   //   //this.loadData();
    //   //   this.props.history.push("/assessment");
    //   // }
    // }

    componentDidUpdate(prevProps, nextState) {
        if (this.props.sessionData !== prevProps.sessionData) {
            this.props.history.push('/')
        }
    }

    loadData() {
        let selectedClass = this.props.sessionData.selectedSchoolClass

        if (selectedClass) {
            this.setState({
                selectedSchoolClass: selectedClass,
            })

            this.getStudentDiagnostics(
                this.props.match.params.diagnosticId,
                this.props.match.params.studentId
            )
        } else {
            this.props.setIsDataLoading(false)
            if (isTeacherAdminOrSuper(this.props.sessionData.authenticatedTeacher.accessLevel)) {
                this.props.history.push(
                    `/admin/class/${this.props.sessionData.authenticatedTeacher.schoolId}`
                )
            } else {
                this.props.history.push('/no-data-yet')
            }
        }

        this.props.setIsDataLoading(false)
    }

    async getStudentDiagnostics(diagnosticId: string, studentId: string) {
        let token = await this.props.authState.accessToken.accessToken

        this.props.setIsDataLoading(true)

        return getStudentDiagnosticInterviewQuestionsApi(diagnosticId, studentId, token)
            .then((res) => {
                if (res.ok) {
                    let completedAllQuestions = res.data.questionsDtos.every(
                        this.checkIfAllQuestionsAreAnswered
                    )

                    this.props.setIsDataLoading(false)
                    this.setState({
                        studentDiagnosticDto: res.data.studentDiagnosticDto,
                        questionsDtos: res.data.questionsDtos,
                        interviewGroupQuestions: this.convertIntoGroupsByKeyConcept(
                            res.data.questionsDtos
                        ),
                        ableToSubmitInterview:
                            completedAllQuestions &&
                            res.data.studentDiagnosticDto.startedDate === null,
                        note: res.data.studentDiagnosticDto.note,
                    })
                } else {
                    this.handleknownError(res.error)
                }
            })
            .catch((error) => {
                this.handleUnknownError(error)
            })
    }

    convertIntoGroupsByKeyConcept = (questions: StudentDiagnosticInterviewQuestionDto[]) => {
        let newGroupBy = _.groupBy(questions, 'questionAudioText')
        let keyConcepts = new Array<KeyConceptInterviewGroup>()

        for (let key in newGroupBy) {
            if (newGroupBy.hasOwnProperty(key)) {
                let questionGroup: KeyConceptInterviewGroup = {
                    keyConceptName: key,
                    assets: this.getUniqueImage(newGroupBy[key]),
                    interviewGroupQuestions: this.convertIntoGroupsByQuestionDisplayText(
                        newGroupBy[key]
                    ),
                }

                keyConcepts.push(questionGroup)
            }
        }

        return keyConcepts
    }

    convertIntoGroupsByQuestionDisplayText = (
        questions: StudentDiagnosticInterviewQuestionDto[]
    ) => {
        const groupsByDisplayText = _.groupBy(questions, 'questionDisplay')
        const test = new Array<InterviewGroupQuestion>()

        for (let key in groupsByDisplayText) {
            if (groupsByDisplayText.hasOwnProperty(key)) {
                let questionGroup: InterviewGroupQuestion = {
                    questionText: key,
                    questions: groupsByDisplayText[key],
                }

                test.push(questionGroup)
            }
        }

        return test
    }

    getUniqueImage = (questions: StudentDiagnosticInterviewQuestionDto[]) => {
        const uniqImages: Set<string> = new Set()

        questions.forEach((question) => {
            if (question.assets) {
                question.assets.forEach((asset) => {
                    if (!uniqImages.has(asset)) {
                        uniqImages.add(asset)
                    }
                })
            }
        })

        // questions.map(question => {
        //   if (question.assets) {
        //     question.assets.map(asset => {
        //       if (!uniqImages.has(asset)) {
        //         uniqImages.add(asset);
        //       }
        //     });
        //   }
        // });
        let array = new Array<string>()

        uniqImages.forEach((v) => array.push(v))

        return array
    }

    updateQuestion = async (
        question: StudentDiagnosticInterviewQuestionDto,
        isCorrect: boolean
    ) => {
        let index = this.state.questionsDtos.indexOf(question)
        const newItems = [...this.state.questionsDtos]

        newItems[index].correct = isCorrect
        newItems[index].answeredDate = new Date().toUTCString()

        if (isCorrect) {
            newItems[index].knowns.forEach((knownId) => {
                newItems.forEach((question) => {
                    if (question.questionId === knownId) {
                        question.correct = isCorrect
                        question.answeredDate = new Date().toUTCString()
                    }
                })
            })
        } else {
            newItems[index].notKnowns.forEach((unKnownId) => {
                newItems.forEach((question) => {
                    if (question.questionId === unKnownId) {
                        question.correct = isCorrect
                        question.answeredDate = new Date().toUTCString()
                    }
                })
            })
        }

        let completedAllQuestions = newItems.every(this.checkIfAllQuestionsAreAnswered)

        this.setState({
            questionsDtos: newItems,
            ableToSubmitInterview: completedAllQuestions,
        })
    }

    checkIfAllQuestionsAreAnswered = (question: StudentDiagnosticInterviewQuestionDto) => {
        return question.answeredDate !== null
    }

    finishInterview = async () => {
        const diagnosticId = this.props.match.params.diagnosticId
        const studentId = this.props.match.params.studentId
        let token = await this.props.authState.accessToken.accessToken

        this.props.setIsDataLoading(true)

        //Remove Blank Questions
        let filteredQuestions = this.state.questionsDtos.filter(
            (a) => a.questionText !== this.blankQuestionText
        )
        let questionDtos: InterviewQuestionDto[] = filteredQuestions.map((a) => ({
            questionId: a.questionId,
            correct: a.correct,
        }))
        let updateStudentDiagnostic: StudentDiagnosticFinishDto = {
            diagnosticId,
            studentId,
            questions: questionDtos,
            note: this.state.note,
        }

        return finishStudentDiagnosticInterviewApi(updateStudentDiagnostic, token)
            .then((res) => {
                if (res.ok) {
                    this.props.setIsDataLoading(false)
                    this.props.history.push(
                        `/assessment/details/${this.props.match.params.diagnosticId}`
                    )
                } else {
                    this.handleknownError(res.error)
                }
            })
            .catch((error) => {
                this.handleUnknownError(error)
            })
    }

    updateNotes() {
        this.props.setIsDataLoading(true)

        return updateStudentDiagnosticNoteApi(
            this.state.studentDiagnosticDto.diagnosticId,
            this.state.studentDiagnosticDto.studentId,
            this.state.note,
            this.props.authState.accessToken.accessToken
        )
            .then((res) => {
                if (res.ok) {
                    this.props.setIsDataLoading(false)
                    ;['Notes saved'].forEach((v) => {
                        ;(this.props as any).enqueueSnackbar(v, {
                            variant: 'success',
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            autoHideDuration: 2000,
                        })
                    })

                    this.setState({ noteHasChanged: false })
                } else {
                    this.handleknownError(res.error)
                }
            })
            .catch((error) => {
                this.handleUnknownError(error)
            })
    }

    getCardColor = (question: StudentDiagnosticInterviewQuestionDto) => {
        if (question.answeredDate !== null && question.questionText !== this.blankQuestionText) {
            if (question.correct) {
                return 'card-mastered'
            }

            return 'card-not-mastered'
        }

        return ''
    }

    handleOnChange = (e) => {
        this.setState({ note: e.target.value })
        this.setState({ noteHasChanged: true })
    }

    render() {
        //   // if (this.props.isDataLoading) return null;

        const { interviewGroupQuestions, studentDiagnosticDto } = this.state
        let studentFullName = ''

        if (studentDiagnosticDto) {
            studentFullName = `${studentDiagnosticDto.firstName} ${studentDiagnosticDto.lastName}`
        }

        return (
            <div>
                <div className="pb-3">
                    <div className="mb-2">
                        {this.props.match.params.parentPage === 'assessment-details' ? (
                            <BreadCrumbs
                                items={[
                                    { title: 'Home', link: '/' },
                                    { title: 'Assessments', link: '/assessment' },
                                    {
                                        title: 'Assessment Details',
                                        link: `/assessment/details/${this.props.match.params.diagnosticId}`,
                                    },
                                    { title: 'Student Inteview', link: '' },
                                ]}
                            />
                        ) : (
                            <BreadCrumbs
                                items={[
                                    { title: 'Home', link: '/' },
                                    { title: 'Student', link: '/student' },
                                    {
                                        title: 'Student Profile',
                                        link: `/student/profile/${this.props.match.params.studentId}`,
                                    },
                                    { title: 'Student Digital Results', link: '' },
                                ]}
                            />
                        )}
                    </div>

                    <h2 className="pb-2">{studentFullName}</h2>
                    <div className="row">
                        <div className="col-md-8">
                            <p className="font-weight-light">
                                This page gives you the list of equipment and questions you’ll need
                                to conduct the interview and the statements that are being assessed.
                                Simply ask the questions and record the students&apos; response
                                against the statements as either Mastered or Not Mastered.
                            </p>
                            <p className="font-weight-light">
                                These statements have been included in the interview as they rely on
                                teacher judgement to accurately assess the students’ knowledge. Feel
                                free to change the wording of the questions or ask follow up
                                questions if you need to.
                            </p>
                        </div>
                    </div>
                    {studentDiagnosticDto ? (
                        <div>
                            {interviewGroupQuestions.map((interviewGroup) => {
                                return (
                                    <div className="pb-3" key={interviewGroup.keyConceptName}>
                                        <div className="key-concept-interview-heading mb-3">
                                            <h3>{interviewGroup.keyConceptName}</h3>
                                        </div>
                                        <div className="d-flex mb-3">
                                            {interviewGroup.assets.map((asset) => {
                                                return (
                                                    <img
                                                        key={`${interviewGroup.keyConceptName}_${asset}`}
                                                        src={asset}
                                                        alt=""
                                                        className="img-fluid image-tool mr-3"
                                                    />
                                                )
                                            })}
                                        </div>
                                        <div>
                                            {interviewGroup.interviewGroupQuestions.map(
                                                (interviewGroup) => {
                                                    return (
                                                        <div key={interviewGroup.questionText}>
                                                            <div className="mb-2">
                                                                <h5 className="interview-question-color">
                                                                    {interviewGroup.questionText}
                                                                </h5>
                                                            </div>
                                                            <div className="row">
                                                                {interviewGroup.questions.map(
                                                                    (question) => {
                                                                        return (
                                                                            <div
                                                                                className="col-sm-6 pb-3"
                                                                                key={
                                                                                    question.questionId
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className={`card card-height ${this.getCardColor(
                                                                                        question
                                                                                    )}`}
                                                                                >
                                                                                    <div className="card-body d-flex align-items-center justify-content-between">
                                                                                        <div className="flex-grow-1">
                                                                                            <p
                                                                                                className="card-text"
                                                                                                data-toggle="tooltip"
                                                                                                data-placement="top"
                                                                                                title={`Correct Answer: ${question.questionAnswer}`}
                                                                                            >
                                                                                                {question.questionText !==
                                                                                                this
                                                                                                    .blankQuestionText
                                                                                                    ? question.questionText
                                                                                                    : null}
                                                                                            </p>
                                                                                        </div>
                                                                                        {question.questionText !==
                                                                                        this
                                                                                            .blankQuestionText ? (
                                                                                            <div className="flex-shrink-0">
                                                                                                <ButtonGroup>
                                                                                                    <Button
                                                                                                        className={
                                                                                                            question.answeredDate !==
                                                                                                            null
                                                                                                                ? question.correct
                                                                                                                    ? 'btn btn-success'
                                                                                                                    : 'btn btn-light'
                                                                                                                : 'btn btn-light'
                                                                                                        }
                                                                                                        onClick={() => {
                                                                                                            this.updateQuestion(
                                                                                                                question,
                                                                                                                true
                                                                                                            )
                                                                                                        }}
                                                                                                        disabled={
                                                                                                            studentDiagnosticDto.startedDate !==
                                                                                                                null ||
                                                                                                            studentDiagnosticDto.finishedDate !==
                                                                                                                null
                                                                                                        }
                                                                                                    >
                                                                                                        Mastered
                                                                                                    </Button>
                                                                                                    <Button
                                                                                                        className={
                                                                                                            question.answeredDate ===
                                                                                                            null
                                                                                                                ? 'btn btn-light'
                                                                                                                : question.correct
                                                                                                                ? 'btn btn-light'
                                                                                                                : 'btn btn-warning'
                                                                                                        }
                                                                                                        onClick={() => {
                                                                                                            this.updateQuestion(
                                                                                                                question,
                                                                                                                false
                                                                                                            )
                                                                                                        }}
                                                                                                        disabled={
                                                                                                            studentDiagnosticDto.startedDate !==
                                                                                                                null ||
                                                                                                            studentDiagnosticDto.finishedDate !==
                                                                                                                null
                                                                                                        }
                                                                                                    >
                                                                                                        Not
                                                                                                        Mastered
                                                                                                    </Button>
                                                                                                </ButtonGroup>
                                                                                            </div>
                                                                                        ) : null}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    ) : null}

                    <div className="pb-3">
                        <div className="space-between">
                            <div>
                                <label htmlFor="student-notes" className="pr-2 pb-1">
                                    <strong>Student notes: </strong>
                                </label>
                                {studentDiagnosticDto &&
                                (studentDiagnosticDto.startedDate !== null ||
                                    studentDiagnosticDto.finishedDate !== null) ? (
                                    <Button
                                        disabled={
                                            this.props.isDataLoading || !this.state.noteHasChanged
                                        }
                                        onClick={() => {
                                            this.updateNotes()
                                        }}
                                        color="primary"
                                        size="sm"
                                    >
                                        update
                                    </Button>
                                ) : null}
                            </div>
                            <div></div>
                        </div>
                        <Input
                            style={{ backgroundColor: 'white' }}
                            onChange={(e) => this.handleOnChange(e)}
                            type="textarea"
                            name="text"
                            id="student-notes"
                            rows="5"
                            value={this.state.note}
                            readOnly={
                                this.state.selectedSchoolClass &&
                                this.state.selectedSchoolClass.id === demoClassGuid
                            }
                        />
                    </div>

                    <div className="d-flex justify-content-between">
                        {/* <div>
              <Button
                color="primary"
                tag={Link}
                to={"/assessment/details/" + this.props.match.params.diagnosticId}
              >
                Back
            </Button>
            </div> */}

                        <div></div>

                        {/* <div className="mr-1"> */}
                        <div>
                            {this.state.studentDiagnosticDto &&
                            !this.state.studentDiagnosticDto.finishedDate ? (
                                <>
                                    {this.props.sessionData.authenticatedTeacher.isDemo && (
                                        <DemoPopovers
                                            items={[{ target: 'submit', placement: 'right' }]}
                                        ></DemoPopovers>
                                    )}

                                    <Button
                                        id="submit"
                                        className="btn btn-success"
                                        onClick={this.finishInterview}
                                        disabled={
                                            !this.state.ableToSubmitInterview ||
                                            (this.state.selectedSchoolClass &&
                                                this.state.selectedSchoolClass.id === demoClassGuid)
                                        }
                                    >
                                        Submit interview
                                    </Button>
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withSnackbar(withOktaAuth(StudentDiagnosticIndex))
