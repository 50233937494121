import axios from 'axios'
import { handleApiSuccess, handleApiError, IApiResult } from '../shared/ApiHandler'
import { orderApiUrl, teacherBaseApiUrl } from '../shared/ApiUrls'
import { EarlyInsightsOrderDto } from '../models/EarlyInsightsOrder'
import { DemoCreateDto } from '../models/DemoCreateDto'
import { SalesForceActionEnum } from '../models/Order'

export const ExtendTrialApi = (token: string): Promise<IApiResult> => {
    return axios
        .post(`${orderApiUrl}/ExtendTrial`, null, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const SaveSalesForceActionApi = (
    token: string,
    opportunityId: string,
    demoAction: SalesForceActionEnum
): Promise<IApiResult> => {
    return axios
        .post(`${orderApiUrl}/PostSalesForceAction/${opportunityId}/${demoAction}`, null, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const CreateDemoApi = (demoCreateDto: DemoCreateDto): Promise<IApiResult> => {
    return axios
        .post(`${orderApiUrl}/CreateDemo`, demoCreateDto, {
            headers: {},
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const CheckTeacherIsValidForOrderApi = (
    email: string,
    school: string
): Promise<IApiResult> => {
    return axios
        .get(`${orderApiUrl}/CheckOrderIsValid/${email}/${school}`, {
            headers: {},
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const IsSchoolInAustralianListApi = (
    //email: string,
    school: string
): Promise<IApiResult> => {
    return axios
        .get(`${orderApiUrl}/IsSchoolInAustralianList/${school}`, {
            headers: {},
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const GetStripeIntent = (id: string): Promise<IApiResult> => {
    return axios
        .get(`${orderApiUrl}/GetStripeIntent/${id}`, {
            headers: {},
            baseURL: teacherBaseApiUrl,
            //  headers: { Authorization: "bearer " + token }
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const CreateStripeIntentOrder = (order: EarlyInsightsOrderDto): Promise<IApiResult> => {
    return axios
        .post(`/api/CreateStripeIntentOrder`, order, {
            headers: {},
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const CompleteStripeIntentOrderApi = (
    intentId: string,
    isPurchaseOrder: boolean,
    poRef: string
): Promise<IApiResult> => {
    if (!poRef || poRef === '') poRef = 'none'
    return axios
        .post(`/api/CompleteStripeIntentOrder/${intentId}/${isPurchaseOrder}/${poRef}`, null, {
            headers: {},
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const AbandonCartFromApi = (opportunityId: string): Promise<IApiResult> => {
    return axios
        .post(`${orderApiUrl}/AbandonCart/${opportunityId}`, null, {
            headers: {},
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const GetOrders = (token: string, schoolId: string): Promise<IApiResult> => {
    return axios
        .get(`${orderApiUrl}/${schoolId}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const GetLicenceSummary = (token: string, schoolId: string): Promise<IApiResult> => {
    return axios
        .get(`${orderApiUrl}/GetLicenseSummary/${schoolId}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}
