import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { withOktaAuth } from '@okta/okta-react'
import { Card, Button, Row, Col } from 'reactstrap'
import { AuthService, AuthState } from '../../models/Auth'
import BreadCrumbs from '../layout/BreadCrumbs'
import 'tippy.js/dist/tippy.css' // optional
import 'tippy.js/animations/scale-extreme.css'
import { TeacherDto } from '../../models/Teacher'
import { ISessionData } from '../../AppRouter'

interface IParams {
    schoolId: string
}

interface IProps extends RouteComponentProps<IParams> {
    //  baseUrl: string;
    authService: AuthService
    authState: AuthState
    sessionData: ISessionData
}

interface IState {
    // isPopoverOpen: boolean;
    // popoverMessage: string;
    // popoverTarget: string;
    loggedInTeacher: TeacherDto
    title: string
}

class AdminIndex extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            // isPopoverOpen: false,
            //  popoverMessage: "Add some teachers to your school",
            // popoverTarget: "teachers",
            loggedInTeacher: null,
            title: 'Help',
        }
    }

    async componentDidMount() {
        this.loadData()
    }

    loadData() {
        let teacher = this.props.sessionData.authenticatedTeacher

        if (!teacher) {
            return
        }

        this.setState({ loggedInTeacher: teacher })
    }

    togglePopover = () => {
        //   this.setState({ isPopoverOpen: !this.state.isPopoverOpen });
    }

    render() {
        return (
            <div>
                {/* <Popover
          placement="bottom"
          isOpen={this.state.isPopoverOpen}
          target="teachers"
          toggle={this.togglePopover}
        >
          <PopoverBody>
            {this.state.popoverMessage}
            <button type="button" className="btn btn-link" onClick={(e) => { this.setState({ isPopoverOpen: false }) }}>close</button>
          </PopoverBody>
        </Popover> */}

                {/* <DemoPopovers history={this.props.history} items={[{target:'teachers',message:'test'},{target:'classes',message:'test2'}]}></DemoPopovers> */}

                <div className="mb-2">
                    <BreadCrumbs
                        items={[
                            { title: 'Home', link: '/' },
                            { title: 'Help', link: '' },
                        ]}
                    />
                </div>

                <h2 className="pb-2">{this.state.title}</h2>
                <div className="pb-4">
                    <Row>
                        <Col sm="4">
                            <Card body>
                                <h5 className="card-title">FAQ</h5>
                                <p style={{ minHeight: 50 }}>Set up and manage teacher accounts.</p>
                                {/* <br></br> */}
                                <Button
                                    id="teachers"
                                    color="primary"
                                    tag={Link}
                                    to={`/admin/teacher/${this.props.sessionData.selectedSchool.id}`}
                                >
                                    Manage
                                </Button>
                            </Card>
                        </Col>
                        <Col sm="4">
                            <Card body>
                                <h5 className="card-title">Updates</h5>
                                <p style={{ minHeight: 50 }}>
                                    Create classes, add students and assign teachers.
                                </p>
                                <Button
                                    id="classes"
                                    color="primary"
                                    tag={Link}
                                    to={`/admin/class/${this.props.sessionData.selectedSchool.id}`}
                                >
                                    Manage
                                </Button>
                            </Card>
                        </Col>
                        <Col sm="4">
                            <Card body>
                                <h5 className="card-title">Contact</h5>
                                <p style={{ minHeight: 50 }}>
                                    Manage and purchase student licences.
                                </p>
                                {/* <br></br> */}
                                <Button
                                    id="students"
                                    color="primary"
                                    tag={Link}
                                    to={`/admin/licence/${this.props.sessionData.selectedSchool.id}`}
                                >
                                    Manage
                                </Button>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div className="pb-4">
                    <Row>
                        <Col sm="4">
                            {/* <Card body>
                <h5 className="card-title">Rollover</h5>
                <p style={{ minHeight: 50 }}>
                  Rollover students
                </p>
                <Button id="teachers" color="primary" tag={Link} to={"/admin/school/rollover/" + this.props.sessionData.selectedSchool.id}>
                  Manage
                </Button>
              </Card> */}
                        </Col>
                        <Col sm="4">
                            {/* <Card body>
                <h5 className="card-title">Classes</h5>
                <p style={{ minHeight: 50 }}>
                  Create classes, add students and assign teachers.
                </p>
                <Button id="classes" color="primary" tag={Link} to={"/admin/class/" + this.props.sessionData.selectedSchool.id}>
                  Manage
                </Button>
              </Card> */}
                        </Col>
                        <Col sm="4">
                            {/* <Card body >
                <h5 className="card-title">Licences</h5>
                <p style={{ minHeight: 50 }}>
                  Manage and purchase student licences.
                </p>
                <Button id="students" color="primary" tag={Link} to={"/admin/licence/" + this.props.sessionData.selectedSchool.id}>
                  Manage
                </Button>
              </Card> */}
                        </Col>
                    </Row>
                </div>
                {/* <div className="d-flex justify-content-between mb-2">
          <div>
            <Button color="primary" tag={Link} to={"/"}>
              Back
            </Button>
          </div>
        </div> */}
            </div>
        )
    }
}
export default withOktaAuth(AdminIndex)
