import React, { useEffect, useRef, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Button, Alert } from 'reactstrap'
import { addStudent } from '../../services/StudentApi'
import { StudentCreateDto } from '../../models/Student'
import { getSchoolClassFromApi } from '../../services/SchoolClassApi'
import { demoClassGuid, getSchoolClassLevels } from '../../shared/Helper'
import Select from 'react-select'
import BreadCrumbs from '../layout/BreadCrumbs'
import DemoPopovers from '../layout/DemoPopovers'
import { ISessionData } from '../../AppRouter'
import { useOktaAuth } from '@okta/okta-react'

interface IParams {
    schoolClassId: string
}

interface IProps extends RouteComponentProps<IParams> {
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
    match: any
    history: any
}

export default function StudentCreate(props: IProps) {
    const defaultStudent: StudentCreateDto = {
        firstName: '',
        lastName: '',
        preferredName: '',
        password: '',
        schoolClassId: '',
        schoolClassLevel: 'Foundation',
    }

    const [error, setError] = useState(null)
    const [student, setStudent] = useState(defaultStudent)
    const [title, setTitle] = useState('Create Student ()')
    const [levels, setLevels] = useState(getSchoolClassLevels())
    const [selectedLevel, setSelectedLevel] = useState(null)
    const [schoolClassId, setSchoolClassId] = useState(null)
    const { authState, authService } = useOktaAuth()

    const prevSessionData = useRef(props.sessionData)

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        const { authenticatedTeacher, selectedSchoolClass } = props.sessionData

        let schoolClass

        if (props.match.params.schoolClassId) {
            schoolClass = await getSchoolClass(props.match.params.schoolClassId)
        } else {
            schoolClass = selectedSchoolClass
        }

        if (schoolClass) {
            setTitle(`Create Student (${schoolClass.name})`)
            setStudent({
                ...student,
                schoolClassId: schoolClass.id,
            })

            setSchoolClassId(schoolClass.id)
        }

        props.setIsDataLoading(false)
    }

    const getSchoolClass = (schoolClassId: string) => {
        return getSchoolClassFromApi(schoolClassId, authState.accessToken.accessToken)
            .then((res) => {
                if (res.ok) {
                    return res.data
                }
                // this.handleknownError(res.error)
            })
            .catch((error) => {
                //  this.handleUnknownError(error)
            })
    }

    const onHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStudent({
            ...student,
            [e.target.id]: e.target.value,
        })
    }

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        setError(null)
        e.preventDefault()
        if (
            !student.firstName ||
            !student.lastName ||
            !student.preferredName ||
            !student.password
        ) {
            return
        }

        props.setIsDataLoading(true)

        return addStudent(student, authState.accessToken.accessToken)
            .then((res) => {
                props.setIsDataLoading(false)
                if (res.ok) {
                    return goBack()
                }
                //  this.handleknownError(res.error)
            })
            .catch((error) => {
                // this.handleUnknownError(error)
            })
    }

    const goBack = () => {(props.history as any).goBack()}

    const onHandleDropdownChange = (selectedOption: any) => {
        setStudent({
            ...student,
            schoolClassLevel: selectedOption.value,
        })

        setSelectedLevel(selectedOption)
    }

    // if (props.isDataLoading) return null;

    return (
        <div data-cy="student-create-page-loaded">
            {props.sessionData.authenticatedTeacher.isDemo && (
                <DemoPopovers
                    //  sessionData={props.sessionData}
                    // history={props.history}
                    items={[{ target: 'create', placement: 'right' }]}
                ></DemoPopovers>
            )}

            <div className="mb-2">
                <BreadCrumbs
                    items={[
                        { title: 'Home', link: '/' },
                        { title: 'Students', link: '/student' },
                        { title: 'Create', link: '' },
                    ]}
                />
            </div>
            <h2 className="pb-2">{title}</h2>
            {error !== null ? (
                <Alert color="warning">
                    <p>{error}</p>
                </Alert>
            ) : null}
            <div className="row">
                <div className="col-md-4">
                    <form onSubmit={onSubmit}>
                        <div className="form-group">
                            <label htmlFor="firstName">First Name: </label>
                            <input
                                data-cy="first-name-input"
                                type="text"
                                id="firstName"
                                className="form-control"
                                onChange={onHandleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="lastName">Last Name: </label>
                            <input
                                data-cy="last-name-input"
                                type="text"
                                id="lastName"
                                className="form-control"
                                onChange={onHandleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="preferredName">Preferred Name: </label>
                            <input
                                data-cy="preferred-name-input"
                                type="text"
                                id="preferredName"
                                className="form-control"
                                onChange={onHandleChange}
                                required
                            />
                            <small id="preferredName" className="form-text text-muted">
                                Usernames are automatically generated using the students preferred
                                name and their surname initial. E.g. Sally Winter = SALLYW
                            </small>
                        </div>
                        <div className="form-group">
                            <label htmlFor="schoolClassLevel">Select Level</label>
                            <Select
                                id="schoolClassLevel"
                                value={selectedLevel}
                                onChange={onHandleDropdownChange}
                                options={levels}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="password">Password: </label>
                            <input
                                data-cy="password-input"
                                type="text"
                                id="password"
                                className="form-control"
                                onChange={onHandleChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <div className="space-between">
                                <Button
                                    color="secondary"
                                    onClick={goBack}
                                    disabled={props.isDataLoading}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    data-cy="create-button"
                                    id="create"
                                    color="primary"
                                    type="submit"
                                    disabled={
                                        props.isDataLoading || schoolClassId === demoClassGuid
                                    }
                                >
                                    {props.isDataLoading ? (
                                        <i className="fa fa-spinner fa-spin" />
                                    ) : null}{' '}
                                    Create
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
