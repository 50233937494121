import { Fragment, useState, forwardRef, useImperativeHandle } from 'react'
import { AsyncPaginate } from 'react-select-async-paginate'
import { teacherBaseApiUrl } from '../../shared/ApiUrls'
import appInfo from '../../../package.json'

interface IProps {
    isDisabled: boolean
    onChange: any
}

// eslint-disable-next-line react/display-name
export const SchoolCombo = forwardRef((props: IProps, ref) => {
    const [value, setValue] = useState(null)
    const [ausState, setAusState] = useState('')
    const [cacheKey, setCacheKey] = useState('')

    useImperativeHandle(ref, () => ({
        clearValue() {
            setValue(null)
        },
        changeAusState(e) {
            if (e) {
                setValue(null)
                setCacheKey(e.value)
                setAusState(e.value)
            }
        },
    }))

    const loadOptions = async (search: any, loadedOptions: any) => {
        const response = await fetch(
            `${teacherBaseApiUrl}api/order/getAustralianSchools?search=${search}&ausState=${ausState}&offset=${loadedOptions.length}`,
            {
                method: 'GET',
                headers: {
                    syncversion: appInfo.syncVersion,
                },
            }
        )
        const responseJSON = await response.json()

        return {
            options: responseJSON.results,
            hasMore: responseJSON.has_more,
        }
    }

    const handleInputChange = (newValue: any) => {
        if (newValue && newValue.label) {
            setValue(newValue)
            props.onChange(newValue)

            return
        }

        setValue(null)
        props.onChange(null)
    }

    return (
        <Fragment>
            <AsyncPaginate
                key={cacheKey}
                value={value}
                isDisabled={props.isDisabled}
                isClearable={true}
                loadOptions={loadOptions}
                onChange={handleInputChange}
            />
        </Fragment>
    )
})
