/* eslint-disable no-unused-vars */

import moment, { utc } from 'moment'
import { TeacherAccessLevel, TeacherDto } from '../models/Teacher'
import { removeTempLocalStorage } from './LocalStorageHelpers'

export const archivedClassGuid: string = '00000000-0000-0000-0000-000000000000'
export const emptyGuid: string = '00000000-0000-0000-0000-000000000000'

export const demoClassGuid: string = '5d5b53d9-59eb-4b68-9805-5f2880412538'
import { useOktaAuth } from '@okta/okta-react'
import { OktaAuth } from '@okta/okta-auth-js'

// export const isTeacherAdminOrSuper = (accessLevel: string): boolean => {
//   let result = (TeacherAccessLevel[accessLevel] === TeacherAccessLevel[TeacherAccessLevel.Admin] || TeacherAccessLevel[accessLevel] === TeacherAccessLevel[TeacherAccessLevel.Super])
//   return result;
// }

// export const isTeacherSuper = (teacher: TeacherDto): boolean => {
//   let result = (TeacherAccessLevel[teacher.accessLevel] === TeacherAccessLevel[TeacherAccessLevel.Super])
//   return result;
// }

export const isTeacherAdminOrSuper = (accessLevel: string): boolean => {
    let result = (TeacherAccessLevel[accessLevel] as any) >= TeacherAccessLevel.Admin
    return result
}

// export const isTeacherAdminOrSuper = (teacher: TeacherDto): boolean => {
//   return (TeacherAccessLevel[teacher.accessLevel] as any >= TeacherAccessLevel.Admin)
// }

export const isTeacherSuper = (teacher: TeacherDto): boolean => {
    let result = (TeacherAccessLevel[teacher.accessLevel] as any) >= TeacherAccessLevel.Super
    return result
}

export const getSchoolClassLevels = () => {
    return [
        { value: 'Foundation', label: 'F' },
        { value: 'First', label: '1' },
        { value: 'Second', label: '2' },
        { value: 'Third', label: '3' },
        { value: 'Fourth', label: '4' },
        // { value: "Fifth", label: "5" },
        // { value: "Sixth", label: "6" },
        //  { value: "All", label: "7" },
    ]
}

export const getCalendarYearLevels = () => {
    return [
        { value: 2020, label: '2020' },
        { value: 2021, label: '2021' },
        { value: 2022, label: '2022' },
        { value: 2023, label: '2023' },
        { value: 2024, label: '2024' },
        { value: 2025, label: '2025' },

        // { value: "Fifth", label: "5" },
        // { value: "Sixth", label: "6" },
    ]
}

export const getLevelOptionByValue = (value) => {
    return getSchoolClassLevels().find((v) => v.value === value)
}

export const convertSchoolClassLevelLabels = (levels) => {
    let converted = []

    for (let i = 0; i < levels.length; i++) {
        converted.push(convertSchoolClassLevelLabel(levels[i]))
    }

    return converted
}

export const convertSchoolClassLevelLabel = (level) => {
    switch (level) {
        case 'Foundation':
            return 'F'
        case 'First':
            return '1'
        case 'Second':
            return '2'
        case 'Third':
            return '3'
        case 'Fourth':
            return '4'
        case 'Fifth':
            return '5'
        case 'Sixth':
            return '6'
        default:
            null
    }
}

export enum WebsiteType {
    PRODUCTION,
    TESTING,
    DEMO,
    LOCAL_WITH_LOCAL_API,
    STAGING,
    LOCAL_WITH_EXTERNAL_API,
    //STATIC
}

export const getClientWebsiteType = () => {
    if (window.location.origin.toLowerCase().includes('portal.mathspathway.com'))
        return WebsiteType.PRODUCTION

    if (window.location.origin.toLowerCase().includes('teacherclientproductioncdn.azureedge.net'))
        return WebsiteType.PRODUCTION

    if (
        window.location.origin
            .toLowerCase()
            .includes('teacherclientproduction.z26.web.core.windows.net')
    )
        return WebsiteType.PRODUCTION

    if (
        window.location.origin
            .toLowerCase()
            .includes('teacherclienttesting.z26.web.core.windows.net')
    )
        return WebsiteType.TESTING

    // if (window.location.origin.toLowerCase().includes("tridentteacherdemo.azurewebsites.net"))
    if (window.location.origin.toLowerCase().includes('teacherclientdemo.z8.web.core.windows.net'))
        return WebsiteType.DEMO

    if (
        window.location.origin
            .toLowerCase()
            .includes('tridentteacherwebapp-staging.azurewebsites.net')
    )
        return WebsiteType.STAGING

    if (
        window.location.origin
            .toLowerCase()
            .includes('teacherclientstaging.z26.web.core.windows.net')
    )
        return WebsiteType.STAGING

    if (window.location.origin.toLowerCase().includes('localhost:3000'))
        return WebsiteType.LOCAL_WITH_EXTERNAL_API

    if (window.location.origin.toLowerCase().includes('localhost:5001'))
        return WebsiteType.LOCAL_WITH_LOCAL_API
}

export const getStudentAppUrl = () => {
    let type = getClientWebsiteType()

    switch (type) {
        case WebsiteType.LOCAL_WITH_LOCAL_API:
            return 'https://localhost:5003/'
        //  return "https://studentclienttesting.z26.web.core.windows.net/";
        // return "https://studentclienttesting.z26.web.core.windows.net/";
        ///  return "https://gromo.me/";
        // return "https://studentclientproduction.z26.web.core.windows.net/";
        case WebsiteType.TESTING:
            return 'https://studentclienttesting.z26.web.core.windows.net/'
        case WebsiteType.DEMO:
            return 'https://studentclientdemo.z8.web.core.windows.net/'
        //  case WebsiteType.STATIC:
        //    return "https://tridentstudenttesting.azurewebsites.net/";
        case WebsiteType.STAGING:
            return 'https://studentclientstaging.z26.web.core.windows.net/'
        case WebsiteType.PRODUCTION:
            // return "https://gromo.me/";
            // must be this address for mic permissions to work
            return 'https://studentclientproduction.z26.web.core.windows.net/'
        default:
            null
    }

    return ''
}

export const getWebsiteTypeHeading = () => {
    let type = getClientWebsiteType()

    switch (type) {
        case WebsiteType.DEMO:
            return ' (demo)'
        case WebsiteType.LOCAL_WITH_LOCAL_API:
            return ' (local)'
        case WebsiteType.TESTING:
            //  case WebsiteType.STATIC:
            return ' (test)'
        case WebsiteType.STAGING:
            return ' (staging)'
        default:
            null
    }

    return ''
}

export const convertUtcEpochTicksToLocalDate = (utcTicks) => {
    // var date = new Date('6/29/2011 4:52:48 PM UTC');
    let local = moment.utc(utcTicks).local()
    // var formattedDate = local.format('YYYY-MM-DD HH:mm');
    let formattedDate = local.format('DD/MM/YYYY HH:mm')

    return formattedDate
}

export const getDateNowWithTime = () => {
    var utcMoment = moment.utc()
    return utcMoment.format('YYYY-MM-DD HH:mm')
}

export const getDate7DaysAgoWithTime = () => {
    var utcMoment = moment.utc()
    utcMoment.subtract(7, 'days')
    return utcMoment.format('YYYY-MM-DD HH:mm')
}

export const getDateNow = () => {
    var utcMoment = moment.utc()
    return utcMoment.format('YYYY-MM-DD')
}

export const getDate7DaysAgo = () => {
    var utcMoment = moment.utc()
    utcMoment.subtract(7, 'days')
    return utcMoment.format('YYYY-MM-DD')
}

export const getDateTomorrow = () => {
    var utcMoment = moment.utc()
    utcMoment.add(1, 'days').local()
    return utcMoment.format('YYYY-MM-DD')
}

export const getDateLast6DaysAgo = () => {
    var utcMoment = moment.utc()
    utcMoment.subtract(6, 'days').local()
    return utcMoment.format('YYYY-MM-DD')
}

export const convertLocalToUtc = (local) => {
    var utcMoment = moment(local).utc()
    return utcMoment.format('YYYY-MM-DD')
}

export const convertUtcToLocalDate = (utcDateString) => {
    if (!utcDateString || utcDateString === '') {
        return ''
    }

    let d = new Date(`${utcDateString} UTC`)

    // var local = moment.utc(utc).local();
    //var formattedDate = local.format('DD/MM/YYYY HH:mm');
    return d.toLocaleDateString()
}

// export const convertUtcToLocalDateTime = (utcDateString) => {
//   if (!utcDateString || utcDateString === '') {
//     return '';
//   }

//   var d = new Date(utcDateString + ' UTC');
//   // var local = moment.utc(utc).local();
//   //var formattedDate = local.format('DD/MM/YYYY HH:mm');
//   return d.toLocaleString();
// }

// export const formatDateToString = (date : Date) => {
//   debugger;
//   let a = date.getDate();
//   var dd = String(date.getDate()).padStart(2, '0');
//   var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
//   var yyyy = date.getFullYear();
//   return  (mm + '/' + dd + '/' + yyyy);
// }

export const cloneList = (list) => {
    return list.map((a) => ({ ...a }))
}

export const logout = (oktaAuth: OktaAuth): any => {
    // removeSchoolClassesFromLocalStorage();
    //removeSelectedSchoolClassFromLocalStorage();
    //removeTempLocalStorage();
    removeTempLocalStorage()
    oktaAuth.signOut()
    //	setIsDataLoading && setIsDataLoading(true) // CAUSES DOUBLE LOAD LOGIN PAGE
}

export const RefreshPage = () => {
    window.location.reload()
}

export const RefreshAndMainPage = () => {
    window.location.replace('/')
}

export const ScrollToTop = () => {
    setTimeout(() => {
        try {
            if (document && document.body) {
                document.body.scrollTop = 0
            }

            if (document && document.documentElement) {
                document.documentElement.scrollTop = 0
            }
        } catch (error) {
            console.log(error)
        }
    }, 500)
}

export const GetShorterName = (value: string, maxLenght: number) => {
    if (value) return value.substring(0, maxLenght)
}

export const TrimChar = (string, charToRemove) => {
    while (string.charAt(0) === charToRemove) {
        string = string.substring(1)
    }

    while (string.charAt(string.length - 1) === charToRemove) {
        string = string.substring(0, string.length - 1)
    }

    return string
}
