import React from 'react'
import {
    Button,
    Alert,
    Form,
    FormGroup,
    Label,
    Input,
    ButtonGroup,
    Modal,
    ModalBody,
    ModalFooter,
    Spinner,
    Card,
    CardBody,
    CardFooter,
} from 'reactstrap'
import { withOktaAuth } from '@okta/okta-react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { SchoolClassDto } from '../../models/SchoolClass'
import { addDiagnosticApi, getEstimatesForNewDiagnosticApi } from '../../services/DiagnosticApi'
import { getStudentsFromApi } from '../../services/StudentApi'
import { getKeyConceptsFromApi, getSamplingAssessmentsFromApi } from '../../services/CurriculumApi'
import { StudentDto } from '../../models/Student'
import { ItemDto } from '../../models/KeyConcept'
import { DiagnosticEstimateDto, DiagnosticType } from '../../models/Diagnostic'
import './Diagnostic.css'
import clockAnimated from '../../assets/images/clockAnimated.svg'
import clock from '../../assets/images/clock.svg'
import user from '../../assets/images/user.svg'
import { AuthState, AuthService } from '../../models/Auth'
import { cloneList, demoClassGuid, logout, ScrollToTop } from '../../shared/Helper'
import BreadCrumbs from '../layout/BreadCrumbs'
import DemoPopovers from '../layout/DemoPopovers'
import axios from 'axios'
import BaseClass from '../Bases/BaseClass'
import DiagnosticCreateTable from './DiagnosticCreateTable'
import { ISessionData } from '../../AppRouter'

interface IProps extends RouteComponentProps<any> {
    //  baseUrl: string;
    authService: AuthService
    authState: AuthState
    oktaAuth: any
    // navSchoolClassIdSelected: string;
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
    history: any
    match: any
}

interface IState {
    isDataLoading: boolean
    error: string
    visible: boolean
    name: string
    selectedSchoolClass: SchoolClassDto | null
    students: StudentDto[]
    // originalKeyConcepts: KeyConceptDto[];
    // optionsOfMasterKeyConcepts: [];
    // selectedMasterKeyConcepts: any;
    diagnosticNameState: string
    // studentKeyConcepts: StudentKeyConceptDto[];
    // studentKeyConceptsWarningMessage: string;
    // studentKeyConceptsAllEmpty: boolean;
    // studentKeyConceptsSomeEmpty: boolean;
    showWarningModal: boolean
    showLoadingModal: boolean
    diagnosticEstimateDto: DiagnosticEstimateDto | null
    isCalculatingTimes: boolean
    loadingModalText: string
    // diagnosticType: DiagnosticType;

    isShowNoStudentsAvailable: boolean
}

class DiagnosticCreate extends BaseClass<IProps, IState> {
    samplingSelectorRef
    keyConceptSelectorRef
    diagnosticCreateTableRef

    cancellationTokenSource: any

    constructor(props: IProps) {
        super(props)
        
        this.samplingSelectorRef = React.createRef()
        this.keyConceptSelectorRef = React.createRef()
        this.diagnosticCreateTableRef = React.createRef()

        this.state = {
            isDataLoading: true,
            error: '',
            visible: false,
            name: '',
            selectedSchoolClass: null,
            students: [],
            diagnosticNameState: '',
            showWarningModal: false,
            showLoadingModal: false,
            diagnosticEstimateDto: null,
            isCalculatingTimes: false,
            loadingModalText: 'Loading view',
            isShowNoStudentsAvailable: false,
        }
    }

    componentDidMount() {
        this.loadData()
    }

    componentWillUnmount() {
        if (this.cancellationTokenSource) {
            this.cancellationTokenSource.cancel('Operation canceled by the user.')
        }
    }

    componentDidUpdate(prevProps, nextState) {
        if (this.props.sessionData !== prevProps.sessionData) {
            this.props.history.push('/')
        }
    }

    async loadData() {
        this.setState({ isDataLoading: true })

        let selectedClass = this.props.sessionData.selectedSchoolClass
        if (selectedClass) {
            await this.getStudents(selectedClass.id)
            await this.getDropdownItems()
            this.setState({
                selectedSchoolClass: selectedClass,
            })
        }

        this.props.setIsDataLoading(false)
    }

    getEstimatesForNewDiagnostic = async (studentItems) => {
        if (this.state.isCalculatingTimes) {
            return
        }

        this.setState({
            isCalculatingTimes: true,
        })

        let newDiagnostic = null
        let token = await this.props.authState.accessToken.accessToken

        if (this.diagnosticCreateTableRef.current.getDiagnosticType() === DiagnosticType.Sampling) {
            this.samplingSelectorRef.current.clearEstimates()
            newDiagnostic = {
                name: 'fake',
                schoolClassId: this.state.selectedSchoolClass.id,
                studentItems: this.samplingSelectorRef.current.getStudentItems(),
                diagnosticType: DiagnosticType.Sampling,
            }
        } else if (
            this.diagnosticCreateTableRef.current.getDiagnosticType() === DiagnosticType.KeyConcept
        ) {
            this.keyConceptSelectorRef.current.clearEstimates()
            newDiagnostic = {
                name: 'fake',
                schoolClassId: this.state.selectedSchoolClass.id,
                studentItems: this.keyConceptSelectorRef.current.getStudentItems(),
                diagnosticType: DiagnosticType.KeyConcept,
            }
        }

        if (newDiagnostic.studentItems && newDiagnostic.studentItems.length > 0) {
            newDiagnostic.studentItems = newDiagnostic.studentItems.filter(
                (v) => v.items && v.items.length > 0 && !v.currentDiagnostic
            )
        }

        this.cancellationTokenSource = axios.CancelToken.source()

        return getEstimatesForNewDiagnosticApi(newDiagnostic, token, this.cancellationTokenSource)
            .then((res) => {
                if (res.ok) {
                    this.setState({
                        // isLoading: false
                        isCalculatingTimes: false,
                    })

                    //setTimeout(this.goBack, 500);
                    let result: DiagnosticEstimateDto = res.data

                    this.setState({
                        diagnosticEstimateDto: result,
                    })

                    if (result && result.studentEstimates) {
                        if (
                            this.diagnosticCreateTableRef.current.getDiagnosticType() ===
                            DiagnosticType.Sampling
                        ) {
                            let skc = studentItems

                            result.studentEstimates.forEach((v) => {
                                let s = skc.find((x) => x.studentId === v.studentId)

                                if (s) {
                                    s.timeFormatted = v.timeFormatted
                                }
                            })

                            this.samplingSelectorRef.current.setStudentItems(skc)
                        } else if (
                            this.diagnosticCreateTableRef.current.getDiagnosticType() ===
                            DiagnosticType.KeyConcept
                        ) {
                            let skc = studentItems

                            result.studentEstimates.forEach((v) => {
                                let s = skc.find((x) => x.studentId === v.studentId)

                                if (s) {
                                    s.timeFormatted = v.timeFormatted
                                }
                            })

                            this.keyConceptSelectorRef.current.setStudentItems(skc)
                        }
                    }
                } else {
                    // ScrollToTop();
                    // this.setState({
                    //   isCalculatingTimes: false,
                    //   error: res.errors.title,
                    //   visible: true,
                    // });
                }
            })
            .catch((error) => {
                console.log(error)
                // if (error.status === 401) {
                //   return logout(this.props.authService);
                // }
                // ScrollToTop();
                this.setState({
                    isCalculatingTimes: false,
                    //  error: error.statusText,
                    isDataLoading: false,
                })
            })
    }

    getDropdownItems = async () => {
        let token = await this.props.authState.accessToken.accessToken

        //   getKeyConceptsFromApi(token)
        // this.props.sessionData.keyConcepts.forEach((res) => {
        // this.props.setIsDataLoading(false)
        // if (res.ok) {
        //  }
        // });
        // .catch((error) => {
        //     if (error.status === 401) {
        //         return logout(this.props.oktaAuth)
        //     }

        //     ScrollToTop()
        //     this.setState({
        //         error: error.statusText,
        //         isDataLoading: false,
        //     })
        // })

        getSamplingAssessmentsFromApi(token)
            .then((res) => {
                this.props.setIsDataLoading(false)
                if (res.ok) {
                    let kcs = this.props.sessionData.keyConcepts.map((item: any) => {
                        return {
                            value: item.id,
                            label: item.name,
                            order: item.order,
                        }
                    })
                    this.keyConceptSelectorRef.current.setOptionsOfMasterItems(kcs)
                    let kcs2 = this.props.sessionData.keyConcepts.map((item: any) => {
                        return {
                            value: item.id,
                            label: item.name,
                            order: item.order,
                        }
                    })
                    this.keyConceptSelectorRef.current.setOptionsOfStudentMasterItems(kcs2)

                    let items = res.data
                        .filter(
                            (v) =>
                                this.state.selectedSchoolClass.levels.includes(
                                    v.schoolClassLevel
                                ) || v.schoolClassLevel === 'All'
                        )
                        .map((item: ItemDto) => {
                            return {
                                value: item.id,
                                label: item.name,
                                order: item.order,
                            }
                        })

                    this.samplingSelectorRef.current.setOptionsOfMasterItems(items)
                    let items2 = res.data.map((item: ItemDto) => {
                        return {
                            value: item.id,
                            label: item.name,
                            order: item.order,
                        }
                    })

                    this.samplingSelectorRef.current.setOptionsOfStudentMasterItems(items2)
                }
            })
            .catch((error) => {
                if (error.status === 401) {
                    return logout(this.props.oktaAuth)
                }

                ScrollToTop()
                this.setState({
                    error: error.statusText,
                    isDataLoading: false,
                })
            })
    }

    getStudents = async (schoolClassId: string) => {
        return getStudentsFromApi(schoolClassId, this.props.authState.accessToken.accessToken)
            .then((res) => {
                this.props.setIsDataLoading(false)
                if (res.ok) {
                    let students = res.data

                    if (students.filter((v) => v.currentDiagnostic).length === students.length) {
                        this.setState({ isShowNoStudentsAvailable: true })
                    }

                    this.samplingSelectorRef.current.setStudents(students)
                    this.keyConceptSelectorRef.current.setStudents(cloneList(students))
                    let studentItems = students.map((student: StudentDto) => {
                        return {
                            studentId: student.id,
                            firstName: student.firstName,
                            lastName: student.lastName,
                            items: [],
                            currentDiagnostic: student.currentDiagnostic,
                        }
                    })

                    this.samplingSelectorRef.current.setStudentItems(studentItems)
                    this.keyConceptSelectorRef.current.setStudentItems(cloneList(studentItems))
                }
            })
            .catch((error) => {
                if (error.status === 401) {
                    return logout(this.props.oktaAuth)
                }

                ScrollToTop()
                this.setState({
                    error: error.statusText,
                    isDataLoading: false,
                })
            })
    }

    handleDiagnosticNameOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            name: e.target.value,
        })

        if (e.target.value.length > 0) {
            this.setState(() => ({
                diagnosticNameState: '',
            }))
        } else {
            this.setState(() => ({
                diagnosticNameState: 'fail',
            }))
        }
    }

    toggle = () => {
        this.setState({
            showWarningModal: !this.state.showWarningModal,
        })
    }

    toggleLoadingModal = () => {
        this.setState({
            showLoadingModal: !this.state.showLoadingModal,
        })
    }

    acceptWarningMessage = async () => {
        this.setState({
            showWarningModal: !this.state.showWarningModal,
        })

        await this.proceed()
    }

    onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        this.validateDiagnosticName()
        if (!this.state.name) {
            return
        }

        if (this.diagnosticCreateTableRef.current.getDiagnosticType() === DiagnosticType.Sampling) {
            this.samplingSelectorRef.current.validateItems()
        } else if (
            this.diagnosticCreateTableRef.current.getDiagnosticType() === DiagnosticType.KeyConcept
        ) {
            this.keyConceptSelectorRef.current.validateItems()
        }

        await this.proceed()
    }

    proceed = async () => {
        if (!this.state.selectedSchoolClass) return

        let token = await this.props.authState.accessToken.accessToken

        //  this.setIsDataLoading(true, ['Creating your assessment...']);

        this.setState({
            isDataLoading: false,
            loadingModalText: 'Creating your assessments...',
            showLoadingModal: true,
        })

        let newDiagnostic = null

        if (this.diagnosticCreateTableRef.current.getDiagnosticType() === DiagnosticType.Sampling) {
            newDiagnostic = {
                name: this.state.name,
                schoolClassId: this.state.selectedSchoolClass.id,
                studentItems: this.samplingSelectorRef.current.getStudentItems(),
                diagnosticType: this.diagnosticCreateTableRef.current.getDiagnosticType(),
            }
        } else if (
            this.diagnosticCreateTableRef.current.getDiagnosticType() === DiagnosticType.KeyConcept
        ) {
            newDiagnostic = {
                name: this.state.name,
                schoolClassId: this.state.selectedSchoolClass.id,
                studentItems: this.keyConceptSelectorRef.current.getStudentItems(),
                diagnosticType: this.diagnosticCreateTableRef.current.getDiagnosticType(),
            }
        }

        if (newDiagnostic.studentItems && newDiagnostic.studentItems.length > 0) {
            newDiagnostic.studentItems = newDiagnostic.studentItems.filter(
                (v) => v.items && v.items.length > 0 && !v.currentDiagnostic
            )
        }

        return addDiagnosticApi(newDiagnostic, token).then((res) => {
            if (res.ok) {
                this.goBack()
            } else {
                ScrollToTop()
                this.setState({
                    isDataLoading: false,
                    showLoadingModal: false,
                    error: res.error.detail,
                    visible: true,
                })
            }
        })
    }

    validateDiagnosticName = () => {
        if (this.state.name.length === 0) {
            this.setState({
                diagnosticNameState: 'fail',
            })

            ScrollToTop()
        }
    }

    goBack = () => {
        ;(this.props.history as any).goBack()
    }

    onDismiss = () => {
        this.setState({ visible: false })
    }

    render() {
        // if (this.props.isDataLoading) return null;

        return (
            <div className="pb-2">
                {this.props.sessionData.authenticatedTeacher.isDemo && (
                    <DemoPopovers items={[{ target: 'create', placement: 'top' }]} />
                )}

                <Form onSubmit={this.onSubmit}>
                    <div className="mb-2">
                        <BreadCrumbs
                            items={[
                                { title: 'Home', link: '/' },
                                { title: 'Assessments', link: '/assessment' },
                                { title: 'Create Assessment', link: '' },
                            ]}
                        />
                    </div>

                    {/* <Button onClick={() => {
            if (this.source) {
              this.source.cancel('Operation canceled by the user.');
            }
          }}>TEST</Button> */}

                    <h2>Create Assessment</h2>
                    {this.state.visible ? this.getError() : null}
                    <div className="border bg-white container pt-1 p-3">
                        <Label className="label-bold-style ">Assessment Information: </Label>
                        <div className="row pl-3">
                            <div className="col-md-3 mr-3 p-2 border text-center">
                                <div className="row">
                                    <div className="col ">
                                        <img
                                            alt="clock"
                                            className="clock"
                                            src={
                                                this.state.isCalculatingTimes
                                                    ? clockAnimated
                                                    : clock
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col small-label-bold-style">
                                        {this.getSchoolClassEstimate()}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col small-label-style">
                                        Approximate length of digital portion
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-1"></div> */}
                            <div className="col-md-3 p-2 border text-center">
                                <div className="row">
                                    <div className="col">
                                        <img src={user} alt="user" />
                                        <img src={user} alt="user" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col small-label-bold-style">
                                        {this.getNumberOfStudentInterviews()} students
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col small-label-style">
                                        will have 2-3 minute interviews
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3" />
                            <div className="col-md-3" />
                        </div>

                        <div className="row pt-2">
                            <div className="col-md-12">
                                <FormGroup>
                                    <Label className="label-bold-style" for="name">
                                        Assessment Name:{' '}
                                    </Label>

                                    <div className="row">
                                        <div className="col">
                                            <div className="input-group ">
                                                <Input
                                                    placeholder="e.g. Term 1 focus on number and algebra"
                                                    type="text"
                                                    id="name"
                                                    className="form-control"
                                                    value={this.state.name}
                                                    onChange={this.handleDiagnosticNameOnChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.diagnosticNameState === 'fail' && (
                                        <Alert color="warning">This assessment needs a name</Alert>
                                    )}
                                </FormGroup>
                            </div>
                        </div>
                    </div>

                    <div className="pt-2">
                        <Card>
                            <CardBody className="pt-0 pl-3 pr-3">
                                <DiagnosticCreateTable
                                    ref={this.diagnosticCreateTableRef}
                                    getEstimatesForNewDiagnostic={this.getEstimatesForNewDiagnostic}
                                    authState={this.props.authState}
                                    samplingSelectorRef={this.samplingSelectorRef}
                                    keyConceptSelectorRef={this.keyConceptSelectorRef}
                                    isDiagnosticTypeReadOnly={false}
                                    isShowNoStudentsAvailable={this.state.isShowNoStudentsAvailable}
                                    isCalculatingTimes={this.state.isCalculatingTimes}
                                />
                            </CardBody>
                            <CardFooter className="pb-0">
                                <div className="form-group">
                                    <div className="space-between">
                                        <Button
                                            color="secondary"
                                            tag={Link}
                                            to="/assessment"
                                            disabled={this.props.isDataLoading}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            id="create"
                                            color="primary"
                                            type="submit"
                                            //onClick= {()=>this.onSubmit()}
                                            // disabled={this.state.isLoading}
                                            disabled={
                                                this.state.isShowNoStudentsAvailable ||
                                                (this.state.selectedSchoolClass &&
                                                    this.state.selectedSchoolClass.id ===
                                                        demoClassGuid)
                                            }
                                        >
                                            {this.props.isDataLoading ? (
                                                <i className="fa fa-spinner fa-spin" />
                                            ) : null}{' '}
                                            Create
                                        </Button>
                                    </div>
                                </div>
                            </CardFooter>
                        </Card>
                    </div>

                    {/* </>
            )} */}

                    <div>
                        <Modal
                            isOpen={this.state.showWarningModal}
                            toggle={this.toggle}
                            className="warning"
                        >
                            <ModalBody>
                                Some students don&apos;t have any questions assigned, are you sure
                                you want to create this assessment?
                            </ModalBody>
                            <ModalFooter>
                                <ButtonGroup>
                                    <Button color="secondary" onClick={this.toggle}>
                                        No, I&apos;ll assign them some questions
                                    </Button>
                                    <Button color="primary" onClick={this.acceptWarningMessage}>
                                        Yes, they don&apos;t need any questions
                                    </Button>
                                </ButtonGroup>
                            </ModalFooter>
                        </Modal>
                    </div>
                    <div>
                        <Modal isOpen={this.state.showLoadingModal} size="sm" centered={true}>
                            <ModalBody>
                                <div className="d-flex justify-content-center mb-3 mt-3">
                                    <Spinner style={{ width: '3rem', height: '3rem' }} />
                                </div>
                                <p className="text-center">{this.state.loadingModalText}</p>
                            </ModalBody>
                        </Modal>
                    </div>
                </Form>
            </div>
        )
    }

    getError(): React.ReactNode {
        // debugger;
        return (
            <Alert id="uncontrolled-alert" color="warning">
                <p>{this.state.error}</p>
            </Alert>
        )
    }

    private getSchoolClassEstimate(): React.ReactNode {
        return this.state.diagnosticEstimateDto
            ? this.state.diagnosticEstimateDto.schoolClassCompletionTimeRangeFormatted
            : '... mins'
    }

    private getNumberOfStudentInterviews(): React.ReactNode {
        return this.state.diagnosticEstimateDto
            ? this.state.diagnosticEstimateDto.numberOfInterviews
            : '...'
    }
}
export default withOktaAuth(DiagnosticCreate)
