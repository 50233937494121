import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../layout/BreadCrumbs'
import FaqComponent from 'react-faq-component'
import './Faq.css'

export default function Faq() {
    const [rows, setRowsOption] = useState(null)
    //const [isOpen, setIsOpen] = useState([false, false, false, false, false, false, false]);
    const [isOpen, setIsOpen] = useState([true, true, true, true, true, true, true])

    useEffect(() => {
        // if (rows) {
        //   setTimeout(() => {
        //     rows[0].expand();
        //     rows[0].scrollIntoView();
        //     // rows[0].scrollIntoView(true);
        //   }, 1000);
        // }
    }, [rows])

    const styles = {
        bgColor: 'none',
        //  titleTextColor: 'black',
        //  titleTextSize: '34px',
        titleContentPaddingBottom: '10px',
        rowTitleColor: 'rgb(40,159,225)',
        //    rowTitleTextSize: '18px',
        //  rowContentColor: 'black',
        // rowContentTextSize: '16px',
        // rowContentPaddingTop: '10px',
        rowContentPaddingBottom: '10px',
        // rowContentPaddingLeft: '50px',
        // rowContentPaddingRight: '150px',
        // arrowColor: "red",
        //transitionDuration: "1s",
        // timingFunc: "ease"
    }

    // const tableRows =
    //   [
    //     ['Counting', 'Skip counting'],
    //     ['Counting collections and subitising', 'Number recognition'],
    //     ['Number lines', 'Numbers in words'],
    //     ['Patterns', 'Ordering and comparing'],
    //     ['Addition', 'Ordinal numbers'],
    //     ['Subtraction', 'Place value - structure'],
    //     ['Multiplication', 'Place value - renaming numbers'],
    //     ['Division', 'Place value - representing numbers using models'],
    //     ['Geometric properties ', 'Fractions'],
    //     ['', 'Decimals'],
    //     ['', 'Money'],
    //     ['', 'Length, mass & temperature'],
    //     ['', 'Area, capacity & volume'],
    //     ['', 'Time'],
    //     ['', 'Understanding Chance'],
    //     ['', 'Representing and interpreting data'],
    //   ]

    const getStartedData = {
        rows: [
            {
                title: 'How do I get started?',
                content: <div>test2</div>,
                //         content:
                //           `
                //             Setup<div class='br'></div>
                //             <div class='br'></div>
                // 	-make a class<div class='br'></div>
                // 	-add some students<div class='br'></div>
                //   <div class='br'></div>
                // Assessing your students<div class='br'></div>
                // <div class='br'></div>

                // 	-check data and choose key concept (look for data to be tested)<div class='br'></div>
                // 	-create assessment (check time estimate etc)<div class='br'></div>
                // 	-run pre assessment<div class='br'></div>
                // 	-check data<div class='br'></div>
                // 	-create groups and teach<div class='br'></div>
                // 	-run post assessment<div class='br'></div>
                // 	-repeat<div class='br'></div>

                //           `
            },
            {
                title: 'Whats the recommended way to use Early Insights?',
                content: (
                    <div>
                        content: Setup<div className="br"></div>
                        <div className="br"></div>
                        -make a class<div className="br"></div>
                        -add some students<div className="br"></div>
                        <div className="br"></div>
                        Assessing your students
                        <div className="br"></div>
                        -check data and choose key concept (look for data to be tested)
                        <div className="br"></div>
                        -create assessment (check time estimate etc)<div className="br"></div>
                        -run pre assessment<div className="br"></div>
                        -check data<div className="br"></div>
                        -create groups and teach<div className="br"></div>
                        -run post assessment<div className="br"></div>
                        -repeat<div className="br"></div>
                    </div>
                ),
            },
        ],
    }

    const adminPortalData = {
        // title: "Early-Insights - Frequenty Asked Questions",
        rows: [
            {
                title: 'Who has access to my schools Admin Portal?',
                content: (
                    <div>
                        When your school started with Early Insights, the main contact was made an
                        Admin user for your school. This person can grant other teachers access to
                        their schools Admin portal by clicking ‘edit’ on the Teachers page.
                    </div>
                ),
            },
            {
                title: 'How do I add a new teacher?',
                content: (
                    <div>
                        New teachers can only be added by teachers with access to the Admin Portal.
                        <div className="br"></div>
                        After selecting the Admin tab in the top bar, navigate to the Teachers page
                        by clicking ‘Manage’.<div className="br"></div>
                        To create a new teacher account, you will need to provide their name and
                        email address. Teachers can be added individually or in bulk. This is also
                        where you can grant selected teachers access to the Admin Portal by ticking
                        ‘Give Admin access’. You can edit all these details, except email, later by
                        clicking the ‘edit’ button beside the individual teacher.
                        <div className="br"></div>
                        Sometimes a teachers activation email can get stuck in their email’s trash
                        folder, however, if it gets lost or expires, please contact our support team
                        (<a href="mailto:primary@mathspathway.com">primary@mathspathway.com</a>),
                        and they’ll resend it.
                    </div>
                ),
            },
            {
                title: 'How do I create a new class?',
                content: (
                    <div>
                        New classes can only be added by teachers with access to the Admin Portal.{' '}
                        <div className="br"></div>
                        After selecting the Admin tab in the top bar, navigate to the Classes page
                        by clicking ‘Manage’. <div className="br"></div>
                        You can create classes individually using the ‘Add class’ button or in bulk
                        by clicking ‘Add Classes in Bulk’. Both options require you to provide a
                        class name and the year level of that class.<div className="br"></div>A
                        class can have up to two year levels added, to accommodate multi-age
                        classes. <div className="br"></div>
                        You can also assign teachers to the class here or do it later by clicking
                        ‘edit’. Each class can have multiple teachers assigned.{' '}
                        <div className="br"></div>
                    </div>
                ),
            },
            {
                title: 'Can I assign more than one teacher to the same class?',
                content: (
                    <div>
                        Yes. Classes can have as many teachers assigned to them as you need. This is
                        great for team teaching, to give support staff access to multiple classes or
                        for principals/administrators access to their student data.
                    </div>
                ),
            },
            {
                title: 'I’ve just signed up, can I add all my students at once?',
                content: (
                    <div>
                        Yes. <div className="br"></div>
                        Teachers with access to the Admin Portal can add multiple classes of
                        students at once. <div className="br"></div>
                        (Make sure you have already created your classes before trying to add your
                        students. )<div className="br"></div>
                        When in the Admin Portal, navigate to the Classes page and click ‘Add
                        Students in Bulk’. Here you can add students from multiple classes using one
                        spreadsheet. Appropriate passwords for the students’ age will be
                        automatically generated if you choose to leave that column blank. Class
                        names need to match exactly for the upload to work.{' '}
                        <div className="br"></div>
                    </div>
                ),
            },
            {
                title: 'How do I add students?',
                content: (
                    <div>
                        Once your classes have been created, you can add students in 3 different
                        ways depending on your access level and how many students you would like to
                        add.<div className="br"></div>
                        All methods of adding students require you to provide the students first
                        name, last name and year level. You will also need to add a preferred name.
                        Preferred names are used to generate the student’s username automatically.
                        <div className="br"></div>
                        <div className="faq-content-inner-heading">
                            I want to add multiple classes at once.
                        </div>
                        Teachers with access to the Admin Portal can add multiple classes of
                        students at once.<div className="br"></div>
                        (Make sure you have already created your classes before trying to add your
                        students. )<div className="br"></div>
                        When in the Admin Portal, navigate to the Classes page and click ‘Add
                        Students in Bulk’. Here you can add students from multiple classes using one
                        spreadsheet. Appropriate passwords for the students’ age will be
                        automatically generated if you choose to leave that column blank. Class
                        names need to match exactly for the upload to work.
                        <div className="br"></div>
                        <div className="faq-content-inner-heading">
                            I want to add just one class/student.
                        </div>
                        Through Admin Portal - When in the Admin Portal, navigate to the ‘Classes’
                        page and click ‘Manage students’ beside the class you want to add students
                        to. Here you can add students individually by clicking on ‘Add student’ or
                        upload the whole class at once by clicking the ‘Add students in bulk’
                        button.<div className="br"></div>
                        Through Students page - Click on the Students tab in the top navigation bar.
                        Here you can add students individually by clicking on ‘Add student’ or
                        upload the whole class at once by clicking the ‘Add students in bulk’
                        button.<div className="br"></div>
                    </div>
                ),
            },
            {
                title: 'My school has run out of licences, how can I purchase more?',
                content: (
                    <div>
                        By navigating to the Licence page in the Admin Portal, you will be able to
                        view important information about your school’s Early Insights account,
                        including:
                        <ul className="pt-2">
                            <li>
                                the number of Early Insights student licences your school has
                                purchased,
                            </li>
                            <li>the number of active students; and</li>
                            <li>
                                any licences that have been purchased but are yet to be assigned to
                                a student.
                            </li>
                        </ul>
                        If new students arrive and you need more accounts, click the ‘Purchase more
                        student licences’ button. This allows you to purchase additional licences
                        with a Credit card or purchase order (we’ll send you an invoice). These new
                        licences can be assigned to students straightaway.
                    </div>
                ),
            },
        ],
    }

    const teacherPortalData = {
        rows: [
            {
                title: 'Can I change my students’ passwords?',
                content: (
                    <div>
                        Yes. Passwords are automatically set to 3 or 4 letter words that students
                        will most commonly be able to spell. However, you can change these by
                        clicking on the ‘edit’ button beside a students name on the Students page.
                        There is no personal information about the student stored on the Student
                        app, these passwords are to ensure the student completes the correct
                        assessment that has been assigned to them.
                    </div>
                ),
            },
        ],
    }

    const settingAnAssessmentData = {
        rows: [
            {
                title: 'What’s the difference between Deep assessments and Broad assessments?',
                content: (
                    <div>
                        There are two different types of assessments you can run using Early
                        Insights – deep assessments and broad assessments. Maths Pathway knows that
                        teachers and school administrators need different types of data to suit
                        their many needs. These two types of assessments have been designed to serve
                        different purposes, so they collect different types of data. One collects
                        detailed and specific data, while the other gives more broad and overarching
                        information.<div className="br"></div>
                        <div className="faq-content-inner-heading">Deep Assessment</div>
                        Deep assessments allow you to target specific Key Concepts to get granular
                        data about what your students already know and are ready to learn next. This
                        type of assessment is great as a pre-test as the detailed and specific data
                        allows you to create lessons tailored to the specific needs of your
                        students. It can also be used as a post-test to measure growth.
                        <div className="br"></div> (Make sure you have already created your classes
                        before trying to add your students. )<div className="br"></div>
                        When in the Admin Portal, navigate to the Classes page and click ‘Add
                        Students in Bulk’. Here you can add students from multiple classes using one
                        spreadsheet. Appropriate passwords for the students’ age will be
                        automatically generated if you choose to leave that column blank. Class
                        names need to match exactly for the upload to work.
                        <div className="br"></div>
                        <div className="faq-content-inner-heading">Broad Assessments</div>
                        Broad assessments are best used when first starting with Early Insights.
                        These assessments are designed to give teachers a snapshot of a students
                        achievement within a particular curriculum strand. Each broad assessment
                        includes a pre-selected group of statements from one strand of the
                        curriculum. They include content from the year level of the student and one
                        level below; however, you can individualise assessments by selecting a broad
                        assessment from another year level for specific students. These assessments
                        are great for getting a rough idea about how one student is achieving, but
                        the data is most powerful when looking at class and cohort reports.
                    </div>
                ),
            },
            {
                title: 'How do I set a Deep assessment?',
                content: (
                    <div>
                        Once on the ‘Assessment’ page, select ‘Start a New Assessment’ to begin
                        collecting data. <div className="br"></div>
                        <ol>
                            <li> Give your assessment a name. E.g Term 2, Addition Pre-test</li>
                            <li> Select Deep Assessment </li>
                            <li>
                                {' '}
                                Use the drop-down menu to choose which Key Concepts you would like
                                to assess all your students on. We recommend you don’t set any more
                                than 2-3 Key Concepts, to ensure the assessment doesn’t take too
                                long.{' '}
                            </li>
                            <li>
                                {' '}
                                Click the ‘Get estimated time’ button. This will take everything we
                                already know about your students and calculate how long it will take
                                them to complete this specific assessment.{' '}
                            </li>
                            <li>
                                {' '}
                                Customise individual assessments by adding or removing key concepts
                                from particular students.{' '}
                            </li>
                            <li>
                                {' '}
                                Don’t forget to get an updated time estimation by clicking the ‘Get
                                Estimated Time’ button again.
                            </li>
                        </ol>
                    </div>
                ),
            },
            {
                title: 'How do I set a Broad assessment?',
                content: (
                    <div>
                        Once on the ‘Assessment’ page, select ‘Start a New Assessment’ to begin
                        collecting data.
                        <ol>
                            <li>Give your assessment a name. E.g Term 2, Addition Pre-test </li>
                            <li> Select Broad Assessment </li>
                            <li>
                                Use the drop-down menu to choose which Curriculum strands you would
                                like to assign to all your students. The list will include the Broad
                                assessments for the Classes year level.
                            </li>
                            <li>
                                Customise individual assessments by using the drop-down box beside
                                an individual students name to assign them a Broad assessment from a
                                different year level.{' '}
                            </li>
                            <li>
                                {' '}
                                Don’t forget to get an updated time estimation by clicking the ‘Get
                                Estimated Time’ button again.
                            </li>
                        </ol>
                    </div>
                ),
            },
        ],
    }

    const interviewsData = {
        rows: [
            {
                title: 'Why don’t all assessments have interview questions?',
                content: (
                    <div>
                        Your time is valuable so we only use interview questions when teacher
                        judgement is required to make an accurate assessment of a students
                        knowledge/skills.
                    </div>
                ),
            },
            {
                title: 'Which Key Concepts have interview questions?',
                content: (
                    <div>
                        Here is a list of which key concepts have interview questions and which do
                        not.
                        <div className="faq-content-inner-heading">
                            Key Concepts with interview questions
                        </div>
                        Counting <div className="br"></div>
                        Counting collections and subitising <div className="br"></div>
                        Number lines <div className="br"></div>
                        Patterns <div className="br"></div>
                        Addition <div className="br"></div>
                        Subtraction <div className="br"></div>
                        Multiplication <div className="br"></div>
                        Division <div className="br"></div>
                        Geometric properties <div className="br"></div>
                        <div className="faq-content-inner-heading">
                            Key Concepts without interview questions
                        </div>
                        Skip counting <div className="br"></div>
                        Number recognition <div className="br"></div>
                        Numbers in words <div className="br"></div>
                        Ordering and comparing <div className="br"></div>
                        Ordinal numbers <div className="br"></div>
                        Place value -structure <div className="br"></div>
                        Place value - renaming numbers <div className="br"></div>
                        Place value - representing numbers using models <div className="br"></div>
                        Fractions <div className="br"></div>
                        Decimals <div className="br"></div>
                        Money <div className="br"></div>
                        Length, mass & temperature <div className="br"></div>
                    </div>
                ),
            },
            {
                title: 'What equipment do I need for the interviews?',
                content: (
                    <div>
                        This is all the equipment you will need for the interviews.
                        <div className="br"></div>
                        <ul>
                            <li>
                                30 objects (E.g counters, cubes, paddle pop sticks, teddy bears etc){' '}
                            </li>
                            <li>Pencil and paper (or whiteboard and pen)</li>
                        </ul>
                    </div>
                ),
            },
        ],
    }

    const studentAnswersData = {
        rows: [
            {
                title: 'Can I see my students answers to the digital questions?',
                content: (
                    <div>
                        When you’re on the Assessment page, click on the name of the assessment you
                        would like to view the student answers for. Clicking the ‘View digital
                        answers’ button beside a students name will show you their answers. You can
                        even listen to any voice questions.
                    </div>
                ),
            },
            {
                title: 'Can I change a student’s answer?',
                content: <div></div>,
            },
        ],
    }

    const studentAppData = {
        rows: [
            {
                title: '',
                content: <div></div>,
            },
        ],
    }

    return (
        <div>
            <div className="mb-2">
                <BreadCrumbs
                    items={[
                        { title: 'Home', link: '/' },
                        { title: 'FAQ', link: '/FAQ' },
                    ]}
                />
            </div>
            <h2 className="pb-3">Frequently Asked Questions</h2>
            <div>
                <div></div>
            </div>

            {[
                { title: 'Getting Started', data: getStartedData },
                { title: 'Admin Portal', data: adminPortalData },
                { title: 'Teacher Portal', data: teacherPortalData },
                { title: 'Setting an Assessment', data: settingAnAssessmentData },
                { title: 'Interviews', data: interviewsData },
                { title: 'Student Answers', data: studentAnswersData },
                { title: 'Student App', data: studentAppData },
            ].map((v, index) => {
                return (
                    <div key={index} className="pb-2">
                        <button
                            className="btn"
                            style={{ border: '0px', padding: '0px', outline: 'none' }}
                            color="link"
                            onClick={() => {
                                let newArr = [...isOpen] // copying the old datas array

                                newArr[index] = !isOpen[index] // replace e.target.value with whatever you want to change it to
                                setIsOpen(newArr)
                            }}
                        >
                            <h3 className="faq-subheading">{v.title}</h3>
                        </button>
                        {/* <Collapse isOpen={isOpen[index]}> */}
                        {/* <Collapse> */}
                        <FaqComponent data={v.data} styles={styles} getRowOptions={setRowsOption} />
                        {/* </Collapse> */}
                    </div>
                )
            })}

            {/*
      <div>
        <Button style={{ border: '0px', padding: '0px' }} color="link" onClick={() => setIsOpenAdmminPortal(!isOpen)} >  <h3 className='faq-subheading'>Admin Portal</h3></Button>
        <Collapse isOpen={isOpenAdminPortal}>
          <FaqComponent
            data={adminPortalData}
            styles={styles}
            getRowOptions={setRowsOption}
          />
        </Collapse>
      </div>

      <div>
        <Button style={{ border: '0px', padding: '0px' }} color="link" onClick={() => setIsOpen(!isOpenTeacherPortal)} >  <h3 className='faq-subheading'>Teacher Portal</h3></Button>
        <Collapse isOpen={isOpenTeacherPortal}>
          <FaqComponent
            data={teacherPortalData}
            styles={styles}
            getRowOptions={setRowsOption}
          />
        </Collapse>
      </div>

      <div>
        <Button style={{ border: '0px', padding: '0px' }} color="link" onClick={() => setIsOpen(!isOpen)} >  <h3 className='faq-subheading'>Getting Started</h3></Button>
        <Collapse isOpen={isOpen}>
          <FaqComponent
            data={getStartedData}
            styles={styles}
            getRowOptions={setRowsOption}
          />
        </Collapse>
      </div>

      <div>
        <Button style={{ border: '0px', padding: '0px' }} color="link" onClick={() => setIsOpen(!isOpen)} >  <h3 className='faq-subheading'>Getting Started</h3></Button>
        <Collapse isOpen={isOpen}>
          <FaqComponent
            data={getStartedData}
            styles={styles}
            getRowOptions={setRowsOption}
          />
        </Collapse>
      </div>

      <div>
        <Button style={{ border: '0px', padding: '0px' }} color="link" onClick={() => setIsOpen(!isOpen)} >  <h3 className='faq-subheading'>Getting Started</h3></Button>
        <Collapse isOpen={isOpen}>
          <FaqComponent
            data={getStartedData}
            styles={styles}
            getRowOptions={setRowsOption}
          />
        </Collapse>
      </div>


      <div className='pt-3'>
        <h3 className='faq-subheading'>Setting an Assessment</h3>
        <FaqComponent
          data={settingAnAssessmentData}
          styles={styles}
          getRowOptions={setRowsOption}
        />
      </div>

      <div className='pt-3'>
        <h3 className='faq-subheading'>Interviews</h3>
        <FaqComponent
          data={interviewsData}
          styles={styles}
          getRowOptions={setRowsOption}
        />
      </div>

      <div className='pt-3'>
        <h3 className='faq-subheading'>Students Answers</h3>
        <FaqComponent
          data={studentAnswersData}
          styles={styles}
          getRowOptions={setRowsOption}
        />
      </div>

      <div className='pt-3'>
        <h3 className='faq-subheading'>Students App</h3>
        <FaqComponent
          data={studentAppData}
          styles={styles}
          getRowOptions={setRowsOption}
        />
      </div> */}

            {/* <div className='pt-3'>
        <h3 className='faq-subheading'>Other</h3>
        <FaqComponent
          data={data}
          styles={styles}
          getRowOptions={setRowsOption}
        />
      </div> */}
        </div>
    )
}

// const data = {
//   // title: "Early-Insights - Frequenty Asked Questions",
//   rows: [
//     {
//       title: "How do I get started?",
//       content:
//         `
//       <div>
//       Setup
//       <ul>
//         <li>make a class</li>
//         <li>add some students</li>
//       </ul>
//       Assessing your students
//       <ul>
//         <li>check data and choose key concept (look for data to be tested)</li>
//         <li>create assessment (check time estimate etc)</li>
//         <li>run pre assessment</li>
//         <li>check data</li>
//         <li>create groups and teach</li>
//         <li>run post assessment</li>
//         <li>repeat</li>
//       </ul>
//       </div>
//       `
//     },

//     {
//       title: "Why did I get asked an easy question after a difficult one?",
//       content: "Lorem ipsum dolor sit amet, consectetur "
//     },
//     {
//       title: "How does the adapative question selection work?",
//       content: "Lorem ipsum dolor sit amet, consectetur "
//     },
//     {
//       title: "Why does it feel like I am getting asked the same question?",
//       content: "Nunc maximus, magna at ultricies elementum, risus turpis vulputate quam."
//     },
//     {
//       title: "Why am I getting asked a foundation question when I'm in grade 4?",
//       content: "Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc"
//     },
//     {
//       title: "When I talk on the audio questions nothing appears.",
//       content: "Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc"
//     },
//     {
//       title: "What is the difference between broad and deep assessments, and what should I use first?",
//       content: "Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc"
//     }
//     ,
//     {
//       title: "The wrong word appears when I talk on the audio questions.",
//       content: "Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc"
//     },
//     {
//       title: "What is the reason deep assessments take so long.",
//       content: "Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc"
//     },
//     {
//       title: "what assessment should I start with (i.e. broad or deep).",
//       content: "Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc"
//     },
//     {
//       title: "How often should I run an assessment?",
//       content: "Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc"
//     }
//     ,
//     {
//       title: "Our school doesn't have headsets for students. How can I best ensure their microphones work properly?",
//       content: "<div><strong> TEST</strong> </div>"
//     }
//     ,
//     {
//       title: "What happens when I stop an assessment",
//       content: "<div><strong> TEST</strong> </div>"
//     }
//     ,
//     {
//       title: "I dont have time for inteview quesitons what should I do?",
//       content: "<div><strong> TEST</strong> </div>"
//     }
//     ,
//     {
//       title: "Why do i need to do interview questions first?",
//       content: (<div>TEST</div>)
//     }
//     ,
//     {
//       title: "sequence overrides?",
//       content: (<div>TEST</div>)
//     }
//   ],
// }
