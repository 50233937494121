/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import logo from '../../assets/images/EarlyInsights_CMYK.svg'

interface IParams {
    orderType: string
}

interface IProps extends RouteComponentProps<IParams> {
    inPortal: boolean
    setIsDataLoading: any
    match: any
    history: any
}

export enum OrderType {
    newCustomerAndPayingWithCC = 0,
    newCustomerAndPayingWithPO = 1,
    newCustomerAndSchoolDoesNotExistAndPayingWithCC = 2,
    newCustomerAndSchoolDoesNotExistAndPayingWithPO = 3,
    existingTeacherAndExistingCustomerAndPayingWithCC = 4,
    existingTeacherAndExistingCustomerAndPayingWithPO = 5,
    newTeacherAndExistingCustomerAndPayingWithPO = 6,
    newTeacherAndExistingCustomerAndPayingWithCC = 7,
    demoPurchaseAndPayingWithPO = 8,
    demoPurchaseAndPayingWithCC = 9,
    demoPurchaseAndSchoolDoesNotExistAndPayingWithCC = 10,
    demoPurchaseAndSchoolDoesNotExistAndPayingWithPO = 11,
    additionalLicencesPurchasedAndPayingWithPO = 12,
    additionalLicencesPurchasedAndPayingWithCC = 13,
}

const message1 = (
    <>
        You will receive an email with your account activation details in the next two hours.
        Sometimes these emails can get stuck in your junk folder, but if it doesn&apos;t arrive
        please email us at <a href="mailto:primary@mathspathway.com">primary@mathspathway.com</a>.
        You will receive your tax receipt in a separate email shortly.
    </>
)

const message2 = (
    <>
        An invoice will be emailed to you within three business days. You will receive an email with
        your account activation details within 24hrs of completing this order.
    </>
)

const message3 = (
    <>
        These new licences are available immediately. You can add new students using the Admin
        Portal or the class teacher can create them in the Teacher Portal. Your tax receipt will be
        emailed to you shortly.
    </>
)

const message4 = (
    <>
        These new licences are available immediately. You can add new students using the Admin
        Portal or the class teacher can create them in the Teacher Portal. An invoice will be
        emailed to you within 3 business days.
    </>
)

const message5 = (
    <>
        You will receive an email with your account activation details in the next two hours.
        Sometimes these emails can get stuck in your trash folder, but if it doesn&apos;t arrive
        please email us at <a href="mailto:primary@mathspathway.com">primary@mathspathway.com</a>.
        An invoice will be emailed to you within 3 business days.
    </>
)

//   const message6 =
//   (
//     <>
// These new licences are available immediately. Someone with Admin access at your school can add new students using the Admin Portal or the class teacher can create them in the Teacher Portal.
// You will receive an email with your account activation details in the next two hours. Sometimes these emails can get stuck in your trash folder,
// but if it doesn't arrive please email us at <a href="mailto:primary@mathspathway.com">primary@mathspathway.com</a>.
// An invoice will be emailed to you within 3 business days.
// </>
//   );

//   const message7 =
//   (
//     <>
// These new licences are available immediately. Someone with Admin access at your school can add new students using the Admin Portal or the class teacher can create them in the Teacher Portal.
// You will receive an email with your account activation details in the next two hours. Sometimes these emails can get stuck in your trash folder,
// but if it doesn't arrive please email us at <a href="mailto:primary@mathspathway.com">primary@mathspathway.com</a>.
// Your tax receipt will be emailed to you shortly.

// </>
//   );

const message8 = (
    <>
        Your licences have been purchased.
        <br></br>
        <br></br>
        You can logon here{' '}
        <a href="https://portal.mathspathway.com">https://portal.mathspathway.com</a>
        <br></br>
        If you need help please email{' '}
        <a href="mailto:primary@mathspathway.com">primary@mathspathway.com</a>
    </>
)

// const message9 =
//  (
//   <>
//   An invoice will be emailed to you within three business days. You will receive an email with your licence purchase within 24hrs of completing this order.
//   </>
// );

const message10 = (
    <>
        These licences have been added to your school&apos;s account and any other teachers with
        trial accounts now have full access. To begin adding more teachers and setting up your
        classes, click on the Admin tab in the top navigation bar. Simple instructions can be found
        in this{' '}
        <a href="https://tridentazurestorage.blob.core.windows.net/files/Admin%20Portal%20-%20Getting%20started.pdf">
            <i>Quick guide to getting started</i>
        </a>
        .<br></br>
        <br></br>
        You can logon here{' '}
        <a href="https://portal.mathspathway.com">https://portal.mathspathway.com</a>
        <br></br>
        If you need help please email{' '}
        <a href="mailto:primary@mathspathway.com">primary@mathspathway.com</a>
    </>
)

const message11 = (
    <>
        An invoice will be emailed to you within three business days. You will receive a
        confirmation email once payment is received and your account will have changed from trial to
        full access.
    </>
)

export default function OrderComplete(props: IProps) {
    useEffect(() => {

        if (!props.inPortal) {
            try {
                document.domain = 'mathspathway.com'
                window.frameElement.setAttribute('data-form-complete', 'yes')
            } catch (error) {
                //  console.log(error);
            }

            document.querySelector('html').style.backgroundColor = 'white'
            document.getElementById('root').style.backgroundColor = 'white'
        }

        // reload teacher session if inportal to reflect not a demo anymore
        if (props.inPortal) {

            // props.reloadNavBarClass()
            //   // RELOAD LAYOUT
            // window.location.reload()
        }

        props.setIsDataLoading && props.setIsDataLoading(false)

    }, [])

    const getCustomMessage = () => {
        switch (parseInt(props.match.params.orderType)) {
            case OrderType.newCustomerAndPayingWithCC: {
                return message1
            }
            case OrderType.newCustomerAndPayingWithPO: {
                return message5
            }
            case OrderType.newCustomerAndSchoolDoesNotExistAndPayingWithCC: {
                return message1
            }
            case OrderType.newCustomerAndSchoolDoesNotExistAndPayingWithPO: {
                return message2
            }
            case OrderType.existingTeacherAndExistingCustomerAndPayingWithCC: {
                return message3
            }
            case OrderType.existingTeacherAndExistingCustomerAndPayingWithPO: {
                return message4
            }
            case OrderType.demoPurchaseAndPayingWithCC: {
                return message10
            }
            case OrderType.demoPurchaseAndPayingWithPO: {
                return message10
            }
            case OrderType.demoPurchaseAndSchoolDoesNotExistAndPayingWithCC: {
                return message10
            }
            case OrderType.demoPurchaseAndSchoolDoesNotExistAndPayingWithPO: {
                return message11
            }
            case OrderType.additionalLicencesPurchasedAndPayingWithCC: {
                return message8
            }
            case OrderType.additionalLicencesPurchasedAndPayingWithPO: {
                return message8
            }
            default:
                return message8
        }
    }

    return (
        <div data-cy="order-complete-page-loaded">
            <div className="container ">
                <div className="py-3 text-center ">
                    <img
                        className="mx-auto d-block logo "
                        alt="logo"
                        style={{ width: '100%' }}
                        src={logo}
                    ></img>
                </div>
                <div className="py-3 text-center ">
                    <h2>Order Complete</h2>
                    <div>
                        <p>
                            <br></br>
                            Thank you for completing your Early Insights order!
                            <br></br>
                            <br></br>
                            <>{getCustomMessage()}</>
                        </p>
                    </div>
                </div>
                <div className=" text-center "></div>
            </div>
        </div>
    )
}
