import { KeyConceptDto } from './../models/KeyConcept'
import { SamplingAssessmentDto } from './../models/SamplingAssessment'
import { SchoolClassDto } from '../models/SchoolClass'
import { TeacherDto } from '../models/Teacher'
import { SchoolDto } from '../models/School'

export const getStudentIndividualReportViewingModeFromLocalStorage = () => {
    let result = localStorage.getItem('studentIndividualReportViewingType')

    if (result) return JSON.parse(result)
}

export const setStudentIndividualReportViewingModeToLocalStorage = (data: any) => {
    localStorage.setItem('studentIndividualReportViewingType', JSON.stringify(data))
}

export const getSamplingAsssesmentsFromLocalStorage = () => {
    let samplingAsssesments = localStorage.getItem('samplingAsssesments')

    if (samplingAsssesments) return JSON.parse(samplingAsssesments)
}

export const getKeyConceptsFromLocalStorage = () => {
    let keyConcepts = localStorage.getItem('keyConcepts')

    if (keyConcepts) return JSON.parse(keyConcepts)
}

export const getStatementsFromLocalStorage = () => {
    let statements = localStorage.getItem('statements')

    if (statements) return JSON.parse(statements)
}

export const setStatementsToLocalStorage = (data: KeyConceptDto[]) => {
    localStorage.setItem('statements', JSON.stringify(data))
}

export const setSamplingAsssesmentsToLocalStorage = (data: SamplingAssessmentDto[]) => {
    localStorage.setItem('samplingAsssesments', JSON.stringify(data))
}

export const setKeyConceptsToLocalStorage = (data: KeyConceptDto[]) => {
    localStorage.setItem('keyConcepts', JSON.stringify(data))
}

// export const setSchoolClassesToLocalStorage = (data: SchoolClassDto[]) => {
//   localStorage.setItem('schoolClasses', JSON.stringify(data))
// }

// export const setSchoolsToLocalStorage = (data: SchoolDto[]) => {
//   localStorage.setItem('schools', JSON.stringify(data))
// }

// export const getSchoolsFromLocalStorage = () => {
//   let schools = localStorage.getItem('schools')

//   if (schools) return JSON.parse(schools)
// }

export const getSelectedSchoolFromLocalStorage = () => {
    let selectedSchool = localStorage.getItem('selectedSchool')
    if (selectedSchool) return JSON.parse(selectedSchool)
    else return null
}

export const setSelectedSchoolToLocalStorage = (school) => {
    localStorage.setItem('selectedSchool', JSON.stringify(school))
}

export const getSelectedSchoolClassFromLocalStorage = () => {
    let selectedSchoolClass = localStorage.getItem('selectedSchoolClass')
    if (selectedSchoolClass) return JSON.parse(selectedSchoolClass)
    else return null
}

export const setSelectedSchoolClassToLocalStorage = (shoolClass: SchoolClassDto) => {
    localStorage.setItem('selectedSchoolClass', JSON.stringify(shoolClass))
}

export const removeSelectedSchoolClassFromLocalStorage = () => {
    localStorage.removeItem('selectedSchoolClass')
}

export const removeSelectedSchoolFromLocalStorage = () => {
    localStorage.removeItem('selectedSchool')
}

// export const removeSchoolClassesFromLocalStorage = () => {
//   localStorage.removeItem('schoolClasses')
// }

// export const removeTeacherFromLocalStorage = () => {
//   localStorage.removeItem('teacher')
// }

// export const setTeacherToLocalStorage = (data: TeacherDto) => {
//   localStorage.setItem('teacher', JSON.stringify(data))
// }

// export const getTeacherFromLocalStorage = (): TeacherDto => {
//   let teacher = localStorage.getItem('teacher')

//   if (teacher) return JSON.parse(teacher)
// }

export const removeAllLocalStorage = () => {
    localStorage.clear()
}

export const removeTempLocalStorage = () => {
    // localStorage.removeItem("teacher");
    // localStorage.removeItem("schoolClasses");
    // localStorage.removeItem("selectedSchoolClasses");
    //  localStorage.removeItem("schools");
    //localStorage.removeItem("teacher");
    localStorage.removeItem('statements')
    localStorage.removeItem('keyConcepts')
    localStorage.removeItem('samplingAsssesments')
}
