import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { withOktaAuth } from '@okta/okta-react'
import { Button, Alert } from 'reactstrap'
import { AuthService, AuthState } from '../../../models/Auth'
import { addBulkTeachers } from '../../../services/TeacherApi'

import BaseClass from '../../Bases/BaseClass'
import BreadCrumbs from '../../layout/BreadCrumbs'
import { IBaseState } from '../../Bases/BaseState'
import { ISessionData } from '../../../AppRouter'

interface IParams {
    //  schoolId: string;
}

interface IProps extends RouteComponentProps<IParams> {
    authService: AuthService
    authState: AuthState
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
    history: any
    match: any
}

interface IState extends IBaseState {
    selectedFile: any
    selectedFileName: string
    isDataLoading: boolean
}

class TeacherCreateBulk extends BaseClass<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            isLoading: false,
            error: '',
            visible: false,
            selectedFile: null,
            selectedFileName: 'filename...',
            isDataLoading: false,
        }
    }

    componentDidMount() {
        this.loadData()
    }

    async loadData() {
        this.props.setIsDataLoading(true)
        // await super.getAuthenticatedTeacher(
        //   this.props.authState.accessToken.accessToken
        // );
        this.props.setIsDataLoading(false)
        this.props.setIsDataLoading(false)
    }

    handleFileSelect = (e: any) => {
        console.log(e.target.files[0])
        this.setState({
            selectedFile: e.target.files[0],
            selectedFileName: e.target.files[0].name,
        })
    }

    onSubmit = async (e: any) => {
        this.props.setIsDataLoading(true, ['Please wait while your teachers are created...'])
        e.preventDefault()
        const formData = new FormData()

        formData.append('file', this.state.selectedFile)
        let token = this.props.authState.accessToken.accessToken

        return addBulkTeachers(this.props.sessionData.selectedSchool.id, formData, token)
            .then((res) => {
                this.props.setIsDataLoading(false)
                if (res.ok) {
                    this.goBack()
                } else {
                    this.handleknownError(res.error)
                }
            })
            .catch((error) => {
                this.handleUnknownError(error)
            })
    }

    goBack = () => {
        ;(this.props.history as any).goBack()
    }

    render() {
        //  // if (this.props.isDataLoading) return null;

        //  return (this.state.isLoading || this.props.authState.isPending || !this.context.navMenuDataLoaded) ? (
        return (
            <>
                <div>
                    <div className="mb-2">
                        <BreadCrumbs
                            items={[
                                { title: 'Home', link: '/' },
                                {
                                    title: 'Admin',
                                    link: `/admin/select/${this.props.sessionData.selectedSchool.id}`,
                                },
                                {
                                    title: 'Teacher',
                                    link: `/admin/teacher/${this.props.sessionData.selectedSchool.id}`,
                                },
                                { title: 'Bulk Upload Teachers', link: '' },
                            ]}
                        />
                    </div>
                    <div className="mb-3">
                        <h2 className="pb-2">Bulk Upload Teachers</h2>
                        <p className="font-weight-light">
                            Create accounts for multiple teachers here. Their login details will be
                            emailed to them automatically.
                            <br></br>
                            <br></br>
                            1. Download the sample spreadsheet below.
                            <br></br>
                            2. Enter your teacher&apos;s details. Giving a teacher Admin access
                            allows them to create new teachers, classes, and purchase additional
                            licences.
                            <br></br>
                            3. Save the spreadsheet to your computer.
                            <br></br>
                            4. Use the &apos;Browse&apos; button to upload the spreadsheet.
                            <br></br>
                            <br></br>
                        </p>
                    </div>
                    {this.state.visible ? (
                        <Alert color="warning">
                            <p>{this.state.error}</p>
                        </Alert>
                    ) : null}

                    <div className="row">
                        <div className="col-md-7">
                            <form onSubmit={this.onSubmit}>
                                {/*
                  <a
                    rel="noopener noreferrer"
                    href="https://tridentazurestorage.blob.core.windows.net/files/StudentImport.xlsx"
                    target="_blank"
                  >
                    Download Sample
          </a> */}
                                <div className="mb-3">
                                    <Button
                                        color="success"
                                        rel="noopener noreferrer"
                                        href="https://tridentazurestorage.blob.core.windows.net/files/TeacherImport.xlsx"
                                        target="_blank"
                                        disabled={this.state.isLoading}
                                    >
                                        {this.state.isLoading ? (
                                            <i className="fa fa-spinner fa-spin" />
                                        ) : null}{' '}
                                        Download Sample
                                    </Button>
                                    {/* <input
                    type="file"
                    className="form-control-file"
                    id="exampleFormControlFile1"
                    onChange={this.handleFileSelect}
                  /> */}
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span
                                            className="input-group-text"
                                            id="inputGroupFileAddon01"
                                        >
                                            Select excel file
                                        </span>
                                    </div>
                                    <div className="custom-file">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            id="exampleFormControlFile1"
                                            aria-describedby="inputGroupFileAddon01"
                                            onChange={this.handleFileSelect}
                                            // value={this.state.selectedFile}
                                        />
                                        <label
                                            className="custom-file-label"
                                            htmlFor="exampleFormControlFile1"
                                        >
                                            {this.state.selectedFileName}
                                        </label>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="space-between">
                                        <Button
                                            color="secondary"
                                            //tag={Link}
                                            //to="/student"
                                            //onClick={goBack}
                                            onClick={this.goBack}
                                            disabled={this.state.isLoading}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color="primary"
                                            type="submit"
                                            disabled={
                                                this.state.isLoading ||
                                                this.state.selectedFile === null
                                            }
                                        >
                                            {this.state.isLoading ? (
                                                <i className="fa fa-spinner fa-spin" />
                                            ) : null}{' '}
                                            Upload teachers and send emails
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div />
                </div>
            </>
        )
    }
}

export default withOktaAuth(TeacherCreateBulk)
