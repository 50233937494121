import React, { useEffect, useState } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { LicenceDto, SummaryDto } from '../../../models/Order'
import { Alert, Button, Table } from 'reactstrap'
import { GetOrders, GetLicenceSummary } from '../../../services/OrderApi'
import { convertUtcToLocalDate } from '../../../shared/Helper'
import BreadCrumbs from '../../layout/BreadCrumbs'
import { ISessionData } from '../../../AppRouter'
import { useOktaAuth } from '@okta/okta-react'

interface IParams {
    schoolId: string
}

interface IProps extends RouteComponentProps<IParams> {
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
}

interface IState {
    orders: LicenceDto[]
    summary: SummaryDto
    modal: boolean
    isDataLoading: boolean
}

export default function Licence(props: IProps) {
    const [error, setError] = useState('')
    const [modalVisible, setModalVisible] = useState(false)
    const [orders, setOrders] = useState([])
    // const [summary, setSummary] = useState(false)
    const { authState, authService } = useOktaAuth()

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        return Promise.all([
            GetOrders(authState.accessToken.accessToken, props.sessionData.selectedSchool.id),
            // GetLicenceSummary(
            //     authState.accessToken.accessToken,
            //     props.sessionData.selectedSchool.id
            // ),
        ])
            .then((values) => {
                setOrders(values[0].data)
                // setSummary(values[1].data)
                props.setIsDataLoading(false)
            })
            .catch((error) => {
                // this.handleUnknownError(error)
            })
    }

    const onDismiss = () => {
        setModalVisible(false)
    }

    const getLicenceStyle = (licence: LicenceDto) => {
        let expiryUtc = new Date(`${licence.licenseExpiry} UTC`)
        let tmp = new Date()
        let nowUtc = new Date(tmp.toUTCString())
        if (expiryUtc < nowUtc) return { color: 'red' }
        return {}
    }

    const { authenticatedTeacher } = props.sessionData

    return (
        <div data-cy="licence-page-loaded">
            <div className="mb-2">
                <BreadCrumbs
                    items={[
                        { title: 'Home', link: '/' },
                        {
                            title: 'Admin',
                            link: `/admin/select/${props.sessionData.selectedSchool.id}`,
                        },
                        { title: 'Licences', link: '' },
                    ]}
                />
            </div>
            {authenticatedTeacher.isDemo ? (
                <div>
                    <div className="py-3 text-center ">
                        <div className="pb-3">
                            <p>
                                <br></br>
                                <h5>
                                    {' '}
                                    Unlock full access to Early Insights and begin gathering
                                    valuable data about your students.
                                </h5>
                            </p>
                        </div>
                        <Button color="success" tag={Link} to={'/admin/order'}>
                            Purchase Licences
                        </Button>
                    </div>
                </div>
            ) : (
                <>
                    <div className="pb-2">
                        <h2>Licences</h2>
                    </div>
                    <Button
                        data-cy="purchase-more-student-licences-button"
                        color="success"
                        tag={Link}
                        to={'/admin/order'}
                    >
                        Purchase more Student Licences
                    </Button>
                </>
            )}
            {error ? (
                <Alert color="warning" isOpen={modalVisible} toggle={onDismiss}>
                    <p>{error}</p>
                </Alert>
            ) : null}

            {!authenticatedTeacher.isDemo ? (
                <div>
                    <div className="mb-2"></div>
                    <div>
                        <Table className="table" bordered>
                            <thead>
                                <tr className="text-nowrap">
                                    <th>Teacher Name</th>
                                    <th>Purchase Date</th>
                                    <th>Start Date</th>
                                    <th>Quantity</th>
                                    <th>Expiry</th>
                                    <th>Active</th>
                                    <th>Archived</th>
                                    <th>Available</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders.map((order: LicenceDto) => {
                                    return (
                                        <tr key={order.id} style={getLicenceStyle(order)}>
                                            <td>{`${order.teacherFirstName[0]}. ${order.teacherLastName}`}</td>
                                            <td>{convertUtcToLocalDate(order.purchasedDate)}</td>
                                            <td>{convertUtcToLocalDate(order.startDateUtc)}</td>
                                            <td>{order.numOfLicenses}</td>
                                            <td>{convertUtcToLocalDate(order.licenseExpiry)}</td>
                                            <td>{order.activeStudents}</td>
                                            <td>{order.archivedStudents}</td>
                                            <td>{order.availableStudents}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>
            ) : null}
        </div>
    )
}
