import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { withOktaAuth } from '@okta/okta-react'
import { Card, Button, Row, Col } from 'reactstrap'
import './Data.css'
import { AuthService } from '../../models/Auth'
import { SchoolClassDto } from '../../models/SchoolClass'
import BreadCrumbs from '../layout/BreadCrumbs'
import { isTeacherAdminOrSuper } from '../../shared/Helper'
import { ISessionData } from '../../AppRouter'

interface IProps extends RouteComponentProps<any> {
    authService: AuthService
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
    history: any
    match: any
}

interface IState {
    title: string
}

class Data extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            title: '',
        }
    }

    componentDidMount() {
        this.loadData()
    }

    componentDidUpdate(prevProps) {
        // if (
        //   this.props.sessionData &&
        //   this.props.sessionData.selectedSchoolClass !==
        //     prevProps.sessionData.selectedSchoolClass
        // ) {
        //   this.loadData();
        // }
    }

    loadData() {
        let teacher = this.props.sessionData.authenticatedTeacher
        let selectedClass = this.props.sessionData.selectedSchoolClass

        if (selectedClass) {
            this.setState({
                title: `Data (${selectedClass.name})`,
            })
        } else {
            //setIsDataLoading(false);
            if (teacher && isTeacherAdminOrSuper(teacher.accessLevel)) {
                this.props.history.push(`/admin/class/${teacher.schoolId}`)
            } else {
                this.props.history.push('/no-data-yet')
            }
        }
    }

    render() {
        this.props.setIsDataLoading(false)

        return (
            <div data-cy="data-page-loaded">
                <div className="mb-2">
                    <BreadCrumbs
                        items={[
                            { title: 'Home', link: '/' },
                            { title: 'Data', link: '' },
                        ]}
                    />
                </div>
                <h2 className="pb-2">{this.state.title}</h2>
                <div className="pb-4">
                    <Row>
                        <Col sm="6">
                            <Card body>
                                <h5 className="card-title">Class Report</h5>
                                <p>
                                    See how all your students are going. This is a great place to
                                    see trends within your class.
                                </p>
                                <Button color="primary" tag={Link} to={'/data/class'}>
                                    Class Report
                                </Button>
                            </Card>
                        </Col>
                        <Col sm="6">
                            <Card body>
                                <h5 className="card-title">Individual Student Report</h5>
                                <p>See how each student is tracking against the curriculum.</p>
                                <br />
                                <Button color="primary" tag={Link} to={'/data/individual'}>
                                    Individual Student Report
                                </Button>
                            </Card>
                        </Col>
                    </Row>
                </div>
                {/* <div className="d-flex justify-content-between mb-2">
          <div>
            <Button color="primary" tag={Link} to={"/"}>
              Back
            </Button>
          </div>
        </div> */}
            </div>
        )
    }
}
export default withOktaAuth(Data)
