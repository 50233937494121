import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { withOktaAuth } from '@okta/okta-react'
import { AuthService, AuthState } from '../../models/Auth'
import BreadCrumbs from '../layout/BreadCrumbs'
import BaseClass from '../Bases/BaseClass'
import { getStudentApi } from '../../services/StudentApi'
import { StudentDto } from '../../models/Student'
import { getStudentDiagnosticsByStudentIdApi } from '../../services/DiagnosticApi'
import { StudentDiagnosticDto } from '../../models/Diagnostic'
import DiagnosticDetailsTable, {
    DiagnosticDetailsTableDisplayType,
} from '../diagnostic/DiagnosticDetailsTable'
import ClassReport from '../data/ClassReport'
import { getOverallReportByStudentApi } from '../../services/ReportApi'
import { OverallReportByStudentDto } from '../../models/OverallReport'
import { StatementDto } from '../../models/Statement'
import { Card, CardBody, CardHeader } from 'reactstrap'

interface IParams {
    id: string
    studentId: string
    parentPage: string
}

interface IProps extends RouteComponentProps<IParams> {
    // baseUrl: string;
    authService: AuthService
    //  navSchoolClassIdSelected: string;
    authState: AuthState
    setIsDataLoading: any
    isDataLoading: boolean
    sessionData: any
    match: any
    history: any
}

interface IState {
    title: string
    student: StudentDto
    studentDiagnosticList: StudentDiagnosticDto[]
    //  keyConcepts: any[]
    studentStatements: any[]
    //  statements: StatementDto[]
    filteredStudentStatements: any
}

class StudentProfile extends BaseClass<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            filteredStudentStatements: [],
            title: '',
            student: null,
            studentDiagnosticList: [],
            //   keyConcepts: [],
            studentStatements: [],
            //   statements: [],
        }
    }

    componentDidMount() {
        this.loadData()
    }

    componentDidUpdate(prevProps) {
        //TODO:
        // if (
        //   this.props.navSchoolClassIdSelected !== prevProps.navSchoolClassIdSelected
        // ) {
        //   // this.loadData();
        // }
    }

    loadData() {
        this.populateStudent(this.props.match.params.id)
        this.populateStudentDiagnostics(this.props.match.params.id)
        // this.getKeyConcepts()
        // this.getStatements()
        this.getClassReport(this.props.match.params.id)
    }

    populateStudent = async (studentId: string) => {
        this.props.setIsDataLoading(true)
        try {
            let result = await getStudentApi(
                studentId,
                this.props.authState.accessToken.accessToken
            )

            this.props.setIsDataLoading(false)
            if (result.ok) {
                return this.setState({
                    student: result.data,
                    title: `Student Profile (${result.data.firstName} ${result.data.lastName})`,
                })
            }

            return this.handleknownError(result.error)
        } catch (error) {
            this.handleUnknownError(error)
        }
    }

    populateStudentDiagnostics = async (studentId: string) => {
        this.props.setIsDataLoading(true)
        try {
            let result = await getStudentDiagnosticsByStudentIdApi(
                studentId,
                this.props.authState.accessToken.accessToken
            )

            this.props.setIsDataLoading(false)
            if (result.ok) {
                return this.setState({
                    studentDiagnosticList: result.data,
                })
            }

            return this.handleknownError(result.error)
        } catch (error) {
            this.handleUnknownError(error)
        }
    }

    // getKeyConcepts = async () => {
    //     try {
    //         let result = await getKeyConceptsFromApi(this.props.authState.accessToken.accessToken)

    //         this.setState({
    //             keyConcepts: result.data,
    //         })
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // getStatements = async () => {
    //     try {
    //         let result = await getStatementsFromApi(this.props.authState.accessToken.accessToken)

    //         this.setState({
    //             statements: result.data,
    //         })
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    async getClassReport(studentId: string) {
        this.props.setIsDataLoading(true)
        let token = await this.props.authState.accessToken.accessToken

        return getOverallReportByStudentApi(studentId, token)
            .then((res) => {
                if (res.ok) {
                    this.props.setIsDataLoading(false)
                    this.setState({
                        // keyConcepts: res.data.keyConcepts,
                        studentStatements: res.data.students,
                        // statements: res.data.statements,
                        filteredStudentStatements: res.data.students.map(
                            (st: OverallReportByStudentDto) => {
                                return {
                                    student: st.student,
                                    studentStatements: [],
                                }
                            }
                        ),
                        // optionsOfMasterKeyConcepts: res.data.keyConcepts.map(
                        //   (keyConcept: KeyConceptDto) => {
                        //     return {
                        //       value: keyConcept.id,
                        //       label: keyConcept.name,
                        //       order: keyConcept.order,
                        //     };
                        //   }
                        // ),
                    })

                    //  this.selectAllKeyConceptsByDefault(res.data.keyConcepts);
                } else {
                    this.handleknownError(res.error)
                }
            })
            .catch((error) => {
                this.handleUnknownError(error)
            })
    }

    lastStrandName: string = ''

    generateClassReport(kc, newList, statements, index) {
        //let strandTitle = ''
        // if (kc.name === 'Counting') {
        //   strandTitle = 'Number and Algebra'
        // } else if (kc.name === 'Length, Mass & Temperature') {
        //   strandTitle = 'Measurement and Geometry'
        // } else if (kc.name === 'Understanding Chance') {
        //   strandTitle = 'Statistics and Probability'
        // }

        let hasStrandTitleChanged = kc.strandName !== this.lastStrandName

        this.lastStrandName = kc.strandName

        return (
            <div key={index} className="pl-2">
                {hasStrandTitleChanged ? (
                    <h5 className="pb-3 pt-3 font-weight-bold ">{kc.strandName}</h5>
                ) : null}

                <ClassReport
                    closeFullscreen={() => {}}
                    fullscreen={false}
                    scale={1}
                    scalingFactor={1}
                    printView={false}
                    keyConcepts={[kc]}
                    studentReports={newList}
                    showSideHeader={false}
                    keyConceptHeadingSize="18px"
                    keyConceptFontWeight={500}
                    statements={statements}
                />
            </div>
        )
    }

    render() {
        return !this.state.student || !this.state.studentDiagnosticList ? (
            <div className="center">
                {/* <Spinner style={{ width: "3rem", height: "3rem" }} /> */}
            </div>
        ) : (
            <div data-cy="student-profile-page-loaded">
                <div className="mb-2">
                    <BreadCrumbs
                        items={[
                            { title: 'Home', link: '/' },
                            { title: 'Student', link: '/Student' },
                            { title: 'Student Profile', link: '' },
                        ]}
                    />
                </div>
                <h2 className="pb-3">{this.state.title}</h2>
                <div className="pb-4">
                    <Card>
                        <CardHeader>
                            <h4 className="font-weight-bold ">Assessment History</h4>
                        </CardHeader>
                        <CardBody className="p-2">
                            {this.state.studentDiagnosticList.length > 0 ? (
                                <DiagnosticDetailsTable
                                    refresh={() => this.loadData()}
                                    displayType={DiagnosticDetailsTableDisplayType.StudentProfile}
                                    studentDiagnosticList={this.state.studentDiagnosticList}
                                    authState={this.props.authState}
                                    parentPage="student-profile"
                                    sessionData={this.props.sessionData}
                                    //keyConcepts={this.state.keyConcepts}
                                ></DiagnosticDetailsTable>
                            ) : (
                                <>No assesssments yet</>
                            )}
                        </CardBody>
                    </Card>
                </div>

                <div>
                    <Card>
                        <CardHeader>
                            <div className="pb-2 align-items-center space-between ">
                                <h4 className="pb-2 font-weight-bold">Current Student Data</h4>
                                <div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span className="dot-mastered mr-2" />
                                                </td>
                                                <td>Recently Mastered</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className="dot-previously-mastered mr-2" />
                                                </td>
                                                <td>Previously Mastered</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className="dot-not-mastered mr-2" />
                                                </td>
                                                <td>Not Mastered Yet</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className="dot-not-asses-yet mr-2" />
                                                </td>
                                                <td>Not Assessed Yet</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody className="p-0">
                            {/* {(this.state.keyConcepts.length > 0 && this.state.studentStatements.length > 0 && this.state.statements.length > 0) ? ( */}
                            {this.props.sessionData.keyConcepts.map((kc, index) => {
                                const kcs = [kc.id]
                                const newList = this.state.studentStatements.map(
                                    (st: OverallReportByStudentDto) => {
                                        return {
                                            student: st.student,
                                            studentStatements: st.studentStatements.filter((ss) =>
                                                // kcs.includes(ss.statement.keyConceptId)
                                                kcs.includes(ss.keyConceptId)
                                            ),
                                        }
                                    }
                                )

                                return this.generateClassReport(
                                    kc,
                                    newList,
                                    this.props.sessionData.statements,
                                    index
                                )
                            })}
                        </CardBody>
                    </Card>
                </div>

                <div className="pb-2"></div>
            </div>
        )
    }
}
export default withOktaAuth(StudentProfile)
