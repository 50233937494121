import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { OverallReportByStudentDto } from '../../models/OverallReport'
import { StatementDto, StudentStatementReportDto } from '../../models/Statement'
import { ComponentDto } from '../../models/Component'
import { Table } from 'reactstrap'
import { withOktaAuth } from '@okta/okta-react'
import { SchoolClassDto } from '../../models/SchoolClass'
import { getComponentReport } from '../../services/ReportApi'
import { AuthService, AuthState } from '../../models/Auth'
import BaseClass from '../Bases/BaseClass'
import { isTeacherAdminOrSuper } from '../../shared/Helper'
import BreadCrumbs from '../layout/BreadCrumbs'
import { getStatementsFromApi } from '../../services/CurriculumApi'
import { IBaseState } from '../Bases/BaseState'
import $ from 'jquery'
import { ISessionData } from '../../AppRouter'

//let jQuery = (window as any).$

interface IParams {
    componentId: string
}

interface IProps extends RouteComponentProps<IParams> {
    // baseUrl: string;
    authService: AuthService
    authState: AuthState
    // navSchoolClassIdSelected: string;
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
    history: any
    match: any
}

interface IState extends IBaseState {
    component: ComponentDto
    studentStatements: OverallReportByStudentDto[]
    selectedSchoolClass: SchoolClassDto | null
    statements: StatementDto[]
    isDataLoading: boolean
}

class ComponentReport extends BaseClass<IProps, IState> {
    defaultComponent: ComponentDto = {
        id: '',
        name: '',
        code: '',
        keyConceptId: '',
        order: 0,
        statements: Array<StatementDto>(),
    }

    constructor(props: IProps) {
        super(props)
        this.state = {
            isLoading: false,
            error: '',
            visible: false,
            // authenticatedTeacher: null,
            component: this.defaultComponent,
            studentStatements: Array<OverallReportByStudentDto>(),
            selectedSchoolClass: null,
            statements: [],
            isDataLoading: true,
        }
    }

    // getStatements = async () => {
    //     try {
    //         let result = await getStatementsFromApi(this.props.authState.accessToken.accessToken)
    //         this.setState({
    //             statements: result.data,
    //         })
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    async componentDidMount() {
        this.props.setIsDataLoading(true)
        let teacher = this.props.sessionData.authenticatedTeacher

        //  let selectedClass: SchoolClassDto = getSelectedSchoolClassFromLocalStorage();
        if (this.props.sessionData.selectedSchoolClass) {
            // this.setState({
            //   selectedSchoolClass: selectedClass,
            // });
            //await this.getStatements()
            this.setState({
                statements: this.props.sessionData.statements,
            })

            //  this.setState({
            //     statements: this.props.sessionData.statements,
            //   })

            await this.getComponents(
                this.props.sessionData.selectedSchoolClass.id,
                this.props.match.params.componentId
            )
        } else {
            //  this.setIsDataLoading(false);
            if (teacher && isTeacherAdminOrSuper(teacher.accessLevel)) {
                this.props.history.push(`/admin/class/${teacher.schoolId}`)
            } else {
                this.props.history.push('/no-data-yet')
            }
        }

        this.props.setIsDataLoading(false)
        this.props.setIsDataLoading(false)
    }

    async getComponents(schoolClassId: string, componentId: string) {
        let token = await this.props.authState.accessToken.accessToken

        return getComponentReport(schoolClassId, componentId, token)
            .then((res) => {
                this.props.setIsDataLoading(false)
                if (res.ok) {
                    this.setState({
                        component: res.data.component,
                        studentStatements: res.data.students,
                    })

                    // let height = $(window).innerHeight() - 30
                    // $('#report').css({
                    //     height,
                    // })
                } else {
                    this.handleknownError(res.error)
                }
            })
            .catch((error) => {
                this.handleUnknownError(error)
            })
    }

    getListOfStatement = (component: ComponentDto) => {
        let result = ''

        component.statements.forEach((statement) => {
            result += `${statement.code} - ${statement.name} \n`
        })

        return result
    }

    getMastery = (studentStatement: StudentStatementReportDto) => {
        if (studentStatement.answeredDate) {
            if (studentStatement.mastered) {
                if (studentStatement.previouslyMastered) {
                    return <span className="dot-previously-mastered" />
                }

                return <span className="dot-mastered" />
            }

            return <span className="dot-not-mastered" />
        }

        return <span className="dot-not-asses-yet" />
    }

    render() {
        // if (this.props.isDataLoading) return null;

        return (
            <div>
                <div className="mb-2">
                    <BreadCrumbs
                        items={[
                            { title: 'Home', link: '/' },
                            { title: 'Data', link: '/data' },
                            { title: 'Class Report', link: '/data/class' },
                            { title: 'Component', link: '' },
                        ]}
                    />
                </div>
                <h2 className="pb-2">{this.state.component.name}</h2>
                <div className="d-flex justify-content-between pb-4">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <span className="dot-mastered mr-2" />
                                </td>
                                <td>Recently Mastered</td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="dot-previously-mastered mr-2" />
                                </td>
                                <td>Previously Mastered</td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="dot-not-mastered mr-2" />
                                </td>
                                <td>Not Mastered Yet</td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="dot-not-asses-yet mr-2" />
                                </td>
                                <td>Not Assessed Yet</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* <div className="d-flex justify-content-between mb-2">
            <div>
              <Button color="primary" tag={Link} to={"/data/class"}>
                Back
            </Button>
            </div>
          </div> */}
                <div className="report-container">
                    <div style={{ overflowX: 'scroll' }} id="report">
                        <Table className="table table-bordered tableHeader mb-0">
                            <thead>
                                <tr>
                                    <th
                                        rowSpan={2}
                                        className="sticky-left-header offset-studentName text-left align-middle"
                                    >
                                        Student Name
                                    </th>
                                    <th colSpan={this.state.component.statements.length}>
                                        <h4>{this.state.component.name}</h4>
                                    </th>
                                </tr>
                                <tr>
                                    {this.state.component.statements.map((statement) => {
                                        return (
                                            <th
                                                className="component-column-cell"
                                                key={statement.id}
                                            >
                                                <div className="d-flex flex-column align-items-baseline">
                                                    <span>
                                                        <code>{statement.code}</code>
                                                    </span>
                                                    <span>
                                                        <small> {statement.name}</small>
                                                    </span>
                                                </div>
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                        </Table>
                        <Table size="sm" className="table table-bordered table-condensed">
                            <tbody>
                                {this.state.studentStatements.map((studentReport) => {
                                    return (
                                        <tr key={studentReport.student.id}>
                                            <td className="sticky-left-header offset-studentName text-left align-middle pl-2">
                                                {studentReport.student.firstName}{' '}
                                                {studentReport.student.lastName}
                                            </td>
                                            {studentReport.studentStatements.map(
                                                (studentStatement) => {
                                                    let statement = this.getStatement(
                                                        studentStatement.statementId,
                                                        this.state.statements
                                                    )
                                                    // if (!statement){
                                                    //   return(null);
                                                    // }

                                                    return (
                                                        <td
                                                            className="component-column-cell text-center align-middle"
                                                            key={studentStatement.statementId}
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title={`${statement.code} - ${statement.name}`}
                                                        >
                                                            {this.getMastery(studentStatement)}
                                                        </td>
                                                    )
                                                }
                                            )}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }

    getStatement(statementId: string, statements: StatementDto[]) {
        return statements.find((v) => v.id === statementId)
    }
}
export default withOktaAuth(ComponentReport)
