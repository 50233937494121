import React from 'react'
import { logout, ScrollToTop } from '../../shared/Helper'

export default class BaseClass<IProps, IState> extends React.Component<IProps, IState> {
    // checkIfAuthenticated(authService) {
    //   debugger;
    //   let authState = authService.getAuthState();

    //   if (!authState.isAuthenticated) {
    //     this.logoutOfApp(authService);

    //     return false;
    //   }

    //   return true;
    // }

    // getAuthenticatedTeacher(authState): Promise<TeacherDto> {
    //   let token = authState.accessToken.accessToken;

    //   return getAuthenticatedTeacherFromApi(token, true)
    //     .then((res) => {
    //       if (res.ok) {
    //         (this as any).setState({ authenticatedTeacher: res.data });

    //         return res.data;
    //       }

    //       this.handleknownError(res.error);
    //     })
    //     .catch((error) => {
    //       this.handleUnknownError(error);
    //     });
    // }

    // setPageIsLoading = (loading, messages?) => {
    //   // (this as any)
    //   // .setState({ isLoading: loading })(this.context as any)
    //   // .setScreenLoaderMessages(messages)(this.context as any)
    //   // .setShowScreenLoader(loading)
    // };

    handleknownError = (errors) => {
        ScrollToTop()
        ;(this.props as any).setIsDataLoading(false)
        ;(this as any).setState({
            error: errors.detail,
            visible: true,
        })
    }

    handleUnknownError = (error) => {
        ScrollToTop()
        ;(this.props as any).setIsDataLoading(false)
        console.log(error)
        if (error.status === 401) {
            return this.logoutOfApp()
        }

        console.log(error)
        ;(this as any).setState({
            error: error.statusText,
        })
    }

    logoutOfApp = () => {
        ;(this.props as any).setIsDataLoading(true)

        // return logout();
    }

    showError = (message) => {
        ;(this.props as any).setIsDataLoading(false)
        ;(this as any).setState({ error: message, visible: true })
    }
}
