/**
 * Use the CSS tab above to style your Element's container.
 */
import React from 'react'
import { CardElement } from '@stripe/react-stripe-js'
import './CardSectionStylings.css'

// const createOptions: any = {
//   iconStyle: 'solid',
//   style: {
//     base: {
//       iconColor: '#c4f0ff',
//       color: '#ffc7ee',
//       backgroundColor: '#ffc7ee',
//       fontWeight: 500,
//       fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
//       fontSize: '16px',
//       fontSmoothing: 'antialiased',
//       ':-webkit-autofill': { color: '#fce883' },
//       '::placeholder': { color: '#87bbfd' },
//     },
//     invalid: {
//       iconColor: '#ffc7ee',
//       color: '#ffc7ee',
//     },
//   },
// };

// const createOptions : any = () => {
//   return {
//     style: {
//       base: {
//         fontSize: '16px',
//         color: '#424770',
//         fontFamily: 'Open Sans, sans-serif',
//         letterSpacing: '0.025em',
//         '::placeholder': {
//           color: '#aab7c4',
//         },
//       },
//       invalid: {
//         color: '#c23d4b',
//       },
//     }
//   }
// };

function CardSection() {
    return <CardElement className="card" />
}

export default CardSection
