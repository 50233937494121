import axios from 'axios'
import { handleApiSuccess, handleApiError, IApiResult } from '../shared/ApiHandler'
import { studentReportApiUrl, teacherBaseApiUrl } from '../shared/ApiUrls'
import { OverallReport, ComponentReport } from '../models/OverallReport'

export const getOverallReportByClassApi = (
    schoolClassId: string,
    token: string
): Promise<IApiResult> => {
    return axios
        .get<OverallReport>(`${studentReportApiUrl}/GetOverallReport/${schoolClassId}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const getOverallReportByStudentApi = (
    studentId: string,
    token: string
): Promise<IApiResult> => {
    return axios
        .get<OverallReport>(`${studentReportApiUrl}/GetOverallReportByStudentId/${studentId}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const getComponentReport = (
    schoolClassId: string,
    componentId: string,
    token: string
): Promise<IApiResult> => {
    return axios
        .get<ComponentReport>(
            `${studentReportApiUrl}/GetComponentReport/${schoolClassId}/${componentId}`,
            {
                headers: { Authorization: `bearer ${token}` },
                baseURL: teacherBaseApiUrl,
            }
        )
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const getStudentReport = (studentId: string, token: string): Promise<IApiResult> => {
    return axios
        .get<ComponentReport>(`${studentReportApiUrl}/GetStudentReport/${studentId}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const generateExcelReport = (schoolClassId: string, token: string): Promise<IApiResult> => {
    return axios
        .get<string>(`${studentReportApiUrl}/GenerateReport/${schoolClassId}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
            responseType: 'blob',
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}
