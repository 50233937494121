import axios from 'axios'
import { handleApiSuccess, handleApiError, IApiResult } from '../shared/ApiHandler'
import { schoolClassApiUrl, teacherBaseApiUrl } from '../shared/ApiUrls'
import { SchoolClassCreateDto, SchoolClassEditDto } from '../models/SchoolClass'

export const getSchoolClassesFromApi = (
    token: string,
    includeArchivedClass: boolean
): Promise<IApiResult> => {
    return axios
        .get(
            `${schoolClassApiUrl}/GetAllForTeacher${
                includeArchivedClass ? '?includeArchivedClass=true' : ''
            }`,
            {
                headers: { Authorization: `bearer ${token}` },
                baseURL: teacherBaseApiUrl,
            }
        )
        .then(handleApiSuccess)
        .catch(handleApiError)
}

// export const getAllSchoolClassesFromApi = (token: string,schoolId:string, includeArchivedClass:boolean): Promise<IApiResult> => {
//   return axios
//     .get(`${schoolClassApiUrl}/getAllSchoolClasses/${schoolId}` + (includeArchivedClass ? `?includeArchivedClass=true` : ``), {
//       headers: { Authorization: "bearer " + token }, baseURL: baseApiUrl
//     })
//     .then(handleApiSuccess)
//     .catch(handleApiError);
// };

export const getSchoolClassFromApi = (
    schoolClassId: string,
    token: string
): Promise<IApiResult> => {
    return axios
        .get(`${schoolClassApiUrl}/${schoolClassId}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const updateSchoolClass = (
    // schoolClassId: string,
    schoolClass: SchoolClassEditDto,
    token: string
): Promise<IApiResult> => {
    return axios
        .put(`${schoolClassApiUrl}`, schoolClass, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const deleteSchoolClass = (
    schoolClassId: string,
    schoolId: string,
    token: string
): Promise<IApiResult> => {
    return axios
        .delete(`${schoolClassApiUrl}/${schoolClassId}/${schoolId}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const addSchoolClass = (
    schoolId: string,
    newSchoolClass: SchoolClassCreateDto,
    token: string
): Promise<IApiResult> => {
    return axios
        .post(`${schoolClassApiUrl}/${schoolId}`, newSchoolClass, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const addBulkSchoolClasses = (
    //schoolId: string,
    schoolId: string,
    teachers: FormData,
    token: string
): Promise<IApiResult> => {
    return (
        axios
            //.post(`${studentApiUrl}/AddBulk/${schoolId}/${schoolClassId}`, students, {
            .post(`${schoolClassApiUrl}/AddBulk/${schoolId}`, teachers, {
                headers: { Authorization: `bearer ${token}` },
                baseURL: teacherBaseApiUrl,
            })
            .then(handleApiSuccess)
            .catch(handleApiError)
    )
}
