import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { KeyConceptDto } from '../../models/KeyConcept'
import { OverallReportByStudentDto } from '../../models/OverallReport'
import { Spinner, Button } from 'reactstrap'
import { withOktaAuth } from '@okta/okta-react'
import { SchoolClassDto } from '../../models/SchoolClass'
import { generateExcelReport, getOverallReportByClassApi } from '../../services/ReportApi'
import ClassReport from './ClassReport'
import MySelect from '../diagnostic/MySelect'
import { saveAs } from 'file-saver'
import './Data.css'
import { AuthService, AuthState } from '../../models/Auth'
import { demoClassGuid, isTeacherAdminOrSuper } from '../../shared/Helper'
import BaseClass from '../Bases/BaseClass'
import BreadCrumbs from '../layout/BreadCrumbs'
import { StatementDto } from '../../models/Statement'
import { IBaseState } from '../Bases/BaseState'
import { ISessionData } from '../../AppRouter'
import { updateTeacherSettingsApi } from '../../services/TeacherApi'

interface IParams {
    diagnosticId: string
}

interface ISelect {
    value: string
    label: string
    order: number
}

interface IProps extends RouteComponentProps<IParams> {
    //  baseUrl: string;
    authService: AuthService
    authState: AuthState
    //navSchoolClassIdSelected: string;
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
    history: any
    match: any
}

interface IState extends IBaseState {
    keyConcepts: KeyConceptDto[]
    filteredKeyConcepts: KeyConceptDto[]
    // same as "filtetedKeyConcepts" but in different format that needed for React-Select Library
    selectedKeyConcepts: ISelect[]
    // same as "keyConcepts" but in different format that needed for React-Select Library
    optionsOfMasterKeyConcepts: any
    // master list of students keyconcepts and mastery
    studentStatements: OverallReportByStudentDto[]
    // statements: StatementDto[]
    filteredStudentStatements: OverallReportByStudentDto[]
    reportGenerating: boolean
    statementNumber: number
    selectedSchoolClass: SchoolClassDto | null
    reportLink: string
    printView: boolean
    scale: number
    scalingFactor: number
    title: string
    fullscreen: boolean
    isDataLoading: boolean
}

class ClassReportPage extends BaseClass<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            isLoading: false,
            error: '',
            visible: false,
            keyConcepts: [],
            filteredKeyConcepts: [],
            selectedKeyConcepts: [],
            optionsOfMasterKeyConcepts: [],
            studentStatements: [],
            //  statements: [],
            filteredStudentStatements: [],
            reportGenerating: false,
            statementNumber: 0,
            selectedSchoolClass: null,
            reportLink: '',
            printView: false,
            scale: 1,
            scalingFactor: 1,
            title: 'Class Report ()',
            fullscreen: false,
            isDataLoading: true,
        }
    }

    componentDidMount() {
        this.loadData()
    }

    componentWillUnmount() {
        this.updateTeacherSettingsToApi()
    }

    //TODO:
    // componentDidUpdate(prevProps, nextState) {
    //   if (
    //     this.props.sessionData.selectedSchoolClass !==
    //     prevProps.sessionData.selectedSchoolClass
    //   ) {
    //     this.loadData();
    //   }

    //   if (this.state.filteredKeyConcepts !== nextState.filteredKeyConcepts) {
    //     this.updateTeacherSettings();
    //   }
    // }

    async loadData() {
        this.props.setIsDataLoading(true)
        // let teacher = await super.getAuthenticatedTeacher(
        //   this.props.authState.accessToken.accessToken
        // );

        let teacher = this.props.sessionData.authenticatedTeacher

        this.setState({
            //  keyConcepts: res.data,
            optionsOfMasterKeyConcepts: this.props.sessionData.keyConcepts.map(
                (keyConcept: KeyConceptDto) => {
                    return {
                        value: keyConcept.id,
                        label: keyConcept.name,
                        order: keyConcept.order,
                    }
                }
            ),
        })

        let selectedClass = this.props.sessionData.selectedSchoolClass

        if (selectedClass) {
            this.setState({
                selectedSchoolClass: selectedClass,
                title: `Class Report (${selectedClass.name})`,
            })

            this.getClassReport(selectedClass.id)
        } else {
            this.props.setIsDataLoading(false)
            if (teacher && isTeacherAdminOrSuper(teacher.accessLevel)) {
                this.props.history.push(`/admin/class/${teacher.schoolId}`)
            } else {
                this.props.history.push('/no-data-yet')
            }
        }

        this.props.setIsDataLoading(false)
    }

    updateTeacherSettings() {
        if (this.props.sessionData.authenticatedTeacher) {
            let selectedKcs = ''

            if (this.state.selectedKeyConcepts) {
                selectedKcs = this.state.selectedKeyConcepts.map((v) => v.value).join(',')
            }

            // TODO: make sure this gets updated globally

            this.props.sessionData.authenticatedTeacher.teacherSettings.classReportPageSettings.filteredKeyConceptIds =
                selectedKcs
            // setTeacherToLocalStorage(this.props.sessionData.authenticatedTeacher);
        }
    }

    updateTeacherSettingsToApi() {
        if (this.props.sessionData.authenticatedTeacher) {
            updateTeacherSettingsApi(
                this.props.sessionData.authenticatedTeacher.id,
                this.props.sessionData.authenticatedTeacher.teacherSettings,
                this.props.authState.accessToken.accessToken
            )
        }
    }

    handleChange = (selectedKeyConcepts: ISelect[]) => {
        if (selectedKeyConcepts && selectedKeyConcepts.length > 0) {
            const sortedKeyConcepts = selectedKeyConcepts.sort((a, b) => {
                return a.order - b.order
            })

            this.setState({ selectedKeyConcepts: sortedKeyConcepts })
            let newListOfKeyConcepts = new Array<KeyConceptDto>()

            selectedKeyConcepts.forEach((kc: any) => {
                const index = this.props.sessionData.keyConcepts.findIndex((k) => k.id === kc.value)

                if (index !== -1) {
                    newListOfKeyConcepts.push(this.props.sessionData.keyConcepts[index])
                }
            })

            const kcIds = newListOfKeyConcepts.map((st) => st.id)
            const newList = this.state.studentStatements.map((st: OverallReportByStudentDto) => {
                return {
                    student: st.student,
                    studentStatements: st.studentStatements.filter((ss) =>
                        kcIds.includes(ss.keyConceptId)
                    ),
                }
            })

            this.setState({
                filteredKeyConcepts: newListOfKeyConcepts,
                filteredStudentStatements: newList,
            })
        } else {
            this.setState({
                selectedKeyConcepts,
                filteredKeyConcepts: [],
                filteredStudentStatements: this.state.studentStatements.map(
                    (st: OverallReportByStudentDto) => {
                        return {
                            student: st.student,
                            studentStatements: [],
                        }
                    }
                ),
            })
        }
    }

    // getKeyConcepts = async () => {
    //     let token = await this.props.authState.accessToken.accessToken

    //     return getKeyConceptsFromApi(token)
    //         .then((res) => {
    //             if (res.ok) {
    //                 this.setState({
    //                     keyConcepts: res.data,
    //                     optionsOfMasterKeyConcepts: res.data.map((keyConcept: KeyConceptDto) => {
    //                         return {
    //                             value: keyConcept.id,
    //                             label: keyConcept.name,
    //                             order: keyConcept.order,
    //                         }
    //                     }),
    //                 })
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error)
    //         })
    // }

    async getClassReport(schoolClassId: string) {
        this.props.setIsDataLoading(true)
        let token = await this.props.authState.accessToken.accessToken

        return getOverallReportByClassApi(schoolClassId, token)
            .then((res) => {
                if (res.ok) {
                    this.props.setIsDataLoading(false)
                    this.setState({
                        studentStatements: res.data.students,
                        filteredStudentStatements: res.data.students.map(
                            (st: OverallReportByStudentDto) => {
                                return { student: st.student, studentStatements: [] }
                            }
                        ),
                    })

                    let skcs = this.props.sessionData.keyConcepts

                    if (
                        this.props.sessionData.authenticatedTeacher.teacherSettings &&
                        this.props.sessionData.authenticatedTeacher.teacherSettings
                            .classReportPageSettings &&
                        this.props.sessionData.authenticatedTeacher.teacherSettings
                            .classReportPageSettings.filteredKeyConceptIds &&
                        this.props.sessionData.authenticatedTeacher.teacherSettings
                            .classReportPageSettings.filteredKeyConceptIds.length > 0
                    ) {
                        skcs = this.props.sessionData.keyConcepts.filter((v) =>
                            this.props.sessionData.authenticatedTeacher.teacherSettings.classReportPageSettings.filteredKeyConceptIds
                                .toLowerCase()
                                .split(',')
                                .includes(v.id.toLowerCase())
                        )
                    }

                    this.selectKeyConceptsByDefault(skcs)
                } else {
                    this.handleknownError(res.error)
                }
            })
            .catch((error) => {
                this.handleUnknownError(error)
            })
    }

    generateReportLink = async () => {
        let token = await this.props.authState.accessToken.accessToken
        const schooClassId = this.state.selectedSchoolClass
            ? this.state.selectedSchoolClass.id
            : null

        if (schooClassId && token) {
            this.setState({
                reportGenerating: true,
            })

            return generateExcelReport(schooClassId, token)
                .then((res) => {
                    this.setState({
                        reportGenerating: false,
                    })

                    if (res.ok) {
                        const blob = new Blob([res.data], {
                            type: 'application/octet-stream',
                        })

                        saveAs(blob, `${schooClassId}.xlsx`)
                    } else {
                        this.handleknownError(res.error)
                    }
                })
                .catch((error) => {
                    this.handleUnknownError(error)
                })
        }
    }

    selectKeyConceptsByDefault = (keyConcepts: KeyConceptDto[]) => {
        const kc = keyConcepts.map((keyConcept: KeyConceptDto) => {
            return {
                value: keyConcept.id,
                label: keyConcept.name,
                order: keyConcept.order,
            }
        })

        this.handleChange(kc)
    }

    onScaleChange = (e: any) => {
        this.setState({ scale: e.target.value })
    }

    onScalingFactorChange = (e: any) => {
        this.setState({ scalingFactor: e.target.value })
    }

    render() {
        // if (this.props.isDataLoading) return null;

        return (
            <div>
                <div className="mb-2">
                    <BreadCrumbs
                        items={[
                            { title: 'Home', link: '/' },
                            { title: 'Data', link: '/data' },
                            { title: 'Class Report', link: '' },
                        ]}
                    />
                </div>
                <div className="d-flex justify-content-between pb-4">
                    <div>
                        <h2 className="pb-2">{this.state.title}</h2>
                        <p className="font-weight-light">
                            This page shows all your student data in one place. The content is
                            broken down into 3 tiers:
                        </p>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <strong>Key Concept</strong> - Eg. ‘Counting’
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Components</strong> - Eg. ‘Counting by Ones’ is a
                                        component of the Key Concept ‘Counting’
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Statements</strong> - Eg. ‘Counting forwards by ones
                                        to 5’ is a statement within the component ‘Counting by
                                        Ones’.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <span className="dot-mastered mr-2" />
                                    </td>
                                    <td>Recently Mastered</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="dot-previously-mastered mr-2" />
                                    </td>
                                    <td>Previously Mastered</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="dot-not-mastered mr-2" />
                                    </td>
                                    <td>Not Mastered Yet</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="dot-not-asses-yet mr-2" />
                                    </td>
                                    <td>Not Assessed Yet</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                    <div>
                        <Button
                            id="print"
                            color="success"
                            onClick={() => {
                                this.setState({ printView: true })
                                this.forceUpdate(() => {
                                    ;(window as any).print()
                                    this.setState({ printView: false })
                                })
                            }}
                            disabled={
                                this.state.reportGenerating ||
                                (this.state.selectedSchoolClass &&
                                    this.state.selectedSchoolClass.id === demoClassGuid)
                            }
                        >
                            {'  '} Print
                        </Button>{' '}
                        <Button
                            id="export"
                            color="success"
                            onClick={this.generateReportLink}
                            disabled={
                                this.state.reportGenerating ||
                                (this.state.selectedSchoolClass &&
                                    this.state.selectedSchoolClass.id === demoClassGuid)
                            }
                        >
                            {this.state.reportGenerating ? (
                                <Spinner size="sm" color="light" />
                            ) : null}
                            {'  '} Export
                        </Button>{' '}
                        <Button
                            id="export"
                            color="success"
                            onClick={() => this.setState({ fullscreen: true })}
                            disabled={this.state.reportGenerating}
                        >
                            {'  '} Fullscreen
                        </Button>
                    </div>
                </div>
                <div className="mb-2">
                    <strong>Display student data for these Key Concepts</strong>
                </div>
                <div className="mb-4" style={{ zIndex: 0 }}>
                    <MySelect
                        id="masterSelect"
                        isMulti
                        allowSelectAll={true}
                        value={this.state.selectedKeyConcepts}
                        onChange={this.handleChange}
                        options={this.state.optionsOfMasterKeyConcepts}
                    />
                </div>
                <div className="pb-4">
                    <ClassReport
                        closeFullscreen={() => this.setState({ fullscreen: false })}
                        fullscreen={this.state.fullscreen}
                        scale={this.state.scale}
                        scalingFactor={this.state.scalingFactor}
                        printView={this.state.printView}
                        keyConcepts={this.state.filteredKeyConcepts}
                        studentReports={this.state.filteredStudentStatements}
                        showSideHeader={true}
                        keyConceptHeadingSize="24px"
                        keyConceptFontWeight={500}
                        statements={this.props.sessionData.statements}
                    />
                </div>
            </div>
        )
    }
}

export default withOktaAuth(ClassReportPage)
