import React from 'react'
import { DiagnosticDto } from '../../models/Diagnostic'
import { Link, RouteComponentProps } from 'react-router-dom'
import { withOktaAuth } from '@okta/okta-react'
import { Spinner, Button, Table, Alert } from 'reactstrap'
import { SchoolClassDto } from '../../models/SchoolClass'
import './Diagnostic.css'
import { AuthService } from '../../models/Auth'
import { ISessionData } from '../../AppRouter'

interface IProps extends RouteComponentProps<any> {
    // baseUrl: string;
    authService: AuthService
    sessionData: ISessionData
    history: any
    match: any
}

interface IState {
    diagnosticList: DiagnosticDto[]
    isLoading: boolean
    error: string
    visible: boolean
    selectedSchoolClass: SchoolClassDto | null
    selectedDiagnostic: DiagnosticDto | null
    modal: boolean
    diagnosticIsRunning: boolean
}

class Sandbox extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            isLoading: false,
            diagnosticList: Array<DiagnosticDto>(),
            error: '',
            visible: false,
            selectedSchoolClass: null,
            selectedDiagnostic: null,
            modal: false,
            diagnosticIsRunning: false,
        }
    }

    componentDidMount() {
        let selectedClass = this.props.sessionData.selectedSchoolClass

        if (selectedClass) {
            this.setState({
                selectedSchoolClass: selectedClass,
            })
        } else {
            this.props.history.push('/class/create')
        }
    }

    toggle = () => {
        this.setState((prevState) => ({
            modal: !prevState.modal,
        }))
    }

    render() {
        return this.state.isLoading ? (
            <div className="center">
                <Spinner style={{ width: '3rem', height: '3rem' }} />
            </div>
        ) : (
            <div>
                <h2 className="pb-2">Create Assessment</h2>
                {this.state.visible ? (
                    <Alert color="warning">
                        <p>{this.state.error}</p>
                    </Alert>
                ) : null}
                <div className="mb-0 border bg-white ">
                    <div className="row">
                        <div className="col">
                            assessment. You can only have one active assessment at a time, so don’t
                            forget to STOP any previous assessments that are still in progress.
                            Don’t worry, this won’t affect your students if they haven’t finished
                            yet. The new assessment will pick up right where they left off.
                        </div>
                        <div className="col">Some Text in Column Two</div>
                    </div>
                </div>

                <div className="mt-4 border bg-white p-4 ">
                    <Table className="table-borderless ">
                        <thead className="test">
                            <tr className="test">
                                <th className=""></th>
                                <th className="bg-white border">Foundation</th>
                                <th className="bg-white border">Year 1</th>
                                <th className="bg-white border">Year 2</th>
                                <th className="bg-white border">Year 3</th>
                                <th className="bg-white border">Year 4</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="bg-white border">Counting</td>
                                <td id="test9" className={this.getCellClassNames(true)}>
                                    1
                                </td>
                                <td className="bg-white border">2</td>
                                <td className="bg-white border">3</td>
                                <td className="bg-white border">4</td>
                                <td className="bg-white border">5</td>
                            </tr>
                            <tr>
                                <td className="bg-white border">Counting</td>
                                <td className="bg-white border">1</td>
                                <td className="bg-white border">2</td>
                                <td className="bg-white border">3</td>
                                <td className="bg-white border">4</td>
                                <td className="bg-white border">5</td>
                            </tr>
                            <tr>
                                <td className="bg-white border">Counting</td>
                                <td className="bg-white border">1</td>
                                <td className="bg-white border">2</td>
                                <td className="bg-white border">3</td>
                                <td className="bg-white border">4</td>
                                <td className="bg-white border">5</td>
                            </tr>
                            {/* {this.state.diagnosticList.map((diagnostic: DiagnosticDto) => {
              return (
                <tr key={diagnostic.id}>
                  <td className="align-middle">
                    <Link to={"/diagnostic/details/" + diagnostic.id}>
                      {diagnostic.name}
                    </Link>
                  </td>
                  <td className="align-middle">{diagnostic.createdDate}</td>
                  <td className="align-middle"></td>
                  <td className="align-middle">
                    <div className="d-flex justify-content-between">
                      <div>
                        <Button
                          color="primary"
                          tag={Link}
                          to={"diagnostic/edit/" + diagnostic.id}
                        >
                          Edit
                        </Button>
                      </div>
                      <div>
                        {diagnostic.startedDate && !diagnostic.finishedDate ? (
                          <Button
                            color="danger"
                            disabled={this.state.isLoading}
                          >
                            Stop
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })} */}
                        </tbody>
                    </Table>
                </div>

                <div className="d-flex justify-content-between mb-2">
                    {/* <div>
            <Button color="primary" tag={Link} to={"/"}>
              Back
            </Button>
          </div> */}
                    <div>
                        <Button
                            className={
                                this.state.diagnosticIsRunning
                                    ? 'btn btn-secondary'
                                    : 'btn btn-success'
                            }
                            tag={Link}
                            to="/assessment/create"
                            disabled={this.state.diagnosticIsRunning}
                        >
                            Review
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
    getCellClassNames(selected: boolean): string | undefined {
        let result = 'bg-white border '

        if (selected) {
            result = 'red-cell border '
        }

        return result
    }
}
export default withOktaAuth(Sandbox)
