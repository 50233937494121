import React, { useEffect, useState } from 'react'
import MyComponent from 'react-fullpage-custom-loader'
import $ from 'jquery'

//import $ from "jquery";
//let jQuery = (window as any).$

interface IProps {
    sentences?: any[]
    isVisible: boolean
}

export function FullPageSpinner(props: IProps) {
    useEffect(() => {
        updateHeight()
    })

    // const [isVisible, setIsVisible] = useState(false);

    // use this when showing the loader on a screen with data longer than the page
    const updateHeight = () => {
        let height = $(document).height()
        let x = document.getElementsByClassName('styles_WrapperUi__2TxXl')

        if (x && x[0]) {
            ;(x[0] as any).style.height = `${height}px`
        }
    }

    if (!props.isVisible) return null

    return props.sentences && props.sentences.length > 0 ? (
        <div>
            <MyComponent
                sentences={props.sentences}
            />
        </div>
    ) : (
        <div>
            <MyComponent
                color={'black'}
                wrapperBackgroundColor={'rgba(0,0,0,0.1)'}
                sentences={[]}
            />
        </div>
    )
}
