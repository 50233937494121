/* eslint-disable no-unused-vars */
export interface QuestionDto {
    questionAudioText: string
    questionText: string
    questionTemplate: QuestionTemplate
    questionAnswers: string[]
}

export enum QuestionTemplate {
    A1 = 'A1',
    A2 = 'A2',
    A4 = 'A4',
    A5 = 'A5',
    A6 = 'A6',
    D_FB2 = 'D_FB2',
    D_FB3 = 'D_FB3',
    D_FB4 = 'D_FB4',
    D1 = 'D1',
    D4 = 'D4',
    D5 = 'D5',
    D7 = 'D7',
    D8 = 'D8',
    DD_FB1 = 'DD_FB1',
    DD_FB2 = 'DD_FB2',
    DD_FB3 = 'DD_FB3',
    DD_FB4 = 'DD_FB4',
    DD_FB5 = 'DD_FB5',
    DD_FB6 = 'DD_FB6',
    DD1 = 'DD1',
    DD10 = 'DD10',
    DD11 = 'DD11',
    DD12 = 'DD12',
    DD13 = 'DD13',
    DD14 = 'DD14',
    DD15 = 'DD15',
    DD16 = 'DD16',
    DD17 = 'DD17',
    DD18 = 'DD18',
    DD19 = 'DD19',
    DD2 = 'DD2',
    DD20 = 'DD20',
    DD21 = 'DD21',
    DD22 = 'DD22',
    DD3 = 'DD3',
    DD4 = 'DD4',
    DD6 = 'DD6',
    DD7 = 'DD7',
    DD8 = 'DD8',
    DD9 = 'DD9',
    FB1 = 'FB1',
    FB10 = 'FB10',
    FB11 = 'FB11',
    FB12 = 'FB12',
    FB13 = 'FB13',
    FB14 = 'FB14',
    FB15 = 'FB15',
    FB16 = 'FB16',
    FB2 = 'FB2',
    FB3 = 'FB3',
    FB4 = 'FB4',
    FB5 = 'FB5',
    FB6 = 'FB6',
    FB7 = 'FB7',
    FB8 = 'FB8',
    FB9 = 'FB9',
    MC1 = 'MC1',
    MC2 = 'MC2',
    MC3 = 'MC3',
    MC4 = 'MC4',
    MC5 = 'MC5',
    MC6 = 'MC6',
    MC7 = 'MC7',
    T1 = 'T1',
    Practice = 'Practice',
    DD23 = 'DD23',
    DD24 = 'DD24',
    DD25 = 'DD25',
    DD26 = 'DD26',
    DD27 = 'DD27',
    D_FB7 = 'D_FB7',
    D_FB8 = 'D_FB8',
    TB1 = 'TB1',

    FB2WithJson = 'FB2WithJson',
    DD23WithJson = 'DD23WithJson',
    DD24WithJson = 'DD24WithJson',
    DD25WithJson = 'DD25WithJson',
    DD26WithJson = 'DD26WithJson',
    D_FB7WithJson = 'D_FB7WithJson',
    D_FB8WithJson = 'D_FB8WithJson',

    MC7WithJson = 'MC7WithJson',

    STARTUP = 'STARTUP',
    LOGIN = 'LOGIN',
    HOME = 'HOME',
    DRAW_EXAMPLE = 'DRAW_EXAMPLE',
    TEMPLATE_TESTER = 'TEMPLATE_TESTER',
    FB_EXAMPLE = 'FB_EXAMPLE',
    DIAGNOSTIC = 'DIAGNOSTIC',
    STUDENT_SELECT = 'STUDENT_SELECT',
    AUDIO_TEST = 'AUDIO_TEST',
    DEMO = 'DEMO',
    NO_DIAGNOSTIC_READY = 'NO_DIAGNOSTIC_READY',
    FINISH = 'FINISH',
    SANDBOX = 'SANDBOX',
    SNAKE = 'SNAKE',
    AUTO_TESTER = 'AUTO_TESTER',

    INTEGRATION_TESTS = 'INTEGRATION_TESTS',
    SETTINGS = 'SETTINGS',
    // MIC_TEST = "MIC_TEST",
    MIC_TROUBLESHOOT = 'MIC_TROUBLESHOOT',
    STRESS_TEST = 'STRESS_TEST',
    DOORTAP = 'DOORTAP',
    BANKPANIC = 'BANKPANIC',
    BREAKOUT = 'BREAKOUT',
    BREAKOUTGAME = 'BREAKOUTGAME',
    EMOJIMATCH = 'EMOJIMATCH',
    FLOODFILL = 'FLOODFILL',
    GERMS = 'GERMS',
    STACKER = 'STACKER',
    GAME_TESTER = 'GAME_TESTER',
}
