import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

interface IProps {
    items: any
}

export default function BreadCrumbs(props: IProps) {
    useEffect(() => {}, [])

    return (
        <>
            <div className="pl-3 row" style={{ color: 'black', fontWeight: 500 }}>
                {props.items &&
                    props.items.map((element: any, index: number) => {
                        return index !== props.items.length - 1 ? (
                            <div key={index}>
                                <NavLink activeClassName="none" to={element.link}>
                                    {element.title}
                                </NavLink>
                                <b>{' > '}</b>
                            </div>
                        ) : (
                            <div key={index}>{element.title}</div>
                        )
                    })}
            </div>
        </>
    )
}

// <div style={{ color: 'black' }}>
// {props.items2 && props.items2.map((element) => {
//   return (
//   <div key={element}>
//     {element}
//   </div>
//   );
// })}
// </div>
