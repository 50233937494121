import React from 'react'
import { withOktaAuth } from '@okta/okta-react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { SchoolClassDto } from '../../models/SchoolClass'
import { updateDiagnosticNameApi, getDiagnosticApi } from '../../services/DiagnosticApi'
import { DiagnosticUpdateDto, DiagnosticDto } from '../../models/Diagnostic'
import { Button, Alert } from 'reactstrap'
import { AuthService, AuthState } from '../../models/Auth'
import BaseClass from '../Bases/BaseClass'
import BreadCrumbs from '../layout/BreadCrumbs'
import { ISessionData } from '../../AppRouter'

interface IParams {
    diagnosticId: string
}

interface IProps extends RouteComponentProps<IParams> {
    authService: AuthService
    authState: AuthState
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
    history: any
    match: any
}

interface IState {
    isDataLoading: boolean
    updating: boolean
    error: string
    visible: boolean
    diagnostic: DiagnosticDto
    selectedSchoolClass: SchoolClassDto | null
    modal: boolean
}

class DiagnosticEdit extends BaseClass<IProps, IState> {
    defaultDiagnostic: DiagnosticDto = {
        id: '',
        createdDateUtcEpochTicks: 0,
        createdDate: '',
        finishedDate: '',
        name: '',
        startedDate: '',
    }

    constructor(props: IProps) {
        super(props)
        this.state = {
            isDataLoading: true,
            updating: false,
            error: '',
            visible: false,
            selectedSchoolClass: null,
            diagnostic: this.defaultDiagnostic,
            modal: false,
        }
    }

    async componentDidMount() {
        this.loadData()
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.sessionData.selectedSchoolClass !== prevProps.sessionData.selectedSchoolClass
        ) {
            // this.loadData();
            ;(this.props as any).history.push('/assessment')
        }
    }

    async loadData() {
        this.setState({ isDataLoading: true })
        this.props.setIsDataLoading(true)
        let selectedClass = this.props.sessionData.selectedSchoolClass
        if (selectedClass) {
            this.setState({
                selectedSchoolClass: selectedClass,
            })

            await this.getDiagnostic(selectedClass.id, this.props.match.params.diagnosticId)
        }
        this.props.setIsDataLoading(false)
    }

    async getDiagnostic(schoolId: string, diagnosticId: string) {
        this.props.setIsDataLoading(true)

        let token = await this.props.authState.accessToken.accessToken

        return getDiagnosticApi(schoolId, diagnosticId, token)
            .then((res) => {
                this.props.setIsDataLoading(false)
                if (res.ok) {
                    this.setState({
                        diagnostic: res.data,
                    })
                } else {
                    this.handleknownError(res.error)
                }
            })
            .catch((error) => {
                this.handleUnknownError(error)
            })
    }

    handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            diagnostic: {
                ...this.state.diagnostic,
                [e.target.id]: e.target.value,
            },
        })
    }

    toggle = () => {
        this.setState((prevState) => ({
            modal: !prevState.modal,
        }))
    }

    onDismiss = () => {
        this.setState({ visible: false })
    }

    onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (!this.state.diagnostic || !this.state.selectedSchoolClass) return

        let token = await this.props.authState.accessToken.accessToken

        this.props.setIsDataLoading(true)
        const updateDiagnosticDto: DiagnosticUpdateDto = {
            id: this.state.diagnostic.id,
            name: this.state.diagnostic.name,
        }

        if (this.state.selectedSchoolClass) {
            return updateDiagnosticNameApi(
                this.state.selectedSchoolClass.id,
                updateDiagnosticDto,
                token
            )
                .then((res) => {
                    this.props.setIsDataLoading(false)
                    if (res.ok) {
                        this.goBack()
                    } else {
                        this.handleknownError(res.error)
                    }
                })
                .catch((error) => {
                    this.handleUnknownError(error)
                })
        }
    }

    goBack() {(this.props.history as any).goBack()}

    render() {
        // if (this.props.isDataLoading) return null;

        const { error } = this.state

        return (
            <div>
                <div className="mb-2">
                    <BreadCrumbs
                        items={[
                            { title: 'Home', link: '/' },
                            { title: 'Assessments', link: '/assessment' },
                            { title: 'Edit Assessment', link: '' },
                        ]}
                    />
                </div>
                <h2 className="pb-2">Edit Assessment</h2>
                {this.state.visible ? (
                    <Alert color="warning">
                        <p>{error}</p>
                    </Alert>
                ) : null}
                <div className="row">
                    <div className="col-md-4">
                        <form onSubmit={this.onSubmit}>
                            <div className="form-group">
                                <label htmlFor="name">Name: </label>
                                <input
                                    type="text"
                                    id="name"
                                    className="form-control"
                                    value={this.state.diagnostic.name}
                                    onChange={this.handleOnChange}
                                />
                            </div>
                            <div className="form-group">
                                <div className="space-between">
                                    <Button
                                        color="secondary"
                                        tag={Link}
                                        to="/assessment"
                                        disabled={this.state.updating}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        color="primary"
                                        type="submit"
                                        disabled={this.state.updating}
                                    >
                                        {this.state.updating ? (
                                            <i className="fa fa-spinner fa-spin" />
                                        ) : null}{' '}
                                        Update
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
export default withOktaAuth(DiagnosticEdit)
