import React, { Component, useRef, useEffect } from 'react'
import OktaSignIn from '@okta/okta-signin-widget'
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'
import '@okta/okta-auth-js/polyfill'
import './Auth.css'

// var config = {
//   baseUrl: 'https://{yourOktaDomain}',
//   logo: '/path/to/logo.png',
//   logoText: 'logo text',
//   helpSupportNumber: '(123) 456-7890',
//   language: 'en',
//   i18n: {
//     en: {
//       'primaryauth.title': 'Sign in to Acme'
//     }
//   },
//   helpLinks: {
//     help: 'https://acme.com/help'
//   },
//   authParams: {
//     // Configuration for authClient. See https://github.com/okta/okta-auth-js#configuration-options
//   }
// };

// interface IProps {
//   baseUrl:string,
//   clientId:any,
//   redirectUri:any,
//   onSuccess:any,
//   onError:any,
// }

const OktaSignInWidget = ({props, config, onSuccess, onError }) => {
    const widgetRef = useRef()
    useEffect(() => {
        if (!widgetRef.current) return false

        const widget = new OktaSignIn(config)

        widget
            .showSignInToGetTokens({
                el: widgetRef.current,
            })
            .then(onSuccess)
            .catch(onError)

        // props.setIsDataLoading(false);


        return () => widget.remove()
    }, [config, onSuccess, onError])

    return <div ref={widgetRef} />
}
export default OktaSignInWidget

//     this.widget = new OktaSignIn(
//       {
//         logo,
//         language: 'en',
//         i18n: {
//           en: {
//             //OKTA ERROR OVERRIDES - https://github.com/okta/okta-signin-widget/blob/master/packages/@okta/i18n/src/properties/login.properties
//             'primaryauth.username.placeholder': 'Email',
//             //  'primaryauth.username.tooltip': 'Enter your email',
//             // 'primaryauth.password.placeholder': 'Password',
//             // 'primaryauth.password.tooltip': 'Enter your password',
//             'error.username.required': 'Please enter an email',
//             'error.password.required': 'Please enter a password',
//             'errors.E0000004': 'Cannot login - check your email and password',
//             'oform.error.unexpected': 'There was a problem logging in. Please contact Early-Insights support@mathspathway.com.',
//             'oform.errorbanner.title': 'Please fill out all the fields',
//             'help': 'Other support - primary@mathspathway.com',
//             'needhelp': 'Need help?',
//             'forgotpassword': 'Click here to reset password',
//             'error.network.connection': 'There was a problem logging in. Please contact Early-Insights support@mathspathway.com.',
//           },
//         },
//         helpLinks: {
//           help: 'mailto:primary@mathspathway.com',
//         },

//         baseUrl: this.props.baseUrl,
//         clientId: this.props.clientId,
//         redirectUri: this.props.redirectUri,
//         authParams: {
//           responseType: 'id_token token',
//           pkce: true,
//         },
//       })

//     this.widget.renderEl({ el: '#okta-login-container' }, this.props.onSuccess, this.props.onError)
//   }

//   componentWillUnmount () {
//     this.widget.remove()
//   }

//   render () {
//     return <div id="okta-login-container" className="login" />
//   }
// }
