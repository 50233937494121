import React, { useState, useEffect } from 'react'
import { KeyConceptDto } from '../../models/KeyConcept'
import { getKeyConceptsFromApi } from '../../services/CurriculumApi'
import MySelect from '../diagnostic/MySelect'
import { useOktaAuth } from '@okta/okta-react'
import { ISelect } from './ISelect'

export function KeyConceptPicker(props) {
    const [selectedKeyConceptOptions, setSelectedKeyConceptOptions] = useState([])
    const [keyConceptOptions, setKeyConceptOptions] = useState([])
    // const [keyConcepts, setKeyConcepts] = useState([]);
    const { authState } = useOktaAuth()

    useEffect(() => {
        getKeyConcepts()
    }, [])

    useEffect(() => {
        if (props.selectedKeyConceptIds && keyConceptOptions.length > 0) {
            let kcs = []
            props.selectedKeyConceptIds.forEach((id: any) => {
                const index = keyConceptOptions.findIndex((v) => v.value === id)
                if (index !== -1) kcs.push(keyConceptOptions[index])
            })
            setSelectedKeyConceptOptions(kcs)
        }
    }, [props.selectedKeyConceptIds, keyConceptOptions])

    useEffect(() => {}, [props.enabled])

    const handleChange = (selectedKeyConcepts: ISelect[]) => {
        let kcs = []
        if (selectedKeyConcepts && selectedKeyConcepts.length > 0) {
            const sortedKeyConcepts = selectedKeyConcepts.sort((a, b) => {
                return a.order - b.order
            })
            kcs = sortedKeyConcepts
        } else {
            kcs = []
        }

        setSelectedKeyConceptOptions(kcs)
        props.onSelectedKeyConceptIdsChange(kcs.map((v) => v.value))
    }

    const getKeyConcepts = async () => {
        // let token = await authState.accessToken.accessToken;
        // return getKeyConceptsFromApi(token)
        //  .then((res) => {
        //   if (res.ok) {
        //  setKeyConcepts(res.data)
        setKeyConceptOptions(
            props.keyConcepts.map((keyConcept: KeyConceptDto) => {
                return {
                    value: keyConcept.id,
                    label: keyConcept.name,
                    order: keyConcept.order,
                }
            })
        )
        //  }
        // })
        //  .catch((error) => {
        //   console.log(error);
        // });
    }

    return (
        <MySelect
            id="masterSelect"
            isDisabled={props.isDisabled}
            isMulti
            allowSelectAll={true}
            value={selectedKeyConceptOptions}
            onChange={handleChange}
            options={keyConceptOptions}
        />
    )
}
