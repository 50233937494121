import axios from 'axios'
import { handleApiSuccess, handleApiError, IApiResult } from '../shared/ApiHandler'
import { schoolApiUrl, teacherBaseApiUrl } from '../shared/ApiUrls'

export const getSchoolsFromApi = (token: string): Promise<IApiResult> => {
    return axios
        .get(`${schoolApiUrl}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const getSchoolFromApi = (schoolId: string, token: string): Promise<IApiResult> => {
    return axios
        .get(`${schoolApiUrl}/${schoolId}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}
