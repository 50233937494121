import { RouteComponentProps } from 'react-router-dom'
import { StudentDiagnosticResultDto } from '../../models/Diagnostic'
import {
    getAnsweredStudentDiagnosticQuestionsApi,
    overrideAnswerApi,
    sendFeedbackApi,
} from '../../services/DiagnosticApi'
import { Button, Alert } from 'reactstrap'
import { withOktaAuth } from '@okta/okta-react'
import { AuthService, AuthState } from '../../models/Auth'
import BaseClass from '../Bases/BaseClass'
import BreadCrumbs from '../layout/BreadCrumbs'
import { withSnackbar } from 'notistack'
import QuestionResultsTable, { IQuestionResult } from './QuestionResultsTable'

interface IParams {
    diagnosticId: string
    studentId: string
    parentPage: string
}

interface IProps extends RouteComponentProps<IParams> {
    authService: AuthService
    authState: AuthState
    sessionData: any
    setIsDataLoading: any
    isDataLoading: boolean
    match: any
}

interface IState {
    error: string
    errorVisible: boolean
    data: StudentDiagnosticResultDto
    showOverrideSequenceQuestionModal: boolean
    refreshLabel: string
    overrideErrorMessage: string
}

class StudentDiagnosticResult extends BaseClass<IProps, IState> {
    defaultStudentDiagnosticResultDto: StudentDiagnosticResultDto = {
        studentDiagnosticDto: {
            studentId: '',
            firstName: '',
            lastName: '',
            username: '',
            password: '',
            diagnosticId: '',
            diagnosticName: '',
            startedDate: '',
            startedOnlineTest: false,
            finishedDate: '',
            hasInterviewQuestion: false,
            hasAnyQuestion: false,
            keyConceptIds: [],
            samplingAssessmentIds: [],
            timeFormatted: '',
            showLogInDetails: false,
            isStudentLicenced: false,
            schoolClassFormattedName: '',
            note: null,
            stoppedByTeacherId: null,
        },
        questionsDtos: [],
    }

    constructor(props: IProps) {
        super(props)
        this.state = {
            error: '',
            errorVisible: false,
            data: this.defaultStudentDiagnosticResultDto,
            refreshLabel: '',
            showOverrideSequenceQuestionModal: false,
            overrideErrorMessage: '',
        }
    }

    autoRefresh: any

    async componentDidMount() {
        this.loadData()
        // this.autoRefresh = setInterval(() => {
        //   this.loadData();
        // }, 60000);
    }

    componentWillUnmount() {
        this.autoRefresh && clearInterval(this.autoRefresh)
    }

    async loadData() {
        this.props.setIsDataLoading(true)
        if (this.props.match.params.diagnosticId && this.props.match.params.studentId) {
            let token = this.props.authState.accessToken.accessToken

            try {
                const res = await getAnsweredStudentDiagnosticQuestionsApi(
                    this.props.match.params.diagnosticId,
                    this.props.match.params.studentId,
                    token
                )

                if (res.ok) {
                    this.props.setIsDataLoading(false)
                    this.setState({
                        data: res.data,
                        refreshLabel: `Last refresh - ${new Date().toLocaleTimeString()}`,
                    })
                } else {
                    this.handleknownError(res.error)
                }
            } catch (error) {
                this.handleUnknownError(error)
            }
        }

        this.props.setIsDataLoading(false)
    }

    async overrideAnswer(questionId) {
        return new Promise<void>(async (resolve, reject) => {
            this.props.setIsDataLoading(true)
            let token = await this.props.authState.accessToken.accessToken
            return overrideAnswerApi(token, {
                studentId: this.state.data.studentDiagnosticDto.studentId,
                diagnosticId: this.state.data.studentDiagnosticDto.diagnosticId,
                questionId,
            })
                .then((res) => {
                    this.props.setIsDataLoading(false)
                    if (res.ok) {
                        // this.toggleOverrideAnswerModal();
                    } else {
                        // this.toggleOverrideAnswerModal();
                        this.setState({ overrideErrorMessage: res.error.detail })
                        this.toggleOverrideSequenceQuestionModal()
                    }

                    this.loadData()
                    return resolve()
                })
                .catch((error) => {
                    //   this.toggleOverrideAnswerModal();
                    this.handleUnknownError(error)
                    return reject()
                })
        })
    }

    onshowError(error) {
        this.handleUnknownError(error)
    }

    render() {
        return (
            <div>
                <div className="mb-2">
                    {this.props.match.params.parentPage === 'assessment-details' ? (
                        <BreadCrumbs
                            items={[
                                { title: 'Home', link: '/' },
                                { title: 'Assessments', link: '/assessment' },
                                {
                                    title: 'Assessment Details',
                                    link: `/assessment/details/${this.props.match.params.diagnosticId}`,
                                },
                                { title: 'Student Digital Results', link: '' },
                            ]}
                        />
                    ) : (
                        <BreadCrumbs
                            items={[
                                { title: 'Home', link: '/' },
                                { title: 'Student', link: '/student' },
                                {
                                    title: 'Student Profile',
                                    link: `/student/profile/${this.props.match.params.studentId}`,
                                },
                                { title: 'Student Digital Results', link: '' },
                            ]}
                        />
                    )}
                </div>

                {this.state.errorVisible ? (
                    <Alert color="warning">
                        <p>{this.state.error}</p>
                    </Alert>
                ) : null}

                <h2 className="pb-2">
                    <div className="space-between">
                        {this.state.data.studentDiagnosticDto.firstName}{' '}
                        {this.state.data.studentDiagnosticDto.lastName}
                        <div></div>
                        <div>
                            <Button color="link" onClick={() => this.loadData()}>
                                {this.state.refreshLabel}
                            </Button>
                        </div>
                    </div>
                </h2>

                <QuestionResultsTable
                    {...this.props}
                    allowOverride={true}
                    questionResults={this.convertToQuestionResults(this.state.data)}
                    onOverride={(e) => this.overrideAnswer(e)}
                    onFeedback={(id, num) => this.sendFeedback(id, num)}
                    onError={(e) => this.onshowError(e)}
                    showOverrideSequence = {this.state.showOverrideSequenceQuestionModal}
                />
            </div>
        )
    }

    convertToQuestionResults(data: StudentDiagnosticResultDto): IQuestionResult[] {
        let converted = []
        data.questionsDtos.forEach((element) => {
            converted.push({
                audioAnswerUri: element.audioAnswerUri,
                screenShotUri: element.screenShotUri,
                questionAnswers: element.question.questionAnswers,
                questionText: element.question.questionText,
                questionAudioText: element.question.questionAudioText,
                questionTemplate: element.question.questionTemplate,
                questionId: element.questionId,
                teacherOverride: element.teacherOverride,
                correct: element.correct,
                studentAnswerDescription: element.studentAnswerDescription,
            })
        })

        return converted
    }

    async sendFeedback(id, num) {
        let teacher = this.props.sessionData.authenticatedTeacher
        if (!teacher) return

        let feedbackText = (document.getElementById('feedbackText') as HTMLInputElement).value

        this.props.setIsDataLoading(true)
        await sendFeedbackApi(
            feedbackText,
            this.props.match.params.diagnosticId,
            this.props.match.params.studentId,
            id,
            num,
            teacher.id,
            this.props.authState.accessToken.accessToken
        )

        this.props.setIsDataLoading(false)
        ;['Feedback sent!'].forEach((v) => {
            ;(this.props as any).enqueueSnackbar(v, {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                autoHideDuration: 2000,
            })
        })
    }

    toggleOverrideSequenceQuestionModal = () => {
        this.setState({
            showOverrideSequenceQuestionModal: !this.state.showOverrideSequenceQuestionModal,
        })
    }
}
export default withSnackbar(withOktaAuth(StudentDiagnosticResult))
