import React from 'react'
import PropTypes from 'prop-types'
import { default as ReactSelect } from 'react-select'

const MySelect = (props) => {
    if (props.allowSelectAll) {
        return (
            <ReactSelect
                // isDisabled={props.isDisabled}
                style={{ zIndex: -1 }}
                {...props}
                options={[props.allOption, ...props.options]}
                onChange={(selected, metadata) => {
                    if (
                        selected !== null &&
                        selected.length > 0 &&
                        selected[selected.length - 1].value === props.allOption.value
                    ) {
                        return props.onChange(props.options, metadata)
                    }

                    return props.onChange(selected, metadata)
                }}
            />
        )
    }

    return <ReactSelect {...props} />
}

MySelect.propTypes = {
    id: PropTypes.string,
    isMulti: PropTypes.bool,
    options: PropTypes.array,
    value: PropTypes.any,
    components: PropTypes.object,
    onChange: PropTypes.func,
    allowSelectAll: PropTypes.bool,
    allOption: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    }),
    isDisabled: PropTypes.bool,
}

MySelect.defaultProps = {
    allOption: {
        label: 'Select all',
        value: '*',
    },
}

export default MySelect
