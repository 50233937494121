import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { withOktaAuth } from '@okta/okta-react'
import diagnosticLogo from '../../assets/images/diagnostics.png'
import feedbackLogo from '../../assets/images/feedback.png'
import testsLogo from '../../assets/images/tests.png'
import { Row, Col, Card, CardImg, CardText, CardBody, CardTitle, Button } from 'reactstrap'
import { SchoolClassDto } from '../../models/SchoolClass'
import { getDiagnosticsApi } from '../../services/DiagnosticApi'
import './Home.css'
import logo from '../../assets/images/EarlyInsights_CMYK.svg'
import { AuthService, AuthState } from '../../models/Auth'
import '../../../node_modules/react-modal-video/css/modal-video.min.css'
import ModalVideo from 'react-modal-video'
import BaseClass from '../Bases/BaseClass'
import { isTeacherAdminOrSuper, logout } from '../../shared/Helper'
import { withSnackbar } from 'notistack'
import { ISessionData } from '../../AppRouter'

interface IProps extends RouteComponentProps<any> {
    //authService: AuthService;
    authState: AuthState
    oktaAuth: any
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
    history: any
    match: any
}

interface IState {
    error: string
    showError: boolean
    selectedSchoolClass: SchoolClassDto | null
    showVideo: boolean
    showPdf: boolean
    test: string
    // isLoading: boolean;
    // isDataLoading: boolean;
}

class Home extends BaseClass<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            //isDataLoading: true,
            showPdf: false,
            // isLoading: false,
            error: '',
            showError: false,
            selectedSchoolClass: null,
            showVideo: false,
            test: 'blank',
        }
    }

    componentDidMount() {
        this.props.sessionData && this.loadData()
    }

    componentDidUpdate(prevProps) {
        this.props.setIsDataLoading(false)
    }

    loadData() {
        this.props.setIsDataLoading(true)
        if (this.props.sessionData.authenticatedTeacher.isFirstLogon) {
            this.setState({ showVideo: true })
        }
        this.props.setIsDataLoading(false)
    }

    openDiagnostic = async () => {
        const { authenticatedTeacher, selectedSchoolClass } = this.props.sessionData

        if (selectedSchoolClass) {
            await this.getDiagnostics(selectedSchoolClass.id)
        } else {
            if (isTeacherAdminOrSuper(authenticatedTeacher.accessLevel)) {
                this.props.history.push(`/admin/class/${authenticatedTeacher.schoolId}`)
            } else {
                this.props.history.push('/no-data-yet')
            }
        }
    }

    getDiagnostics = async (schoolClassId: string) => {
        let token = await this.props.authState.accessToken.accessToken
        return getDiagnosticsApi(schoolClassId, token)
            .then((res) => {
                if (res.ok) {
                    if (res.data.length > 0) {
                        this.props.history.push(`/assessment/details/${res.data[0].id}`)
                    }
                } else {
                    this.handleknownError(res.error)
                }
            })
            .catch((error) => {
                this.handleUnknownError(error)
            })
    }

    render() {
        // if (this.props.isDataLoading) return null;

        return (
            <div data-cy="home-page-loaded">
                <Row className="d-flex justify-content-center">
                    <div className="m-3 text-center">
                        <img className="logo" src={logo} alt="logo"></img>
                        <p className="pt-1" style={{ fontWeight: 400 }}>
                            Foundation - Year 4.
                        </p>
                        {this.props.sessionData.authenticatedTeacher.isDemo ? (
                            <div className="row text-left">
                                <div className="col-1"></div>
                                <div
                                    className="col-10 pt-2"
                                    style={{ backgroundColor: '#1960a5', color: 'white' }}
                                >
                                    <p className="font-weight-normal">
                                        Welcome to your Trial Account. Take 10 minutes to watch{' '}
                                    </p>
                                    <a
                                        href=""
                                        onClick={(e) => {
                                            e.preventDefault()
                                            this.setState({ showVideo: true })
                                        }}
                                    >
                                        <strong
                                            style={{ backgroundColor: '#1960a5', color: 'white' }}
                                        >
                                            {' '}
                                            this short video
                                        </strong>
                                    </a>{' '}
                                    to find how easy it is to get started, then check out:
                                    <ul>
                                        <li>See the data</li>
                                        <li>Log in as a student</li>
                                        <li>Create an assessment</li>
                                    </ul>
                                </div>
                                <div className="col-1"></div>
                            </div>
                        ) : (
                            <div>
                                <p className="font-weight-medium mb-0">
                                    Welcome! Before you get started, we recommend you watch{' '}
                                    <a
                                        style={{ fontWeight: 600 }}
                                        href=""
                                        onClick={(e) => {
                                            e.preventDefault()
                                            this.setState({ showVideo: true })
                                        }}
                                    >
                                        this short tutorial video
                                    </a>
                                    .
                                </p>
                                <p className="font-weight-medium">
                                    You can also access the implementation{' '}
                                    <a
                                        style={{ fontWeight: 600 }}
                                        rel="noopener noreferrer"
                                        href="https://tridentazurestorage.blob.core.windows.net/files/Assessment%20Tool%20Implementation%20Guide.pdf"
                                        target="_blank"
                                    >
                                        guide here
                                    </a>{' '}
                                    for some more information.
                                </p>
                                <div className="pt-1">
                                    <h6>
                                        Student App Link -{' '}
                                        <a
                                            href="https://gromo.me"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            https://gromo.me
                                        </a>
                                    </h6>
                                </div>
                            </div>
                        )}
                    </div>
                </Row>
                <Row>
                    <Col sm="4">
                        <Card className="text-center">
                            <Link to={'/assessment'}>
                                <CardImg
                                    top
                                    className="dashboard-image"
                                    width="350px"
                                    height="350px"
                                    src={diagnosticLogo}
                                    alt="Card image cap"
                                    style={{ backgroundColor: '#ed6b09', borderColor: '#333' }}
                                />
                            </Link>
                            <CardBody className="card-body-container">
                                <CardTitle>
                                    <h2 style={{ color: '#ed6b09' }}>Assessments</h2>
                                </CardTitle>
                                <div className="d-flex flex-column">
                                    <CardText style={{ minHeight: '80px' }}>
                                        Collect student data and see a record of previous
                                        assessments.
                                    </CardText>
                                    <Button color="primary" tag={Link} to={'/assessment'}>
                                        Set an assessment
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="4">
                        <Card className="text-center">
                            <CardImg
                                top
                                className="dashboard-image"
                                width="350px"
                                height="350px"
                                src={feedbackLogo}
                                alt="Card image cap"
                                style={{ backgroundColor: '#009fe3', borderColor: '#333' }}
                                onClick={this.openDiagnostic}
                            />
                            <CardBody className="card-body-container">
                                <CardTitle>
                                    <h2 style={{ color: '#009fe3' }}>Interviews</h2>
                                </CardTitle>
                                <div className="d-flex flex-column">
                                    <CardText style={{ minHeight: '80px' }}>
                                        Conduct a short interview with each student before they can
                                        start the digital questions.
                                    </CardText>
                                    <Button color="primary" onClick={this.openDiagnostic}>
                                        Current Assessment
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="4">
                        <Card className="text-center">
                            <Link to={'/group'}>
                                <CardImg
                                    top
                                    className="dashboard-image"
                                    width="350px"
                                    height="350px"
                                    src={testsLogo}
                                    alt="Card image cap"
                                    style={{ backgroundColor: '#63ccca', borderColor: '#333' }}
                                />
                            </Link>
                            <CardBody className="card-body-container">
                                <CardTitle>
                                    <h2 style={{ color: '#63ccca' }}>Groups</h2>
                                </CardTitle>
                                <div className="d-flex flex-column">
                                    <CardText style={{ minHeight: '80px' }}>
                                        Use student data to create groups for targeted teaching.
                                    </CardText>
                                    <Button color="primary" tag={Link} to={'/group'}>
                                        Create and view groups
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <div className="m-4 text-center">
                        <div style={{ fontWeight: 700 }}>
                            Having a problem or got a great idea? We’d love to hear from you!
                        </div>
                        <div className="p-2 text-center" style={{ fontWeight: 700 }}>
                            <a href="mailto:primary@mathspathway.com?Subject=Hello" target="_top">
                                primary@mathspathway.com
                            </a>
                        </div>
                        <p>
                            Maybe you want to see your data in a different way or your students are
                            struggling to find a button, we want to know everything! So if something
                            isn’t working or you want to give us some feedback, send us an email and
                            we’ll get back to you, ASAP!
                        </p>
                    </div>
                </Row>
                <div>
                    <ModalVideo
                        url={"https://tridentazurestorage.blob.core.windows.net/videos/Teacher%20Tutorial%20v3%20-%20July%202020.mp4"}
                        channel="custom"
                        isOpen={this.state.showVideo}
                        onClose={() => this.setState({ showVideo: false })}
                    />
                </div>
            </div>
        )
    }
}

export default withSnackbar(withOktaAuth(Home))
