/* eslint-disable no-unused-vars */
export interface LicenceDto {
    id: string
    schoolName: string
    teacherFirstName: string
    teacherLastName: string
    teacherEmail: string
    purchasedDate: string
    startDateUtc: string
    numOfLicenses: number
    licenseExpiry: string
    salesForceSchoolId: string
    salesForceContractNumber: string

    activeStudents: number
    archivedStudents: number
    availableStudents: number
}

export interface SummaryDto {
    schoolId: string
    totalActiveStudents: number
    totalLicences: number
    availableLicences: number
}

export enum SalesForceActionEnum {
    Demo_assessment_page_viewed__c,
    Demo_buy_now_clicked__c,
    Demo_data_page_viewed__c,
    Demo_extend_demo_requested__c,
    Demo_has_logged_in__c,
    Demo_try_as_student_clicked__c,
}
