import { getOktaClientId } from '../../app.config'

const oktaAuthConfig = {
    // Note: If your app is configured to use the Implicit flow
    // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
    // you will need to add `pkce: false`
    issuer: 'https://dev-537791.okta.com/oauth2/default',
    clientId: getOktaClientId(),
    redirectUri: window.location.origin + '/login/callback',
    // pkce: true,
}

import logo from '../../assets/images/EarlyInsights_CMYK.svg'

//url: 'https://dev-537791.okta.com',
//issuer: 'https://dev-537791.okta.com/oauth2/default',
//redirect_uri: `${window.location.origin.toLowerCase()}/implicit/callback`,
//client_id: getOktaClientId(),

const oktaSignInConfig = {
    baseUrl: 'https://dev-537791.okta.com',
    clientId: getOktaClientId(),
    redirectUri: window.location.origin + '/login/callback',
    authParams: {
        // If your app is configured to use the Implicit flow
        // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
        // you will need to uncomment the below line
        // pkce: false
        responseType: 'id_token token',
        pkce: true,
    },
    logo,
    language: 'en',
    i18n: {
        en: {
            //OKTA ERROR OVERRIDES - https://github.com/okta/okta-signin-widget/blob/master/packages/@okta/i18n/src/properties/login.properties
            'primaryauth.username.placeholder': 'Email',
            'error.username.required': 'Please enter an email',
            'error.password.required': 'Please enter a password',
            'errors.E0000004': 'Cannot login - check your email and password',
            'oform.error.unexpected':
                'There was a problem logging in. Please contact Early-Insights support@mathspathway.com.',
            'oform.errorbanner.title': 'Please fill out all the fields',
            help: 'Other support - primary@mathspathway.com',
            needhelp: 'Need help?',
            forgotpassword: 'Click here to reset password',
            'error.network.connection':
                'There was a problem logging in. Please contact Early-Insights support@mathspathway.com.',
        },
    },
    helpLinks: {
        help: 'mailto:primary@mathspathway.com',
    },

    //   baseUrl: this.props.baseUrl,
    //   clientId: this.props.clientId,
    //   redirectUri: this.props.redirectUri,
    //   authParams: {
    //     responseType: "id_token token",
    //     pkce: true,
    //   },
    // Additional documentation on config options can be found at https://github.com/okta/okta-signin-widget#basic-config-options
}

export { oktaAuthConfig, oktaSignInConfig }
