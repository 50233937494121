/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import { KeyConceptDto } from '../../models/KeyConcept'
import { StudentStatementReportDto } from '../../models/Statement'
import { GroupTableByStudentReportDto, StudentGroupTable, ShortGroupDto } from '../../models/Group'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { archivedClassGuid, demoClassGuid } from '../../shared/Helper'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import './Group.css'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup, Label } from 'reactstrap'
import { SchoolClassDto } from '../../models/SchoolClass'

interface IProps {
    allKeyConcepts: KeyConceptDto[]
    filteredKeyConcepts: KeyConceptDto[]
    groupTableByStudent: GroupTableByStudentReportDto
    editableGroup: boolean
    groups: ShortGroupDto[]
    onDelete: (students: StudentGroupTable[], groupId: string) => void
    onAssign: (students: StudentGroupTable[], groupId: string) => void
    onDeleteGroup: (groupTableByStudent: GroupTableByStudentReportDto) => void
    onEditGroup: (groupTableByStudent: GroupTableByStudentReportDto, newGroupName: string) => void
    selectedSchoolClass: SchoolClassDto | null
    clearSelection: number
    // history: any
}

interface IState {
    selectedStudents: StudentGroupTable[]
    selectedGroupId: string
    modalStudent: boolean
    modalGroup: boolean
    isEditMode: boolean
    groupName: string
    // sortName:string;
}

//class GroupTable extends React.Component<IProps, IState> {
class GroupTable extends Component<IProps, IState> {
    tableRef

    constructor(props: IProps) {
        super(props)
        this.state = {
            selectedStudents: [],
            selectedGroupId: props.groups[0].id,
            modalStudent: false,
            modalGroup: false,
            isEditMode: false,
            groupName: props.groupTableByStudent.name,
            //  sortName:null,
        }

        this.tableRef = React.createRef()
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true
    }

    UNSAFE_componentWillUpdate(nextProps) {
        this.beforeUpdate(nextProps)
    }

    beforeUpdate(nextProps) {
        if (this.props.clearSelection !== nextProps.clearSelection) {
            this.handleSelectAll(false, [])
            this.tableRef.current.cleanSelected()
        }
    }

    // getSnapshotBeforeUpdate(nextProps, nextState) {

    // }

    componentDidMount() {}

    getNumberOfStatements(keyConcept: KeyConceptDto) {
        let statementNumber = 0

        for (let i = 0; i < keyConcept.components.length; ++i) {
            statementNumber += keyConcept.components[i].statements.length
        }

        return statementNumber
    }

    getWidthForKeyConcept(keyConcept: KeyConceptDto): string {
        let number = this.getNumberOfStatements(keyConcept)
        let widthOfDiv = number * 60

        return `${widthOfDiv}px`
    }

    handleRowSelect = (row: StudentGroupTable, isSelected: boolean, e: any) => {
        let array = [...this.state.selectedStudents]

        if (isSelected) {
            const newArray = [...this.state.selectedStudents, row]

            this.setState({
                selectedStudents: newArray,
            })
        } else {
            let index = array.indexOf(row)

            if (index !== -1) {
                array.splice(index, 1)
                this.setState({ selectedStudents: array })
            }
        }
    }

    handleSelectAll = (isSelected: boolean, rows: StudentGroupTable[]) => {
        if (isSelected) {
            const allStudents = rows

            this.setState({
                selectedStudents: allStudents,
            })
        } else {
            this.setState({
                selectedStudents: [],
            })
        }
    }

    assignStudents = () => {
        this.props.onAssign(this.state.selectedStudents, this.state.selectedGroupId)

        this.setState({
            selectedStudents: [],
        })

        this.toggleStudentModal()
    }

    deleteStudents = () => {
        this.props.onDelete(this.state.selectedStudents, this.props.groupTableByStudent.id)

        this.setState({
            selectedStudents: [],
        })

        this.toggleStudentModal()
    }

    editGroup = () => {
        this.setState({
            isEditMode: true,
        })
    }

    updateGroup = () => {
        if (this.state.groupName.length > 0) {
            this.props.onEditGroup(this.props.groupTableByStudent, this.state.groupName)
        }

        this.setState({
            isEditMode: false,
        })
    }

    cancelEdit = () => {
        this.setState({
            isEditMode: false,
            groupName: this.props.groupTableByStudent.name,
        })
    }

    deleteGroup = () => {
        this.props.onDeleteGroup(this.props.groupTableByStudent)
    }

    toggleStudentModal = () => {
        if (this.getCustomGroups().length > 0) {
            this.setState({ selectedGroupId: this.getCustomGroups()[0].id })
        }

        this.setState((prevState) => ({
            modalStudent: !prevState.modalStudent,
        }))
    }

    toggleGroupModal = () => {
        this.setState((prevState) => ({
            modalGroup: !prevState.modalGroup,
        }))
    }

    setGroupId = (groupId: string) => {
        if (groupId) {
            this.setState({
                selectedGroupId: groupId,
            })
        }
    }

    handleGroupNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            groupName: e.target.value,
        })
    }

    render() {
        const { isEditMode } = this.state
        const { allKeyConcepts, filteredKeyConcepts: keyConcepts, groupTableByStudent } = this.props
        const columnClassNameFormat = (
            fieldValue: any,
            row: any,
            rowIdx: number,
            colIdx: number
        ) => {
            if (colIdx === 0) {
                if (row.groupId && row.groupId !== archivedClassGuid) {
                    return 'sticky-left-header assigned-group'
                }

                return 'sticky-left-header unassigned-group'
            }

            return ''
        }

        const statementFormatter = (
            fieldValue: StudentStatementReportDto,
            row: any,
            rowIdx: number,
            colIdx: number
        ) => {
            if (fieldValue && fieldValue.answeredDate) {
                if (fieldValue.mastered) {
                    if (fieldValue.previouslyMastered) {
                        return <span className="dot-previously-mastered" />
                    }

                    return <span className="dot-mastered" />
                }

                return <span className="dot-not-mastered" />
            }

            return <span className="dot-not-asses-yet" />
        }

        const sortMastery = (a: any, b: any, order: string, field: any) => {
            let anumber = a[field].mastered ? 1 : 0
            let bnumber = b[field].mastered ? 1 : 0

            // console.log(a[field]);
            // console.log(b[field]);
            // console.log(field);
            if (order === 'desc') {
                return bnumber - anumber
            }

            return anumber - bnumber
        }

        const keyConceptHeaders = allKeyConcepts.map((keyConcept) => {
            return (
                <TableHeaderColumn
                    row="0"
                    colSpan={this.getNumberOfStatements(keyConcept)}
                    hidden={!this.props.filteredKeyConcepts.find((v) => v.id === keyConcept.id)}
                    key={keyConcept.id}
                    style={{
                        width: this.getWidthForKeyConcept(keyConcept),
                    }}
                >
                    {keyConcept.name}
                </TableHeaderColumn>
            )
        })

        const componentHeaders = allKeyConcepts.map((keyConcept) => {
            return keyConcept.components.map((component) => {
                return (
                    <TableHeaderColumn
                        row="1"
                        hidden={!this.props.filteredKeyConcepts.find((v) => v.id === keyConcept.id)}
                        colSpan={component.statements.length}
                        key={component.id}
                        columnTitle="test"
                    >
                        {component.name}
                    </TableHeaderColumn>
                )
            })
        })

        const statementHeaders = allKeyConcepts.map((keyConcept) => {
            return keyConcept.components.map((component) => {
                return component.statements.map((statement) => {
                    return (
                        <TableHeaderColumn
                            width={'50px'}
                            row="2"
                            key={statement.id}
                            dataField={statement.code.toLowerCase()}
                            dataAlign="center"
                            columnTitle={statement.name}
                            dataSort
                            sortFunc={sortMastery}
                            dataFormat={statementFormatter}
                            hidden={
                                !this.props.filteredKeyConcepts.find((v) => v.id === keyConcept.id)
                            }
                            // style={{color:'yellow'}}
                            thStyle={{ fontSize: '10px', padding: '0px' }}
                            padding={'0px'}
                        >
                            {statement.code.toUpperCase()}
                        </TableHeaderColumn>
                    )
                })
            })
        })

        const selectRowProp = {
            mode: 'checkbox',
            onSelect: this.handleRowSelect,
            onSelectAll: this.handleSelectAll,
            columnWidth: '40px',
        }

        const deleteStudentsButton = this.props.editableGroup ? (
            <div className="mt-2 mb-2">
                <Modal isOpen={this.state.modalStudent} toggle={this.toggleStudentModal}>
                    <ModalHeader toggle={this.toggleStudentModal}>
                        Delete Students from {this.props.groupTableByStudent.name} Group
                    </ModalHeader>
                    <ModalBody>
                        <h5>Remove the following students from this group</h5>
                        <div>
                            <ul>
                                {this.state.selectedStudents.map((student) => {
                                    return <li key={student.id}>{student.name}</li>
                                })}
                            </ul>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggleStudentModal}>
                            Cancel
                        </Button>
                        <Button color="danger" onClick={() => this.deleteStudents()}>
                            Remove
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        ) : (
            <div className="mt-2 mb-2">
                <Label className="label-bold-style">Select Students:</Label>

                <Modal isOpen={this.state.modalStudent} toggle={this.toggleStudentModal}>
                    <ModalHeader toggle={this.toggleStudentModal}>
                        Add students to a group
                    </ModalHeader>
                    <ModalBody>
                        <h5>Students selected:</h5>
                        <div className="pb-1">
                            <ul>
                                {this.state.selectedStudents.map((student) => {
                                    return <li key={student.id}>{student.name}</li>
                                })}
                            </ul>
                        </div>
                        <h5>Add these students to group:</h5>
                        <div>
                            {this.getCustomGroups().map((group) => {
                                // let firstItem = (this.getCustomGroups()[0] === group);
                                //  if (firstItem) {
                                //   return (
                                //     <div key={group.id} className="form-check pb-1">
                                //       <input
                                //         className="form-check-input"
                                //         type="radio"
                                //         name="groupRadios"
                                //         id="groupRadio"
                                //         onChange={() => this.setGroupId(group.id)}
                                //         checked={this.state.selectedGroupId === group.id}
                                //       />
                                //       <label className="form-check-label" htmlFor="groupRadio">
                                //         {group.name}
                                //       </label>
                                //     </div>)
                                // } else {
                                return (
                                    <div key={group.id} className="form-check pb-1 pl-4">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="groupRadios"
                                            id="groupRadio"
                                            checked={this.state.selectedGroupId === group.id}
                                            onChange={() => this.setGroupId(group.id)}
                                        />
                                        <label className="form-check-label" htmlFor="groupRadio">
                                            {group.name}
                                        </label>
                                    </div>
                                )
                                // }
                            })}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggleStudentModal}>
                            Cancel
                        </Button>
                        <Button color="primary" onClick={() => this.assignStudents()}>
                            Save
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        )

        const editButtons = (
            <div>
                {isEditMode ? (
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control"
                            value={this.state.groupName}
                            onChange={this.handleGroupNameChange}
                            aria-label="Group Name"
                            aria-describedby="basic-addon2"
                        />
                        <div className="input-group-append">
                            <Button color="primary" onClick={this.updateGroup}>
                                Update
                            </Button>
                            <Button color="danger" onClick={this.cancelEdit}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="d-flex justify-content-between">
                        <div className="mr-3">
                            <h3>{groupTableByStudent.name}</h3>
                        </div>
                        <ButtonGroup>
                            <Button color="primary" onClick={this.editGroup}>
                                Edit
                            </Button>
                            &nbsp;
                            <Button color="danger" onClick={this.toggleGroupModal}>
                                Delete
                            </Button>
                        </ButtonGroup>
                    </div>
                )}

                <div>
                    <Modal isOpen={this.state.modalGroup} toggle={this.toggleGroupModal}>
                        <ModalHeader toggle={this.toggleGroupModal}>
                            Delete {groupTableByStudent.name} Group
                        </ModalHeader>
                        <ModalBody>
                            <span>
                                All students from this group will return to the main class group.
                            </span>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.toggleGroupModal}>
                                Cancel
                            </Button>
                            <Button color="danger" onClick={() => this.deleteGroup()}>
                                Remove
                            </Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        )

        return (
            <div>
                {/* <DemoPopovers history={this.props.history} items={[{ target: 'add', placement: 'top' }]}></DemoPopovers> */}
                {this.props.editableGroup ? editButtons : null}
                {deleteStudentsButton}
                <div style={{ backgroundColor: 'white' }}>
                    <BootstrapTable
                        ref={this.tableRef}
                        // options={{sortName:this.state.sortName}}
                        style={{ backgroundColor: 'white' }}
                        data={groupTableByStudent.students}
                        selectRow={selectRowProp}
                        // tableContainerclassName=""
                    >
                        <TableHeaderColumn
                            width={'200px'}
                            row="0"
                            rowSpan={allKeyConcepts.length > 0 ? '3' : '1'}
                            dataField="name"
                            isKey
                            className="sticky-left-header bg-white"
                            style={{ backgroundColor: 'white', fontSize: '18px' }}
                            columnClassName={columnClassNameFormat}
                            thStyle={{ backgroundColor: 'rgb(250,250,250)', border: '10px' }}
                        >
                            <div style={{ backgroundColor: 'white' }} className="">
                                Student Name
                            </div>
                        </TableHeaderColumn>

                        {keyConceptHeaders}
                        {componentHeaders}
                        {statementHeaders}
                    </BootstrapTable>
                </div>

                <div className="mt-2">
                    {this.props.editableGroup ? (
                        <Button
                            color="danger"
                            onClick={this.toggleStudentModal}
                            disabled={this.state.selectedStudents.length === 0}
                        >
                            Remove from group
                        </Button>
                    ) : (
                        <Button
                            id="add"
                            color="success"
                            onClick={this.toggleStudentModal}
                            //  disabled={(this.props.selectedSchoolClass && this.props.selectedSchoolClass.id === demoClassGuid)}
                            disabled={
                                this.state.selectedStudents.length === 0 ||
                                this.getCustomGroups().length === 0 ||
                                (this.props.selectedSchoolClass &&
                                    this.props.selectedSchoolClass.id === demoClassGuid)
                            }
                        >
                            Add to group
                        </Button>
                    )}
                </div>
            </div>
        )
    }

    MASTER_STUDENT_CLASS = '00000000-0000-0000-0000-000000000000'

    getCustomGroups() {
        return this.props.groups.filter((v) => v.id !== this.MASTER_STUDENT_CLASS)
    }
}

export default GroupTable
