import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { withOktaAuth } from '@okta/okta-react'
import '../../../node_modules/react-modal-video/css/modal-video.min.css'

interface IProps extends RouteComponentProps<any> {}

interface IState {}

class NoDataYet extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
    }

    render() {
        return (
            <div className="text-center pt-4">
                <h2>No data found</h2>
                <div>
                    <p>
                        <br></br>
                        You have not been assigned any classes yet. Please speak to the person at
                        your school who manages your Early Insights accounts. They can create
                        classes and assign teachers in the Admin Portal.
                    </p>
                </div>
            </div>
        )
    }
}

export default NoDataYet
