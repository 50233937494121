import axios from 'axios'
import { handleApiSuccess, handleApiError, IApiResult } from '../shared/ApiHandler'
import { studentApiUrl, teacherBaseApiUrl } from '../shared/ApiUrls'
import { StudentCreateDto, StudentMoveDto, StudentSchoolClassLogType } from '../models/Student'

export const reinstateUnusedLicencesApi = (
    schoolId: string,
    token: string
): Promise<IApiResult> => {
    return axios
        .delete(`${studentApiUrl}/ReinstateUnusedLicences/${schoolId}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const getStudentsFromApi = (schoolClassId: string, token: string): Promise<IApiResult> => {
    return axios
        .get(`${studentApiUrl}/GetStudents/${schoolClassId}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const getArchivedStudentsFromApi = (token: string): Promise<IApiResult> => {
    return axios
        .get(`${studentApiUrl}/GetArchivedStudents`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const getStudentApi = (studentId: string, token: string): Promise<IApiResult> => {
    return axios
        .get(`${studentApiUrl}/GetStudent/${studentId}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const updateStudentApi = (
    studentId: string,
    updatedStudent: StudentCreateDto,
    token: string
): Promise<IApiResult> => {
    return axios
        .put(`${studentApiUrl}/${studentId}`, updatedStudent, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const activateStudentLicenceApi = (
    studentId: string,
    token: string
): Promise<IApiResult> => {
    return axios
        .post(`${studentApiUrl}/activateStudentLicence/${studentId}`, null, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const activateStudentLicencesByClassApi = (
    classId: string,
    token: string
): Promise<IApiResult> => {
    return axios
        .post(`${studentApiUrl}/activateStudentLicencesByClass/${classId}`, null, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const addStudent = (newStudent: StudentCreateDto, token: string): Promise<IApiResult> => {
    return axios
        .post(studentApiUrl, newStudent, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const deleteStudentFromApi = (
    studentId: string,
    schoolClassId: string,
    token: string
): Promise<IApiResult> => {
    return axios
        .delete(`${studentApiUrl}/${studentId}/${schoolClassId}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const moveStudentsApi = (
    studentSchoolClassLogType: StudentSchoolClassLogType,
    moveStudents: StudentMoveDto[],
    token: string
): Promise<IApiResult> => {
    return axios
        .put(`${studentApiUrl}/MoveStudents/${studentSchoolClassLogType}`, moveStudents, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const enableStudentFromApi = (studentId: string, token: string): Promise<IApiResult> => {
    return axios
        .get(`${studentApiUrl}/enable/${studentId}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const addBulkStudents = (
    //schoolId: string,
    schoolClassId: string,
    students: FormData,
    token: string
): Promise<IApiResult> => {
    let url = ''

    if (!schoolClassId || schoolClassId.trim() === '') {
        url = `${studentApiUrl}/AddBulk`
    } else {
        url = `${studentApiUrl}/AddBulk?schoolClassId=${schoolClassId}`
    }

    return axios
        .post(url, students, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}
