import React from 'react'
import { withOktaAuth } from '@okta/okta-react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { AuthService, AuthState } from '../../../models/Auth'
import { Button, Alert } from 'reactstrap'
import {
    archivedClassGuid,
    getCalendarYearLevels,
    getSchoolClassLevels,
} from '../../../shared/Helper'
import { addSchoolClass, getSchoolClassesFromApi } from '../../../services/SchoolClassApi'
import { SchoolClassCreateDto } from '../../../models/SchoolClass'
import Select from 'react-select'
import { TeacherDto } from '../../../models/Teacher'
import { getTeachers } from '../../../services/TeacherApi'
import BreadCrumbs from '../../layout/BreadCrumbs'
import DemoPopovers from '../../layout/DemoPopovers'
import BaseClass from '../../Bases/BaseClass'
import { IBaseState } from '../../Bases/BaseState'
import { ISessionData } from '../../../AppRouter'

interface IParams {
    schoolId: string
}

interface IProps extends RouteComponentProps<IParams> {
    authService: AuthService
    authState: AuthState
    //	reloadNavBarSchoolClass: any;
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
    onChangeSessionData: any
    history: any
}

interface IState extends IBaseState {
    name: string
    levels: {}[]
    selectedLevel: any
    teachers: TeacherDto[]
    selectedTeachers: any
    calendarYears: any
    selectedCalendarYear: any
    isDataLoading: boolean
}

export default withOktaAuth(
    class SchoolClassCreate extends BaseClass<IProps, IState> {
        constructor(props: IProps) {
            super(props)
            this.state = {
                isDataLoading: true,
                isLoading: false,
                error: '',
                visible: false,
                //authenticatedTeacher: null,
                name: '',
                levels: getSchoolClassLevels(),
                selectedLevel: null,
                teachers: [],
                selectedTeachers: null,
                calendarYears: getCalendarYearLevels(),
                selectedCalendarYear: getCalendarYearLevels()[0],
            }
        }

        componentDidMount() {
            this.loadData()
        }

        componentDidUpdate(prevProps, nextState) {
            if (this.props.sessionData !== prevProps.sessionData) {
                this.props.history.push('/')
            }
        }

        async loadData() {
            await this.getTeachers(this.props.sessionData.authenticatedTeacher.schoolId)

            this.props.setIsDataLoading(false)
        }

        handleChange = (selectedOption: any) => {
            this.setState({ selectedLevel: selectedOption })
        }

        handleCalendarYearChange = (selectedOption: any) => {
            this.setState({ selectedCalendarYear: selectedOption })
        }

        onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
            e.preventDefault()

            if (!this.state.selectedTeachers) {
                return this.showError('You must select a teacher.')
            }

            if (!this.state.selectedLevel) {
                return this.showError('You must select a level.')
            }

            if (!this.state.name || !this.state.selectedLevel) return

            let token = await this.props.authState.accessToken.accessToken
            let newClass: SchoolClassCreateDto = {
                name: this.state.name,
                levels:
                    this.state.selectedLevel && this.state.selectedLevel.map((a: any) => a.value),
                teachers:
                    this.state.selectedTeachers &&
                    this.state.selectedTeachers.map((a: any) => a.value),
                calendarYear: this.state.selectedCalendarYear.value,
            }

            this.props.setIsDataLoading(true)
            let teacher = this.props.sessionData.authenticatedTeacher

            return addSchoolClass(teacher.schoolId, newClass, token)
                .then(async (res) => {
                    if (res.ok) {
                        this.props.setIsDataLoading(false)
                        var result = await getSchoolClassesFromApi(
                            this.props.authState.accessToken.accessToken,
                            true
                        )
                        let updatedSessionData = {
                            ...this.props.sessionData,
                            schoolClasses: result.data,
                            selectedSchoolClass: !this.props.sessionData.selectedSchoolClass
                                ? result.data.filter((v) => v.id !== archivedClassGuid)[0]
                                : this.props.sessionData.selectedSchoolClass,
                        }

                        this.props.onChangeSessionData(updatedSessionData)

                        this.props.history.push(
                            `/admin/class/${this.props.sessionData.selectedSchool.id}`
                        )
                    } else {
                        this.handleknownError(res.error)
                    }
                })
                .catch((error) => {
                    this.handleUnknownError(error)
                })
        }

        async getTeachers(schoolId: string) {
            let token = await this.props.authState.accessToken.accessToken

            return getTeachers(schoolId, token)
                .then((res) => {
                    if (res.ok) {
                        this.setState({
                            teachers: res.data.map((data: TeacherDto) => {
                                return {
                                    value: data.id,
                                    label: `${data.firstname[0]}. ${data.lastname}`,
                                }
                            }),
                        })
                    } else {
                        this.handleknownError(res.error)
                    }
                })
                .catch((error) => {
                    this.handleUnknownError(error)
                })
        }

        handleTeacherChange = (selectedTeacher: any) => {
            this.setState({ selectedTeachers: selectedTeacher })
        }

        onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({
                name: e.target.value,
            })
        }

        render() {
            //  // if (this.props.isDataLoading) return null;

            return (
                <div>
                    {this.props.sessionData.authenticatedTeacher.isDemo && (
                        <DemoPopovers items={[{ target: 'create', placement: 'right' }]} />
                    )}

                    <div className="mb-2">
                        <BreadCrumbs
                            items={[
                                { title: 'Home', link: '/' },
                                {
                                    title: 'Admin',
                                    link: `/admin/select/${this.props.sessionData.selectedSchool.id}`,
                                },
                                {
                                    title: 'Classes',
                                    link: `/admin/class/${this.props.sessionData.selectedSchool.id}`,
                                },
                                { title: 'Create Class', link: '' },
                            ]}
                        />
                    </div>
                    <h2 className="pb-2">Create Class</h2>
                    {this.state.visible ? (
                        <Alert color="warning">
                            <p>{this.state.error}</p>
                        </Alert>
                    ) : null}
                    <div className="row">
                        <div className="col-md-4">
                            <form onSubmit={this.onSubmit}>
                                <div className="form-group">
                                    <label htmlFor="name">Name: </label>
                                    <input
                                        type="text"
                                        id="name"
                                        className="form-control"
                                        onChange={this.onChangeName}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="teachers">Select Teachers</label>
                                    <Select
                                        isMulti
                                        value={this.state.selectedTeachers}
                                        onChange={this.handleTeacherChange}
                                        options={this.state.teachers}
                                        placeholder="Select Teacher"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Select Levels</label>
                                    <Select
                                        id="exampleFormControlSelect1"
                                        isMulti
                                        value={this.state.selectedLevel}
                                        onChange={this.handleChange}
                                        options={this.state.levels}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="calendarYear">Select Calendar Year</label>
                                    <Select
                                        id="calendarYear"
                                        isSearchable={false}
                                        value={this.state.selectedCalendarYear}
                                        onChange={this.handleCalendarYearChange}
                                        options={this.state.calendarYears}
                                    />
                                </div>
                                <div className="form-group">
                                    <div className="space-between">
                                        <Button
                                            color="secondary"
                                            tag={Link}
                                            to={`/admin/class/${this.props.sessionData.selectedSchool.id}`}
                                            disabled={this.state.isLoading}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            id="create"
                                            color="primary"
                                            type="submit"
                                            disabled={
                                                this.state.isLoading ||
                                                (this.props.sessionData.authenticatedTeacher &&
                                                    this.props.sessionData.authenticatedTeacher
                                                        .isDemo)
                                            }
                                        >
                                            {this.state.isLoading ? (
                                                <i className="fa fa-spinner fa-spin" />
                                            ) : null}{' '}
                                            Create
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )
        }
    }
)
