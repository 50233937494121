import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { withOktaAuth } from '@okta/okta-react'
import { Card, Button, Row, Col } from 'reactstrap'
import { AuthService, AuthState } from '../../models/Auth'
import BreadCrumbs from '../layout/BreadCrumbs'
import { ISessionData } from '../../AppRouter'

interface IParams {
    schoolId: string
}

interface IProps extends RouteComponentProps<IParams> {
    authService: AuthService
    authState: AuthState
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
}

interface IState {
    isPopoverOpen: boolean
    popoverMessage: string
    popoverTarget: string
    title: string
}

class Admin extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            isPopoverOpen: false,
            popoverMessage: 'Add some teachers to your school',
            popoverTarget: 'teachers',
            title: 'Admin',
            //	isDataLoading: true
        }
    }

    componentDidMount() {
        this.loadData()
    }

     componentDidUpdate (prevProps, nextState) {
    //   if (this.props.sessionData !== prevProps.sessionData) {
    //     this.loadData()
    //   }
         this.props.setIsDataLoading(false)
     }

    loadData() {
        this.props.setIsDataLoading(true)
        this.setState({
            title: `Admin (${this.props.sessionData.authenticatedTeacher.schoolName})`,
        })
        this.props.setIsDataLoading(false)
    }

    togglePopover = () => {
        this.setState({ isPopoverOpen: !this.state.isPopoverOpen })
    }

    render() {
        // // if (this.props.isDataLoading) return null;

        return (
            <div data-cy="admin-page-loaded">
                <div className="mb-2">
                    <BreadCrumbs
                        items={[
                            { title: 'Home', link: '/' },
                            { title: 'Admin', link: '' },
                        ]}
                    />
                </div>

                <h2 className="pb-2">{this.state.title}</h2>
                <div className="pb-4">
                    <Row>
                        <Col sm="4">
                            <Card body>
                                <h5 className="card-title">Teachers</h5>
                                <p style={{ minHeight: 50 }}>Set up and manage teacher accounts.</p>
                                <Button
                                    id="teachers"
                                    color="primary"
                                    tag={Link}
                                    to={`/admin/teacher/${this.props.sessionData.selectedSchool.id}`}
                                >
                                    Manage
                                </Button>
                            </Card>
                        </Col>
                        <Col sm="4">
                            <Card body>
                                <h5 className="card-title">Classes</h5>
                                <p style={{ minHeight: 50 }}>
                                    Create classes, add students and assign teachers.
                                </p>
                                <Button
                                    id="classes"
                                    color="primary"
                                    tag={Link}
                                    to={`/admin/class/${this.props.sessionData.selectedSchool.id}`}
                                >
                                    Manage
                                </Button>
                            </Card>
                        </Col>
                        <Col sm="4">
                            <Card body>
                                <h5 className="card-title">Licences</h5>
                                <p style={{ minHeight: 50 }}>
                                    Manage and purchase student licences.
                                </p>
                                <Button
                                    data-cy="licences-button"
                                    id="students"
                                    color="primary"
                                    tag={Link}
                                    to={`/admin/licence/${this.props.sessionData.selectedSchool.id}`}
                                >
                                    Manage
                                </Button>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div className="pb-4">
                    <Row>
                        <Col sm="4" />
                        <Col sm="4" />
                        <Col sm="4" />
                    </Row>
                </div>
            </div>
        )
    }
}
export default withOktaAuth(Admin)
