import axios from 'axios'
import { handleApiSuccess, handleApiError, IApiResult } from '../shared/ApiHandler'
import { groupApiUrl, teacherBaseApiUrl } from '../shared/ApiUrls'
import {
    GroupCreateDto,
    OverallGroupDto,
    StudentGroupUpdate,
    GroupUpdateDto,
} from '../models/Group'

export const getGroups = (schoolClassId: string, token: string): Promise<IApiResult> => {
    return axios
        .get<OverallGroupDto>(`${groupApiUrl}/${schoolClassId}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const addGroup = (groupDto: GroupCreateDto, token: string): Promise<IApiResult> => {
    return axios
        .post(`${groupApiUrl}`, groupDto, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const DeleteGroup = (
    schoolClassId: string,
    groupId: string,
    token: string
): Promise<IApiResult> => {
    return axios
        .delete(`${groupApiUrl}/${schoolClassId}/${groupId}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const UpdateGroupName = (
    schoolClassId: string,
    newGroupName: GroupUpdateDto,
    token: string
): Promise<IApiResult> => {
    return axios
        .put(`${groupApiUrl}/${schoolClassId}`, newGroupName, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const AddStudentsToGroup = (
    students: StudentGroupUpdate,
    token: string
): Promise<IApiResult> => {
    return axios
        .post(`${groupApiUrl}/add`, students, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const RemoveStudentsFromGroup = (
    students: StudentGroupUpdate,
    token: string
): Promise<IApiResult> => {
    return axios
        .post(`${groupApiUrl}/remove`, students, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}
