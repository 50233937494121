import React, { Component, useEffect } from 'react'
import BaseClass from '../Bases/BaseClass'

interface IProps {
    setIsDataLoading: any
}

const NotFound = (props: IProps) => {

    useEffect(() => {
props.setIsDataLoading(false)
    }, [])

    return (
        <div></div>
    )
}
export default NotFound
