import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { withOktaAuth } from '@okta/okta-react'
import { AuthService, AuthState } from '../../../models/Auth'
import { TeacherAccessLevel, TeacherDto } from '../../../models/Teacher'
import { getTeachers as getTeachersFromApi, deleteTeacher } from '../../../services/TeacherApi'
import { Button, Table, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import BaseClass from '../../Bases/BaseClass'
import BreadCrumbs from '../../layout/BreadCrumbs'
import DemoPopovers from '../../layout/DemoPopovers'
import { ISessionData } from '../../../AppRouter'

interface IParams {
    schoolId: string
}

interface IProps extends RouteComponentProps<IParams> {
    // baseUrl: string;
    authService: AuthService
    authState: AuthState
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
}

interface IState {
    isLoading: boolean
    error: string
    visible: boolean
    teachers: TeacherDto[]
    schoolId: string
    deleteModal: boolean
    selectedTeacher: TeacherDto
    // loggdInTeacher: TeacherDto;
    isDataLoading: boolean
}

class TeacherIndex extends BaseClass<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        // let teacher = getTeacherFromLocalStorage();
        this.state = {
            isLoading: true,
            error: '',
            visible: false,
            teachers: [],
            schoolId: null,
            deleteModal: false,
            selectedTeacher: null,
            //  selectedSchoolClass: null,
            // loggdInTeacher: null,
            isDataLoading: true,
        }
    }

    componentDidMount() {
        this.loadData()
    }

    async loadData() {
        console.log(this.props.sessionData.selectedSchool.id)
        let teacher = this.props.sessionData.authenticatedTeacher
        this.props.setIsDataLoading(true)
        await this.getTeachers(teacher.schoolId)
        this.props.setIsDataLoading(false)
        this.props.setIsDataLoading(false)
    }

    async getTeachers(schoolId: string) {
        this.props.setIsDataLoading(true)
        let token = await this.props.authState.accessToken.accessToken

        return getTeachersFromApi(schoolId, token)
            .then((res) => {
                if (res.ok) {
                    let teachers = res.data.filter(
                        (v) => v.accessLevel !== TeacherAccessLevel.Super.toString()
                    )

                    this.setState({
                        teachers,
                    })
                } else {
                    this.handleknownError(res.error)
                }
            })
            .catch((error) => {
                this.handleUnknownError(error)
            })
    }

    async delete() {
        this.props.setIsDataLoading(true)
        this.setState({
            deleteModal: false,
        })

        let token = await this.props.authState.accessToken.accessToken

        await deleteTeacher(this.state.selectedTeacher.id, token)
            .then((res) => {
                if (res.ok) {
                    this.props.setIsDataLoading(false)
                    this.setState({
                        teachers: res.data,
                    })
                } else {
                    this.handleknownError(res.error)
                }
            })
            .catch((error) => {
                this.handleUnknownError(error)
            })
    }

    toggleDelete = (teacher) => {
        this.setState({
            selectedTeacher: teacher,
            deleteModal: !this.state.deleteModal,
        })
    }

    render() {
        // if (this.props.isDataLoading) return null;

        return (
            <div>
                {this.props.sessionData.authenticatedTeacher.isDemo && (
                    <DemoPopovers
                        items={[
                            {
                                target: 'bulk',
                                placement: 'right',
                                message: 'These features are not available for trial accounts.',
                            },
                        ]}
                    />
                )}

                <div className="mb-2">
                    <BreadCrumbs
                        items={[
                            { title: 'Home', link: '/' },
                            {
                                title: 'Admin',
                                link: `/admin/select/${this.props.sessionData.selectedSchool.id}`,
                            },
                            { title: 'Teacher', link: '' },
                        ]}
                    />
                </div>
                <div>
                    <h2 className="pb-2">Teachers</h2>
                </div>
                <div className="mb-2">
                    <Button
                        className="mr-1"
                        id="add"
                        color="success"
                        tag={Link}
                        to={`/admin/teacher/create/${this.props.sessionData.selectedSchool.id}`}
                        disabled={
                            this.props.sessionData.authenticatedTeacher &&
                            this.props.sessionData.authenticatedTeacher.isDemo
                        }
                    >
                        Add Teacher
                    </Button>
                    <Button
                        className="mr-1"
                        id="bulk"
                        color="success"
                        tag={Link}
                        to={`/admin/teacher/bulk/${this.props.sessionData.selectedSchool.id}`}
                        disabled={
                            this.props.sessionData.authenticatedTeacher &&
                            this.props.sessionData.authenticatedTeacher.isDemo
                        }
                    >
                        Add Teachers in Bulk
                    </Button>
                </div>

                <div className="pb-2">
                    <Table bordered striped className="bg-white">
                        <thead>
                            <tr>
                                <th style={{ width: '80%' }}>Name</th>
                                <th id="actions" style={{ width: '20%' }}>
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.teachers.map((teacher: TeacherDto) => {
                                return (
                                    <tr key={teacher.id}>
                                        <td>{`${teacher.firstname[0].toUpperCase()}. ${
                                            teacher.lastname
                                        }`}</td>
                                        <td className="text-nowrap">
                                            <Button
                                                className="mr-2"
                                                color="primary"
                                                tag={Link}
                                                to={`/admin/teacher/edit/${teacher.id}`}
                                                disabled={
                                                    this.props.sessionData.authenticatedTeacher &&
                                                    this.props.sessionData.authenticatedTeacher
                                                        .isDemo
                                                }
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                color="danger"
                                                // tag={Link}
                                                onClick={() => {
                                                    this.toggleDelete(teacher)
                                                }}
                                                disabled={
                                                    this.props.sessionData.authenticatedTeacher &&
                                                    this.props.sessionData.authenticatedTeacher
                                                        .isDemo
                                                }
                                            >
                                                Delete
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
                <Modal isOpen={this.state.deleteModal} toggle={this.toggleDelete}>
                    <ModalHeader toggle={this.toggleDelete}>Delete Teacher</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete{' '}
                        {this.state.selectedTeacher != null
                            ? `${this.state.selectedTeacher.firstname} ${this.state.selectedTeacher.lastname}`
                            : ''}
                        &apos;s account?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggleDelete}>
                            Cancel
                        </Button>
                        <Button color="danger" onClick={() => this.delete()}>
                            Delete teacher
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default withOktaAuth(TeacherIndex)
