import React, { useContext, useEffect, useRef, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
    deleteStudentFromApi,
    getStudentApi,
    moveStudentsApi,
    updateStudentApi,
} from '../../services/StudentApi'
import {
    Button,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Alert,
} from 'reactstrap'
import { StudentDto, StudentCreateDto, StudentSchoolClassLogType } from '../../models/Student'
import Select from 'react-select'
import {
    demoClassGuid,
    getLevelOptionByValue,
    getSchoolClassLevels,
    logout,
    ScrollToTop,
} from '../../shared/Helper'
import BreadCrumbs from '../layout/BreadCrumbs'
import { useOktaAuth } from '@okta/okta-react'
import { SchoolClassDto } from '../../models/SchoolClass'
import { getSchoolClassesFromApi } from '../../services/SchoolClassApi'
import { ISessionData } from '../../AppRouter'

interface IParams {
    id: string
}

interface IProps extends RouteComponentProps<IParams> {
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
    history: any
    match: any
}

export default function StudentEdit(props: IProps) {
    const defaultStudent: StudentDto = {
        id: '',
        firstName: '',
        lastName: '',
        preferredName: '',
        username: '',
        password: '',
        schoolClassId: '',
        schoolClassLevel: '',
        isLicenced: false,
        currentDiagnostic: null,
    }

    const [updating, setUpdating] = useState(false)
    const [error, setError] = useState('')
    const [errorVisible, setErrorVisible] = useState(false)
    const [student, setStudent] = useState(defaultStudent)
    const [selectedLevel, setSelectedLevel] = useState(null)
    const { oktaAuth, authState, authService } = useOktaAuth()
    const [selectedMoveClassId, setSelectedMoveClassId] = useState(null)
    const [showMoveClassModal, setShowMoveClassModal] = useState(false)
    const [schoolClasses, setSchoolClasses] = useState(null)
    const [selectedSchoolClassId, setSelectedSchoolClassId] = useState('')
    const [levels, setLevels] = useState([])

    useEffect(() => {
        loadData()
    }, [])

    const handleknownError = (errors) => {
        ScrollToTop()
        setError(errors.detail)
        setErrorVisible(true)
        props.setIsDataLoading(false)
    }

    const handleUnknownError = (error) => {
        ScrollToTop()
        if (error.status === 401) {
            //  return logout(oktaAuth);
        }

        props.setIsDataLoading(false)
        console.log(error)
        setError(error.statusText)
    }

    const loadData = async () => {
        setLevels(getSchoolClassLevels())
        if (props.sessionData.selectedSchoolClass) {
            setSelectedSchoolClassId(props.sessionData.selectedSchoolClass.id)
        }
        await populateStudent(props.match.params.id)
        props.setIsDataLoading(false)
    }

    const populateStudent = async (studentId: string) => {
        let token = await authState.accessToken.accessToken
        return getStudentApi(studentId, token)
            .then((res) => {
                if (res.ok) {
                    setStudent(res.data)
                    setSelectedLevel(getLevelOptionByValue(res.data.schoolClassLevel))
                } else {
                    handleknownError(res.error)
                }
            })
            .catch((error) => {
                handleUnknownError(error)
            })
    }

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let s = {
            ...student,
            [e.target.id]: e.target.value,
        }

        setStudent(s)
    }

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (
            !student.firstName ||
            !student.lastName ||
            !student.preferredName ||
            !student.password
        ) {
            return
        }

        await update(props.match.params.id)
    }

    const update = async (studentId: string) => {
        setErrorVisible(false)
        let token = await authState.accessToken.accessToken
        setUpdating(true)
        if (student) {
            const studentToUpdate: StudentCreateDto = {
                firstName: student.firstName,
                lastName: student.lastName,
                preferredName: student.preferredName,
                password: student.password,
                schoolClassId: student.schoolClassId,
                schoolClassLevel: student.schoolClassLevel,
            }

            return updateStudentApi(studentId, studentToUpdate, token)
                .then((res) => {
                    setUpdating(false)
                    if (res.ok) {
                        goBack()
                    } else {
                        handleknownError(res.error)
                    }
                })
                .catch((error) => {
                    handleUnknownError(error)
                })
        }
    }

    const goBack = () => {
        ;(props.history as any).goBack()
    }

    const handleChange = (selectedOption: any) => {
        setStudent({
            ...student,
            schoolClassLevel: selectedOption.value,
        })

        setSelectedLevel(selectedOption)
    }

    const moveStudent = async () => {
        return moveStudentsApi(
            StudentSchoolClassLogType.Move,
            [
                {
                    studentId: student.id,
                    oldSchoolClassId: student.schoolClassId,
                    newSchoolClassId: selectedMoveClassId,
                    newSchoolClassName: '',
                    firstName: '',
                    lastName: '',
                },
            ],
            authState.accessToken.accessToken
        )
            .then((res) => {
                setShowMoveClassModal(false)
                if (res.ok) {
                    // loadData();
                    goBack()
                } else {
                    handleknownError(res.error)
                }
            })
            .catch((error) => {
                setShowMoveClassModal(false)
                handleUnknownError(error)
            })
    }

    const getUserSchoolClasses = async () => {
        props.setIsDataLoading(true)
        let token = authState.accessToken.accessToken
        return getSchoolClassesFromApi(token, true)
            .then((res) => {
                if (res.ok) {
                    props.setIsDataLoading(false)
                    setSchoolClasses(res.data)
                } else {
                    handleknownError(res.error)
                }
            })
            .catch((error) => {
                handleUnknownError(error)
            })
    }

    const deleteStudent = async (studentId: string, schoolClassId: string | undefined) => {
        if (!schoolClassId) {
            return
        }

        let token = await authState.accessToken.accessToken

        props.setIsDataLoading(true)

        return deleteStudentFromApi(studentId, schoolClassId, token)
            .then((res) => {
                props.setIsDataLoading(false)
                if (res.ok) {
                    return props.history.push('/student')
                }

                handleknownError(res.error)
            })
            .catch((error) => {
                handleUnknownError(error)
            })
    }

    return (
        <div data-cy="student-edit-page-loaded">
            <div className="mb-2">
                <BreadCrumbs
                    items={[
                        { title: 'Home', link: '/' },
                        { title: 'Students', link: '/student' },
                        { title: 'Edit Student', link: '' },
                    ]}
                />
            </div>
            <h2 className="pb-2">Edit Student Details</h2>
            {errorVisible ? (
                <Alert color="warning">
                    <p>{error}</p>
                </Alert>
            ) : null}

            <div className="row">
                <div className="col-md-4">
                    <div className="pb-2 space-between">
                        <Button
                            data-cy="move-class-button"
                            color="danger"
                            onClick={async () => {
                                if (!schoolClasses) {
                                    await getUserSchoolClasses()
                                }

                                setShowMoveClassModal(true)
                            }}
                        >
                            Move class
                        </Button>{' '}
                        <Button
                            data-cy="delete-button"
                            color="danger"
                            // tag={Link}
                            onClick={() => {
                                deleteStudent(student.id, selectedSchoolClassId)
                            }}
                            disabled={selectedSchoolClassId === demoClassGuid}
                        >
                            Delete
                        </Button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <form onSubmit={onSubmit}>
                        <div className="form-group">
                            <label htmlFor="firstName">First Name: </label>
                            <input
                                type="text"
                                id="firstName"
                                className="form-control"
                                value={student.firstName}
                                onChange={handleOnChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="lastName">Last Name: </label>
                            <input
                                type="text"
                                id="lastName"
                                className="form-control"
                                value={student.lastName}
                                onChange={handleOnChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="lastName">Preferred Name: </label>
                            <input
                                type="text"
                                id="preferredName"
                                className="form-control"
                                value={student.preferredName}
                                onChange={handleOnChange}
                                required
                            />
                            <small id="preferredName" className="form-text text-muted">
                                Usernames are automatically generated using the students preferred
                                name and their surname initial. E.g. Sally Winter = SALLYW
                            </small>
                        </div>

                        <div className="form-group">
                            <label htmlFor="exampleFormControlSelect1">Select Level</label>
                            <Select
                                id="schoolClassLevel"
                                value={selectedLevel}
                                onChange={handleChange}
                                options={levels}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password: </label>
                            <input
                                type="text"
                                id="password"
                                className="form-control"
                                value={student.password}
                                onChange={handleOnChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <div className="space-between">
                                <Button
                                    data-cy="cancel-button"
                                    color="secondary"
                                    //tag={Link}
                                    //to="/student"
                                    onClick={goBack}
                                    disabled={updating}
                                >
                                    Cancel
                                </Button>
                                <div>
                                    <Button
                                        data-cy="update-button"
                                        color="primary"
                                        type="submit"
                                        disabled={updating}
                                    >
                                        {updating ? <i className="fa fa-spinner fa-spin" /> : null}{' '}
                                        Update
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div />
            <div>
                <Modal
                    data-cy="move-student-to-another-class-modal"
                    isOpen={showMoveClassModal}
                    toggle={() => setShowMoveClassModal(false)}
                >
                    <ModalHeader toggle={() => setShowMoveClassModal(false)}>
                        Move student to another class
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label for="exampleSelect">Select Class</Label>
                                <Input
                                    type="select"
                                    name="select"
                                    id="exampleSelect"
                                    onChange={(e) => setSelectedMoveClassId(e.target.value)}
                                >
                                    <option key="empty" value="">
                                        Select Option
                                    </option>
                                    {schoolClasses &&
                                        schoolClasses
                                            .filter((v) => v.id !== student.schoolClassId)
                                            .map((schoolClass: SchoolClassDto) => {
                                                return (
                                                    <option
                                                        key={schoolClass.id}
                                                        value={schoolClass.id}
                                                    >
                                                        {schoolClass.name}
                                                    </option>
                                                )
                                            })}
                                </Input>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="danger"
                            onClick={moveStudent}
                            disabled={
                                // !selectedMoveClassStudentId ||
                                !selectedMoveClassId
                            }
                        >
                            Move Student
                        </Button>
                        <Button color="secondary" onClick={() => setShowMoveClassModal(false)}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        </div>
    )
}
// export default withOktaAuth(StudentEdit);
