import React, { useState, useEffect, useContext } from 'react'
import { StudentDto } from '../../models/Student'
import { Link, RouteComponentProps } from 'react-router-dom'
import { Button, Table, Alert } from 'reactstrap'
import {
    getStudentsFromApi,
    enableStudentFromApi,
    activateStudentLicenceApi,
    activateStudentLicencesByClassApi as activateStudentsLicenceByClassApi,
    reinstateUnusedLicencesApi,
} from '../../services/StudentApi'
import './Student.css'
import { useOktaAuth } from '@okta/okta-react'
import {
    convertSchoolClassLevelLabel,
    archivedClassGuid,
    logout,
    demoClassGuid,
    ScrollToTop,
    isTeacherAdminOrSuper,
} from '../../shared/Helper'
import BreadCrumbs from '../layout/BreadCrumbs'
import DemoPopovers from '../layout/DemoPopovers'
import { ISessionData } from '../../AppRouter'
import { FaAmericanSignLanguageInterpreting } from 'react-icons/fa'

interface IParams {}

interface IProps extends RouteComponentProps<IParams> {
    sessionData: ISessionData
    // setIsDataLoading: any; isDataLoading:boolean;
    isDataLoading
    setIsDataLoading
    match: any
    history: any
}

export default function Student(props: IProps) {
    const [studentList, setStudentList] = useState(null)
    const [error, setError] = useState(null)
    const [selectedSchoolClassId, setSelectedSchoolClassId] = useState('')
    const [title, setTitle] = useState('Students ()')
    const { oktaAuth, authState, authService } = useOktaAuth()

    // useEffect(() => {
    //   loadData();
    // }, []);

    // useEffect(() => {
    //   loadData();
    // }, [props.sessionData && props.sessionData.selectedSchoolClass]);

    useEffect(() => {
        loadData()
    }, [props.sessionData])

    // componentDidUpdate(prevProps, prevState) {
    //   if (this.props.sessionData &&
    //     (this.props.sessionData.selectedSchoolClass
    //       !== prevProps.sessionData.selectedSchoolClass)) {
    //       this.setState({ isDataLoading: true });
    //       this.loadData();
    //   }
    // }

    const loadData = async () => {
        props.setIsDataLoading(true)
        const { authenticatedTeacher, selectedSchoolClass } = props.sessionData
        if (selectedSchoolClass) {
            setTitle(`Students (${selectedSchoolClass.name})`)
            setSelectedSchoolClassId(selectedSchoolClass.id)
            await getStudents(selectedSchoolClass.id)
        } else {
            if (isTeacherAdminOrSuper(authenticatedTeacher.accessLevel)) {
                props.history.push(`/admin/class/${authenticatedTeacher.schoolId}`)
            } else {
                props.history.push('/no-data-yet')
            }
        }
        props.setIsDataLoading(false)
    }

    const handleknownError = (errors) => {
        ScrollToTop()
        //  setShowMoveClassModal(false)
        //props.setIsDataLoading(false);
        props.setIsDataLoading(false)
        setError(errors.detail)
    }

    const handleUnknownError = (error) => {
        ScrollToTop()
        if (error.status === 401) {
            // return logout(oktaAuth);
        }

        props.setIsDataLoading(false)
        console.log(error)
        setError(error.statusText)
    }

    const getStudents = async (schoolClassId: string) => {
        return getStudentsFromApi(schoolClassId, authState.accessToken.accessToken)
            .then((res) => {
                // setIsDataLoading(false)
                if (res.ok) {
                    setStudentList(res.data)
                } else {
                    handleknownError(res.error)
                }
            })
            .catch((error) => {
                handleUnknownError(error)
            })
    }

    const reinstateUnusedLicences = async (schoolId) => {
        return reinstateUnusedLicencesApi(schoolId, authState.accessToken.accessToken)
            .then((res) => {
                // setIsDataLoading(false)
                if (res.ok) {
                    setError(`${res.data} licences reinstated.`)
                    //  alert(`${res.data} licences reinstated.`)
                    //  setStudentList(res.data)
                } else {
                    handleknownError(res.error)
                }
            })
            .catch((error) => {
                handleUnknownError(error)
            })
    }

    const enableStudent = async (studentId: string) => {
        return enableStudentFromApi(studentId, authState.accessToken.accessToken)
            .then((res) => {
                props.setIsDataLoading(false)
                if (res.ok) {
                    loadData()
                    // setStudentList(res.data);
                } else {
                    handleknownError(res.error)
                }
            })
            .catch((error) => {
                handleUnknownError(error)
            })
    }

    const activateStudentsByClass = async (classId) => {
        props.setIsDataLoading(true)
        return activateStudentsLicenceByClassApi(classId, authState.accessToken.accessToken)
            .then((res) => {
                props.setIsDataLoading(false)
                //  setShowMoveClassModal(false);
                if (res.ok) {
                    loadData()
                } else {
                    handleknownError(res.error)
                }
            })
            .catch((error) => {
                //  setShowMoveClassModal(false);
                handleUnknownError(error)
            })
    }

    const activateStudentLicence = async (studentId) => {
        props.setIsDataLoading(true)
        return activateStudentLicenceApi(studentId, authState.accessToken.accessToken)
            .then((res) => {
                props.setIsDataLoading(false)
                //  setShowMoveClassModal(false);
                if (res.ok) {
                    loadData()
                } else {
                    handleknownError(res.error)
                }
            })
            .catch((error) => {
                //  setShowMoveClassModal(false);
                handleUnknownError(error)
            })
    }

    const getNextYear = () => {
        let d = new Date()
        let n = d.getFullYear()

        if (d.getMonth() >= 10) {
            n = n + 1
        }

        return n
    }


    const isOctoberOrLater = () => {
        let d = new Date()
        let n = d.getFullYear()

        return (d.getMonth() >= 10 || d.getMonth() <= 3 ) ;
    }

    // props.setIsDataLoading(isDataLoading);

    // if (props.isDataLoading) return null;

    return (
        <div data-cy="student-page-loaded">
            <div className="pb-2">
                <div className="mb-2">
                    <BreadCrumbs
                        items={[
                            { title: 'Home', link: '/' },
                            { title: 'Students', link: '' },
                        ]}
                    />
                </div>
                <h2 className="pb-2">{title}</h2>
                {error ? (
                    <Alert color="warning">
                        <p>{error}</p>
                    </Alert>
                ) : null}

                {selectedSchoolClassId === archivedClassGuid ? (
                    <div className="mb-2">
                        <Button
                            id="resinstate-unused-lic-button"
                            className="mt-1 mr-1"
                            color="warning"
                            onClick={() => {
                                reinstateUnusedLicences(props.sessionData.selectedSchool.id)
                            }}
                            disabled={selectedSchoolClassId === demoClassGuid}
                        >
                            Reinstate unused licenses
                        </Button>
                    </div>
                ) : (
                    <div className="form-group mb-2">
                        <div className="space-between">
                            <div>
                                <Button
                                    data-cy="add-student-button"
                                    className="mt-1 mr-1"
                                    color="success"
                                    tag={Link}
                                    to={`/student/create/${selectedSchoolClassId}`}
                                >
                                    Add Student
                                </Button>
                                <Button
                                    data-cy="add-student-in-bulk-button"
                                    className="mt-1 mr-1"
                                    color="success"
                                    tag={Link}
                                    to={'/class/student/bulk'}
                                >
                                    Add Students in Bulk
                                </Button>
                                <Button
                                    className="mt-1 mr-1"
                                    color="success"
                                    onClick={() => {
                                        activateStudentsByClass(selectedSchoolClassId)
                                    }}
                                    disabled={selectedSchoolClassId === demoClassGuid}
                                >
                                    Activate all Students
                                </Button>
                            </div>
                            <div className="space-between">
                                <div>
                                    <Button
                                        className="mt-1 mr-1"
                                        id="print"
                                        color="success"
                                        onClick={() => {
                                            (window as any).print()
                                        }}
                                        disabled={selectedSchoolClassId === demoClassGuid}
                                    >
                                        Print
                                    </Button>
                                </div>

                                <div>
                                    <Button
                                        id="rollover"
                                        className="mt-1 mr-1"
                                        color="warning"
                                        tag={Link}
                                        to={'/class/student/rollover'}
                                        disabled={selectedSchoolClassId === demoClassGuid}
                                    >
                                        Move/Rollover class
                                    </Button>
                                </div>



                            </div>
                        </div>
                    </div>
                )}
                <Table striped className="bg-white table-outer-border">
                    <thead>
                        <tr>
                            <th>Full Name</th>
                            <th>Preferred Name</th>
                            <th>Username</th>
                            <th>Password</th>
                            <th>Year Level</th>
                            <th>Current Assessment</th>
                            <th id="status">Status</th>
                            <th id="actions">Actions</th>
                        </tr>
                    </thead>
                    <tbody data-cy="student-table-body">
                        {studentList &&
                            studentList.map((student: StudentDto) => {
                                return (
                                    <tr key={student.id}>
                                        <td
                                            data-cy="full-name"
                                            className="align-middle font-weight-bold "
                                        >
                                            <Link to={`/student/profile/${student.id}`}>
                                                {`${student.firstName} ${student.lastName}`}
                                            </Link>
                                        </td>
                                        <td className="align-middle  ">{student.preferredName}</td>
                                        <td className="align-middle ">
                                            <code>{student.username.toUpperCase()}</code>
                                        </td>
                                        <td className="align-middle">
                                            <code>{student.password}</code>
                                        </td>
                                        <td className="align-middle">
                                            {convertSchoolClassLevelLabel(student.schoolClassLevel)}
                                        </td>
                                        <td className="align-middle font-weight-bold ">
                                            {student.currentDiagnostic ? (
                                                <>
                                                    <Link
                                                        to={`/assessment/details/${student.currentDiagnostic.id}`}
                                                    >
                                                        {student.currentDiagnostic.name}
                                                    </Link>
                                                </>
                                            ) : (
                                                <>none</>
                                            )}
                                        </td>
                                        {selectedSchoolClassId === archivedClassGuid ? (
                                            <td className="align-middle ">Archived</td>
                                        ) : (
                                            <td className="align-middle font-weight-bold ">
                                                {student.isLicenced ? (
                                                    <span className="text-success">Active</span>
                                                ) : (
                                                    <Button
                                                        color="success"
                                                        onClick={() => {
                                                            activateStudentLicence(student.id)
                                                        }}
                                                        disabled={
                                                            selectedSchoolClassId === demoClassGuid
                                                        }
                                                    >
                                                        Activate
                                                    </Button>
                                                )}{' '}
                                            </td>
                                        )}
                                        <td className="align-middle text-nowrap">
                                            {selectedSchoolClassId === archivedClassGuid ? (
                                                <Button
                                                    className="mr-2"
                                                    color="success"
                                                    onClick={() => {
                                                        enableStudent(student.id)
                                                    }}
                                                    disabled={
                                                        selectedSchoolClassId === demoClassGuid
                                                    }
                                                >
                                                    Enable
                                                </Button>
                                            ) : (
                                                <>
                                                    <Button
                                                        data-cy="edit-row-button"
                                                        color="primary"
                                                        tag={Link}
                                                        to={`/student/edit/${student.id}`}
                                                        disabled={
                                                            selectedSchoolClassId === demoClassGuid
                                                        }
                                                    >
                                                        Edit
                                                    </Button>{' '}
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </Table>
            </div>

            {/* {props.sessionData.authenticatedTeacher.isDemo && (
                <DemoPopovers
                    items={[
                        {
                            target: 'rollover',
                            placement: 'top',
                            message: 'These features are not available for trial accounts.',
                        },
                    ]}
                ></DemoPopovers>
            )} */}
        </div>
    )
}
