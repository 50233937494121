import React, { Component } from 'react'
import { StudentDto, StudentItem } from '../../models/Student'
import { Button, FormGroup, Label, Alert } from 'reactstrap'
import MySelect from './MySelect'
import { default as Select } from 'react-select'
import DropdownIndicator from './DropDownIndicator'
import { DiagnosticEstimateDto, DiagnosticType } from '../../models/Diagnostic'
import { ItemDto } from '../../models/KeyConcept'
import { ScrollToTop } from '../../shared/Helper'

interface IProps {
    authState
    title: string
    subtitle: string
    onGetEstimatedTime: any
    type: DiagnosticType
    studentListDescription: string
    isCalculatingTimes: boolean
}

interface IState {
    isLoading: boolean
    error: ''
    visible: boolean
    name: string
    showErrorModal: boolean
    students: StudentDto[]
    optionsOfMasterItems: []
    optionsOfStudentMasterItems: []
    selectedMasterItems: any
    diagnosticNameState: string
    studentItems: StudentItem[]
    studentItemsWarningMessage: string
    studentItemsAllEmpty: boolean
    studentItemsSomeEmpty: boolean
    showWarningModal: boolean
    showLoadingModal: boolean
    diagnosticEstimateDto: DiagnosticEstimateDto | null
    loadingModalText: string
    assesmentType: string
}

class StudentDiagnosticSelector extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            isLoading: false,
            error: '',
            visible: false,
            name: '',
            showErrorModal: false,
            students: [],
            optionsOfMasterItems: [],
            optionsOfStudentMasterItems: [],
            studentItems: [],
            selectedMasterItems: [],
            diagnosticNameState: '',
            studentItemsWarningMessage: '',
            studentItemsAllEmpty: true,
            studentItemsSomeEmpty: true,
            showWarningModal: false,
            showLoadingModal: false,
            diagnosticEstimateDto: null,
            loadingModalText: 'Loading view',
            assesmentType: 'Sampling',
        }
    }

    async componentDidMount() {}

    setStudents(students) {
        this.setState({ students })
    }

    setStudentItems(studentItems) {
        this.setState({ studentItems })
    }

    setOptionsOfMasterItems(optionsOfMasterItems) {
        this.setState({ optionsOfMasterItems })
    }

    setOptionsOfStudentMasterItems(optionsOfStudentMasterItems) {
        this.setState({ optionsOfStudentMasterItems })
    }

    getStudentItems() {
        return this.state.studentItems
    }

    handleOnMasterSelect = (selectedItems: any[], metaData: any) => {
        this.sortOptions(selectedItems)
        this.setState({ selectedMasterItems: selectedItems })
        if (metaData && metaData.action === 'select-option') {
            const addedOption = metaData.option

            this.setState({
                studentItemsAllEmpty: false,
                studentItemsSomeEmpty: false,
            })

            const newStudentItems = [...this.state.studentItems]

            // Select All is selected
            if (addedOption.value === '*') {
                newStudentItems.forEach((studentItem: StudentItem) => {
                    if (studentItem.currentDiagnostic) {
                        return
                    }

                    studentItem.items = selectedItems
                })
            } else {
                newStudentItems.forEach((studentItem: StudentItem) => {
                    if (studentItem.currentDiagnostic) {
                        return
                    }

                    if (studentItem.items !== null) {
                        let indexOfAddedOption = studentItem.items.findIndex(
                            (v) => v.value === addedOption.value
                        )

                        if (indexOfAddedOption === -1) {
                            studentItem.items = [...studentItem.items, addedOption]

                            this.sortOptions(studentItem.items)
                        }
                    } else {
                        studentItem.items = [addedOption]
                    }
                })
            }
        }

        if (metaData && metaData.action === 'remove-value') {
            const removedValue = metaData.removedValue
            const newStudentItems = [...this.state.studentItems]

            newStudentItems.forEach((studentItem) => {
                if (studentItem.currentDiagnostic) {
                    return
                }

                if (studentItem.items !== null) {
                    const indexOfRemovedValue = studentItem.items.indexOf(removedValue)

                    if (indexOfRemovedValue !== -1) {
                        // use filter to remove
                        studentItem.items = studentItem.items.filter(function (item) {
                            return removedValue['value'] !== item['value']
                        })
                    }
                }
            })
        }

        if (metaData && metaData.action === 'clear') {
            const newStudentItems = [...this.state.studentItems]

            newStudentItems.forEach((studentItem) => {
                studentItem.items = []
            })
        }
    }

    setStudentOptions(selectedItems) {
        const newStudentItems = [...this.state.studentItems]

        newStudentItems.forEach((studentItem: StudentItem) => {
            if (studentItem.currentDiagnostic) {
                return
            }

            studentItem.items = selectedItems
        })
    }

    itemOfStudent = (selectedOption: any, studentItem: any, metaData: any) => {
        const oldStudents = [...this.state.studentItems]
        const indexOfStudent = oldStudents.indexOf(studentItem)

        this.sortOptions(selectedOption)
        if (indexOfStudent !== -1) {
            oldStudents[indexOfStudent].items = selectedOption
            this.setState({
                studentItems: oldStudents,
            })
        }

        if (metaData && metaData.action === 'clear') {
            this.setState({ studentItemsSomeEmpty: true })
        }
    }

    clearEstimates() {
        this.setState({ diagnosticEstimateDto: null })
        let skc = this.state.studentItems

        skc.forEach((v) => (v.timeFormatted = '... mins'))
        this.setState({ studentItems: skc })
    }

    sortOptions(items: ItemDto[]) {
        if (items) {
            items.sort((a: ItemDto, b: ItemDto) => (a.order > b.order ? 1 : -1))
        }
    }

    checkIfAllStudentItemsAreEmpty = () => {
        return this.state.studentItems.every(
            (element) => element.items === null || element.items.length === 0
        )
    }

    checkIfSomeStudentItemsAreEmpty = () => {
        return this.state.studentItems.some(
            (element) => element.items === null || element.items.length === 0
        )
    }

    validateItems = () => {
        if (this.checkIfAllStudentItemsAreEmpty()) {
            this.setState({
                studentItemsWarningMessage: 'Assign at least one student a key concept.',
                studentItemsAllEmpty: true,
            })

            ScrollToTop()
        } else {
            this.setState({
                studentItemsAllEmpty: false,
            })

            if (this.checkIfSomeStudentItemsAreEmpty()) {
                this.setState({
                    studentItemsSomeEmpty: true,
                    showWarningModal: !this.state.showWarningModal,
                })
            } else {
                this.setState({
                    studentItemsSomeEmpty: false,
                })
            }
        }
    }

    render() {
        let subtitle
        if (this.props.subtitle) {
            subtitle = <div className="label-subtitle">{this.props.subtitle}</div>
        }

        return (
            <>
                <div className="border p-3 mt-3 bg-white">
                    <FormGroup>
                        <Label
                            className="label-bold-style"
                            for={`masterSelect-${this.props.type.toString()}`}
                        >
                            {this.props.title}
                        </Label>
                        {subtitle}
                        <MySelect
                            id={`masterSelect-${this.props.type.toString()}`}
                            isMulti
                            allowSelectAll={false}
                            components={{ DropdownIndicator }}
                            onChange={this.handleOnMasterSelect}
                            value={this.state.selectedMasterItems}
                            options={this.state.optionsOfMasterItems}
                        />
                        <div className="mt-2">
                            <Button
                                color="success"
                                onClick={() =>
                                    this.props.onGetEstimatedTime(this.state.studentItems, this)
                                }
                                disabled={this.props.isCalculatingTimes}
                            >
                                Get estimated time
                            </Button>
                        </div>
                        {this.state.studentItemsWarningMessage &&
                            this.state.studentItemsAllEmpty && (
                                <Alert color="warning">
                                    {this.state.studentItemsWarningMessage}
                                </Alert>
                            )}
                    </FormGroup>
                    <div className="label-bold-style">Student List:</div>
                    <div className="row">
                        <div className="col-12 label-subtitle">
                            {this.props.studentListDescription}
                        </div>
                    </div>
                    <div className="form-group pt-2">
                        <div className="container student-list-key-concept">
                            {this.state.studentItems.map((studentItem: StudentItem) => {
                                if (studentItem.currentDiagnostic === null) {
                                    return this.getStudentAvailableLine(studentItem)
                                }

                                return this.getAlreadyInActiveDiagnosticLine(studentItem)
                            })}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    getStudentAvailableLine(studentItem: StudentItem) {
        return (
            <div
                className="row pt-2 pb-2"
                key={`${studentItem.studentId}-${this.props.type.toString()}`}
            >
                <div className="col-md-4 d-flex align-items-center">
                    <strong className="">
                        {`${studentItem.firstName} ${studentItem.lastName}`}
                    </strong>
                </div>
                <div className="col-md-7">
                    <Select
                        isMulti
                        closeMenuOnSelect={false}
                        components={{ DropdownIndicator }}
                        value={studentItem.items}
                        onChange={(selected: any, metaData: any) =>
                            this.itemOfStudent(selected, studentItem, metaData)
                        }
                        options={this.state.optionsOfStudentMasterItems}
                    />
                </div>
                <div className="col-md-1 small-label-bold-style text-nowrap d-flex align-items-center ">
                    {studentItem.timeFormatted ? studentItem.timeFormatted : '... mins'}
                </div>
            </div>
        )
    }

    getAlreadyInActiveDiagnosticLine(studentItem: StudentItem) {
        return (
            <div
                className="row pt-2 pb-2"
                key={`${studentItem.studentId}-${this.props.type.toString()}`}
            >
                <div className="col-md-4 d-flex align-items-center text-secondary">
                    <strong className="">
                        {`${studentItem.firstName} ${studentItem.lastName}`}
                    </strong>
                </div>
                <div className="col-md-7  text-secondary" style={{ fontStyle: 'italic' }}>
                    Student completing assessment - {studentItem.currentDiagnostic.name}
                </div>
                <div className="col-md-1 small-label-bold-style text-nowrap d-flex align-items-center ">
                    {}
                </div>
            </div>
        )
    }
}

export default StudentDiagnosticSelector
