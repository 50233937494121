import React from 'react'
import { Button, ButtonGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { withOktaAuth } from '@okta/okta-react'
import { QuestionTemplate } from '../../models/Question'
import feedback from '../../assets/images/feedback_image.png'
import { withSnackbar } from 'notistack'

export interface IQuestionResult {
    audioAnswerUri: any
    screenShotUri: string
    questionAnswers: string[]
    questionText: any
    questionAudioText: any
    questionTemplate: QuestionTemplate
    questionId: string
    teacherOverride: boolean
    correct: boolean
    finishType: string
    studentAnswerDescription: string
}

interface IProps {
    sessionData: any
    setIsDataLoading: any
    isDataLoading: boolean
    allowOverride: boolean
    questionResults: IQuestionResult[]
    onOverride: any
    onFeedback: any
    onError: any
    showOverrideSequence:boolean
}

interface IState {
    error: string
    errorVisible: boolean
    showFeedbackModal: boolean
    showOverrideSequenceQuestionModal: boolean
    showOverrideAnswerModal: boolean
    overrideId: string
    refreshLabel: string
    feedbackQuestionId: string
    feedbackQuestionNumber: number
    overrideErrorMessage: string
}

export default class QuestionResultsTable extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            error: '',
            errorVisible: false,
            refreshLabel: '',
            showFeedbackModal: false,
            showOverrideSequenceQuestionModal: false,
            feedbackQuestionId: '',
            feedbackQuestionNumber: null,
            overrideErrorMessage: '',
            showOverrideAnswerModal: false,
            overrideId: null,
        }
    }

    autoRefresh: any

    async componentDidMount() {
        this.loadData()
        // this.autoRefresh = setInterval(() => {
        //   this.loadData();
        // }, 60000);
    }

    componentDidUpdate(prevProps, nextState) {
        if (this.props.showOverrideSequence !== prevProps.showOverrideSequence) {
            this.setState({showOverrideSequenceQuestionModal :this.props.showOverrideSequence});
        }
    }

    componentWillUnmount() {
        this.autoRefresh && clearInterval(this.autoRefresh)
    }

    async loadData() {
        this.props.setIsDataLoading(true)
        this.props.setIsDataLoading(false)
    }


    showHeader = (quesionTemplate: QuestionTemplate) => {
        if (
            quesionTemplate === QuestionTemplate.D1 ||
            quesionTemplate === QuestionTemplate.MC1 ||
            quesionTemplate === QuestionTemplate.MC2
        ) {
            return true
        }

        return false
    }

    getCardColor = (answeredQuestion: IQuestionResult) => {
        if (answeredQuestion.teacherOverride) {
            return 'card-teacher-override'
        }

        if (answeredQuestion.correct) {
            return 'card-mastered'
        }

        return 'card-not-mastered'
    }

    render() {
        return (
            <div>
                <div className="row">
                    {this.props.questionResults.map((answeredQuestion, index) => {
                        return (
                            <div className="col-md-6" key={answeredQuestion.questionId}>
                                <div className={`card mb-4 ${this.getCardColor(answeredQuestion)}`}>
                                    <div className="form-group p-2">
                                        <div className="space-between">
                                            <strong>
                                                {answeredQuestion.studentAnswerDescription}
                                            </strong>
                                            <a
                                                href=""
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    this.toggleFeedback(
                                                        answeredQuestion.questionId,
                                                        index
                                                    )
                                                }}
                                            >
                                                {' '}
                                                <img
                                                    alt="feedback"
                                                    src={feedback}
                                                    title="send feedback"
                                                ></img>
                                            </a>
                                        </div>
                                    </div>

                                    {answeredQuestion.screenShotUri !== null ? (
                                            <img
                                            className="card-img-top pl-3 pr-3"
                                            src={answeredQuestion.screenShotUri}
                                            alt=" Screenshot was not saved due to network problems."
                                            />
                                    ) : null  }

                                    <div className="card-body card-body-height header-in-center-div p-0">
                                        {answeredQuestion.audioAnswerUri !== null ? (
                                            <audio controls>
                                                <source
                                                    src={answeredQuestion.audioAnswerUri}
                                                    type="audio/mpeg"
                                                />
                                                Your browser does not support the audio tag.
                                            </audio>
                                        ) : null}

                                        {answeredQuestion.finishType && (
                                            <h6>{answeredQuestion.finishType}</h6>
                                        )}

                                        {this.showTeacherOverrideControls(answeredQuestion)}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div>
                    <Modal
                        isOpen={this.state.showFeedbackModal}
                        toggle={() => this.toggleFeedback(null, 0)}
                        className="warning"
                    >
                        <ModalHeader>Question Feedback</ModalHeader>
                        <ModalBody>
                            Please let us know any feedback you have regarding this question, and
                            Early Insights will review your comments and get back to you shortly.
                            <div className="pt-2">
                                <Input type="textarea" name="text" id="feedbackText" rows="10" />
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div style={{ width: '100%' }}>
                                <div className="space-between">
                                    <Button
                                        color="secondary"
                                        onClick={() => this.toggleFeedback(null, 0)}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            this.props.onFeedback(
                                                this.state.feedbackQuestionId,
                                                this.state.feedbackQuestionNumber
                                            )
                                            this.toggleFeedback(null, 0)
                                        }}
                                    >
                                        Send
                                    </Button>
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal>
                </div>
                <div>
                    <Modal
                        isOpen={this.state.showOverrideSequenceQuestionModal}
                        toggle={this.toggleOverrideSequenceQuestionModal}
                        className="warning"
                    >
                        <ModalBody>{"This question is part of a sequence of questions within one statement. They must be answered correctly together in the same assessment to master their statement."}</ModalBody>
                        <ModalFooter>
                            <ButtonGroup>
                                <Button
                                    color="secondary"
                                    onClick={this.toggleOverrideSequenceQuestionModal}
                                >
                                    Close
                                </Button>
                            </ButtonGroup>
                        </ModalFooter>
                    </Modal>
                </div>
                <div>
                    <Modal
                        isOpen={this.state.showOverrideAnswerModal}
                        toggle={this.toggleOverrideAnswerModal}
                    >
                        <ModalHeader toggle={this.toggleOverrideAnswerModal}>
                            Override Answer
                        </ModalHeader>
                        <ModalBody>
                            Are you sure you want to override this question&apos;s answer to
                            correct?
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.toggleOverrideAnswerModal}>
                                Cancel
                            </Button>
                            <Button
                                color="danger"
                                onClick={async () => {
                                    await this.props.onOverride(this.state.overrideId)
                                    this.toggleOverrideAnswerModal()
                                }}
                            >
                                Override
                            </Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        )
    }

    toggleFeedback = (questionId, questionNumber) => {
        this.setState({
            showFeedbackModal: !this.state.showFeedbackModal,
            feedbackQuestionId: questionId,
            feedbackQuestionNumber: questionNumber,
        })
    }

    toggleOverrideSequenceQuestionModal = () => {
        this.setState({
            showOverrideSequenceQuestionModal: !this.state.showOverrideSequenceQuestionModal,
        })
    }

    toggleOverrideAnswerModal = () => {
        this.setState({
            showOverrideAnswerModal: !this.state.showOverrideAnswerModal,
        })
    }

    showTeacherOverrideControls(questionResult: IQuestionResult): React.ReactNode {
        if (questionResult.teacherOverride) {
            return (
                <div className="p-2 text-center">
                    {' '}
                    <strong>Answer changed to correct by the teacher</strong>{' '}
                </div>
            )
        }

        if (this.props.allowOverride && !questionResult.correct) {
            return (
                <Button
                    color="primary"
                    onClick={() => {
                        this.setState({ overrideId: questionResult.questionId })
                        this.toggleOverrideAnswerModal()
                    }}
                >
                    Override answer
                </Button>
            )
        }
    }
}
withSnackbar(withOktaAuth(QuestionResultsTable))
