import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { overrideAnswerApi, sendFeedbackApi } from '../../services/DiagnosticApi'
import { Button, Alert } from 'reactstrap'
import { withOktaAuth } from '@okta/okta-react'
import { AuthService, AuthState } from '../../models/Auth'
import BaseClass from '../Bases/BaseClass'
import BreadCrumbs from '../layout/BreadCrumbs'
import { withSnackbar } from 'notistack'

import {
    getSpacedPracticeStudentSessionQuestionsApi,
    postFeedback,
} from '../../services/SpacedPracticeApi'
import { archivedClassGuid, emptyGuid } from '../../shared/Helper'
import QuestionResultsTable, { IQuestionResult } from '../diagnostic/QuestionResultsTable'

interface IParams {
    // sessionId: string;
    studentId: string
    start: string
    end: string
}

interface IProps extends RouteComponentProps<IParams> {
    authService: AuthService
    authState: AuthState
    sessionData: any
    setIsDataLoading: any
    isDataLoading: boolean
    match: any
    history: any
}

interface IState {
    error: string
    errorVisible: boolean
    data: any
    showOverrideSequenceQuestionModal: boolean
    refreshLabel: string
    overrideErrorMessage: string
}

class SpacedPracticeResult extends BaseClass<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            error: '',
            errorVisible: false,
            data: [],
            refreshLabel: '',
            showOverrideSequenceQuestionModal: false,
            overrideErrorMessage: '',
        }
    }

    autoRefresh: any

    async componentDidMount() {
        this.loadData()
        // this.autoRefresh = setInterval(() => {
        //   this.loadData();
        // }, 60000);
    }

    componentWillUnmount() {
        this.autoRefresh && clearInterval(this.autoRefresh)
    }

    async loadData() {
        this.props.setIsDataLoading(true)
        try {
            const res = await getSpacedPracticeStudentSessionQuestionsApi(
                this.props.authState.accessToken.accessToken,
                this.props.match.params.studentId,
                this.props.match.params.start,
                this.props.match.params.end,
                4
            )
            if (res.ok) {
                this.props.setIsDataLoading(false)
                this.setState({
                    data: res.data,
                    refreshLabel: `Last refresh - ${new Date().toLocaleTimeString()}`,
                })
            } else {
                this.handleknownError(res.error)
            }
        } catch (error) {
            this.handleUnknownError(error)
        }

        this.props.setIsDataLoading(false)
    }

    onshowError(error) {
        this.handleUnknownError(error)
    }

    convertToQuestionResults(data: any): IQuestionResult[] {
        if (!data || data.length === 0) return []

        let converted = []
        data.questionResultDtos.forEach((element) => {
            converted.push({
                audioAnswerUri: element.audioAnswerUri,
                screenShotUri: element.screenShotUri,
                questionAnswers: element.questionAnswers,
                questionText: element.questionText,
                questionAudioText: element.questionAudioText,
                questionTemplate: element.questionTemplate,
                questionId: element.questionId,
                teacherOverride: element.teacherOverride,
                correct: element.correct,
                finishType: element.finishType,
                studentAnswerDescription: element.studentAnswerDescription,
            })
        })

        return converted
    }

    async sendFeedback(id, num) {
        let teacher = this.props.sessionData.authenticatedTeacher
        if (!teacher) return

        let feedbackText = (document.getElementById('feedbackText') as HTMLInputElement).value

        this.props.setIsDataLoading(true)
        await postFeedback(
            this.props.authState.accessToken.accessToken,
            this.props.match.params.studentId,
            id,
            teacher.id,
            feedbackText
        )

        this.props.setIsDataLoading(false)
        ;['Feedback sent!'].forEach((v) => {
            ;(this.props as any).enqueueSnackbar(v, {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                autoHideDuration: 2000,
            })
        })
    }

    render() {
        return (
            <div>
                <div className="mb-2">
                    <BreadCrumbs
                        items={[
                            { title: 'Home', link: '/' },
                            { title: 'Spaced Practice', link: '/spaced-practice' },
                            ,
                            { title: 'Spaced Practice Results', link: '' },
                        ]}
                    />
                </div>

                {this.state.errorVisible ? (
                    <Alert color="warning">
                        <p>{this.state.error}</p>
                    </Alert>
                ) : null}

                <h2 className="pb-2">
                    <div className="space-between">
                        {this.state.data && this.state.data.firstName}{' '}
                        {this.state.data && this.state.data.lastName} <div></div>
                        <div>
                            <Button color="link" onClick={() => this.loadData()}>
                                {this.state.refreshLabel}
                            </Button>
                        </div>
                    </div>
                </h2>
                <div className="pb-2">
                    <h6>
                        {'Date range: '} {this.props.match.params.start}{' '}
                        {this.props.match.params.end}
                    </h6>
                </div>
                <QuestionResultsTable
                    {...this.props}
                    allowOverride={false}
                    questionResults={this.convertToQuestionResults(this.state.data)}
                    onOverride={(e) => {}}
                    onFeedback={(id, num) => this.sendFeedback(id, num)}
                    onError={(e) => this.onshowError(e)}
                    showOverrideSequence={false}
                />
            </div>
        )
    }
}
export default withSnackbar(withOktaAuth(SpacedPracticeResult))
