import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import OktaSignInWidget from './OktaSignInWidget'
import '@okta/okta-auth-js/polyfill'
import { useOktaAuth } from '@okta/okta-react'
import { getVersionCheck } from '../../services/TestSupportApi'

interface IProps {
    config: any
    setIsDataLoading: any
}

const Login = (props: IProps) => {
    const { oktaAuth, authState } = useOktaAuth()

    useEffect(() => {
      //  props.setIsDataLoading(false);
        async () => {
            let response = await getVersionCheck()
            if (response.ok) {
                console.log('api version: ' + response.data.version)
                console.log('sync version: ' + response.data.syncVersion)
            }
        }
    }, [])

    const onSuccess = (tokens) => {
        props.setIsDataLoading(true)
        oktaAuth.handleLoginRedirect(tokens)
    }

    const onError = (err) => {
        props.setIsDataLoading(false)
        console.log('error logging in', err)
    }

    if (!authState) {
       // props.setIsDataLoading(false)
        return null
    }

    if (!authState.isAuthenticated ){
        props.setIsDataLoading(false)
    }
    else{
        props.setIsDataLoading(true)
    }

    return authState.isAuthenticated ? (
        <Redirect to={{ pathname: '/' }} />
    ) : (
        <div data-cy="login-page-loaded" className="pt-5">
            <OktaSignInWidget props={props} config={props.config} onSuccess={onSuccess} onError={onError} />
        </div>
    )
}
export default Login
