import 'bootstrap/dist/css/bootstrap.min.css'

import 'jquery-ui/themes/base/all.css'
import $ from 'jquery'
import 'jquery-ui/ui/widgets/tooltip.js'
import React from 'react'
import ReactDOM from 'react-dom'
import './styles/custom.scss'
import './index.css'
import 'font-awesome/css/font-awesome.min.css'
import App from './App'
import * as Sentry from '@sentry/browser'
import ReactGA from 'react-ga'
import { getClientWebsiteType, WebsiteType } from './shared/Helper'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import appInfo from '../package.json'
import '@okta/okta-auth-js/polyfill'
import { SnackbarProvider } from 'notistack'
import axios from 'axios'

// TOOL TIP CONFIG

$(function () {
    ;($(document) as any).tooltip({
        track: true,
        content() {
            let element = $(this)

            if (element.is('[title]')) {
                let title = element.attr('title')
                let res = title.split('\n')
                let t = ''

                res.forEach((v) => {
                    t += `<div class="text-nowrap">${v}</div>`
                })

                return t
            }
        },

        show: {
            effect: 'none',
            delay: 300,
        },

        hide: {
            effect: 'none',
            delay: 0,
        },
    })
})

let websiteType = getClientWebsiteType()

if (
    websiteType !== WebsiteType.LOCAL_WITH_LOCAL_API &&
    websiteType !== WebsiteType.LOCAL_WITH_EXTERNAL_API
) {
    Sentry.init({
        dsn: 'https://6941d813715d45308b36868387c4eb2a@sentry.io/1731405',
    })

    Sentry.setTag('version', appInfo.version)
}

const history = createBrowserHistory()

// // Initialize google analytics page view tracking
// ReactGA.initialize('UA-165379028-1')
// history.listen((data) => {
//   ReactGA.set({ page: data.location.pathname }) // Update the user's current page
//   ReactGA.pageview(data.location.pathname) // Record a pageview for the given page
// })

console.log(appInfo.version)

axios.defaults.headers.common['syncversion'] = appInfo.syncVersion

const script = document.createElement('script')

script.src = '/scripts/iframeResizer.contentWindow.min.js'
script.async = false
document.body.appendChild(script)

ReactDOM.render(
    <Router history={history}>
        <SnackbarProvider maxSnack={10}>
            <App />
        </SnackbarProvider>
    </Router>,
    document.getElementById('root')
)
