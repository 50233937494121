import axios from 'axios'
import { handleApiSuccess, handleApiError, IApiResult } from '../shared/ApiHandler'
import { teacherBaseApiUrl, teacherApiUrl } from '../shared/ApiUrls'
import { TeacherCreateDto } from '../models/Teacher'

export const updateTeacherSettingsApi = (
    teacherId: string,
    teacherSettings: any,
    token: string
): Promise<IApiResult> => {
    return axios
        .put(`${teacherApiUrl}/UpdateTeacherSettings/${teacherId}`, teacherSettings, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const getAuthenticatedTeacherFromApi = (
    token: string,
    isLogin: boolean
): Promise<IApiResult> => {
    console.log('GetTeacher')
    return axios
        .get(`${teacherApiUrl}/GetTeacher?isLogin=${isLogin ? 'true' : 'false'}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then((res) => {
            return handleApiSuccess(res)
        })
        .catch(handleApiError)
}

export const getTeachers = (schoolId: string, token: string): Promise<IApiResult> => {
    return axios
        .get(`${teacherApiUrl}/GetListTeachers/${schoolId}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const getTeacherApi = (teacherId: string, token: string): Promise<IApiResult> => {
    return axios
        .get(`${teacherApiUrl}/GetTeacher/${teacherId}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const addTeacher = (
    schoolId: string,
    teacher: TeacherCreateDto,
    token: string
): Promise<IApiResult> => {
    return axios
        .post(`${teacherApiUrl}/${schoolId}`, teacher, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

// export const activateTeacher = (
//   teacherId: string,
//   token: string
// ): Promise<IApiResult> => {
//   return axios
//     .post(`${teacherApiUrl}/activate/${teacherId}`, null, {
//       headers: { Authorization: "bearer " + token }, baseURL: teacherBaseApiUrl
//     })
//     .then(handleApiSuccess)
//     .catch(handleApiError);
// };

export const updateSuperTeacherToNewSchoolApi = (
    teacherId: string,
    schoolId: string,
    token: string
): Promise<IApiResult> => {
    return axios
        .put(`${teacherApiUrl}/UpdateSuperTeacherToNewSchool/${teacherId}/${schoolId}`, null, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const updateTeacher = (
    teacherId: string,
    teacher: TeacherCreateDto,
    token: string
): Promise<IApiResult> => {
    return axios
        .put(`${teacherApiUrl}/${teacherId}`, teacher, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const deleteTeacher = (
    teacherId: string,
    // teacher: TeacherCreateDto,
    token: string
): Promise<IApiResult> => {
    return axios
        .delete(`${teacherApiUrl}/${teacherId}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const addBulkTeachers = (
    //schoolId: string,
    schoolId: string,
    teachers: FormData,
    token: string
): Promise<IApiResult> => {
    return (
        axios
            //.post(`${studentApiUrl}/AddBulk/${schoolId}/${schoolClassId}`, students, {
            .post(`${teacherApiUrl}/AddBulk/${schoolId}`, teachers, {
                headers: { Authorization: `bearer ${token}` },
                baseURL: teacherBaseApiUrl,
            })
            .then(handleApiSuccess)
            .catch(handleApiError)
    )
}
