/* eslint-disable no-unused-vars */
export interface TeacherDto {
    id: string
    firstname: string
    lastname: string
    username: string
    email: string
    schoolId: string
    schoolName: string
    accessLevel: string

    isDemo: boolean
    isExtendedDemo: boolean
    //hasDemoExpired:boolean;
    demoExpiryInSecs: number
    demoOpportunityId: string
    isFirstLogon: boolean
    teacherSettings: any
}

export interface TeacherCreateDto {
    firstname: string
    lastname: string
    accessLevel: string
    email: string
    teacherSettings: any
}

export enum TeacherAccessLevel {
    Standard = 0,
    Admin = 1,
    Super = 2,
}

export interface TeacherSettings {
    groupPageSettings: GroupPageSettings
    classReportPageSettings: ClassReportPageSettings
    studentProfilePageSettings: StudentProfilePageSettings
}

export interface GroupPageSettings {
    filteredKeyConceptIds: string
}

export interface ClassReportPageSettings {
    filteredKeyConceptIds: string
}

export interface StudentProfilePageSettings {
    filteredKeyConceptIds: string
}
