/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react'
import { Button, Alert, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import './Order.css'
import Select from 'react-select'
import { SchoolCombo } from './SchoolCombo'
import { CreateDemoApi } from '../../services/OrderApi'
import { FullPageSpinner } from '../layout/FullPageSpinner'
import ReactPixel from 'react-facebook-pixel'
import { ScrollToTop } from '../../shared/Helper'

const options = [
    { value: 'NSW', label: 'NSW' },
    { value: 'VIC', label: 'VIC' },
    { value: 'QLD', label: 'QLD' },
    { value: 'ACT', label: 'ACT' },
    { value: 'TAS', label: 'TAS' },
    { value: 'NT', label: 'NT' },
    { value: 'WA', label: 'WA' },
    { value: 'SA', label: 'SA' },
]

interface IProps {
    // sessionData: ISessionData;
    // setIsDataLoading:any
    setIsDataLoading: any
    isDataLoading: any
    history: any
    inPortal: boolean
}

export enum ValidForDemoStatusEnum {
    Failed,
    PassedAsNewCustomer,
    PassedAsNewTeacherAndExistingCustomer,
    PassedAsExistingTeacherAndExistingCustomer,
    NeedsConfirmation,
}

export default function DemoIFrame(props: IProps) {
    const [email, setEmail] = useState(null)
    const [schoolOption, setSchoolOption] = useState(null)
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [error, setError] = useState('')
    const [errorVisible, setVisible] = useState(false)
    const [showMustSelectSchool, setShowMustSelectSchool] = useState(false)
    const [useCustomSchool, setUseCustomSchool] = useState(false)
    const [customSchoolName, setCustomSchoolName] = useState('')
    const [customSchoolAddress, setCustomSchoolAddress] = useState('')
    const [customSchoolAddress2, setCustomSchoolAddress2] = useState('')
    const [customSchoolState, setCustomSchoolState] = useState('')
    const [customSchoolSuburb, setCustomSchoolSuburb] = useState('')
    const [customSchoolPostCode, setCustomSchoolPostCode] = useState('')
    const [customSchoolPhone, setCustomSchoolPhone] = useState('')
    // const [isOpen, setIsOpen] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false)
    // const [disableSchools, setDisableSchools] = useState(false)

    const childRef = useRef()

    useEffect(() => {
        props.setIsDataLoading(true)

        try {
            document.domain = 'mathspathway.com'
        } catch (error) {
            // console.log(error);
        }

        // override background colour
        document.querySelector('html').style.backgroundColor = 'white'
        document.getElementById('root').style.backgroundColor = 'white'

        ReactPixel.init('356309674824876')
        ReactPixel.pageView() // For tracking page view
        ReactPixel.track('InitiateCheckout') // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9

        props.setIsDataLoading(false)
    }, [])

    const onHandleChange = (e: any) => {
        if (childRef.current != null) {
            ;(childRef.current as any).changeAusState(e)
        }
    }

    const createDemo = () => {
        setVisible(false)
        setError('')
        props.setIsDataLoading(true, ['Please wait while we set up your trial...'])
        if (!useCustomSchool && !schoolOption) {
            setShowMustSelectSchool(true)
            props.setIsDataLoading(false)
            return
        }

        let demoCreateDto = {
            school: schoolOption ? schoolOption.label : null,
            email,
            firstName,
            lastName,
            //  createdDate: date.format(new Date(), 'YYYY-MM-DD HH:mm:ss'),
            useCustomSchool,
            customSchool: {
                schoolName: customSchoolName,
                schoolSuburb: customSchoolSuburb,
                schoolState: customSchoolState,
                schoolPostCode: customSchoolPostCode,
                schoolAddress: customSchoolAddress,
                schoolAddress2: customSchoolAddress2,
                schoolPhone: customSchoolPhone,
            },
        }

        return CreateDemoApi(demoCreateDto)
            .then((res) => {
                if (res.ok) {
                    props.setIsDataLoading(false)
                    setVisible(false)

                    return props.history.push('/demo/complete')
                }

                props.setIsDataLoading(false)
                setVisible(true)
                setError(res.error.detail)
                ScrollToTop()

                return ValidForDemoStatusEnum.Failed
            })
            .catch((error) => {
                props.setIsDataLoading(false)
                setVisible(true)
                setError(error.statusText)
                ScrollToTop()

                return ValidForDemoStatusEnum.Failed
            })
    }

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        await createDemo()
    }

    // const togglePopover = () => {
    //   setIsOpen(!isOpen);
    // }

    const toggleConfirm = () => {
        props.setIsDataLoading(false)
        setConfirmModal(!confirmModal)
    }

    const confirm = async () => {
        await createDemo()
    }

    const dansStyling = {
        fontWeight: 600,
        color: 'black',
    }

    // props.setIsDataLoading(isDataLoading);
    // if (props.isDataLoading) return null

    return (
        <div data-cy="trial-iframe-page-loaded" className="container bg-white">
            <link rel="stylesheet" type="text/css" href={'iframe-style.css'} />

            {errorVisible ? (
                <Alert color="warning">
                    <p>{error}</p>
                </Alert>
            ) : null}

            <form className="needs-validation" onSubmit={onSubmit}>
                <div className="iframe-font flex flex-wrap justify-between">
                    {!props.inPortal ? (
                        <>
                            <div className="iframe w-full md:w-1/2 mb-4 md:pr-4">
                                <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">
                                    First Name
                                </label>
                                <input
                                    data-cy="first-name-input"
                                    type="text"
                                    className="form-control w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight"
                                    style={dansStyling}
                                    id="firstName"
                                    placeholder="John"
                                    required
                                    onChange={(e) => setFirstName(e.target.value)}
                                ></input>
                            </div>
                            <div className="w-full md:w-1/2 mb-4 md:pl-4">
                                <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">
                                    Last Name
                                </label>
                                <input
                                    data-cy="last-name-input"
                                    type="text"
                                    className="form-control w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight"
                                    style={dansStyling}
                                    id="lastName"
                                    placeholder="Smith"
                                    required
                                    onChange={(e) => setLastName(e.target.value)}
                                ></input>
                            </div>
                            <div className="w-full md:w-1/2 mb-4 md:pr-4">
                                <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">
                                    Email
                                </label>
                                <input
                                    data-cy="email-input"
                                    type="email"
                                    className="form-control w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight"
                                    style={dansStyling}
                                    id="email"
                                    placeholder="you@example.com"
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                ></input>
                            </div>
                            <div className="w-full md:w-1/2 mb-4 md:pl-4">
                                <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">
                                    State
                                </label>
                                <Select
                                    id="state"
                                    isDisabled={false}
                                    options={options}
                                    onChange={onHandleChange}
                                />
                            </div>
                            <div className="w-full mb-4">
                                {useCustomSchool ? (
                                    <></>
                                ) : (
                                    <>
                                        <div className="mb-2">
                                            <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">
                                                School
                                            </label>
                                            <span id="school">
                                                <SchoolCombo
                                                    ref={childRef}
                                                    isDisabled={false}
                                                    onChange={setSchoolOption}
                                                />
                                            </span>
                                        </div>
                                        {showMustSelectSchool ? (
                                            <>
                                                <Alert color="warning">
                                                    You must select a school from the dropdown. if
                                                    you cannot find it please tick the box below.
                                                </Alert>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="w-full mb-4">
                                <input
                                    type="checkbox"
                                    id="save-info-000"
                                    name="no-school"
                                    className="ml-2"
                                    onChange={(e) => setUseCustomSchool(e.target.checked)}
                                />
                                <label htmlFor="scales" className="ml-2">
                                    I can´t find my school
                                </label>
                            </div>
                            {useCustomSchool ? (
                                <>
                                    <Alert color="warning">
                                        Please enter your school details — you must be associated
                                        with a school to proceed with your purchase. Early Insights
                                        is not currently available for homeschooling or private
                                        tutoring.
                                    </Alert>
                                    <div className="w-full mb-4">
                                        <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">
                                            School Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight"
                                            style={dansStyling}
                                            id="Name"
                                            placeholder="Name"
                                            required
                                            onChange={(e) => setCustomSchoolName(e.target.value)}
                                        ></input>
                                    </div>
                                    <div className="w-full mb-4">
                                        <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">
                                            School Address
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight"
                                            style={dansStyling}
                                            id="Address"
                                            placeholder="Address"
                                            required
                                            onChange={(e) => setCustomSchoolAddress(e.target.value)}
                                        ></input>
                                    </div>
                                    <div className="w-full mb-4">
                                        <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">
                                            School Address 2
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight"
                                            style={dansStyling}
                                            id="Address2"
                                            placeholder="Address2"
                                            onChange={(e) =>
                                                setCustomSchoolAddress2(e.target.value)
                                            }
                                        ></input>
                                    </div>
                                    <div className="w-full mb-4">
                                        <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">
                                            School Suburb
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight"
                                            style={dansStyling}
                                            id="Suburb"
                                            placeholder="Suburb"
                                            required
                                            onChange={(e) => setCustomSchoolSuburb(e.target.value)}
                                        ></input>
                                    </div>
                                    <div className="w-full mb-4">
                                        <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">
                                            School State
                                        </label>
                                        <Select
                                            id="state2"
                                            options={options}
                                            onChange={(e) => setCustomSchoolState((e as any).value)}
                                        />
                                    </div>
                                    <div className="w-full mb-4">
                                        <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">
                                            School Postcode
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight"
                                            style={dansStyling}
                                            id="Postcode"
                                            placeholder="Postcode"
                                            required
                                            onChange={(e) =>
                                                setCustomSchoolPostCode(e.target.value)
                                            }
                                        ></input>
                                    </div>
                                    <div className="w-full mb-4">
                                        <label className="block ml-2 text-lg leading-tight mb-3 font-semibold">
                                            School Phone
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control w-full px-6 py-4 bg-blue-100 border-0 rounded-full leading-tight"
                                            style={dansStyling}
                                            id="Phone"
                                            placeholder="Phone"
                                            required
                                            onChange={(e) => setCustomSchoolPhone(e.target.value)}
                                        ></input>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : null}
                    <div className="flex justify-center w-full pb-4 md:pb-0 align-middle">
                        <button
                            data-cy="submit-button"
                            type="submit"
                            className=" relative px-8 py-3 rounded-full bg-orange-500 text-lg text-white font-bold leading-none button-border-orange hover:bg-orange-400 transition duration-200 align-top"
                        >
                            Get started now
                        </button>
                    </div>
                    <div>
                        <Modal isOpen={confirmModal} toggle={toggleConfirm}>
                            <ModalHeader toggle={toggleConfirm}>
                                Continue with purchase?
                            </ModalHeader>
                            <ModalBody>
                                Your school already has an Early Insights account. Do you want to
                                purchase new student licences for your school?
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={toggleConfirm}>
                                    Cancel
                                </Button>
                                <Button color="primary" onClick={confirm}>
                                    Yes
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                </div>
            </form>
        </div>
    )
}
