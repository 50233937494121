/* eslint-disable no-unused-vars */
import { Button, Container, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import NavMenu from './SecureNavMenu'
import { useOktaAuth } from '@okta/okta-react'
import DemoNavMenu from './DemoNavMenu'
import * as Sentry from '@sentry/browser'
import ReactGA from 'react-ga'
import { getStudentAppUrl, logout, ScrollToTop, isTeacherSuper } from '../../shared/Helper'
import { SalesForceActionEnum } from '../../models/Order'
import { ExtendTrialApi, SaveSalesForceActionApi } from '../../services/OrderApi'
import { TeacherDto } from '../../models/Teacher'
import React, { useContext, useEffect, useState } from 'react'
import { getSchoolsFromApi } from '../../services/SchoolApi'
import { SchoolDto } from '../../models/School'
import { RouteComponentProps, useHistory } from 'react-router'
import { ISessionData } from '../../AppRouter'
import { useHooksPagecore } from '../admin/schoolClass/SchoolClassIndex'
import { getSchoolClassesFromApi } from '../../services/SchoolClassApi'
import {
    getSelectedSchoolClassFromLocalStorage,
    getSelectedSchoolFromLocalStorage,
} from '../../shared/LocalStorageHelpers'
import { getKeyConceptsFromApi, getStatementsFromApi } from '../../services/CurriculumApi'
import { getAuthenticatedTeacherFromApi } from '../../services/TeacherApi'

interface IProps extends RouteComponentProps {
    children: any
    sessionData: ISessionData
    onChangeSessionData: any
    setIsDataLoading: any
    isDataLoading: boolean
}

export default function SecureApp(props: IProps) {
    const { oktaAuth, authState, authService } = useOktaAuth()
    const [isDemo, setIsDemo] = useState(false)
    const [demoView, setDemoView] = useState('teacher')
    const [error, setError] = useState('')
    const [errorVisible, setErrorVisible] = useState(false)
    const [timerInSecs, setTimerInSecs] = useState(0)
    const [showExtendDemoModal, setShowExtendDemoModal] = useState(false)
    const history = useHistory()

    useEffect(() => {
        console.log('load')
        authState.isAuthenticated && loadData()
    }, [])

    // useEffect(() => {
    //   && props.sessionData.selectedSchoolClass
    //   authState.isAuthenticated && loadData();
    // }, [props.sessionData ]);

    const loadData = async () => {
        let token = authState.accessToken.accessToken
        let authenticatedTeacher = await getAuthenticatedTeacher(token)
        if (!authenticatedTeacher) return logout(oktaAuth)

        let keyConcepts = await getKeyConcepts(token)
        let statements = await getStatements(token)

        populateExternalAppData(authenticatedTeacher)
        let schools = await getSchoolsApi()
        let schoolClasses = await getUserSchoolClasses()
        if (authenticatedTeacher.isDemo) {
            if (authenticatedTeacher.demoExpiryInSecs < 0) {
                if (authenticatedTeacher.isExtendedDemo) {
                    alert('your demo has expired')
                    return logout(oktaAuth)
                }
                setShowExtendDemoModal(true)
            }
            saveSalesForceAction(authenticatedTeacher, SalesForceActionEnum.Demo_has_logged_in__c)
        }

        let selectedSchool = getSelectedSchoolFromLocalStorage()
        var doesSchoolExist = selectedSchool
            ? schools && schools.findIndex((v) => v.id === selectedSchool.id) > -1
            : false
        let selectedSchoolClass = getSelectedSchoolClassFromLocalStorage()
        var firstSchool = schools.length > 0 ? schools[0] : null
        var doesSchoolClassExist = selectedSchoolClass
            ? schoolClasses.findIndex((v) => v.id === selectedSchoolClass.id) > -1
            : false
        var firstClass = schoolClasses.length > 0 ? schoolClasses[0] : null

        setTimerInSecs(authenticatedTeacher.demoExpiryInSecs)
        setIsDemo(authenticatedTeacher.isDemo)

        props.onChangeSessionData({
            authenticatedTeacher,
            schools,
            selectedSchool: doesSchoolExist ? selectedSchool : firstSchool,
            schoolClasses: schoolClasses,
            selectedSchoolClass: doesSchoolClassExist ? selectedSchoolClass : firstClass,
            keyConcepts: keyConcepts,
            statements: statements,
        })
    }

    const getStatements = async (token) => {
        return getStatementsFromApi(token)
            .then((res) => {
                if (res.ok) {
                    return res.data
                }
                handleknownError(res.error)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getKeyConcepts = async (token) => {
        return getKeyConceptsFromApi(token)
            .then((res) => {
                if (res.ok) {
                    return res.data
                }
                handleknownError(res.error)
            })
            .catch((error) => {
                handleUnknownError(error)
            })
    }

    const getAuthenticatedTeacher = (token): Promise<any> => {
        return getAuthenticatedTeacherFromApi(token, true)
            .then((res) => {
                if (res.ok) return res.data
                return logout(oktaAuth)
                // handleknownError(res.error);
            })
            .catch((error) => {
                console.log(error)
                //  handleUnknownError(error);
                return logout(oktaAuth)
            })
    }

    const handleknownError = (errors) => {
        console.log(errors)
        ScrollToTop()
        setError(errors.detail)
        setErrorVisible(true)
        alert(errors.detail)
        // return logout(oktaAuth);
    }

    const handleUnknownError = (error) => {
        console.log(error)
        ScrollToTop()
        if (error.status === 401) {
            //  return logout(oktaAuth);
        }
        setError(error.statusText)
    }

    const getSchoolsApi = (): Promise<any> => {
        let token = authState.accessToken.accessToken
        return getSchoolsFromApi(token)
            .then((res) => {
                if (res.ok) {
                    return res.data
                }
                handleknownError(res.error)
            })
            .catch((error) => {
                handleUnknownError(error)
            })
    }

    const getUserSchoolClasses = (): Promise<any> => {
        let token = authState.accessToken.accessToken
        return getSchoolClassesFromApi(token, true)
            .then((res) => {
                if (res.ok) {
                    return res.data
                }
                handleknownError(res.error)
            })
            .catch((error) => {
                handleUnknownError(error)
            })
    }

    const populateExternalAppData = (teacher: TeacherDto) => {
        Sentry.configureScope((scope) => {
            scope.setUser({ id: teacher.id, email: teacher.email })
        })
        ReactGA.set({
            userId: teacher.email,
        })
    }

    const saveSalesForceAction = (teach, demoAction: SalesForceActionEnum) => {
        if (!teach.isDemo || !teach.demoOpportunityId) {
            return
        }
        let token = authState.accessToken.accessToken
        return SaveSalesForceActionApi(token, teach.demoOpportunityId, demoAction)
            .then((res) => {
                if (res.ok) return res.data

                console.log(res.error.detail)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const setView = async (demoView) => {
        setDemoView(demoView)
        if (demoView === 'teacher') {
            history.push('/data')
        }
    }

    const extendTrial = (): Promise<any> => {
        let token = authState.accessToken.accessToken
        return ExtendTrialApi(token)
            .then((res) => {
                if (res.ok) {
                    loadData()
                } else {
                    handleknownError(res.error)
                }
            })
            .catch((error) => {
                handleUnknownError(error)
            })
    }

    const extend = async () => {
        await setShowExtendDemoModal(false)
        await extendTrial()
    }

    if (!props.sessionData) return null

    return (
        <div
            data-cy="secure-app-page-loaded"
            style={{
                height: '100%',
            }}
        >
            {isDemo ? (
                <DemoNavMenu
                    {...props}
                    history={history}
                    authState={authState}
                    authService={authService}
                    timerInSecs={timerInSecs}
                    onChangeDemoViewClick={(demoView) => setView(demoView)}
                    //  setIsDataLoading={setIsDataLoading}
                    //  isDataLoading={isDataLoading}
                ></DemoNavMenu>
            ) : null}

            {demoView === 'student' ? (
                <iframe
                    data-cy="student-app-iframe"
                    // title="gromo app"
                    //  onLoad={() => (context.showScreenLoader = false)}
                    // onLoadStart={() => (context.showScreenLoader = true)}
                    allow="camera;microphone"
                    style={{
                        border: '0',
                        width: '100%',
                        height: '90%',
                        backgroundColor: 'black',
                        padding: '1%',
                    }}
                    src={`${getStudentAppUrl()}?is-demo=true`}
                ></iframe>
            ) : (
                <>
                    <NavMenu
                        {...authService}
                        sessionData={props.sessionData}
                        onChangeSessionData={props.onChangeSessionData}
                        setIsDataLoading={props.setIsDataLoading}
                        refreshAllData={() => {
                            loadData()
                        }}
                    >
                        {' '}
                    </NavMenu>
                    <Container>{props.children}</Container>
                </>
            )}

            <Modal isOpen={showExtendDemoModal}>
                <ModalHeader>Oh no, your trial account has expired!</ModalHeader>
                <ModalBody>
                    Did you finish looking around or get a chance to show your colleagues? No? Would
                    you like us to extend your trial for another 2 weeks?
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="success"
                        // onClick={moveStudent}
                        onClick={() => extend()}
                        disabled={false}
                    >
                        Yes, I need 2 more weeks
                    </Button>
                    <Button
                        color="danger"
                        onClick={() => {
                            logout(oktaAuth)
                        }}
                    >
                        No, thanks
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
