import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { KeyConceptDto } from '../../models/KeyConcept'
import { ComponentDto } from '../../models/Component'
import { OverallReportByStudentDto } from '../../models/OverallReport'
import { Button, Table } from 'reactstrap'
import './Data.css'
import { StatementDto, StudentStatementReportDto } from '../../models/Statement'
import $ from 'jquery'

export interface IProps {
    keyConcepts: KeyConceptDto[]
    studentReports: OverallReportByStudentDto[]
    statements: StatementDto[]
    printView: boolean
    scale: number
    scalingFactor: number
    fullscreen: boolean
    closeFullscreen: any
    showSideHeader: boolean
    keyConceptHeadingSize: string
    keyConceptFontWeight: number
}

export interface IState {
    scale: number
}

class ClassReport extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            scale: props.scale,
        }
    }

    getListOfComponent(keyConcept: KeyConceptDto) {
        let result = ''

        keyConcept.components.forEach((component) => {
            result += `${component.code} - ${component.name} \n`
        })

        return result
    }

    getMastery(studentStatement: StudentStatementReportDto) {
        if (studentStatement.answeredDate) {
            if (studentStatement.mastered) {
                if (studentStatement.previouslyMastered) {
                    return <span className="dot-previously-mastered" />
                }

                return <span className="dot-mastered" />
            }

            return <span className="dot-not-mastered" />
        }

        return <span className="dot-not-asses-yet" />
    }

    formatComponentName(component: ComponentDto, statements: StatementDto[]) {
        let numOfStatements = this.getNumOfStatementsInComponent(component.id, statements)

        if (numOfStatements === 1 && component.name.length > 40) {
            return `${component.name.substr(0, 40)}..`
        }

        return component.name
    }

    getNumOfStatementsInComponent(componentId: string, statements: StatementDto[]) {
        if (!statements) {
            return 0
        }

        return statements.filter((v) => v.componentId === componentId).length
    }

    getStatement(statementId: string, statements: StatementDto[]) {
        return statements.find((v) => v.id === statementId)
    }

    getNumberOfStatement(keyConcept: KeyConceptDto, statements: StatementDto[]) {
        let numOfStatements = 0

        for (let i = 0; i < keyConcept.components.length; ++i) {
            numOfStatements += this.getNumOfStatementsInComponent(
                keyConcept.components[i].id,
                statements
            )
        }

        return numOfStatements
    }

    getListOfStatement(component: ComponentDto, statements: StatementDto[]) {
        let result = `<div style="font-size:20px;font-weight:bold" class="pb-2">${component.name}</div>`

        statements
            .filter((v) => v.componentId === component.id)
            .forEach((statement) => {
                result += `${statement.code} - ${statement.name} \n`
            })

        return result
    }

    portrait = {
        position: 'relative' as any,
        overflowX: 'auto' as any,
        overflowY: 'auto' as any,
    }

    startScalingAmount = 17

    landscape = this.getLandscapeStyle(1)

    getLandscapeStyle(scale) {
        return {
            height: 'auto',
            overflowX: 'visible' as any,
            transformOrigin: 'right top',
            pageBreakInside: 'avoid' as any,
            transform: `scale(${scale}) rotate(90deg) translateX(100%)`,
        }
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.studentReports !== nextProps.studentReports) {
            return true
        }

        if (this.props.fullscreen !== nextProps.fullscreen) {
            return true
        }

        if (this.props.printView !== nextProps.printView) {
            return true
        }

        return false
    }

    UNSAFE_componentWillUpdate(nextProps) {
        this.beforeUpdate(nextProps)
    }

    beforeUpdate(nextProps) {
        this.setupFullscreen()

        if (this.props.fullscreen !== nextProps.fullscreen && nextProps.fullscreen) {
            let screenWidth = $(window).innerWidth()
            let screenHeight = $(window).innerHeight()

            $('#masterSelect').css({
                visibility: 'hidden' as any,
            })

            let totalStatements = 0

            this.props.keyConcepts.forEach((element) => {
                totalStatements += this.getNumberOfStatement(element, this.props.statements)
            })

            $('#reportDiv').css({
                backgroundColor: 'white',
                position: 'fixed' as any,
                top: 0, //Use it later
                left: 0,
                width: screenWidth,
                overflowX: 'hidden' as any,
                overflowY: 'hidden' as any,
                height: screenHeight,
            })

            $('#scrollSpaceDiv').css({
                height: this.props.studentReports.length * 50 + 200,
                width: totalStatements * 50 + 200,
                overflowX: 'hidden' as any,
                overflowY: 'hidden' as any,
            })
        } else {
            $('#reportDiv').css({
                width: '100%',
                height: '100%',
                position: 'static' as any,
                overflow: 'auto' as any,
            })

            $('#scrollSpaceDiv').css({
                width: '100%',
                height: '100%',
                overflowX: 'hidden' as any,
                overflowY: 'hidden' as any,
            })

            $('#masterSelect').css({
                visibility: 'visible' as any,
            })
        }

        if (this.props.printView !== nextProps.printView && nextProps.printView) {
            if (this.props.studentReports.length > this.startScalingAmount) {
                let originalItemWidth = 1 / this.startScalingAmount
                let newItemWidth = 1 / this.props.studentReports.length
                let scale = newItemWidth / originalItemWidth

                this.landscape = this.getLandscapeStyle(scale)
            }
        }
    }

    // componentWillUpdate(nextProps, nextState) {
    //   this.setupFullscreen();

    //   if (this.props.fullscreen !== nextProps.fullscreen && nextProps.fullscreen) {
    //     let screenWidth = $(window).innerWidth();
    //     let screenHeight = $(window).innerHeight();

    //     $('#masterSelect').css({
    //       'visibility': 'hidden' as any
    //     });

    //     var totalStatements = 0;
    //     this.props.keyConcepts.forEach(element => {
    //       totalStatements += this.getNumberOfStatement(element, this.props.statements);
    //     });

    //     $('#reportDiv').css({
    //       'backgroundColor': 'white',
    //       'position': 'fixed' as any,
    //       'top': 0, //Use it later
    //       'left': 0,
    //       'width': screenWidth,
    //       'overflowX': 'hidden' as any,
    //       'overflowY': 'hidden' as any,
    //       'height': screenHeight,
    //     });

    //     $('#scrollSpaceDiv').css({
    //       'height': (this.props.studentReports.length * 50) + 200,
    //       'width': totalStatements * 50 + 200,
    //       'overflowX': 'hidden' as any,
    //       'overflowY': 'hidden' as any
    //     });

    //   }
    //   else {
    //     $('#reportDiv').css({
    //       'width': '100%',
    //       'height': '100%',
    //       'position': 'static' as any,
    //       'overflow': 'auto' as any,
    //     });

    //     $('#scrollSpaceDiv').css({
    //       'width': '100%',
    //       'height': '100%',
    //       'overflowX': 'hidden' as any,
    //       'overflowY': 'hidden' as any
    //     });

    //     $('#masterSelect').css({
    //       'visibility': 'visible' as any
    //     });
    //   }

    //   if (this.props.printView !== nextProps.printView && nextProps.printView) {
    //     if ((this.props.studentReports.length > this.startScalingAmount)) {
    //       let originalItemWidth = (1 / this.startScalingAmount);
    //       let newItemWidth = (1 / (this.props.studentReports.length));
    //       let scale = newItemWidth / originalItemWidth;
    //       this.landscape = this.getLandscapeStyle(scale);
    //     }
    //   }
    // }

    setupFullscreen() {
        let reportDiv = document.getElementById('reportDiv')
        let myReportDivTop = $('#reportDiv').offset().top

        $(window).on('scroll', () => {
            if (!this.props.fullscreen) {
                return
            }

            let screenScrollY = $(window).scrollTop()

            reportDiv.scrollTop = screenScrollY - myReportDivTop

            let screenScrollX = $(window).scrollLeft()

            reportDiv.scrollLeft = screenScrollX
        })
    }

    render() {
        return (
            <div className="report-container">
                <div style={this.props.printView ? this.landscape : this.portrait} id="reportDiv">
                    {this.props.fullscreen ? (
                        <>
                            <Button
                                color="primary"
                                style={{
                                    top: '15px',
                                    right: '15px',
                                    position: 'fixed',
                                    zIndex: 5,
                                }}
                                onClick={() => this.props.closeFullscreen()}
                            >
                                Close
                            </Button>
                        </>
                    ) : null}
                    <Table
                        id="myTable2"
                        className={'table table-bordered first-column-max-width mb-0 tableHeader'}
                    >
                        <thead id="myHeader">
                            <tr>
                                {this.props.showSideHeader ? (
                                    <th
                                        style={{
                                            backgroundColor: 'white',
                                            fontSize: 18,
                                        }}
                                        rowSpan={2}
                                        className=" offset-studentName text-left align-middle"
                                    >
                                        Student Name
                                    </th>
                                ) : null}
                                {this.props.keyConcepts.map((keyconcept) => {
                                    return (
                                        <th
                                            className="p-0"
                                            style={{
                                                backgroundColor: 'white',
                                            }}
                                            key={keyconcept.id}
                                            colSpan={this.getNumberOfStatement(
                                                keyconcept,
                                                this.props.statements
                                            )}
                                        >
                                            <div
                                                style={{
                                                    fontSize: this.props.keyConceptHeadingSize,
                                                    fontWeight: this.props.keyConceptFontWeight,
                                                }}
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title={this.getListOfComponent(keyconcept)}
                                            >
                                                {keyconcept.name}
                                            </div>
                                        </th>
                                    )
                                })}
                            </tr>
                            <tr>
                                {this.props.keyConcepts.map((keyconcept) => {
                                    return keyconcept.components.map((component) => {
                                        let numOfStatements = this.getNumOfStatementsInComponent(
                                            component.id,
                                            this.props.statements
                                        )

                                        return (
                                            <th
                                                className={
                                                    numOfStatements === 1
                                                        ? 'p-0 align-middle'
                                                        : 'p-0'
                                                }
                                                key={component.id}
                                                colSpan={numOfStatements}
                                                style={{
                                                    backgroundColor: 'white',
                                                    maxHeight: '100px',
                                                    minWidth: `${numOfStatements * 50}px`,
                                                    maxWidth: `${numOfStatements * 50}px`,
                                                    wordWrap: 'break-word',
                                                }}
                                            >
                                                <Button
                                                    style={{
                                                        minWidth: `${numOfStatements * 50}px`,
                                                        maxWidth: `${numOfStatements * 50}px`,
                                                        wordWrap: 'break-word',
                                                        padding: 0,
                                                        fontSize:
                                                            numOfStatements <= 2 ? '12px' : '16px', // transform: `rotate(90deg) origin(0 0)`,
                                                        writingMode:
                                                            numOfStatements === 1
                                                                ? 'vertical-rl'
                                                                : 'horizontal-tb',
                                                        maxHeight: '100px',
                                                        textAlign: 'left',
                                                    }}
                                                    className=""
                                                    color="link"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title={this.getListOfStatement(
                                                        component,
                                                        this.props.statements
                                                    )}
                                                    tag={Link}
                                                    onClick={() => {
                                                        $('[data-toggle="tooltip"]').remove()
                                                    }}
                                                    to={`/data/component/${component.id}`}
                                                >
                                                    {this.formatComponentName(
                                                        component,
                                                        this.props.statements
                                                    )}
                                                </Button>
                                            </th>
                                        )
                                    })
                                })}
                            </tr>
                        </thead>
                    </Table>
                    <Table size="sm" id="table" className="table table-bordered table-condensed ">
                        <tbody>
                            {this.props.studentReports.map((studentReport) => {
                                return (
                                    <tr key={studentReport.student.id}>
                                        {this.props.showSideHeader ? (
                                            <td className="sticky-left-header offset-studentName text-left align-middle pl-2">
                                                <Link
                                                    to={`/student/profile/${studentReport.student.id}`}
                                                >
                                                    {studentReport.student.firstName}{' '}
                                                    {studentReport.student.lastName}
                                                </Link>
                                            </td>
                                        ) : null}
                                        {studentReport.studentStatements.map((studentStatement) => {
                                            let statement = this.getStatement(
                                                studentStatement.statementId,
                                                this.props.statements
                                            )

                                            return (
                                                <td
                                                    className="report-column-cell text-center align-middle"
                                                    key={studentStatement.statementId}
                                                    data-toggle="tooltip"
                                                    title={`${statement.code} - ${statement.name}`}
                                                >
                                                    {this.getMastery(studentStatement)}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
                <div id="scrollSpaceDiv"></div>
            </div>
        )
    }
}
export default ClassReport
