import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { withOktaAuth } from '@okta/okta-react'
import { AuthService, AuthState } from '../../../models/Auth'
import { TeacherAccessLevel, TeacherDto } from '../../../models/Teacher'
import { Alert, Button } from 'reactstrap'
import { getTeacherApi, updateTeacher } from '../../../services/TeacherApi'

import { FiHelpCircle } from 'react-icons/fi'
import BaseClass from '../../Bases/BaseClass'
import BreadCrumbs from '../../layout/BreadCrumbs'
import { isTeacherAdminOrSuper } from '../../../shared/Helper'
import { IBaseState } from '../../Bases/BaseState'
import { ISessionData } from '../../../AppRouter'

interface IParams {
    schoolId: string
    teacherId: string
}

interface IProps extends RouteComponentProps<IParams> {
    //  baseUrl: string;
    authService: AuthService
    authState: AuthState
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
    history: any
    match: any
}

interface IState extends IBaseState {
    teacher: TeacherDto
    isDataLoading: boolean
}

class TeacherEdit extends BaseClass<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            isLoading: false,
            error: '',
            visible: false,
            //  authenticatedTeacher: null,
            teacher: {
                id: '',
                firstname: '',
                lastname: '',
                username: '',
                schoolId: '',
                email: '',
                accessLevel: TeacherAccessLevel.Standard.toString(),
                schoolName: '',
                isDemo: false,
                isExtendedDemo: false,
                demoExpiryInSecs: 0,
                demoOpportunityId: '',
                isFirstLogon: false,
                teacherSettings: null,
            },
            isDataLoading: false,
        }
    }

    componentDidMount() {
        this.loadData()
    }

    async loadData() {
        this.props.setIsDataLoading(true)

        await this.getTeacher(this.props.match.params.teacherId)
        this.props.setIsDataLoading(false)
        this.props.setIsDataLoading(false)
    }

    async getTeacher(teacherId: string) {
        return getTeacherApi(teacherId, this.props.authState.accessToken.accessToken)
            .then((res) => {
                if (res.ok) {
                    this.setState({
                        teacher: res.data,
                    })
                } else {
                    this.handleknownError(res.error)
                }
            })
            .catch((error) => {
                this.handleUnknownError(error)
            })
    }

    handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            teacher: {
                ...this.state.teacher,
                [e.target.id]: e.target.value,
            },
        })
    }

    onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (
            !this.state.teacher.firstname ||
            !this.state.teacher.lastname ||
            !this.state.teacher.email
        )
            return
        let token = await this.props.authState.accessToken.accessToken
        this.props.setIsDataLoading(true)
        return updateTeacher(this.props.match.params.teacherId, this.state.teacher, token)
            .then((res) => {
                if (res.ok) {
                    this.props.setIsDataLoading(false)
                    this.goBack()
                } else {
                    this.handleknownError(res.error)
                }
            })
            .catch((error) => {
                this.handleUnknownError(error)
            })
    }

    goBack = () => {
        this.props.history.push(`/admin/teacher/${this.props.sessionData.selectedSchool.id}`)
    }

    render() {
        //	// if (this.props.isDataLoading) return null;

        return (
            <div>
                <div className="mb-2">
                    <BreadCrumbs
                        items={[
                            { title: 'Home', link: '/' },
                            {
                                title: 'Admin',
                                link: `/admin/select/${this.props.sessionData.selectedSchool.id}`,
                            },
                            {
                                title: 'Teacher',
                                link: `/admin/teacher/${this.props.sessionData.selectedSchool.id}`,
                            },
                            { title: 'Edit Teacher', link: '' },
                        ]}
                    />
                </div>
                <h2 className="pb-2">Edit Teacher</h2>
                {this.state.visible ? (
                    <Alert color="warning">
                        <p>{this.state.error}</p>
                    </Alert>
                ) : null}
                <div className="row">
                    <div className="col-md-4">
                        <form onSubmit={this.onSubmit}>
                            <div className="form-group">
                                <label htmlFor="firstname">First Name: </label>
                                <input
                                    type="text"
                                    id="firstname"
                                    className="form-control"
                                    value={this.state.teacher.firstname}
                                    onChange={this.handleOnChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastname">Last Name: </label>
                                <input
                                    type="text"
                                    id="lastname"
                                    className="form-control"
                                    value={this.state.teacher.lastname}
                                    onChange={this.handleOnChange}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="email">Email: </label>
                                <input
                                    readOnly
                                    type="text"
                                    id="email"
                                    className="form-control"
                                    value={this.state.teacher.email}
                                    onChange={this.handleOnChange}
                                />
                            </div>
                            <div className="form-group">
                                <div
                                    className="custom-control mb-4"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Teachers with Admin access can create new teachers, classes, and purchase new licences"
                                >
                                    <input
                                        type="checkbox"
                                        checked={isTeacherAdminOrSuper(
                                            this.state.teacher.accessLevel
                                        )}
                                        className="form-check-input"
                                        id="admin"
                                        onChange={(e) =>
                                            this.setState({
                                                teacher: {
                                                    accessLevel: e.target.checked
                                                        ? TeacherAccessLevel[
                                                              TeacherAccessLevel.Admin
                                                          ].toString()
                                                        : TeacherAccessLevel[
                                                              TeacherAccessLevel.Standard
                                                          ].toString(),
                                                    firstname: this.state.teacher.firstname,
                                                    lastname: this.state.teacher.lastname,
                                                    email: this.state.teacher.email,
                                                    id: this.state.teacher.id,
                                                    username: this.state.teacher.username,
                                                    schoolId: this.state.teacher.schoolId,
                                                    schoolName: '',
                                                    isDemo: false,
                                                    isExtendedDemo: false,
                                                    demoExpiryInSecs: 0,
                                                    demoOpportunityId: '',
                                                    isFirstLogon: false,
                                                    teacherSettings: null,
                                                },
                                            })
                                        }
                                    />
                                    <label className="form-check-label" htmlFor="admin">
                                        {' '}
                                    </label>
                                    Give Admin access <FiHelpCircle />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="space-between">
                                    <Button
                                        color="secondary"
                                        tag={Link}
                                        to={`/admin/teacher/${this.props.sessionData.selectedSchool.id}`}
                                        disabled={this.state.isLoading}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        color="primary"
                                        type="submit"
                                        disabled={this.state.isLoading}
                                    >
                                        {this.state.isLoading ? (
                                            <i className="fa fa-spinner fa-spin" />
                                        ) : null}
                                        Update
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default withOktaAuth(TeacherEdit)
