/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { convertUtcToLocalDate, demoClassGuid } from '../../shared/Helper'
import { getKeyConceptsFromApi, getSamplingAssessmentsFromApi } from '../../services/CurriculumApi'
import { stopStudentDiagnosticApi } from '../../services/DiagnosticApi'
import {
    StudentDiagnosticDto,
    StudentDiagnosticUpdateDto,
    DiagnosticEstimateDto,
} from '../../models/Diagnostic'
import { Button, Table, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { KeyConceptDto } from '../../models/KeyConcept'
import { SamplingAssessmentDto } from '../../models/SamplingAssessment'
import { SchoolClassDto } from '../../models/SchoolClass'
import {
    getSamplingAsssesmentsFromLocalStorage,
    setSamplingAsssesmentsToLocalStorage,
} from '../../shared/LocalStorageHelpers'
import $ from 'jquery'
import { ISessionData } from '../../AppRouter'

export enum DiagnosticDetailsTableDisplayType {
    DiagnosticDetails,
    StudentProfile,
}

interface IProps {
    studentDiagnosticList: any
    authState: any
    displayType: DiagnosticDetailsTableDisplayType
    refresh: any
    parentPage: string
    sessionData: ISessionData
}

interface IState {
    studentDiagnosticList: Array<StudentDiagnosticDto>
    isLoading: boolean
    error: string
    visible: boolean
    modal: boolean
    firstName: string
    lastName: string
    diagnosticId: string
    studentId: string
    title: string
    expandKeyConceptView: boolean
    isCalculatingTimes: boolean
    diagnosticEstimateDto: DiagnosticEstimateDto | null
    //  keyConcepts: KeyConceptDto[]
    samplingAssessments: SamplingAssessmentDto[]
    refreshLabel: string
}

class DiagnosticDetailsTable extends Component<IProps, IState> {
    //
    constructor(props: IProps) {
        super(props)
        this.state = {
            studentDiagnosticList: Array<StudentDiagnosticDto>(),
            isLoading: true,
            error: '',
            visible: false,
            modal: false,
            firstName: '',
            lastName: '',
            diagnosticId: '',
            studentId: '',
            title: '',
            expandKeyConceptView: false,
            isCalculatingTimes: false,
            diagnosticEstimateDto: null,
            samplingAssessments: [],
            refreshLabel: '',
        }
    }

    async componentDidMount() {
        await this.getSamplingAssessments()
        this.setState({
            studentDiagnosticList: this.props.studentDiagnosticList,
            refreshLabel: `Last refresh - ${new Date().toLocaleTimeString()}`,
        })

        // this.autoRefresh = setInterval(() => {
        //   this.props.refresh();
        // }, 60000);
    }

    autoRefresh: any

    componentWillUnmount() {
        this.autoRefresh && clearInterval(this.autoRefresh)
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.studentDiagnosticList !== this.props.studentDiagnosticList) {
            this.setState({
                studentDiagnosticList: this.props.studentDiagnosticList,
                refreshLabel: `Last refresh - ${new Date().toLocaleTimeString()}`,
            })
        }
    }

    // getKeyConcepts = async () => {
    //     let token = await this.props.authState.accessToken.accessToken
    //     return getKeyConceptsFromApi(token)
    //         .then((res) => {
    //             if (res.ok) {
    //                 this.setState({
    //                     keyConcepts: res.data,
    //                 })
    //             }
    //         })
    //         .catch((error) => {})
    // }

    getSamplingAssessments = async () => {
        let samplingAssessments = getSamplingAsssesmentsFromLocalStorage()

        if (samplingAssessments && samplingAssessments.length > 0) {
            this.setState({
                samplingAssessments,
            })

            return samplingAssessments
        }

        let token = await this.props.authState.accessToken.accessToken

        return getSamplingAssessmentsFromApi(token)
            .then((res) => {
                //  this.setIsDataLoading(false);

                if (res.ok) {
                    this.setState({
                        samplingAssessments: res.data,
                    })

                    setSamplingAsssesmentsToLocalStorage(res.data)
                } else {
                    //   this.handleknownError(res.errors);
                }
            })
            .catch((error) => {
                //  this.handleUnknownError(error);
            })
    }

    setShowLoginDetails(studentId: string, showLoginDetails: boolean) {
        let studentDiagnostics = this.state.studentDiagnosticList
        let s = studentDiagnostics.find((v) => v.studentId === studentId)

        s.showLogInDetails = showLoginDetails
        this.setState({ studentDiagnosticList: studentDiagnostics })
    }

    stopDiagnostic = async (diagnosticId: string, studentId: string) => {
        let token = await this.props.authState.accessToken.accessToken

        this.setState({
            modal: false,
        })

        //this.setIsDataLoading(true);
        let updateStudentDiagnostic: StudentDiagnosticUpdateDto = {
            diagnosticId,
            studentId,
        }

        return stopStudentDiagnosticApi(updateStudentDiagnostic, token).then((res) => {
            //  this.setIsDataLoading(false);

            if (res.ok) {
                // const copyOfStudentDiagnosticList = [...this.state.studentDiagnosticList]
                // const index = copyOfStudentDiagnosticList.findIndex(
                //     (st) => st.studentId === updateStudentDiagnostic.studentId
                // )

                // if (index !== -1) {
                //     copyOfStudentDiagnosticList[index].finishedDate = new Date().toUTCString()
                // }

                // this.setState({
                //     studentDiagnosticList: copyOfStudentDiagnosticList,
                // })

                this.props.refresh()
            } else {
                console.log(res.error)
                // this.handleknownError(res.errors);
            }
        })
        // .catch((error) => {
        //  // this.handleUnknownError(error);
        // });
    }

    toggle = () => {
        this.setState((prevState) => ({
            modal: !prevState.modal,
        }))
    }

    showModal = (studentDiagnostic: StudentDiagnosticDto) => {
        this.setState((prevState) => ({
            diagnosticId: studentDiagnostic.diagnosticId,
            studentId: studentDiagnostic.studentId,
            firstName: studentDiagnostic.firstName,
            lastName: studentDiagnostic.lastName,
            modal: !prevState.modal,
        }))
    }

    getStatus = (studentDiagnostic: StudentDiagnosticDto) => {
        if (
            !studentDiagnostic.isStudentLicenced &&
            this.props.displayType === DiagnosticDetailsTableDisplayType.DiagnosticDetails
        ) {
            return <span className="text-danger font-weight-bold ">Not Active</span>
        }

        if (studentDiagnostic.stoppedByTeacherId) {
            return <span className="text-success font-weight-bold">Stopped by teacher</span>
        }

        if (studentDiagnostic.finishedDate) {
            return <span className="text-success font-weight-bold">Completed by student</span>
        }

        if (studentDiagnostic.startedDate) {
            if (studentDiagnostic.startedOnlineTest) {
                return <span className="text-warning font-weight-bold">Digital in progress</span>
            }

            // return <span className="text-warning">Begin digital questions: {studentDiagnostic.username}/{studentDiagnostic.password} </span>;
            if (studentDiagnostic.showLogInDetails) {
                return (
                    <span className="text-primary font-weight-bold">
                        Begin digital questions<br></br>
                        <span className="text-danger font-weight-normal">
                            {' '}
                            user: {studentDiagnostic.username}
                            <br></br>pass: {studentDiagnostic.password}
                        </span>
                    </span>
                )
            }

            return (
                <span className="text-primary font-weight-bold">
                    Begin digital questions<br></br>
                    <Button
                        className="p-0 text-primary"
                        style={{ fontSize: 'small' }}
                        color="link"
                        onClick={() => {
                            this.setShowLoginDetails(studentDiagnostic.studentId, true)
                        }}
                    >
                        Log in details
                    </Button>
                </span>
            )
        }

        return <span className="text-success font-weight-bold">Begin Interview</span>
    }

    getAnswerActions = (studentDiagnostic: StudentDiagnosticDto) => {
        if (studentDiagnostic.startedDate) {
            if (studentDiagnostic.startedOnlineTest) {
                return (
                    <Button
                        // size="sm"
                        style={{ width: '130px' }}
                        color="primary"
                        tag={Link}
                        to={`/assessment/results/${studentDiagnostic.diagnosticId}/${studentDiagnostic.studentId}/${this.props.parentPage}`}
                    >
                        View digital answers
                    </Button>
                )
            }
        }
    }

    getInterviewActions = (studentDiagnostic: StudentDiagnosticDto) => {
        if (studentDiagnostic && studentDiagnostic.hasInterviewQuestion) {
            if (studentDiagnostic.finishedDate || studentDiagnostic.startedDate) {
                return (
                    <Button
                        // size="sm"

                        style={{ width: '130px' }}
                        // color="info"
                        color="success"
                        tag={Link}
                        to={`/assessment/details/${studentDiagnostic.diagnosticId}/${studentDiagnostic.studentId}/${this.props.parentPage}`}
                    >
                        View interview answers
                    </Button>
                )
            }

            return this.props.displayType ===
                DiagnosticDetailsTableDisplayType.DiagnosticDetails ? (
                studentDiagnostic.isStudentLicenced ? (
                    <Button
                        // size="sm"
                        style={{ width: '130px' }}
                        color="success"
                        tag={Link}
                        to={
                            `/assessment/details/${studentDiagnostic.diagnosticId}/${studentDiagnostic.studentId}/` +
                            'assessment-details'
                        }
                    >
                        Start interview
                    </Button>
                ) : (
                    <span className="text-danger">Go to Students page to activate.</span>
                )
            ) : null
        }

        return null
        // return (
        //   (studentDiagnostic.isStudentLicenced) ? (
        //     <mark>No interview questions</mark>) :
        //     (
        //       <span className="text-danger">Go to Students page to activate.</span>
        //     )
        // );
    }

    getStopActions = (studentDiagnostic: StudentDiagnosticDto) => {
        if (studentDiagnostic) {
            if (!studentDiagnostic.finishedDate) {
                return (
                    <Button
                        size="sm"
                        color="danger"
                        onClick={() => this.showModal(studentDiagnostic)}
                        disabled={
                            this.props.sessionData.selectedSchoolClass &&
                            this.props.sessionData.selectedSchoolClass.id === demoClassGuid
                        }
                    >
                        Stop assessment
                    </Button>
                )
            }
        }

        return null
    }

    getFullName = (studentDiagnostic: StudentDiagnosticDto) => {
        return `${studentDiagnostic.firstName} ${studentDiagnostic.lastName}`
    }

    getAssessmentName = (studentDiagnostic: StudentDiagnosticDto) => {
        return (
            <Link to={`/assessment/details/${studentDiagnostic.diagnosticId}/`}>
                {studentDiagnostic.diagnosticName}
            </Link>
        )
    }

    getDateStarted = (studentDiagnostic: StudentDiagnosticDto) => {
        return convertUtcToLocalDate(studentDiagnostic.startedDate)
    }

    getDateFinished = (studentDiagnostic: StudentDiagnosticDto) => {
        return convertUtcToLocalDate(studentDiagnostic.finishedDate)
    }

    getContentAssessed = (studentDiagnostic: StudentDiagnosticDto) => {
        let keyConcepts = this.getKeyConceptsName(studentDiagnostic)
        let samplingAssessments = this.getSamplingAssessmentNames(studentDiagnostic)

        if (keyConcepts && samplingAssessments) {
            return `${keyConcepts} ${samplingAssessments}`
        }

        if (keyConcepts) {
            return keyConcepts
        }

        if (samplingAssessments) {
            return samplingAssessments
        }
    }

    getKeyConceptsName = (studentDiagnostic: StudentDiagnosticDto) => {
        let kcJointedString = ''

        studentDiagnostic.keyConceptIds.forEach((kcId, index) => {
            const kcIndex = this.props.sessionData.keyConcepts.findIndex((id) => id.id === kcId)

            if (kcIndex !== -1) {
                kcJointedString += this.props.sessionData.keyConcepts[kcIndex].name
                kcJointedString += ', '
            }
        })

        kcJointedString = this.trimCommaFromEnd(kcJointedString)

        if (kcJointedString.length > 40 && !this.state.expandKeyConceptView) {
            return `${kcJointedString.substring(0, 40)}...`
        }

        return kcJointedString
    }

    getSamplingAssessmentNames = (studentDiagnostic: StudentDiagnosticDto) => {
        let kcJointedString = ''

        studentDiagnostic.samplingAssessmentIds.forEach((kcId, index) => {
            const kcIndex = this.state.samplingAssessments.findIndex((id) => id.id === kcId)

            if (kcIndex !== -1) {
                kcJointedString += this.state.samplingAssessments[kcIndex].name
                kcJointedString += ', '
            }
        })

        kcJointedString = this.trimCommaFromEnd(kcJointedString)

        if (kcJointedString.length > 40 && !this.state.expandKeyConceptView) {
            return `${kcJointedString.substring(0, 40)}...`
        }

        return kcJointedString
    }

    getKeyConceptsTooltip = (studentDiagnostic: StudentDiagnosticDto) => {
        let kcJointedString = ''

        studentDiagnostic.keyConceptIds.forEach((kcId, index) => {
            const kcIndex = this.props.sessionData.keyConcepts.findIndex((id) => id.id === kcId)

            if (kcIndex !== -1) {
                kcJointedString += `${index + 1}. ${
                    this.props.sessionData.keyConcepts[kcIndex].name
                }`
                if (index < studentDiagnostic.keyConceptIds.length - 1) {
                    kcJointedString += '\n'
                }
            }
        })

        return kcJointedString
    }

    expandView = () => {
        this.setState({
            expandKeyConceptView: !this.state.expandKeyConceptView,
        })
    }

    private trimCommaFromEnd(kcJointedString: string) {
        kcJointedString = kcJointedString.trim()
        if (kcJointedString.endsWith(',')) {
            kcJointedString = kcJointedString.substring(0, kcJointedString.length - 1)
        }

        return kcJointedString
    }

    getEstimate(studentDiagnostic: StudentDiagnosticDto): React.ReactNode {
        return studentDiagnostic.timeFormatted ? studentDiagnostic.timeFormatted : '... mins'
    }

    isEntireDiagnosticFinished() {
        return !this.state.studentDiagnosticList.find((v) => !v.finishedDate)
    }

    render() {
        return !this.props.studentDiagnosticList ||
            this.props.studentDiagnosticList.length === 0 ? (
            <div className="center">
                {/* <Spinner style={{ width: "3rem", height: "3rem" }} /> */}
            </div>
        ) : (
            <>
                <div className="space-between">
                    <div></div>
                    <Button
                        id="diagnostic-details-refresh"
                        color="link"
                        onClick={() => {
                            this.props.refresh()
                        }}
                    >
                        {this.state.refreshLabel}{' '}
                    </Button>
                </div>

                <Table bordered striped className="bg-white mb-0">
                    <thead>{this.getTableRowHeader()}</thead>
                    <tbody>
                        {this.state.studentDiagnosticList.map((studentDiagnostic, key) => {
                            switch (this.props.displayType) {
                                case DiagnosticDetailsTableDisplayType.DiagnosticDetails: {
                                    return (
                                        <tr key={key}>
                                            <td className="align-middle">
                                                {this.getFullName(studentDiagnostic)}
                                            </td>
                                            <td
                                                className="align-middle"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title={
                                                    this.getKeyConceptsTooltip(studentDiagnostic)
                                                        .length > 0
                                                        ? this.getKeyConceptsTooltip(
                                                              studentDiagnostic
                                                          )
                                                        : null
                                                }
                                            >
                                                {this.getContentAssessed(studentDiagnostic)}
                                            </td>
                                            <td className="align-middle">
                                                <div className="d-flex justify-content-between">
                                                    {this.getInterviewActions(studentDiagnostic)}
                                                    &nbsp;
                                                    {this.getAnswerActions(studentDiagnostic)}
                                                </div>
                                            </td>
                                            <td className="align-middle text-nowrap">
                                                {this.getEstimate(studentDiagnostic)}
                                            </td>
                                            <td className="align-middle">
                                                {this.getStatus(studentDiagnostic)}
                                            </td>
                                            <td className="align-middle">
                                                {this.getStopActions(studentDiagnostic)}
                                            </td>
                                        </tr>
                                    )
                                }
                                case DiagnosticDetailsTableDisplayType.StudentProfile: {
                                    return (
                                        <tr key={key}>
                                            <td className="align-middle  font-weight-bold ">
                                                {this.getAssessmentName(studentDiagnostic)}
                                            </td>
                                            <td
                                                className="align-middle"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title={
                                                    this.getKeyConceptsTooltip(studentDiagnostic)
                                                        .length > 0
                                                        ? this.getKeyConceptsTooltip(
                                                              studentDiagnostic
                                                          )
                                                        : null
                                                }
                                            >
                                                {this.getContentAssessed(studentDiagnostic)}
                                            </td>

                                            <td className="align-middle">
                                                {studentDiagnostic.schoolClassFormattedName}
                                            </td>

                                            <td className="align-middle">
                                                <div className="d-flex justify-content-between">
                                                    {this.getInterviewActions(studentDiagnostic)}
                                                    &nbsp;
                                                    {this.getAnswerActions(studentDiagnostic)}
                                                </div>
                                            </td>

                                            <td
                                                className="align-middle"
                                                style={{ paddingBottom: 0 }}
                                            >
                                                {this.getStatus(studentDiagnostic)}
                                            </td>
                                            {/* <td className="align-middle">
                          {this.getStopActions(studentDiagnostic)}
                        </td> */}
                                            {/* <td className="align-middle">
                          {this.getDateStarted(studentDiagnostic)}
                        </td> */}
                                            <td className="align-middle">
                                                {this.getDateFinished(studentDiagnostic)}
                                            </td>
                                        </tr>
                                    )
                                }
                                default:
                                    console.log('DiagnosticDetailsTableDisplayType not found')
                            }

                            return null
                        })}
                    </tbody>
                </Table>

                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Stop Assessment</ModalHeader>
                    <ModalBody>
                        Are you sure you want to manually stop {this.state.firstName}{' '}
                        {this.state.lastName}&apos;s assessment?
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="danger"
                            onClick={() =>
                                this.stopDiagnostic(this.state.diagnosticId, this.state.studentId)
                            }
                        >
                            Stop
                        </Button>
                        <Button color="secondary" onClick={this.toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }

    getTableRowHeader(): React.ReactNode {
        switch (this.props.displayType) {
            case DiagnosticDetailsTableDisplayType.DiagnosticDetails: {
                return (
                    <tr>
                        <th className="align-top" style={{ width: '15%' }}>
                            Full Name
                        </th>
                        <th className="align-top" style={{ width: '20%' }}>
                            Content Assessed
                            <br></br>
                            <Button className="pl-0" color="link" onClick={this.expandView}>
                                {this.state.expandKeyConceptView ? 'Show less' : 'Show more'}
                            </Button>
                        </th>
                        <th className="align-top" style={{ width: '25%' }}>
                            Actions
                        </th>
                        <th className="align-top" style={{ width: '11%' }}>
                            Estimated Time Remaining
                        </th>
                        <th className="align-top" style={{ width: '19%' }}>
                            Status
                        </th>
                        <th id="actions" className="align-top" style={{ width: '15%' }}>
                            Stop Assessment
                        </th>
                    </tr>
                )
            }
            case DiagnosticDetailsTableDisplayType.StudentProfile: {
                return (
                    <tr>
                        <th className="align-top" style={{ width: '15%' }}>
                            Name
                        </th>
                        <th className="align-top" style={{ width: '20%' }}>
                            Content Assessed
                            <br></br>
                            <Button className="pl-0" color="link" onClick={this.expandView}>
                                {this.state.expandKeyConceptView ? 'Show less' : 'Show more'}
                            </Button>
                        </th>
                        <th className="align-top" style={{ width: '15%' }}>
                            Class Name
                        </th>
                        <th className="align-top" style={{ width: '25%' }}>
                            Actions
                        </th>

                        <th className="align-top" style={{ width: '16%' }}>
                            Status
                        </th>
                        {/* <th id="actions" className="align-top" style={{ width: "15%" }}>
              Stop Assessment
        </th>
            <th id="actions" className="align-top" style={{ width: "15%" }}>
              Started
        </th> */}
                        <th id="actions" className="align-top" style={{ width: '15%' }}>
                            Finished
                        </th>
                    </tr>
                )
            }
            default:
                console.log('DiagnosticDetailsTableDisplayType type not found')
        }
    }
}
export default DiagnosticDetailsTable
