import React from 'react'
import logo from '../../assets/images/EarlyInsights_CMYK.svg'

export default function DemoComplete() {
    return (
        <div data-cy="trial-complete-page-loaded">
            <div className="container ">
                <div className="py-3 text-center ">
                    <img
                        className="mx-auto d-block logo "
                        alt="logo"
                        style={{ width: '100%' }}
                        src={logo}
                    ></img>
                </div>
                <div className="py-3 text-center ">
                    <h2>Trial Setup Complete</h2>
                    <div>
                        <p>
                            <br></br>
                            Thank you for completing your Early Insights Trial Setup!
                            <br></br>
                            <>
                                You will receive an email with your account activation details in
                                the next two hours. Sometimes these emails can get stuck in your
                                trash folder, but if it doesn&apos;t arrive please email us at{' '}
                                <a href="mailto:primary@mathspathway.com">
                                    primary@mathspathway.com
                                </a>
                                .
                            </>
                        </p>
                    </div>
                </div>
                <div className=" text-center "></div>
            </div>
        </div>
    )
}
