import React, { Component } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import config from './app.config'
import './index.css'
import { AppRouter } from './AppRouter'

const stripePromise = loadStripe(config.stripePublicKey)

class App extends Component {
    componentDidMount() {
      //  document.getElementById('loading').hidden = true
    }

    render() {
        return (
            <Elements stripe={stripePromise}>
                <Router>
                    <AppRouter test={true}/>
                </Router>
            </Elements>
        )
    }
}

// withAITracking sets screen height to not 100% need to add some custom css to force back to 100%
//export default withAITracking(reactPlugin, App,'test','root');
export default App
