import React from 'react'
import { Button } from 'reactstrap'
import { SchoolClassDto } from '../../models/SchoolClass'
import { GetShorterName } from '../../shared/Helper'
import './NavMenu.css'
import logo_mini from '../../assets/images/EarlyInsights_White_vertical.svg'
import './Demo.css'
import BaseClass from '../Bases/BaseClass'
import '../../../node_modules/react-component-countdown-timer/lib/styles.css'
import CountdownTimer from 'react-component-countdown-timer'
import { AuthService, AuthState } from '../../models/Auth'
import { RouteComponentProps } from 'react-router'
import { SalesForceActionEnum } from '../../models/Order'
import { SaveSalesForceActionApi } from '../../services/OrderApi'
import { ISessionData } from '../../AppRouter'

interface IProps extends RouteComponentProps {
    authService: AuthService
    authState: AuthState
    timerInSecs: any
    onChangeDemoViewClick: any
    sessionData: ISessionData
    history: any
    //	setIsDataLoading: any; isDataLoading:boolean;
}

interface NavMenuState {
    error: string
    visible: boolean
    isLoading: boolean
    collapsed: boolean
    authenticated: boolean
    dropdownOpen: boolean
    schoolClasses: SchoolClassDto[]
    selectedSchoolClass: SchoolClassDto
    //teacher: TeacherDto | null;
    isPopoverOpen: boolean
    isDataLoading: boolean
}

class DemoNavMenu extends BaseClass<IProps, NavMenuState> {
    defaultSelectedClass: SchoolClassDto = {
        id: '',
        userId: '',
        name: '',
        levels: [''],
        teachers: [],
        schoolId: '',
        calendarYear: 2020,
    }

    constructor(props: IProps) {
        super(props)
        this.state = {
            error: '',
            visible: false,
            isLoading: false,
            collapsed: true,
            authenticated: false,
            dropdownOpen: false,
            schoolClasses: [],
            selectedSchoolClass: this.defaultSelectedClass,
            isPopoverOpen: false,
            isDataLoading: true,

            //  navMenuDisabled :this.context.navMenuDisabled
        }
    }

    updateNav = async () => {
        await this.loadData()
    }

    togglePopover = () => {
        // this.setState({ isOpen: !this.state.isOpen });
    }

    async componentDidMount() {}

    async componentDidUpdate() {}

    async loadData() {}

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
        })
    }

    toggleDropdown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        })
    }

    async login() {
        this.props.authService.login('/')
    }

    selectSchoolClass(schoolClass: SchoolClassDto) {
        //TODO: fix this
        // setSelectedSchoolClassToLocalStorage(schoolClass);
        // if (this.state.selectedSchoolClass.id !== schoolClass.id) {
        //   this.setState({ selectedSchoolClass: schoolClass });
        //   this.props.onNavSchoolClassChanged(schoolClass.id);
        // }
    }

    formatSchoolClassName(schoolClassName: string) {
        if (schoolClassName.length > 20) {
            return `${GetShorterName(schoolClassName, 20)}...`
        }

        return schoolClassName
    }

    saveDemoAction(demoAction: SalesForceActionEnum) {
        let teacher = this.props.sessionData.authenticatedTeacher
        if (!teacher || !teacher.isDemo || !teacher.demoOpportunityId) return
        let token = this.props.authState.accessToken.accessToken
        return SaveSalesForceActionApi(token, teacher.demoOpportunityId, demoAction)
            .then((res) => {
                if (res.ok) {
                    return res.data
                }
                //  this.handleknownError(res.errors);
            })
            .catch((error) => {
                console.log(error)
                // this.handleUnknownError(error);
            })
    }

    render() {
        return (
            <nav
                data-cy="demo-nav-loaded"
                className="navbar navbar-inverse"
                style={{ color: 'white', backgroundColor: 'rgb(237, 107, 9)' }}
            >
                <div className="container-fluid">
                    <div className="navbar-header">
                        <a className="navbar-brand" href="#">
                            <img
                                style={{ width: '80px' }}
                                alt="logo"
                                className="logo-mini"
                                src={logo_mini}
                            />
                        </a>
                        <Button
                            data-cy="see-the-data-button"
                            style={{ fontWeight: 'bold' }}
                            color="light"
                            onClick={() => {
                                this.props.onChangeDemoViewClick('teacher')
                            }}
                        >
                            See the data
                        </Button>{' '}
                        <Button
                            data-cy="log-in-as-student-button"
                            style={{ fontWeight: 'bold' }}
                            color="light"
                            onClick={async () => {
                                this.props.onChangeDemoViewClick('student')
                                await this.saveDemoAction(
                                    SalesForceActionEnum.Demo_try_as_student_clicked__c
                                )
                            }}
                        >
                            Log in as a student
                        </Button>
                    </div>
                    <div
                        style={{ display: 'flex', verticalAlign: 'middle' }}
                        className="align-middle"
                    >
                        <CountdownTimer
                            onEnd={() => {
                                // alert('Your demo has expired');
                                return this.props.history.push('/')

                                // return logout(this.props.authService);
                            }}
                            // className="align-middle" size={24} color='black' backgroundColor='rgb(248, 172, 115)' count={this.props.timerInSecs}>
                            // className="align-middle" size={24} color='white' backgroundColor='rgb(237, 107, 9)'   count={this.props.timerInSecs}>
                            className="countdown-timer-background"
                            size={22}
                            color="black"
                            backgroundColor="rgb(248, 172, 115)"
                            count={this.props.timerInSecs}
                        />
                        <Button
                            data-cy="buy-now-button"
                            onClick={async () => {
                                await this.props.onChangeDemoViewClick('teacher')
                                this.props.history.push('/admin/order')
                                await this.saveDemoAction(
                                    SalesForceActionEnum.Demo_buy_now_clicked__c
                                )
                            }}
                            style={{ fontWeight: 'bold' }}
                            className="ml-2"
                            color="primary"
                        >
                            Buy now
                        </Button>
                    </div>
                </div>
            </nav>
        )
    }
}

export default DemoNavMenu
