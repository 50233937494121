import axios from 'axios'
import { handleApiSuccess, handleApiError, IApiResult } from '../shared/ApiHandler'
import { curriculumApiUrl, teacherBaseApiUrl } from '../shared/ApiUrls'
import {
    getKeyConceptsFromLocalStorage,
    getStatementsFromLocalStorage,
    setKeyConceptsToLocalStorage,
    setStatementsToLocalStorage,
} from '../shared/LocalStorageHelpers'

export const getKeyConceptsFromApi = (token: string): Promise<IApiResult> => {
    // let keyconcepts = getKeyConceptsFromLocalStorage()

    // if (keyconcepts && keyconcepts.length > 0) {
    //   return Promise.resolve({
    //     ok: true,
    //     data: keyconcepts,
    //   })
    // }

    return axios
        .get(`${curriculumApiUrl}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then((res) => {
            // setKeyConceptsToLocalStorage(res.data)

            return handleApiSuccess(res)
        })
        .catch(handleApiError)
}

export const getStatementsFromApi = (token: string): Promise<IApiResult> => {
    // let statements = getStatementsFromLocalStorage()

    // if (statements && statements.length > 0) {
    //   return Promise.resolve({
    //     ok: true,
    //     data: statements,
    //   })
    // }

    return axios
        .get(`${curriculumApiUrl}/GetStatements`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then((res) => {
            //  setStatementsToLocalStorage(res.data)

            return handleApiSuccess(res)
        })
        .catch(handleApiError)
}

export const getSamplingAssessmentsFromApi = (token: string): Promise<IApiResult> => {
    return axios
        .get(`${curriculumApiUrl}/GetSamplingAssessments`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const getDiagnosticKeyConcepts = (token: string): Promise<IApiResult> => {
    let schoolClassLevel = 0
    let goldilocksLowBound = 0.2
    let goldilocksHighBound = 0.8

    return axios
        .get(
            `${curriculumApiUrl}/GetDiagnosticKeyConcepts/${schoolClassLevel}/${goldilocksLowBound}/${goldilocksHighBound}`,
            {
                headers: { Authorization: `bearer ${token}` },
                baseURL: teacherBaseApiUrl,
            }
        )
        .then(handleApiSuccess)
        .catch(handleApiError)
}
