/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import logo from '../../assets/images/EarlyInsights_CMYK.svg'
import './Order.css'
import CheckoutForm from './CheckoutForm'
import {
    AbandonCartFromApi,
    GetStripeIntent,
    CompleteStripeIntentOrderApi,
} from '../../services/OrderApi'
import { Alert } from 'reactstrap'
import { OrderType } from './OrderComplete'
import { FiHelpCircle } from 'react-icons/fi'
import { FullPageSpinner } from '../layout/FullPageSpinner'
import ReactPixel from 'react-facebook-pixel'
import { CreateOrderValidationEnum } from './Order'
import { Beforeunload } from 'react-beforeunload'

interface IParams {
    intentId: string
    validForOrderStatus: string
}

interface IProps extends RouteComponentProps<IParams> {
    inPortal: boolean
    setIsDataLoading: any
    isDataLoading: boolean
    match: any
    history: any
}


export default function Payment(props: IProps) {
    const [quantity, setQuantity] = useState('')
    const [error, setError] = useState('')
    const [visible, setVisible] = useState(false)
    const [creditCard, setCreditCard] = useState(true)
    const [useCustomSchool, setUseCustomSchool] = useState(false)
    const [intentSecret, setIntentSecret] = useState(null)
    const [poRef, setPoRef] = useState('')
    const [price, setPrice] = useState(0)
    const [priceAfterTax, setPriceAfterTax] = useState(0)
    const [currency, setCurrency] = useState('aud')
    const [opportunityId, setOpportunityId] = useState(null)
    const checkboxTermsRef = useRef()
    const [screenLoaderMessages, setScreenLoaderMessages] = useState([])

    useEffect(() => {
        // override background colour
        if (!props.inPortal) {
            document.querySelector('html').style.backgroundColor = 'white'
            document.getElementById('root').style.backgroundColor = 'white'

            // ReactPixel.init('356309674824876');
            ReactPixel.pageView() // For tracking page view
            ReactPixel.track('AddPaymentInfo') // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
        }

        props.setIsDataLoading(true)
        GetStripeIntent(props.match.params.intentId)
            .then((res) => {
                if (res.ok) {
                    let intent = res.data
                    let priceObj = intent.amount / 100
                    props.setIsDataLoading(false)
                    setIntentSecret(intent.client_secret)
                    setPrice(intent.metadata.amountExGst)
                    setPriceAfterTax(priceObj)
                    setQuantity(intent.metadata.quantity)
                    setCurrency(intent.currency)
                    setUseCustomSchool(intent.metadata.useCustomSchool === 'True')
                    setOpportunityId(intent.metadata.opportunityId)
                } else {
                    props.setIsDataLoading(false)
                    setVisible(true)
                    setError(res.error.detail)
                }
            })
            .catch((error) => {
                props.setIsDataLoading(false)
                setVisible(true)
                setError(error.statusText)
            })
    }, [])

    const getOrderType = (status: CreateOrderValidationEnum, isPurchaseOrder, inPortal, isDemo) => {
        if (isDemo) {
            if (isPurchaseOrder) {
                if (status === CreateOrderValidationEnum.SchoolDoesNotExist) {
                    return OrderType.demoPurchaseAndSchoolDoesNotExistAndPayingWithPO
                }

                return OrderType.demoPurchaseAndPayingWithPO
            }

            if (status === CreateOrderValidationEnum.SchoolDoesNotExist) {
                return OrderType.demoPurchaseAndSchoolDoesNotExistAndPayingWithCC
            }

            return OrderType.demoPurchaseAndPayingWithCC
        }

        if (inPortal) {
            if (isPurchaseOrder) {
                return OrderType.additionalLicencesPurchasedAndPayingWithPO
            }

            return OrderType.additionalLicencesPurchasedAndPayingWithCC
        }

        if (status === CreateOrderValidationEnum.SchoolDoesNotExist) {
            if (isPurchaseOrder) {
                return OrderType.newCustomerAndSchoolDoesNotExistAndPayingWithPO
            }

            return OrderType.newCustomerAndSchoolDoesNotExistAndPayingWithCC
        }

        if (status === CreateOrderValidationEnum.NewCustomer) {
            if (isPurchaseOrder) {
                return OrderType.newCustomerAndPayingWithPO
            }

            return OrderType.newCustomerAndPayingWithCC
        }

        if (status === CreateOrderValidationEnum.NewTeacherAndExistingCustomer) {
            if (isPurchaseOrder) {
                return OrderType.newTeacherAndExistingCustomerAndPayingWithPO
            }

            return OrderType.newTeacherAndExistingCustomerAndPayingWithCC
        }

        if (status === CreateOrderValidationEnum.ExistingTeacherAndExistingCustomer) {
            if (isPurchaseOrder) {
                return OrderType.existingTeacherAndExistingCustomerAndPayingWithPO
            }

            return OrderType.existingTeacherAndExistingCustomerAndPayingWithCC
        }
    }

    const onSuccessfulPayment = (paymentIntent: any) => {
        completeStripeIntentOrder(paymentIntent.id, false, '')
    }

    const sendOrderCompleteToAnalytics = () => {
        ReactPixel.pageView() // For tracking page view
        ReactPixel.track('Purchase', {
            value: price,
            currency: currency.toUpperCase(),
        }) // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9

        try {
            let postObject = JSON.stringify({
                //This JSON can be altered to contain dynamic data or static data depending on requirements.
                type: 'Purchase',
                action: 'FormSub',
                revenue: price, // this is payment amount. You can exclude any taxes you don't want to track with revenue
                price: price / parseFloat(quantity), // this is price per 1 license, that user purchased
                quantity: parseInt(quantity), // number of licenses purchased
            })

            ;(window.parent as any).postMessage(postObject, 'https://mathspathway.com')
        } catch (e) {
            //(window.parent as any).console && (window.parent as any).console.log(e);
        }
    }

    const onError = (message: any) => {
        // go to success page and show payment and details
        props.setIsDataLoading(false)
        setVisible(true)
        setError(message)
    }

    const onPurchaseOrderClick = async (e: any) => {
        if (!(checkboxTermsRef.current as any).checked) {
            // return alert('You must agree to the terms and conditions');
            setVisible(true)
            setError('You must agree to the Terms and Conditions')

            return
        }

        props.setIsDataLoading(true)
        setScreenLoaderMessages(['Please wait while we process your order...'])
        completeStripeIntentOrder(props.match.params.intentId, true, poRef)
    }

    const completeStripeIntentOrder = (intentId, isPuchaseOrder, poRef) => {
        CompleteStripeIntentOrderApi(intentId, isPuchaseOrder, poRef)
            .then((res) => {
                if (res.ok) {
                    let isDemo = res.data.isDemo
                    let orderType = getOrderType(
                        parseInt(
                            props.match.params.validForOrderStatus
                        ) as CreateOrderValidationEnum,
                        isPuchaseOrder,
                        props.inPortal,
                        isDemo
                    )

                    sendOrderCompleteToAnalytics()
                    if (props.inPortal) {
                        // do redirect here so page fully reloads and gets new teacher object without trial
                        window.location.href = `/admin/order/complete/${parseInt(
                            orderType.toString()
                        )}`
                    } else {
                        props.history.push(`/order/complete/${parseInt(orderType.toString())}`)
                        props.setIsDataLoading(false)
                    }
                } else {
                    props.setIsDataLoading(false)
                    setVisible(true)
                    setError(res.error.detail)
                }
            })
            .catch((error) => {
                props.setIsDataLoading(false)
                setVisible(true)
                setError(error.statusText)
            })
    }

    const abandonCart = () => {
        if (!opportunityId) {
            return
        }

        AbandonCartFromApi(opportunityId)
            .then((res) => {
                if (res.ok) {
                    return
                }

                alert(res.error)
            })
            .catch((error) => {
                alert(error)
            })
    }

    //if (props.isDataLoading) return null;

    return (
        <div data-cy="payment-page-loaded" className="container ">
            <Beforeunload onBeforeunload={async () => await abandonCart()} />
            {/* {isLoading ? (
        <>
          <FullPageSpinner
            sentences={screenLoaderMessages}
          ></FullPageSpinner>
        </>
      ) : (
        <></>
      )} */}
            <div className="py-3 text-center ">
                {/* <img className="d-block mx-auto mb-4" src="https://getbootstrap.com/docs/4.0/assets/brand/bootstrap-solid.svg" alt="" width="72" height="72"></img> */}
                <img className="mx-auto d-block logo " alt="logo" src={logo}></img>
                {/* <h2>Checkout form</h2>
                <p className="lead">Below is an example form built entirely with Bootstrap's form controls. Each required form group has a validation state that can be triggered by attempting to submit the form without completing it.</p> */}
            </div>
            <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6 ">
                    <hr className="mb-3"></hr>
                    <h4 className="mb-3">Payment</h4>
                    {visible ? (
                        <Alert color="warning">
                            <p>{error}</p>
                        </Alert>
                    ) : null}
                    <div>
                        <div className="d-block my-3">
                            <div className="mb-1">
                                <div
                                    data-cy="credit-card-radio"
                                    className="custom-control custom-radio"
                                >
                                    <input
                                        id="credit"
                                        name="paymentMethod"
                                        type="radio"
                                        className="custom-control-input"
                                        checked={creditCard}
                                        onChange={(e) =>
                                            setCreditCard(e.currentTarget.value === 'on')
                                        }
                                    ></input>
                                    <label className="custom-control-label" htmlFor="credit">
                                        Credit card
                                    </label>
                                </div>
                            </div>
                            <div
                                data-cy="purchase-order-radio"
                                className="custom-control custom-radio"
                            >
                                <input
                                    id="po"
                                    name="paymentMethod"
                                    type="radio"
                                    className="custom-control-input"
                                    onChange={(e) => setCreditCard(e.currentTarget.value !== 'on')}
                                ></input>
                                <label className="custom-control-label" htmlFor="po">
                                    Purchase order
                                </label>
                            </div>
                        </div>
                    </div>
                    {creditCard ? (
                        <>
                            <div className="row">
                                <div className="col mb-3">
                                    <label htmlFor="cc-name">Name on card</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="cc-name"
                                        placeholder=""
                                        required
                                    ></input>
                                    <small className="text-muted">
                                        Full name as displayed on card
                                    </small>
                                    <div className="invalid-feedback">Name on card is required</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col mb-3">
                                    <div className="mb-3">Credit card</div>
                                    <CheckoutForm
                                        price={priceAfterTax}
                                        clientSecret={intentSecret}
                                        onSuccessfulPayment={onSuccessfulPayment}
                                        onError={onError}
                                        setIsDataLoading={(val) => {
                                            props.setIsDataLoading(val, [
                                                'Please wait while we process your order...',
                                            ])
                                        }}
                                        // onLoading={()=>{}}
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <hr className="mb-3"></hr>
                            <div className="row">
                                <div className="col">
                                    <div className="mb-3">
                                        <div className="mb-3">
                                            {useCustomSchool ? (
                                                <>
                                                    An invoice will be emailed to you shortly. Once
                                                    payment has been received, your account will be
                                                    activated and you can begin setting up your
                                                    teachers and classes.
                                                </>
                                            ) : (
                                                <>An invoice will be emailed to you shortly.</>
                                            )}
                                        </div>
                                        <div
                                            className="col-md-6 mb-3"
                                            // data-toggle="tooltip"
                                            // data-placement="top"
                                            title="Your school Purchase Order number or Internal Reference"
                                            // data-tooltip="Your school Purchase Order number or Internal Reference"
                                        >
                                            <div className="text-nowrap">
                                                <label htmlFor="PoRef">
                                                    Purchase Order Reference
                                                </label>{' '}
                                                <FiHelpCircle></FiHelpCircle>{' '}
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="PoRef"
                                                placeholder="123"
                                                onChange={(e) => setPoRef(e.target.value)}
                                            ></input>
                                        </div>
                                        <div className="form-group">
                                            <div className="custom-control mb-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="terms"
                                                    ref={checkboxTermsRef}
                                                ></input>
                                                <label className="form-check-label" htmlFor="terms">
                                                    {' '}
                                                </label>
                                                <a
                                                    href="https://tridentazurestorage.blob.core.windows.net/files/Terms_and_Conditions_-_Early_Insights_-_2020.pdf"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <u>I agree to Terms and Conditions</u>
                                                </a>
                                            </div>
                                        </div>
                                        <button
                                            className="btn btn-primary btn-lg btn-block"
                                            onClick={onPurchaseOrderClick}
                                        >
                                            Proceed with Purchase Order
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className="col-md-3"></div>
            </div>
        </div>
    )
}
